import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import {
  Form,
  Input,
  Button,
  Select,
  message,
  Upload,
  Spin,
  Modal,
  DatePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import moment from "moment";

import HeaderBar from "../Components/Header/HeaderBar";
import ProfileSideBar from "../Components/ProfileSideBar";
import "./ProfileStyles.css";
import crossGreen from "../svgs/cross-green.svg";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import { Images } from "../Themes";
import ProfileTopBarTabs from "../Components/Shared/ProfileTopBar";
import ProgressBar from "../Components/Shared/Progressbar";

import ProcessingPerson from "../Components/Client/ProcessingPerson";
import Connections from "../Components/Client/Connections";
import PersonalInformation from "../Components/Client/PersonalInformation";
import CurrentVisa from "../Components/Client/CurrentVisa";
import Medicals from "../Components/Client/Medicals";
import Passport from "../Components/Client/Passport";
import InzLogin from "../Components/Client/InzLogin";
import NZQADetail from "../Components/Client/NZQADetail";
import BillingAddress from "../Components/Client/BillingAddress";

import PersonalInformationAddPartner from "../Components/Client/PersonalInfoAddPartner";
import PassportAddPartner from "../Components/Client/PassportAddPartner";
import MedicalsAddPartner from "../Components/Client/MedicalsAddPartner";
import InzLoginAddPartner from "../Components/Client/InzLoginAddPartner";
import NZQADetailAddPartner from "../Components/Client/NZQADetailAddPartner";
import BillingAddressAddPartner from "../Components/Client/BillingAddressAddPartner";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { saveAs } from "file-saver";

import headOption from "../Components/Header/HeaderTabOptions";
import activityData from "../Components/ClientActivity/ActivityData";
import AddAutoReminder from "../Components/Reminder/AddAutoReminder";
import { CheckOutlined } from "@material-ui/icons";
import queryString from "query-string";
import { apiRefresh } from "../services/api";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const topBar = [
  { tabName: "CLIENT INFORMATION", linkName: "/profile" },
  { tabName: "EMPLOYER INFORMATION", linkName: "/employer" },
  { tabName: "JOB HISTORY", linkName: "/job-history" },
  { tabName: "OTHER INFO", linkName: "/client-other-info" },
];

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const dateFormat = "DD/MM/YYYY";

class FamilyMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      selectedOption: null,
      date: new Date(),
      signed: false,
      onshore: false,
      active: false,
      allowUpdate: false,
      areaAccess: false,
      updLoading: false,
      addPartner: false,
      addClientImageUrl: "",
      loadUploadImage: false,
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      loadPartner: true,
      clientFamilyMember: null,
      addOtherRelation: false,
      contentNotes: "",
      medicalNotes: "",
      headerOptions: [],
      signedModalVisible: false,
      users: [],
    };
    const initialQueryValue = queryString.parse(
      props.location && props.location.search
    );

    var clientIdParam =
      initialQueryValue && initialQueryValue.para
        ? initialQueryValue.para
        : null;
    var familyIdParam =
      initialQueryValue && initialQueryValue.fId ? initialQueryValue.fId : null;
    this.props.onSetActiveKey(null);
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let clientProfileIdMainLoc = sessionStorage.getItem("clientProfileIdMain");
    let getFamilyId = sessionStorage.getItem("familyId");
    let getFamilyIdLoc = sessionStorage.getItem("familyId");
    let clientId = props.location.state
      ? props.location.state.id
      : clientIdParam || clientProfileIdMain;
    let familyId = props.location.state
      ? props.location.state.familyId
      : familyIdParam || getFamilyId;

    let data = {
      clientId: clientId,
      familyId: familyId,
    };

    let selectedTab = {
      headName: "Family Details",
      headPath: "/client-family-member",
    };
    this.props.onSetClientTab(selectedTab);

    if (clientId) {
      this.props.onGetClientFamily(data).then((res) => {
        this.setState({ loadPartner: false });
        if (
          props.location.state &&
          props.location.state.clickBy === "clientArea"
        ) {
          var findClient = res.payload.items.find(
            (obj) => obj.id === props.location.state.memberId
          );

          if (findClient) {
            this.setState({
              clientFamilyMember: findClient,
              addClientImageUrl: findClient.imageBlobUrl,
              signed: findClient.clientPermission
                ? findClient.clientPermission.signed
                : false,
              onshore: findClient.clientPermission
                ? findClient.clientPermission.onshore
                : false,
              active: findClient.clientPermission
                ? findClient.clientPermission.active
                : false,
            });
            localStorage.setItem(
              "clientprofileid",
              JSON.stringify(findClient.id)
            );

            localStorage.setItem("clientProfileIdMain", clientId);
            localStorage.setItem("familyId", familyId);
            sessionStorage.setItem("clientprofileid", findClient.id);

            sessionStorage.setItem("clientProfileIdMain", clientId);
            sessionStorage.setItem("familyId", familyId);
            sessionStorage.setItem("memberType", "child");
            this.setProfileData(findClient);
          }
        } else if (
          props.location.state &&
          props.location.state.clickBy === "search"
        ) {
          var findClient = res.payload.items.find(
            (obj) => obj.id === props.location.state.memberId
          );

          if (findClient) {
            this.setState({
              clientFamilyMember: findClient,
              addClientImageUrl: findClient.imageBlobUrl,
              signed: findClient.clientPermission
                ? findClient.clientPermission.signed
                : false,
              onshore: findClient.clientPermission
                ? findClient.clientPermission.onshore
                : false,
              active: findClient.clientPermission
                ? findClient.clientPermission.active
                : false,
            });
            localStorage.setItem(
              "clientprofileid",
              JSON.stringify(findClient.id)
            );

            localStorage.setItem("clientProfileIdMain", clientId);
            localStorage.setItem("familyId", familyId);
            this.setProfileData(findClient);
            this.props.onGetAutoImportEmailClient(findClient.id).then((res) => {
              if (res.payload.length > 0 && res.payload[0].isAutoImportEmail) {
                var emailQueueOpt = {
                  url: `v1/ClientEmailImport/ImportEmailQueue`,
                };

                emailQueueOpt.types = [
                  "EMAIL_QUEUE_SUCCESS",
                  "EMAIL_QUEUE_FAILURE",
                ];
                apiRefresh.post(emailQueueOpt, { clientId: findClient.id });
              }
            });
          }
        }
      });
      this.props.onGetCountries();
      this.props.onGetAccessingAuth();
      this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
      this.props.onGetTeamMember();
      this.props.getClientSource();
      this.props.onGetAgents().then((res) => {});
      this.props.onGetPermProcessingPerson(true).then((res) => {
        this.setState({ users: res.payload.users });
      });
    } else {
      setTimeout(() => {
        this.setState({ loadPartner: false });
      }, 1500);
    }
    this.props.onGetBVisaCountries();
  }

  setProfileData = (findClient) => {
    var clientEmails = findClient.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = findClient.emails.find(
      (obj) => obj.emailTypeId == 2
    );
    var clientOtherEmail = findClient.emails.find(
      (obj) => obj.emailTypeId == 3
    );
    let profileData = {
      image: findClient.imageBlobUrl,
      fullName: findClient.firstName + " " + findClient.lastName,
      ezmid: findClient.clientNumberIZM,
      inzNumber: findClient.clientNumber,
      createdDate: findClient.createdDate,
      modifiedDate: findClient.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: findClient.phones.length > 0 ? findClient.phones[0] : null,
    };
    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("agentId", findClient.agentUserId);
    this.props.onSetProfileData(JSON.stringify(profileData));
  };

  componentDidUpdate(PrevProps) {
    if (PrevProps.clientTab !== this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    }
    if (PrevProps.clientFamilyData !== this.props.clientFamilyData) {
    }
  }

  formRef = React.createRef();

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeDate = (date) => this.setState({ date });

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  addPoliceCertificateInfo = (policeCertificateData) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let data = {
      clientId: clientprofileid,
      issueDate: "2020-12-07T08:08:09.142Z",
      certificateExpiryDate: "2020-12-07T08:08:09.142Z",
      country: 168,
    };
  };

  addCertificate = (data) => {
    var id = data.clientId;
    this.props.onAddPoliceCertificate(data).then(() => {
      message.success("Police Certificate added successfully");
      let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
      let familyId = sessionStorage.getItem("familyId");
      let clientId = clientprofileid;

      let myData = {
        clientId: clientId,
        familyId: familyId,
      };
      this.props.onGetClientFamily(myData).then((res) => {
        var findClient = res.payload.items.find((obj) => obj.id === id);
        if (findClient) {
          this.setState({ clientFamilyMember: findClient });
        }
      });
    });
  };

  onGetProfile = () => {
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;

    let myData = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props.onGetClientFamily(myData).then((res) => {
      var findClient = res.payload.items.find(
        (obj) => obj.id === sessionStorage.getItem("clientprofileid")
      );
      if (findClient) {
        this.setState({ clientFamilyMember: findClient });
      }
    });
  };

  addAuthority = (data) => {
    var id = data[0].clientId;
    this.props.onAddAccAuthorities(data).then(() => {
      message.success("Accessing Authority added successfully");
      let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

      let familyId = sessionStorage.getItem("familyId");
      let clientId = clientprofileid;
      let myData = {
        clientId: clientId,
        familyId: familyId,
      };
      this.props.onGetClientFamily(myData).then((res) => {
        var findClient = res.payload.items.find((obj) => obj.id === id);
        if (findClient) {
          this.setState({ clientFamilyMember: findClient });
        }
      });
    });
  };

  removeCertificate = (data) => {
    //
    this.props.onRemovePoliceCertificate(data).then(() => {
      message.success("Police Certificate removed successfully");
      let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

      let familyId = sessionStorage.getItem("familyId");
      let clientId = clientprofileid;
      let data = {
        clientId: clientId,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data).then((res) => {
        this.setOpenMemberState(res.payload);
      });
    });
  };

  removeAuthority = (data) => {
    //
    this.props.onRemoveAccesingAuthority(data).then(() => {
      message.success("Accessing Authority removed successfully");
      let clientprofileid = sessionStorage.getItem("clientprofileid");

      let familyId = sessionStorage.getItem("familyId");
      let clientId = clientprofileid;
      let data = {
        clientId: clientId,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data).then((res) => {
        this.setOpenMemberState(res.payload);
      });
    });
  };

  personalInfoUpdate = (values, content, clientMember) => {
    let date1 = new Date();
    let date2 = new Date(clientMember.dateOfBirth);
    let yearsDiff = 0;
    if (clientMember.dateOfBirth !== "1900-01-01T00:00:00+00:00") {
      yearsDiff = date1.getFullYear() - date2.getFullYear();
    }
    this.setState({ loadPartner: true });
    const userId = localStorage.getItem("userId");
    let addressData = [];
    let phonesData = [];
    let emailData = [];
    if (clientMember.addresses && clientMember.addresses.length > 0) {
      for (var i = 0; i < clientMember.addresses.length; i++) {
        let addressValues = {
          id: clientMember.addresses[i].id,
          clientId: clientMember.addresses[i].clientId,
          contactPerson: clientMember.addresses[i].contactPerson,
          flat: clientMember.addresses[i].flat,
          building: clientMember.addresses[i].building,
          streetName: clientMember.addresses[i].streetName,
          suburb: clientMember.addresses[i].suburb,
          streetNumber: clientMember.addresses[i].streetNumber,
          city:
            clientMember.addresses[i].addressTypeId === 1
              ? values.address || ""
              : clientMember.addresses[i].city,
          state: clientMember.addresses[i].state,
          zip: clientMember.addresses[i].zip,
          country: clientMember.addresses[i].country,
          addressTypeId: clientMember.addresses[i].addressTypeId,
        };
        addressData.push(addressValues);
      }
      // this.props.onUpdClientAddress(addressData);
    } else {
      let addresses = [
        {
          id: 0,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: values.address || "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          id: 0,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 5,
        },
      ];
      addressData = [...addresses];
    }
    if (clientMember.emails && clientMember.emails.length > 0) {
      for (var i = 0; i < clientMember.emails.length; i++) {
        let emailValues = {
          id: clientMember.emails[i].id,
          clientId: clientMember.emails[i].clientId,
          address:
            clientMember.emails[i].emailTypeId === 1
              ? values.email || ""
              : clientMember.emails[i].emailTypeId === 2
              ? values.secondaryEmail || ""
              : values.otherEmail || "",
          emailTypeId: clientMember.emails[i].emailTypeId,
        };
        emailData.push(emailValues);
      }
      // this.props.onUpdClientEmail(emailData);
    } else {
      let primaryEmail = {
        id: 0,
        address: values.email || "",
        emailTypeId: 1,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      let secondaryEmail = {
        id: 0,
        address: values.secondaryEmail || "",
        emailTypeId: 2,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      let otherEmail = {
        id: 0,
        address: values.otherEmail || "",
        emailTypeId: 3,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      emailData.push(primaryEmail);
      emailData.push(secondaryEmail);
      emailData.push(otherEmail);
    }
    if (clientMember.phones && clientMember.phones.length > 0) {
      for (var i = 0; i < clientMember.phones.length; i++) {
        let phonesValues = {
          id: clientMember.phones[i].id,
          clientId: clientMember.phones[i].clientId,
          contact:
            clientMember.phones[i].phoneTypeId === 1
              ? (values.mobilePhone && values.mobilePhone.toString()) || ""
              : clientMember.phones[i].phoneTypeId === 2
              ? (values.secondaryMobile && values.secondaryMobile.toString()) ||
                ""
              : clientMember.phones[i].phoneTypeId === 3
              ? (values.overseasMobile && values.overseasMobile.toString()) ||
                ""
              : clientMember.phones[i].phoneTypeId === 4
              ? (values.landLine && values.landLine.toString()) || ""
              : (values.otherMobile && values.otherMobile.toString()) || "",
          countryCodeId:
            clientMember.phones[i].phoneTypeId === 1
              ? parseInt(values.countryCodeId) ||
                clientMember.phones[i].countryCodeId
              : clientMember.phones[i].countryCodeId,
          phoneTypeId: clientMember.phones[i].phoneTypeId,
        };
        phonesData.push(phonesValues);
      }
      // this.props.onUpdClientPhone(phonesData);
    } else {
      let _phones = [
        {
          id: 0,
          countryCodeId: values.countryCodeId
            ? parseInt(values.countryCodeId)
            : 0,
          phoneTypeId: 1,
          contact: (values.mobilePhone && values.mobilePhone.toString()) || "",
        },
        {
          countryCodeId: 168,
          phoneTypeId: 2,
          contact:
            (values.secondaryMobile && values.secondaryMobile.toString()) || "",
        },
        {
          countryCodeId: 168,
          phoneTypeId: 3,
          contact:
            (values.overseasMobile && values.overseasMobile.toString()) || "",
        },
        {
          countryCodeId: 168,
          phoneTypeId: 4,
          contact: (values.landLine && values.landLine.toString()) || "",
        },
        {
          countryCodeId: 168,
          phoneTypeId: 5,
          contact: (values.otherMobile && values.otherMobile.toString()) || "",
        },
      ];
      phonesData = [..._phones];
    }
    var dateOfBirth = "";
    if (values.dateOfBirth) {
      dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
      // dateOfBirth.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      // dateOfBirth.toISOString();
      // dateOfBirth.format();
    }

    let data = {
      id: clientMember.id,
      clientNumber: clientMember.clientNumber,
      familyId: clientMember.familyId,
      processingGroupId: 0,
      agentId: values.agentId || "00000000-0000-0000-0000-000000000000",
      clientTag: clientMember.clientTag,
      firstName: values.firstName ? values.firstName : "",
      lastName: values.lastName ? values.lastName : "",
      middleName: values.middleName ? values.middleName : "",
      title: values.title ? values.title : "",
      gender: values.gender ? parseInt(values.gender) : 0,
      dateOfBirth: dateOfBirth ? dateOfBirth : "1900-01-01T00:00:00+00:00",
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : clientMember.maritalStatus,
      dependentChildren: values.dependentChildren
        ? parseInt(values.dependentChildren)
        : 0,
      notes: content || "",
      occupation: values.occupation ? values.occupation : "",
      occupationOrganization: clientMember.occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : clientMember.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : clientMember.inzPassword,
      imageBlobUrl: clientMember.imageBlobUrl,
      nationalityId: values.nationalityId !== "" ? values.nationalityId : "",
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : "",
      skypeID: clientMember.skypeID,
      preferredName: clientMember.preferredName,
      isSubscribed: clientMember.isSubscribed,
      arbitaryJson: clientMember.arbitaryJson,
      dependentClientIds: clientMember.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : clientMember.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : clientMember.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : clientMember.travelConditionsValidTo,
      visaText: values.visaText ? values.visaText : clientMember.visaText,
      visaDenied: values.visaDenied,
      deniedText: values.deniedText ? values.deniedText : "",
      clientNumberIZM: clientMember.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : clientMember.inzFeeDate,
      interestedVisa: parseInt(values.interestedVisa) || 0,
      memberType: clientMember.memberType,
      clientId: clientMember.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : clientMember.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : clientMember.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : clientMember.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId ? values.visaCountryId : clientMember.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : clientMember.visaCountyType
      ),
      age: yearsDiff !== 0 ? yearsDiff : clientMember.age,
      jobSectorId: parseInt(
        values.jobSectorId ? values.jobSectorId : clientMember.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : clientMember.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : "",
      clientSerial: values.clientSerial
        ? values.clientSerial
        : clientMember.clientSerial,
      companyOptional: values.companyOptional ? values.companyOptional : "",
      dealWorth: values.dealWorth ? values.dealWorth : "",
      saleDate: values.saleDate
        ? moment(values.saleDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      agentUserId: values.agentUserId || "00000000-0000-0000-0000-000000000000",
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
      addresses: addressData,
      clientEmails: emailData,
      clientPhones: phonesData,
      clientMedical: clientMember.medicals && clientMember.medicals[0],
      passports: clientMember.passports,
      policeCertificates: clientMember.certificates,
    };

    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success("Client family profile updated successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            values.firstName +
            " " +
            values.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(data).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Client family profile update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(data).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  onUpdateCurrentVisa = (values, clientMember) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = {
      id: clientMember.id,
      clientNumber: clientMember.clientNumber,
      familyId: clientMember.familyId,
      processingGroupId: 0,
      agentId: clientMember.agentId,
      clientTag: clientMember.clientTag,
      firstName: values.firstName ? values.firstName : clientMember.firstName,
      lastName: values.lastName ? values.lastName : clientMember.lastName,
      middleName: values.middleName
        ? values.middleName
        : clientMember.middleName,
      title: values.title ? values.title : clientMember.title,
      gender: values.gender ? values.gender : clientMember.gender,
      dateOfBirth: clientMember.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : clientMember.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : clientMember.dependentChildren,
      notes: clientMember.notes,
      occupation: values.occupation
        ? values.occupation
        : clientMember.occupation,
      occupationOrganization: clientMember.occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : clientMember.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : clientMember.inzPassword,
      imageBlobUrl: clientMember.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : clientMember.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : clientMember.nationalityCountry,
      skypeID: clientMember.skypeID,
      preferredName: clientMember.preferredName,
      isSubscribed: clientMember.isSubscribed,
      arbitaryJson: clientMember.arbitaryJson,
      dependentClientIds: clientMember.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId ? values.currentVisaTypeId : 0
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? moment(values.currentNewZealandVisaExpiry).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: values.travelConditionsValidTo
        ? moment(values.travelConditionsValidTo).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      visaText: values.visaText ? values.visaText : "",
      visaDenied: clientMember.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : clientMember.deniedText,
      clientNumberIZM: clientMember.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : clientMember.inzFeeDate,
      interestedVisa: clientMember.interestedVisa,
      memberType: clientMember.memberType,
      clientId: clientMember.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : clientMember.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : clientMember.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : clientMember.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId ? values.visaCountryId : clientMember.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : clientMember.visaCountyType
      ),
      age: values.age ? values.age : clientMember.age,
      jobSectorId: parseInt(
        values.jobSectorId ? values.jobSectorId : clientMember.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : clientMember.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : clientMember.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : clientMember.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : clientMember.companyOptional,
      dealWorth: values.dealWorth ? values.dealWorth : clientMember.dealWorth,
      saleDate: values.saleDate ? values.saleDate : clientMember.saleDate,
      agentUserId:
        this.state.clientFamilyMember.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      clientPermission: clientMember.clientPermission,
      addresses: clientMember.addresses,
      clientEmails: clientMember.emails,
      clientPhones: clientMember.phones,
      clientMedical: clientMember.medicals && clientMember.medicals[0],
      passports: clientMember.passports,
      policeCertificates: clientMember.certificates,
    };
    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success(
          "Client family profile current visa updated successfully"
        );
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            clientMember.firstName +
            " " +
            clientMember.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let familyData = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(familyData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
        if (
          values.currentNewZealandVisaExpiry &&
          moment(values.currentNewZealandVisaExpiry).format("DD/MM/YYYY") !=
            moment(clientMember.currentNewZealandVisaExpiry).format(
              "DD/MM/YYYY"
            ) &&
          moment(values.currentNewZealandVisaExpiry).format("DD/MM/YYYY") !=
            "01/01/1900"
        ) {
          let myData = {
            title: "Current Visa Expiry",
            reminderDate: values.currentNewZealandVisaExpiry
              ? moment(values.currentNewZealandVisaExpiry).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 10,
          };
          AddAutoReminder(myData);
        }
        if (
          values.travelConditionsValidTo &&
          moment(values.travelConditionsValidTo).format("DD/MM/YYYY") !=
            moment(
              this.state.clientFamilyMember.travelConditionsValidTo
            ).format("DD/MM/YYYY") &&
          moment(values.travelConditionsValidTo).format("DD/MM/YYYY") !=
            "01/01/1900"
        ) {
          let tcData = {
            title: "Travel Condition Valid To",
            reminderDate: values.travelConditionsValidTo
              ? moment(values.travelConditionsValidTo).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 13,
          };
          AddAutoReminder(tcData);
        }
      })
      .catch((err) => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Client family profile current visa update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");

        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(data).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  onUpdateNZQA = (values, clientMember) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = {
      id: clientMember.id,
      clientNumber: clientMember.clientNumber,
      familyId: clientMember.familyId,
      processingGroupId: 0,
      agentId: clientMember.agentId,
      clientTag: clientMember.clientTag,
      firstName: values.firstName ? values.firstName : clientMember.firstName,
      lastName: values.lastName ? values.lastName : clientMember.lastName,
      middleName: values.middleName
        ? values.middleName
        : clientMember.middleName,
      title: values.title ? values.title : clientMember.title,
      gender: values.gender ? values.gender : clientMember.gender,
      dateOfBirth: clientMember.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : clientMember.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : clientMember.dependentChildren,
      notes: "",
      occupation: values.occupation
        ? values.occupation
        : clientMember.occupation,
      occupationOrganization: clientMember.occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : clientMember.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : clientMember.inzPassword,
      imageBlobUrl: clientMember.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : clientMember.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : clientMember.nationalityCountry,
      skypeID: clientMember.skypeID,
      preferredName: clientMember.preferredName,
      isSubscribed: clientMember.isSubscribed,
      arbitaryJson: clientMember.arbitaryJson,
      dependentClientIds: clientMember.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : clientMember.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : clientMember.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : clientMember.travelConditionsValidTo,
      visaText: values.visaText ? values.visaText : clientMember.visaText,
      visaDenied: clientMember.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : clientMember.deniedText,
      clientNumberIZM: clientMember.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : clientMember.inzFeeDate,
      interestedVisa: clientMember.interestedVisa,
      memberType: clientMember.memberType,
      clientId: clientMember.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? moment(values.nzqaOnlineSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? moment(values.nzqaDocumentSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? moment(values.nzqaDocumentRetDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      visaCountryId: parseInt(
        values.visaCountryId ? values.visaCountryId : clientMember.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : clientMember.visaCountyType
      ),
      age: values.age ? values.age : clientMember.age,
      jobSectorId: parseInt(
        values.jobSectorId ? values.jobSectorId : clientMember.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : clientMember.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : clientMember.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : clientMember.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : clientMember.companyOptional,
      dealWorth: values.dealWorth ? values.dealWorth : clientMember.dealWorth,
      saleDate: values.saleDate ? values.saleDate : clientMember.saleDate,
      agentUserId:
        this.state.clientFamilyMember.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      clientPermission: clientMember.clientPermission,
      addresses: clientMember.addresses,
      clientEmails: clientMember.emails,
      clientPhones: clientMember.phones,
      clientMedical: clientMember.medicals && clientMember.medicals[0],
      passports: clientMember.passports,
      policeCertificates: clientMember.certificates,
    };
    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success(
          "Client family profile NZQA detail updated successfully"
        );
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            clientMember.firstName +
            " " +
            clientMember.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(data).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Client family profile NZQA detail update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;
        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(data).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  onUpdateMedicals = (values, content, clientMember) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = null;
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    if (clientMember && clientMember.medicals.length > 0) {
      data = [
        {
          id: clientMember.medicals[0].id,
          clientId: clientMember.id,
          er: values.er || "",
          medicalIssueDate: values.medicalIssueDate
            ? moment(values.medicalIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate: values.medicalExpiryDate
            ? moment(values.medicalExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          xrayIssueDate: values.xrayIssueDate
            ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate: values.xrayExpiryDate
            ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medicalGrading.toString() || "",
          xrayGrading: values.xrayGrading.toString() || "",
          medicalNotes: values.medicalNotes || "",
          medicalNotesDetail: content || "",
        },
      ];
    } else {
      data = [
        {
          clientId: clientMember.id,
          er: values.er || "",
          medicalIssueDate: values.medicalIssueDate
            ? moment(values.medicalIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate: values.medicalExpiryDate
            ? moment(values.dateOfBirth).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayIssueDate: values.xrayIssueDate
            ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate: values.xrayExpiryDate
            ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medicalGrading.toString() || "",
          xrayGrading: values.xrayGrading.toString() || "",
          medicalNotes: values.medicalNotes || "",
          medicalNotesDetail: content || "",
        },
      ];
    }

    this.props
      .onUpdClientMedical(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success(
          "Client family profile medical detail updated successfully"
        );
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            clientMember.firstName +
            " " +
            clientMember.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
        if (
          moment(data[0].medicalExpiryDate).format("DD/MM/YYYY") !=
            moment(clientMember.medicals[0].medicalExpiryDate).format(
              "DD/MM/YYYY"
            ) &&
          moment(data[0].medicalExpiryDate).format("DD/MM/YYYY") != "01/01/1900"
        ) {
          let myData = {
            title: "Medical Expiry",
            reminderDate: values.medicalExpiryDate
              ? moment(values.medicalExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 5,
          };
          AddAutoReminder(myData);
        }
        if (
          moment(data[0].xrayExpiryDate).format("DD/MM/YYYY") !=
            moment(clientMember.medicals[0].xrayExpiryDate).format(
              "DD/MM/YYYY"
            ) &&
          moment(data[0].xrayExpiryDate).format("DD/MM/YYYY") != "01/01/1900"
        ) {
          let xrayData = {
            title: "Xray Expiry",
            reminderDate: values.xrayExpiryDate
              ? moment(values.xrayExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 4,
          };
          AddAutoReminder(xrayData);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Client family profile medical detail update failed");
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  onUpdateInzUserDetail = (values, clientMember) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let data = {
      id: clientMember.id,
      clientNumber: values.clientNumber || "",
      familyId: clientMember.familyId,
      processingGroupId: 0,
      agentId: clientMember.agentId,
      clientTag: clientMember.clientTag,
      firstName: values.firstName ? values.firstName : clientMember.firstName,
      lastName: values.lastName ? values.lastName : clientMember.lastName,
      middleName: values.middleName
        ? values.middleName
        : clientMember.middleName,
      title: values.title ? values.title : clientMember.title,
      gender: values.gender ? values.gender : clientMember.gender,
      dateOfBirth: clientMember.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : clientMember.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : clientMember.dependentChildren,
      notes: clientMember.notes,
      occupation: values.occupation
        ? values.occupation
        : clientMember.occupation,
      occupationOrganization: clientMember.occupationOrganization,
      inzUserName: values.inzUserName ? values.inzUserName : "",
      inzPassword: values.inzPassword ? values.inzPassword : "",
      imageBlobUrl: clientMember.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : clientMember.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : clientMember.nationalityCountry,
      skypeID: clientMember.skypeID,
      preferredName: clientMember.preferredName,
      isSubscribed: clientMember.isSubscribed,
      arbitaryJson: clientMember.arbitaryJson,
      dependentClientIds: clientMember.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : clientMember.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : clientMember.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : clientMember.travelConditionsValidTo,
      visaText: values.visaText ? values.visaText : clientMember.visaText,
      visaDenied: clientMember.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : clientMember.deniedText,
      clientNumberIZM: clientMember.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : "1900-01-01T00:00:00+00:00",
      interestedVisa: clientMember.interestedVisa,
      memberType: clientMember.memberType,
      clientId: clientMember.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : clientMember.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : clientMember.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : clientMember.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId ? values.visaCountryId : clientMember.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : clientMember.visaCountyType
      ),
      age: values.age ? values.age : clientMember.age,
      jobSectorId: parseInt(
        values.jobSectorId ? values.jobSectorId : clientMember.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : clientMember.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : clientMember.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : clientMember.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : clientMember.companyOptional,
      dealWorth: values.dealWorth ? values.dealWorth : clientMember.dealWorth,
      saleDate: values.saleDate ? values.saleDate : clientMember.saleDate,
      agentUserId:
        this.state.clientFamilyMember.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      clientPermission: clientMember.clientPermission,
      addresses: clientMember.addresses,
      clientEmails: clientMember.emails,
      clientPhones: clientMember.phones,
      clientMedical: clientMember.medicals && clientMember.medicals[0],
      passports: clientMember.passports,
      policeCertificates: clientMember.certificates,
    };
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            clientMember.firstName +
            " " +
            clientMember.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        message.success("Profile updated successfully");
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Profile update failed");
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  onUpdatePassport = (values, clientMember) => {
    this.setState({ loadPartner: true });
    var setReminder = false;
    var setSecondReminder = false;
    let clientprofileidMem = sessionStorage.getItem("clientprofileid");

    let passportData = [];
    if (
      clientMember &&
      clientMember.passports &&
      clientMember.passports.length > 0
    ) {
      for (var i = 0; i < clientMember.passports.length; i++) {
        let passportValues = {
          id: clientMember.passports[i].id,
          clientId: clientMember.passports[i].clientId,
          passportNo:
            i === 0 ? values.passportNo || "" : values.secondPassportNo || "",
          passportCountry:
            i === 0
              ? parseInt(values.passportCountry) || 0
              : parseInt(values.secondPassportCountry) || 0,
          passportIssueDate:
            i === 0
              ? values.passportIssueDate
                ? moment(values.passportIssueDate).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00"
              : values.secondPassportIssueDate
              ? moment(values.secondPassportIssueDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
          passportExpiryDate:
            i === 0
              ? values.passportExpiryDate
                ? moment(values.passportExpiryDate).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00"
              : values.secondPassportExpiryDate
              ? moment(values.secondPassportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
          passportType: i === 0 ? 1 : 0,
        };
        var expDate = moment(passportValues.passportExpiryDate).format(
          "DD/MM/YYYY"
        );
        var oldDate = moment(
          clientMember &&
            clientMember.passports &&
            clientMember.passports[i].passportExpiryDate
        ).format("DD/MM/YYYY");
        if (i == 0 && expDate != oldDate && expDate != "01/01/1900") {
          setReminder = true;
        }
        if (i == 1 && expDate != oldDate && expDate != "01/01/1900") {
          setSecondReminder = true;
        }
        passportData.push(passportValues);
      }
    } else {
      passportData = [
        {
          id: 0,
          passportNo: values.passportNo || "",
          passportCountry: values.passportCountry || 0,
          passportType: 1,
          passportIssueDate: values.passportIssueDate
            ? moment(values.passportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.passportExpiryDate
            ? moment(values.passportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          clientId: clientprofileidMem,
        },
        {
          id: 0,
          passportNo: values.secondPassportNo || "",
          passportCountry: values.secondPassportCountry || 0,
          passportType: 0,
          passportIssueDate: values.secondPassportIssueDate
            ? moment(values.secondPassportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.secondPassportExpiryDate
            ? moment(values.secondPassportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          clientId: clientprofileidMem,
        },
      ];
    }
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props
      .onUpdClientPassport(passportData)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.success(
          "Client family profile INZ login detail updated successfully"
        );
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            clientMember.firstName +
            " " +
            clientMember.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
        if (setReminder) {
          let myData = {
            title: "Passport Expiry",
            reminderDate: values.passportExpiryDate
              ? moment(values.passportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 7,
          };
          AddAutoReminder(myData);
        }
        if (setSecondReminder) {
          let myDataSecond = {
            title: "Passport Expiry",
            reminderDate: values.secondPassportExpiryDate
              ? moment(values.secondPassportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 7,
          };
          AddAutoReminder(myDataSecond);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Client family profile INZ login detail update failed");
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  onUpdateBillingAddress = (values, clientMember) => {
    const userId = localStorage.getItem("userId");
    this.setState({ loadPartner: true });
    let addressData = [];
    if (clientMember.addresses.length > 0) {
      for (var i = 0; i < clientMember.addresses.length; i++) {
        let addressValues = {
          id: clientMember.addresses[i].id,
          clientId: clientMember.addresses[i].clientId,
          contactPerson:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].contactPerson
              : values.contactPerson,
          flat:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].flat
              : values.flat,
          building: clientMember.addresses[i].building,
          streetName: clientMember.addresses[i].streetName,
          suburb:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].suburb
              : values.suburb,
          streetNumber:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].streetNumber
              : values.streetNumber,
          city:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].city
              : values.city,
          state: clientMember.addresses[i].state,
          zip:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].zip
              : values.zipcode,
          country:
            clientMember.addresses[i].addressTypeId === 1
              ? clientMember.addresses[i].country
              : values.billCountry || 0,
          addressTypeId: clientMember.addresses[i].addressTypeId,
        };
        addressData.push(addressValues);
      }
    } else {
      let addressesAdd = [
        {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: values.contactPerson || "",
          flat: values.flat || "",
          building: "",
          streetName: "",
          suburb: values.suburb || "",
          streetNumber: values.streetNumber || "",
          city: values.city || "",
          state: "",
          zip: values.zipcode || "",
          country: values.billCountry ? parseInt(values.billCountry) : 0,
          addressTypeId: 5,
        },
      ];
      addressData = [...addressesAdd];
    }
    let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let clientId = clientprofileid;
    let clientData = {
      clientId: clientId,
      familyId: familyId,
    };
    this.props
      .onUpdClientAddress(addressData)
      .then(() => {
        this.setState({ updLoading: false, loadPartner: false });
        let userName = localStorage.getItem("userName");
        let logData = {
          clientName: "",
          logMessage:
            "Client Family Member " +
            clientMember.firstName +
            " " +
            clientMember.lastName +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(logData);
        message.success(
          "Client family profile billing address updated successfully"
        );
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Client family profile billing address update failed");
        this.props.onGetClientFamily(clientData).then((res) => {
          this.setOpenMemberState(res.payload);
        });
      });
  };

  setOpenMemberState = (familyMembers) => {
    if (familyMembers.items && familyMembers.items.length > 0) {
      var findMember = familyMembers.items.find(
        (obj) => obj.id == this.state.clientFamilyMember.id
      );

      if (findMember) {
        sessionStorage.setItem("agentId", findMember.agentUserId);
        this.setState({ clientFamilyMember: findMember });
        this.setProfileData(findMember);
      }
    }
  };

  uploadImage = (info, id) => {
    this.setState({ loadUploadImage: true });
    if (id) {
      this.setState({ imageUpdateId: id });
    }

    let formData = new FormData();
    formData.append("File", info);
    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        this.setState({
          addClientImageUrl: this.props.imageUploadSuccess,
          loadUploadImage: false,
        });
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client family member profile picture uploaded by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Family member Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      }
    });
  };

  handleChangeImage = (info) => {
    console.log("show file data ====== ", info);
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  onAddPartner = (values) => {
    this.setState({ loadPartner: true });
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let getFamilyId = sessionStorage.getItem("familyId");
    var dateOfBirth = "";
    if (values.dateOfBirth) {
      dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }

    let data = {
      clientNumber: values.clientNumber || "",
      contactPerson: "",
      familyId: getFamilyId,
      processingGroupId: 0,
      agentId: values.agentId || "00000000-0000-0000-0000-000000000000",
      clientTag: 0,
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      middleName: values.middleName || "",
      title: values.title || "",
      gender: parseInt(values.gender) || 0,
      dateOfBirth: dateOfBirth || "1900-01-01T00:00:00+00:00",
      maritalStatus: values.maritalStatus || "",
      dependentChildren: parseInt(values.dependentChildren) || 0,
      notes: this.state.contentNotes,
      occupation: values.occupation || "",
      occupationOrganization: "",
      inzUserName: values.inzUserName || "",
      inzPassword: values.inzPassword || "",
      imageBlobUrl: this.state.imageBlobUrl || "",
      nationalityId: values.nationalityId || "",
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : "",
      skypeID: "",
      preferredName: "",
      isSubscribed: false,
      arbitaryJson: "",
      dependentClientIds: "",
      currentVisaTypeId: parseInt(values.currentVisaTypeId) || 0,
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? moment(values.currentNewZealandVisaExpiry).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: values.travelConditionsValidTo
        ? moment(values.travelConditionsValidTo).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      visaText: values.visaText || "",
      visaDenied: values.visaDenied || false,
      deniedText: values.deniedText || "",
      clientNumberIZM: "",
      inzFeeDate: values.inzFeeDate
        ? moment(values.inzFeeDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      interestedVisa: parseInt(values.interestedVisa) || 0,
      memberType: this.state.addOtherRelation ? "FamilyMember" : "Child",
      clientId: clientProfileIdMain,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? moment(values.nzqaOnlineSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? moment(values.nzqaDocumentSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? moment(values.nzqaDocumentRetDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      visaCountryId: parseInt(values.visaCountryId) || 0,
      visaCountyType: parseInt(values.visaCountryType) || 0,
      age: 0,
      jobSectorId: parseInt(values.jobSectorId) || 0,
      sourceId: parseInt(values.sourceId) || 0,
      sourceDescription: values.sourceDescription || "",
      clientSerial: values.clientSerial || "",
      companyOptional: values.companyOptional || "",
      dealWorth: values.dealWorth || "",
      saleDate: values.saleDate
        ? moment(values.saleDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      clientPermission: {
        signed: false,
        onshore: true,
        active: true,
        allowUpdate: false,
        areaAccess: false,
      },
      clientMedical: {
        clientId: "00000000-0000-0000-0000-000000000000",
        er: values.er || "",
        medicalIssueDate: values.medicalIssueDate
          ? moment(values.medicalIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        medicalExpiryDate: values.medicalExpiryDate
          ? moment(values.medicalExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        xrayIssueDate: values.xrayIssueDate
          ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        xrayExpiryDate: values.xrayExpiryDate
          ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        medicalGrading: values.medicalGrading || "",
        xrayGrading: values.xrayGrading || "",
        medicalNotes: values.medicalNotes || "",
        medicalNotesDetail: this.state.medicalNotes || "",
      },
      passports: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          passportNo: values.passportNo || "",
          passportCountry: parseInt(values.passportCountry) || 0,
          passportType: 1,
          passportIssueDate: values.passportIssueDate
            ? moment(values.passportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.passportExpiryDate
            ? moment(values.passportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          passportNo: values.secondPassportNo || "",
          passportCountry: parseInt(values.secondPassportCountry) || 0,
          passportType: 0,
          passportIssueDate: values.secondPassportIssueDate
            ? moment(values.secondPassportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.secondPassportExpiryDate
            ? moment(values.secondPassportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
        },
      ],
      clientEmails: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          address: values.email || "",
          emailTypeId: 1,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          address: values.secondaryEmail || "",
          emailTypeId: 2,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          address: values.otherEmail || "",
          emailTypeId: 3,
        },
      ],
      addresses: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: values.address || "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contactPerson: values.contactPerson || "",
          flat: values.flat || "",
          building: "",
          streetName: "",
          suburb: values.suburb || "",
          streetNumber: values.streetNumber || "",
          city: values.city || "",
          state: "",
          zip: values.zipcode || "",
          country: parseInt(values.billCountry) || 0,
          addressTypeId: 5,
        },
      ],
      clientPhones: [
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.mobilePhone ? values.mobilePhone.toString() : "",
          phoneTypeId: 1,
          countryCodeId: values.countryCodeId || 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.secondaryMobile
            ? values.secondaryMobile.toString()
            : "",
          phoneTypeId: 2,
          countryCodeId: 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.overseasMobile
            ? values.overseasMobile.toString()
            : "",
          phoneTypeId: 3,
          countryCodeId: 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.landLine ? values.landLine.toString() : "",
          phoneTypeId: 4,
          countryCodeId: 168,
        },
        {
          clientId: "00000000-0000-0000-0000-000000000000",
          contact: values.otherMobile ? values.otherMobile.toString() : "",
          phoneTypeId: 5,
          countryCodeId: 168,
        },
      ],
      policeCertificates: [],
    };

    this.props
      .onAddPartner(data)
      .then((res) => {
        let clientprofileidMain = sessionStorage.getItem("clientProfileIdMain");
        let data = {
          clientId: clientprofileidMain,
          familyId: getFamilyId,
        };
        message.success("Other member added successfully!");
        this.setState({
          addPartner: false,
          loadPartner: false,
          addOtherRelation: false,
        });
        this.props.onGetClientFamily(data);
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client family member named as " +
              values.firstName +
              " " +
              values.lastName +
              " added by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch(() => {
        message.error("Other member failed to add!");
        this.setState({ loadPartner: false });
      });
  };

  handleChangeUploadCreate = ({ fileList }) => {
    let _type =
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].type;

    if (
      _type === "image/jpeg" ||
      _type === "image/jpg" ||
      _type === "image/png" ||
      _type === undefined
    ) {
      this.setState({ fileList }, () => {
        let formData = new FormData();
        let selectedFile = fileList && fileList[0] && fileList[0].originFileObj;
        formData.append("File", selectedFile);
        if (_type !== undefined) {
          // this.setState({ loading: true });
        }
        if (selectedFile) {
          this.props.onUploadAvatar(formData).then(() => {
            if (this.props.imageUploadSuccess) {
              this.setState({
                imageBlobUrl: this.props.imageUploadSuccess,
              });
            }
          });
        }
      });
    } else {
      message.warning("Only 'png','jpg' & 'jpeg' type of images are allowed");
    }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  showAddChildForm = () => {
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    if (clientProfileIdMain) {
      this.setState({ addPartner: true, addOtherRelation: false });
    } else {
      message.error("Please search and select client first!");
    }
  };

  showAddOtherRelationForm = () => {
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    if (clientProfileIdMain) {
      this.setState({ addOtherRelation: true, addPartner: false });
    } else {
      message.error("Please search and select client first!");
    }
  };

  onProfileTopUpdate = (toggleName) => {
    this.setState({ loadPartner: true });
    const userId = localStorage.getItem("userId");
    let data = {
      id: this.state.clientFamilyMember.id,
      clientNumber: this.state.clientFamilyMember.clientNumber,
      familyId: this.state.clientFamilyMember.familyId,
      processingGroupId: 0,
      agentId: this.state.clientFamilyMember.agentId,
      clientTag: this.state.clientFamilyMember.clientTag,
      firstName: this.state.clientFamilyMember.firstName,
      lastName: this.state.clientFamilyMember.lastName,
      middleName: this.state.clientFamilyMember.middleName,
      title: this.state.clientFamilyMember.title,
      gender: this.state.clientFamilyMember.gender,
      dateOfBirth: this.state.clientFamilyMember.dateOfBirth,
      maritalStatus: this.state.clientFamilyMember.maritalStatus,
      dependentChildren: this.state.clientFamilyMember.dependentChildren,
      notes: this.state.clientFamilyMember.notes,
      occupation: this.state.clientFamilyMember.occupation,
      occupationOrganization: this.state.clientFamilyMember
        .occupationOrganization,
      inzUserName: this.state.clientFamilyMember.inzUserName,
      inzPassword: this.state.clientFamilyMember.inzPassword,
      imageBlobUrl: this.state.addClientImageUrl
        ? this.state.addClientImageUrl
        : "",
      nationalityId: this.state.clientFamilyMember.nationalityId,
      nationalityCountry: this.state.clientFamilyMember.nationalityCountry,
      skypeID: this.state.clientFamilyMember.skypeID || "",
      preferredName: this.state.clientFamilyMember.preferredName,
      isSubscribed: this.state.clientFamilyMember.isSubscribed,
      arbitaryJson: this.state.clientFamilyMember.arbitaryJson,
      dependentClientIds: this.state.clientFamilyMember.dependentClientIds,
      currentVisaTypeId: parseInt(
        this.state.clientFamilyMember.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: this.state.clientFamilyMember
        .currentNewZealandVisaExpiry,
      travelConditionsValidTo: this.state.clientFamilyMember
        .travelConditionsValidTo,
      visaText: this.state.clientFamilyMember.visaText,
      visaDenied: this.state.clientFamilyMember.visaDenied,
      deniedText: this.state.clientFamilyMember.deniedText,
      clientNumberIZM: this.state.clientFamilyMember.clientNumberIZM,
      inzFeeDate: this.state.clientFamilyMember.inzFeeDate,
      interestedVisa: this.state.clientFamilyMember.interestedVisa || 0,
      memberType: this.state.clientFamilyMember.memberType,
      clientId: this.state.clientFamilyMember.clientId,
      nzqaOnlineSubDate: this.state.clientFamilyMember.nzqaOnlineSubDate,
      nzqaDocumentSubDate: this.state.clientFamilyMember.nzqaDocumentSubDate,
      nzqaDocumentRetDate: this.state.clientFamilyMember.nzqaDocumentRetDate,
      visaCountryId: parseInt(this.state.clientFamilyMember.visaCountryId),
      visaCountyType: parseInt(this.state.clientFamilyMember.visaCountyType),
      age: this.state.clientFamilyMember.age,
      jobSectorId: parseInt(this.state.clientFamilyMember.jobSectorId),
      sourceId: parseInt(this.state.clientFamilyMember.sourceId),
      sourceDescription: this.state.clientFamilyMember.sourceDescription,
      clientSerial: this.state.clientFamilyMember.clientSerial,
      companyOptional: this.state.clientFamilyMember.companyOptional,
      dealWorth: this.state.clientFamilyMember.dealWorth,
      saleDate: this.state.clientFamilyMember.saleDate,
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
      addresses: this.state.clientFamilyMember.addresses,
      clientEmails: this.state.clientFamilyMember.emails,
      clientPhones: this.state.clientFamilyMember.phones,
      agentUserId:
        this.state.clientFamilyMember.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      clientMedical:
        this.state.clientFamilyMember.medicals &&
        this.state.clientFamilyMember.medicals[0],
      passports:
        this.state.clientFamilyMember &&
        this.state.clientFamilyMember.passports &&
        this.state.clientFamilyMember.passports,
      policeCertificates: this.state.clientFamilyMember.certificates,
    };

    this.props
      .onUpdatePartner(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;

        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props
          .onGetClientFamily(data)
          .then((res) => {
            this.setState({ loadPartner: false });
            this.setOpenMemberState(res.payload);
          })
          .catch(() => {
            this.setState({ loadPartner: false });
          });
        if (toggleName === "onActive") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let active_inActive = this.state.active ? "Active" : "Inactive";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client status updated to " +
                active_inActive +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
        if (toggleName === "onSigned" && !this.state.signed) {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let signed_unSigned = this.state.signed ? "enabled" : "disabled";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client contract SIGNED status " +
                signed_unSigned +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
        if (toggleName === "onShore") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let onshore_offShore = this.state.onshore ? "Onshore" : "Offshore";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client status updated to " +
                onshore_offShore +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadPartner: false });
        message.error("Profile update failed");
        let clientprofileid = sessionStorage.getItem("clientProfileIdMain");
        let familyId = sessionStorage.getItem("familyId");
        let clientId = clientprofileid;

        let data = {
          clientId: clientId,
          familyId: familyId,
        };
        this.props.onGetClientFamily(data);
      });
  };

  onFinishSigned = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loadPartner: true });
    let data = {
      id: clientprofileid,
      lastAgreementSigned: values.sigedAgreementDate,
    };

    this.props
      .onUpdateSignedDate(data)
      .then((res) => {
        this.setState({ signed: true, signedModalVisible: false });
        this.setState({ loadPartner: false });
        this.onProfileTopUpdate();
        this.formRef.current.resetFields();
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        let signed_unSigned = this.state.signed ? "enabled" : "disabled";
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client contract SIGNED status " +
              moment(values.sigedAgreementDate).format("DD/MM/YYYY") +
              " " +
              signed_unSigned +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((err) => {
        this.setState({ loadPartner: false });
      });
  };

  downlaodImage = () => {
    if (this.state.addClientImageUrl) {
      saveAs(this.state.addClientImageUrl, "image.jpg"); // Put your image url here.
    } else {
      message.warning("Image not attached with this profile!");
    }
  };

  render() {
    const {
      signed,
      onshore,
      active,
      allowUpdate,
      areaAccess,
      updLoading,
      addPartner,
      addClientImageUrl,
      fileList,
      previewVisible,
      previewImage,
      previewTitle,
      loadPartner,
      addOtherRelation,
      clientFamilyMember,
      contentNotes,
      headerOptions,
      signedModalVisible,
    } = this.state;
    const {
      clientFamilyData,
      partnerProfileData,
      countriesData,
      groupsData,
      accessingAuthData,
      groupMembersData,
      visaTypeData,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      employerJobHistoryCurrentRes,
      onSetActiveKey,
      activeKey,
      teamMembers,
      clientSourceListing,
      onUpdatePriority,
      visaPriorityRes,
      onSignedVisa,
      onPaidVisa,
      remindersRes,
      onGetClientTask,
      onUpdateTask,
      onGetAllUsers,
      onAddTaskFollower,
      onRemoveTasks,
      onAddDailyTasks,
      onGetClientFamily,
      onUpdateCompletedTask,
      onAddTaskFileNote,
      onAddTaskComment,
      onGetTaskComments,
      onGetTaskFollowers,
      onGetVisaStatus,

      onGetReminder,
      onGetAdmissionProgram,
      onGetAdmissionStatuses,
      onGetVisaType,
      onGetVisaTypeByCountry,
      brnchVisaCountriesData,
      agentsRes,
    } = this.props;
    console.log("accessingAuthData data", contentNotes);
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <Spin spinning={loadPartner}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs
                  data={headerOptions}
                  activeTab="Family Details"
                />
              )}
              {clientFamilyMember && (
                <ProfileTopBarTabs
                  data={topBar}
                  partnerId={partnerProfileData && partnerProfileData.id}
                  activeTab="CLIENT INFORMATION"
                />
              )}
              <div style={{ display: "flex", width: "98%" }}>
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                >
                  <div
                    className="profile-additional-box"
                    style={{ marginTop: 42 }}
                  >
                    <Modal
                      className="reminder-model-main width-modal-outgoing-payments"
                      title="AGREEMENT SIGNED"
                      visible={signedModalVisible}
                      onCancel={() =>
                        this.setState({ signedModalVisible: false })
                      }
                      footer={null}
                      maskClosable={false}
                    >
                      <Form onFinish={this.onFinishSigned} ref={this.formRef}>
                        <div
                          className="form-container"
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                            padding: 20,
                            alignItems: "center",
                          }}
                        >
                          <p className="medical-label">Date</p>
                          <div
                            style={{
                              display: "flex",
                              border: "none",
                              width: "48%",
                            }}
                          >
                            <Form.Item
                              name="sigedAgreementDate"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ]}
                            >
                              <DatePicker format={dateFormat} />
                            </Form.Item>
                          </div>
                        </div>
                        <Row>
                          <Col xs={4} offset={18} style={{ display: "flex" }}>
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                style={{ marginRight: "10px" }}
                              >
                                Save
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() =>
                                  this.setState({ signedModalVisible: false })
                                }
                              >
                                Close
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Modal>
                    <div className="cf-tags-cont">
                      {clientFamilyData &&
                        clientFamilyData.items.map((familyData, index) => {
                          return (
                            <>
                              {familyData.memberType.toLowerCase() !==
                                "client" &&
                                familyData.memberType.toLowerCase() !==
                                  "spouse" && (
                                  <div
                                    key={index}
                                    className="client-tag-btn"
                                    style={{
                                      display: "inline-block",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        clientFamilyMember: familyData,
                                        addClientImageUrl:
                                          familyData.imageBlobUrl,
                                        signed: familyData.clientPermission
                                          ? familyData.clientPermission.signed
                                          : false,
                                        onshore: familyData.clientPermission
                                          ? familyData.clientPermission.onshore
                                          : false,
                                        active: familyData.clientPermission
                                          ? familyData.clientPermission.active
                                          : false,
                                      });

                                      var clientEmails = familyData.emails.find(
                                        (obj) => obj.emailTypeId == 1
                                      );
                                      var clientSecondaryEmail = familyData.emails.find(
                                        (obj) => obj.emailTypeId == 2
                                      );
                                      var clientOtherEmail = familyData.emails.find(
                                        (obj) => obj.emailTypeId == 3
                                      );
                                      sessionStorage.setItem(
                                        "agentId",
                                        familyData.agentUserId
                                      );
                                      let profileData = {
                                        image: familyData.imageBlobUrl,
                                        fullName:
                                          familyData.firstName +
                                          " " +
                                          familyData.lastName,
                                        ezmid: familyData.clientNumberIZM,
                                        inzNumber: familyData.clientNumber,
                                        createdDate: familyData.createdDate,
                                        modifiedDate: familyData.modifiedDate,
                                        primaryEmail:
                                          (clientEmails &&
                                            clientEmails.address) ||
                                          "",
                                        secondaryEmail:
                                          (clientSecondaryEmail &&
                                            clientSecondaryEmail.address) ||
                                          "",
                                        otherEmail:
                                          (clientOtherEmail &&
                                            clientOtherEmail.address) ||
                                          "",
                                        mobileNumber:
                                          familyData.phones.length > 0
                                            ? familyData.phones[0]
                                            : null,
                                      };
                                      this.props.onSetProfileData(
                                        JSON.stringify(profileData)
                                      );
                                      // localStorage.setItem(
                                      //   "profileData",
                                      //   JSON.stringify(profileData)
                                      // );
                                      this.props
                                        .onGetAutoImportEmailClient(
                                          familyData.id
                                        )
                                        .then((res) => {
                                          if (
                                            res.payload.length > 0 &&
                                            res.payload[0].isAutoImportEmail
                                          ) {
                                            var emailQueueOpt = {
                                              url: `v1/ClientEmailImport/ImportEmailQueue`,
                                            };

                                            emailQueueOpt.types = [
                                              "EMAIL_QUEUE_SUCCESS",
                                              "EMAIL_QUEUE_FAILURE",
                                            ];
                                            apiRefresh.post(emailQueueOpt, {
                                              clientId: familyData.id,
                                            });
                                          }
                                        });
                                      sessionStorage.setItem(
                                        "clientprofileid",
                                        familyData.id
                                      );
                                      sessionStorage.setItem(
                                        "clientProfileIdMain",
                                        familyData.clientId
                                      );
                                      sessionStorage.setItem(
                                        "familyId",
                                        familyData.familyId
                                      );
                                      sessionStorage.setItem(
                                        "memberType",
                                        "child"
                                      );
                                    }}
                                  >
                                    <span style={{ color: "#FFFFFF" }}>
                                      {familyData.firstName +
                                        " " +
                                        familyData.lastName}
                                    </span>
                                  </div>
                                )}
                            </>
                          );
                        })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        padding: 20,
                        paddingBottom: 10,
                      }}
                    >
                      <Button
                        loading={updLoading}
                        className="add-member-btn"
                        onClick={this.showAddChildForm}
                        style={{
                          backgroundColor: addPartner
                            ? "rgb(51, 170, 218)"
                            : "#53a8cc",
                        }}
                      >
                        <span
                          style={{
                            color: "#FFFFFF",
                            fontSize: 13,
                            fontWeight: "500",
                          }}
                        >
                          Add Child
                        </span>
                      </Button>
                      <Button
                        loading={updLoading}
                        className="add-member-btn"
                        style={{
                          marginLeft: 20,
                          backgroundColor: addOtherRelation
                            ? "rgb(51, 170, 218)"
                            : "#53a8cc",
                        }}
                        onClick={this.showAddOtherRelationForm}
                      >
                        <span
                          style={{
                            color: "#FFFFFF",
                            fontSize: 13,
                            fontWeight: "500",
                          }}
                        >
                          Add Other Relation
                        </span>
                      </Button>
                    </div>
                  </div>

                  {(clientFamilyMember || addPartner || addOtherRelation) && (
                    <div style={{ width: "100%" }}>
                      {!addPartner && !addOtherRelation && (
                        <div
                          className="profile-first-box"
                          style={{ marginTop: 45 }}
                        >
                          <div>
                            <div
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              <div className="profile-cont-left">
                                <div className="profile-img-cont ant-upload-profile">
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader ant-upload-profile"
                                    showUploadList={false}
                                    action={this.uploadImage}
                                    onChange={this.handleChangeImage}
                                  >
                                    <Spin spinning={this.state.loadUploadImage}>
                                      {addClientImageUrl ? (
                                        <img
                                          src={addClientImageUrl}
                                          alt="avatar"
                                          style={{ width: 105, height: 105 }}
                                        />
                                      ) : (
                                        <img
                                          src={Images.dummyUserImage}
                                          className="profile-img"
                                        />
                                      )}
                                    </Spin>
                                  </Upload>
                                </div>
                                <h3
                                  style={{
                                    textAlign: "center",
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                  }}
                                >
                                  {clientFamilyMember
                                    ? clientFamilyMember.firstName +
                                      " " +
                                      clientFamilyMember.lastName
                                    : ""}
                                </h3>
                                <h5>
                                  {!clientFamilyMember
                                    ? ""
                                    : clientFamilyMember.visaCountyType === 1
                                    ? "STUDENT"
                                    : clientFamilyMember.visaCountyType === 2
                                    ? "VISA"
                                    : clientFamilyMember.visaCountyType === 3 &&
                                      "UNSUCCESSFULL"}
                                </h5>
                                <div style={{ display: "flex", marginTop: 15 }}>
                                  <Tooltip
                                    title="Save Profile Picture"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        marginRight: 5,
                                      }}
                                      onClick={() => {
                                        this.onProfileTopUpdate();
                                      }}
                                    >
                                      <CheckOutlined
                                        style={{ color: "#56B1F9" }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Remove Profile Picture"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState({
                                          addClientImageUrl: "",
                                        });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate();
                                        }, 500);
                                      }}
                                    >
                                      <img
                                        src={Images.cross}
                                        style={{ width: 13, height: 13 }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Download Profile Picture"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: 10,
                                        cursor: "pointer",
                                      }}
                                      onClick={this.downlaodImage}
                                    >
                                      <img
                                        src={Images.download}
                                        className="svg-img"
                                      />
                                    </div>
                                  </Tooltip>
                                  {/* <div style={{ marginLeft: 10 }}>
                                    <img
                                      src={Images.multimediaOption}
                                      className="svg-img"
                                    />
                                  </div> */}
                                </div>
                              </div>
                              <div className="right-cont">
                                <div className="profile-puq-cont">
                                  <div
                                    className="profile-print-box"
                                    style={{ width: 26, height: 26 }}
                                  >
                                    <img
                                      src={Images.printWhite}
                                      className="profile-print-icon"
                                    />
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      className="profile-updbtn-cont"
                                      style={{
                                        marginLeft: 5,
                                        backgroundColor: "#0F7EB6",
                                        border: 1,
                                        borderStyle: "solid",
                                        borderColor: "#0F7EB6",
                                        height: 26,
                                      }}
                                    >
                                      <span className="profile-updbtn-text">
                                        QUESTIONNAIRE
                                      </span>
                                      <img
                                        src={Images.rightArrow}
                                        style={{
                                          transform: `rotate(90deg)`,
                                          width: 10,
                                          height: 10,
                                          marginLeft: 3,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="date-div">
                                  <div>
                                    <span className="date-text">
                                      Created On:{" "}
                                      {clientFamilyMember
                                        ? moment(
                                            clientFamilyMember.createdDate
                                          ).format("DD/MM/YYYY")
                                        : ""}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="date-text">
                                      Modified On:{" "}
                                      {clientFamilyMember &&
                                        moment(
                                          clientFamilyMember.modifiedDate
                                        ).format("DD/MM/YYYY")}
                                    </span>
                                  </div>
                                </div>
                                <div className="lv-main-cont">
                                  <div className="label-value-cont">
                                    <div className="label-cont">
                                      <span className="label-text">EZM ID</span>
                                    </div>
                                    <div className="value-cont">
                                      <span className="value-text">
                                        {clientFamilyMember
                                          ? clientFamilyMember.clientNumberIZM
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="label-value-cont">
                                    <div className="label-cont">
                                      <span className="label-text">
                                        Client ID
                                      </span>
                                    </div>
                                    <div className="value-cont">
                                      <span className="value-text">
                                        {clientFamilyMember
                                          ? clientFamilyMember.clientNumber
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="label-value-cont">
                                    <div
                                      className="label-cont"
                                      style={{ marginLeft: 0.5 }}
                                    >
                                      <span className="label-text">DOB</span>
                                    </div>
                                    <div className="value-cont">
                                      <span className="value-text">
                                        {clientFamilyMember &&
                                        clientFamilyMember.dateOfBirth !==
                                          "1900-01-01T00:00:00+00:00"
                                          ? moment(
                                              clientFamilyMember.createdDate
                                            ).format("DD/MM/YYYY")
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="buttons-row">
                                  <div>
                                    <div className="black-button">
                                      <span className="black-button-text">
                                        Signed
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        signed
                                          ? "right-green-btn-cont"
                                          : "cross-cont"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (!signed) {
                                          this.setState({
                                            signedModalVisible: true,
                                          });
                                        } else {
                                          this.setState({ signed: !signed });
                                          setTimeout(() => {
                                            this.onProfileTopUpdate("onSigned");
                                          }, 500);
                                        }
                                      }}
                                    >
                                      {signed && (
                                        <img
                                          src={Images.tickWhite}
                                          className="svg-btn-img"
                                          style={{ marginRight: 10 }}
                                        />
                                      )}
                                      <img
                                        src={Images.btnImage}
                                        className="profile-btn-img"
                                      />
                                      {!signed && (
                                        <img
                                          src={Images.crossWhite}
                                          className="svg-btn-img"
                                          style={{ marginLeft: 10 }}
                                        />
                                      )}
                                    </div>
                                    <div></div>
                                  </div>
                                  <div style={{ marginLeft: 10 }}>
                                    <div className="black-button">
                                      <span className="black-button-text">
                                        Onshore
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        onshore
                                          ? "right-green-btn-cont"
                                          : "cross-cont"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState({ onshore: !onshore });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate("onShore");
                                        }, 500);
                                      }}
                                    >
                                      {onshore && (
                                        <img
                                          src={Images.tickWhite}
                                          className="svg-btn-img"
                                          style={{ marginRight: 10 }}
                                        />
                                      )}
                                      <img
                                        src={Images.btnImage}
                                        className="profile-btn-img"
                                      />
                                      {!onshore && (
                                        <img
                                          src={Images.crossWhite}
                                          className="svg-btn-img"
                                          style={{ marginLeft: 10 }}
                                        />
                                      )}
                                    </div>
                                    <div></div>
                                  </div>
                                  <div style={{ marginLeft: 10 }}>
                                    <div className="black-button">
                                      <span className="black-button-text">
                                        Active
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        active
                                          ? "right-green-btn-cont"
                                          : "cross-cont"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState({ active: !active });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate("onActive");
                                        }, 500);
                                      }}
                                    >
                                      {active && (
                                        <img
                                          src={Images.tickWhite}
                                          className="svg-btn-img"
                                          style={{ marginRight: 10 }}
                                        />
                                      )}
                                      <img
                                        src={Images.btnImage}
                                        className="profile-btn-img"
                                      />
                                      {!active && (
                                        <img
                                          src={Images.crossWhite}
                                          className="svg-btn-img"
                                          style={{ marginLeft: 10 }}
                                        />
                                      )}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>

                                {false && clientFamilyMember.agentName && (
                                  <div>
                                    <div className="agent-tag-cont">
                                      <div className="agent-tag">
                                        <img
                                          src={crossGreen}
                                          style={{ width: 8, height: 8 }}
                                        />
                                        <span className="tag-text">
                                          Agent Name:{" "}
                                          {clientFamilyMember.agentName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {addPartner || addOtherRelation ? (
                        <Form onFinish={this.onAddPartner}>
                          <div
                            className="profile-additional-box"
                            style={{
                              paddingBottom: 50,
                              marginTop: addPartner ? 40 : 20,
                            }}
                          >
                            <div style={{ marginLeft: 30, paddingTop: 30 }}>
                              <Upload
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={() => false}
                                onPreview={this.handlePreview}
                                action={this.uploadImage}
                                onChange={this.handleChangeUploadCreate}
                              >
                                {fileList && fileList.length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>
                              <Modal
                                visible={previewVisible}
                                title={previewTitle}
                                footer={null}
                                onCancel={this.handleCancel}
                              >
                                <img
                                  alt="example"
                                  style={{ width: "100%" }}
                                  src={previewImage}
                                />
                              </Modal>
                            </div>
                            <PersonalInformationAddPartner
                              clientInfo={clientFamilyMember}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updatePersonalInfo={() =>
                                console.log("personal info")
                              }
                              personsData={teamMembers}
                              clientSourceListing={clientSourceListing}
                              addChild={addPartner}
                              addOtherRelation={addOtherRelation}
                              onChangeContent={(value) =>
                                this.setState({ contentNotes: value })
                              }
                              brnchVisaCountriesData={brnchVisaCountriesData}
                              users={this.state.users}
                            />
                            <MedicalsAddPartner
                              clientInfo={clientFamilyMember}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileMedicals={() =>
                                console.log("medical")
                              }
                              onChangeContent={(value) =>
                                this.setState({ medicalNotes: value })
                              }
                            />
                            <PassportAddPartner
                              clientInfo={clientFamilyMember}
                              countriesData={countriesData}
                              onAddCertificate={this.addCertificate}
                              onAddAuthority={this.addAuthority}
                              onRemoveCertificate={this.removeCertificate}
                              accessingAuthData={accessingAuthData}
                              visaTypeData={visaTypeData}
                              updateProfilePassport={() =>
                                console.log("passport")
                              }
                            />
                            <InzLoginAddPartner
                              clientInfo={clientFamilyMember}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileInzDetail={() =>
                                console.log("inz login")
                              }
                              isClient={false}
                            />
                            <NZQADetailAddPartner
                              clientInfo={clientFamilyMember}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileNZQA={() => console.log("nzqa")}
                            />
                            <BillingAddressAddPartner
                              clientInfo={clientFamilyMember}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileBillingAddress={() =>
                                console.log("Bill")
                              }
                            />
                            <Form.Item
                              style={{ marginLeft: 30, marginTop: 20 }}
                            >
                              <Button className="button-blue" htmlType="submit">
                                <span style={{ color: "#FFFFFF" }}>Save</span>
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      ) : (
                        <div
                          className="profile-additional-box"
                          style={{
                            paddingBottom: 50,
                            marginTop: addPartner ? 40 : 20,
                          }}
                        >
                          <PersonalInformation
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            visaTypeData={visaTypeData}
                            updatePersonalInfo={this.personalInfoUpdate}
                            personsData={teamMembers}
                            clientSourceListing={clientSourceListing}
                            accessingAuthData={accessingAuthData}
                            brnchVisaCountriesData={brnchVisaCountriesData}
                            history={this.props.history}
                            users={this.state.users}
                            agentsRes={agentsRes}
                          />
                          <CurrentVisa
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            visaTypeData={visaTypeData}
                            updateProfileCurrentVisa={this.onUpdateCurrentVisa}
                          />
                          <Medicals
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            visaTypeData={visaTypeData}
                            updateProfileMedicals={this.onUpdateMedicals}
                          />
                          <Passport
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            onAddCertificate={this.props.onAddPoliceCertificate}
                            onAddAuthority={this.addAuthority}
                            onRemoveCertificate={this.removeCertificate}
                            onRemoveAuthority={this.removeAuthority}
                            accessingAuthData={accessingAuthData}
                            visaTypeData={visaTypeData}
                            updateProfilePassport={this.onUpdatePassport}
                            onAddReminderTask={this.props.onAddReminderTask}
                            onGetProfile={this.onGetProfile}
                          />
                          <InzLogin
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            visaTypeData={visaTypeData}
                            updateProfileInzDetail={this.onUpdateInzUserDetail}
                            isClient={false}
                          />
                          <NZQADetail
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            visaTypeData={visaTypeData}
                            updateProfileNZQA={this.onUpdateNZQA}
                          />
                          <BillingAddress
                            clientInfo={clientFamilyMember}
                            countriesData={countriesData}
                            visaTypeData={visaTypeData}
                            updateProfileBillingAddress={
                              this.onUpdateBillingAddress
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {/*<div
                  style={{
                    float: "left",
                    position: "fixed",
                    left: 250,
                    bottom: 30
                  }}
                >
                    <Button
                      loading={updLoading}
                      className="button-blue"
                      htmlType="submit"
                    >
                      <span style={{ color: "#FFFFFF" }}>Update</span>
                    </Button>
                </div>*/}
                </div>
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 30,
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <ProfileSideBar
                        onGetClientTag={this.props.onGetClientTag}
                        clientTagRes={this.props.clientTagRes}
                        getClientRes={this.props.getClientRes}
                        onGetProfileClientTag={this.props.onGetProfileClientTag}
                        onAddProfileClientTag={this.props.onAddProfileClientTag}
                        addProfielTagRes={this.props.addProfielTagRes}
                        onRemoveProfileClientTag={
                          this.props.onRemoveProfileClientTag
                        }
                        removeProfileTagRes={this.props.removeProfileTagRes}
                        visaStatusData={visaStatusData}
                        onUpdateCaseStatus={onUpdateCaseStatus}
                        visaAppData={visaAppData}
                        onGetVisaApplication={onGetVisaApplication}
                        employerJobHistoryCurrentRes={
                          employerJobHistoryCurrentRes
                        }
                        onSetActiveKey={onSetActiveKey}
                        activeKey={activeKey}
                        onUpdatePriority={onUpdatePriority}
                        visaPriorityRes={visaPriorityRes}
                        onSignedVisa={onSignedVisa}
                        onPaidVisa={onPaidVisa}
                        remindersRes={remindersRes && remindersRes.items}
                        onGetClientTask={onGetClientTask}
                        onUpdateTask={onUpdateTask}
                        onGetAllUsers={onGetAllUsers}
                        onAddTaskFollower={onAddTaskFollower}
                        onRemoveTasks={onRemoveTasks}
                        onAddDailyTasks={onAddDailyTasks}
                        onGetClientFamily={onGetClientFamily}
                        onUpdateCompletedTask={onUpdateCompletedTask}
                        onAddTaskFileNote={onAddTaskFileNote}
                        onAddTaskComment={onAddTaskComment}
                        onGetTaskComments={onGetTaskComments}
                        onGetTaskFollowers={onGetTaskFollowers}
                        onLoadClient={loadPartner}
                        onGetReminder={onGetReminder}
                        onGetVisaStatus={onGetVisaStatus}
                        onGetAdmissionProgram={onGetAdmissionProgram}
                        onGetAdmissionStatuses={onGetAdmissionStatuses}
                        onGetVisaType={onGetVisaType}
                        onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default FamilyMember;
