import React, { useState, Fragment, useEffect } from "react";
import { Modal, Spin, Form, Select, Button, Tooltip, message } from "antd";
import { redirectGmailUrl } from "../../../config";
import "../OutlookIntegration/OutlookIntegration.css";
import { useLocation } from "react-router-dom";

var location = window.location.href;
var searchLocation = window.location.search;
const GmailIntegration = ({
  getGmailMail,
  gmailLogin,
  addGmailAccount,
  removeGmailAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [gmail, setGmailObj] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const state = useLocation().state;

  const redirection = () => {
    setLoading(true);
    onGettingGmailRedirectParams();
  };
  const getAllData = () => {
    setLoading(true);
    getGmailMail()
      .then((res) => {
        setGmailObj(res.payload.accessToken);
        setName(res.payload.name);
        setEmail(res.payload.email);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => redirection(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllData(), []);
  const loginOnGmail = () => {
    setLoading(true);
    let _data = {
      uri: redirectGmailUrl,
      userId: localStorage.getItem("userId"),
    };

    gmailLogin(_data)
      .then((response) => {
        setLoading(false);
        // if (response.payload) window.location = response.payload;
        if (response.payload && response.payload.url) {
          window.open(response.payload.url, "_blank");
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onGettingGmailRedirectParams = async () => {
    let params = new URLSearchParams(searchLocation);
    let code = params.get("code");

    if (code) {
      addGmailAccount({
        code: code,
        uri: redirectGmailUrl,
        userId: localStorage.getItem("userId"),
      })
        .then((res) => {
          searchLocation = "";
          message.error(res, "30");
          getAllData();
        })
        .catch((err) => {
          searchLocation = "";
          message.error(err.payload && err.payload.response.data.Detail, "30");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const deleteGmailAccount = () => {
    setLoading(true);
    removeGmailAccount()
      .then(() => {
        setGmailObj("");
        setLoading(false);
      })
      .catch(() => {
        message.error("Couldn't remove gmail account!");
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className="mar-top-75">
                <Form>
                  <div>
                    <h3>GMAIL</h3>
                    <div className="document-sync-box">
                      {gmail !== "" && (
                        <>
                          <div style={{ marginTop: 15 }}>
                            <span style={{ fontWeight: "500" }}>Name:</span>{" "}
                            <span>{name}</span>
                          </div>
                          <div>
                            <span style={{ fontWeight: "500" }}>Email: </span>
                            <span>{email}</span>
                          </div>
                        </>
                      )}
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {gmail !== "" ? (
                              <div className="doc-office">
                                <div className="userDetail">
                                  {/*{gmail.displayName && (*/}
                                  {/*  <p>Name: {gmail.displayName}</p>*/}
                                  {/*)}*/}
                                  {/*{gmail.userPrincipalName && (*/}
                                  {/*  <p>Email: {gmail.userPrincipalName}</p>*/}
                                  {/*)}*/}
                                  <a
                                    className="remove-account"
                                    onClick={() => deleteGmailAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item className="d-block">
                                <Button
                                  onClick={loginOnGmail}
                                  type="primary"
                                  className="button-blue"
                                >
                                  ADD ACCOUNT
                                </Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default GmailIntegration;
