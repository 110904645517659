import React from "react";
import "./TechnicalSupportStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import {
  Row,
  Col,
  Upload,
  Modal,
  message,
  Button,
  Form,
  Select,
  Spin,
} from "antd";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { connect } from "react-redux";
import { addTechnicalComplaint, uploadAvatar } from "../store/Actions";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
const { Option } = Select;

const headOption = [
  { tabName: "TECHNICAL SUPPORT", linkName: "/technical-support" },
];

const types = [
  {
    value: "Suggestions",
    label: "Suggestions",
  },
  {
    value: "Complaints",
    label: "Complaints",
  },
  {
    value: "Bug",
    label: "Bug",
  },
  {
    value: "Compliment",
    label: "Compliment",
  },
  {
    value: "User Limit",
    label: "User Limit",
  },
];

class TechnicalSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: undefined,
      message: "",
      loading: false,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      attachments: [],
      loadingPage: false,
    };
  }

  handleTypeChange = (value) => {
    this.setState({ type: value }, () => {
      console.log("state type", this.state);
    });
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value }, () => {
      console.log("state message", this.state);
    });
  };

  getTypes = () => {
    return (
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={6}>
          <span className="ts-normal-text">Type</span>
        </Col>
        <Col xs={18} className="select-options-technical-support">
          <Select
            value={this.state.type}
            size="middle"
            showSearch
            optionFilterProp="children"
            placeholder="SELECT TYPE"
            virtual={false}
            onChange={this.handleTypeChange}
          >
            {types.map((data) => {
              // eslint-disable-next-line react/jsx-no-undef
              return <Option value={data.value}>{data.label}</Option>;
            })}
          </Select>
        </Col>
      </Row>
    );
  };

  getMessage = () => {
    return (
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={6}>
          <span className="ts-normal-text">Message (Max 500 Characters)</span>
        </Col>
        <Col xs={18}>
          <textarea
            value={this.state.message}
            onChange={this.handleMessageChange}
            rows={9}
            className="ca-text-area"
            style={{ borderRadius: 10 }}
            maxLength="500"
          />
        </Col>
      </Row>
    );
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 20 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 20MB");
    } else {
      this.setState({ fileList }, () => {
        console.log("state", this.state.fileList);
      });
    }
  };

  getFiles = (
    previewVisible,
    previewImage,
    fileList,
    previewTitle,
    uploadButton
  ) => {
    return (
      <Row>
        <Col span={6}>
          <span className="ts-normal-text">
            Attach File (Max 3 files and 30MB size limit per file)
          </span>
        </Col>
        <Col span={18}>
          <div className="ts-upload-file-cont">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Upload
              listType="picture-card"
              fileList={fileList}
              beforeUpload={() => false}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              className={"upload-technical-support-complaint"}
            >
              {fileList.length > 2 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
        </Col>
      </Row>
    );
  };

  getUploadedFileData = async (formData, selectedFile) => {
    return await this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        let _fileURLS = [...this.state.attachments];
        let attachmentUrlSplit = this.props.imageUploadSuccess.split("/");

        _fileURLS.push({
          attachmentURL: attachmentUrlSplit[attachmentUrlSplit.length - 1],
          attachmentName: selectedFile.name,
        });
        this.setState(
          {
            attachments: _fileURLS,
            // eslint-disable-next-line no-unused-expressions
          },
          () => {
            // eslint-disable-next-line no-unused-expressions
            console.log("state after uploading", this.state);
          }
        );
      }
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    let formData = new FormData();
    let { fileList } = this.state;

    if (this.state.fileList) {
      for (let index = 0; index < fileList.length; index++) {
        let selectedFile =
          fileList && fileList[index] && fileList[index].originFileObj;
        formData.append("BranchId", localStorage.getItem("selectedBranchId"));
        formData.append("File", selectedFile);

        if (selectedFile) {
          await this.getUploadedFileData(formData, selectedFile);
        }
      }
    }
    let data = {
      type: this.state.type,
      message: this.state.message,
      attachments: this.state.attachments,
    };

    this.props
      .addTechnicalComplaint(data)
      .then(() => {
        this.setState({ type: undefined, message: "", fileList: [] });
        message.success("Complaint sent successfully!");
        this.setState({ loading: false }, () => {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 16);
        });
      })
      .catch(() => {
        message.warning("An error occurred!");
      });
  };

  getSubmitButton = () => {
    return (
      <Row className="ts-send-btn-cont">
        <Col span={3} offset={21}>
          <Button
            loading={this.state.loading}
            disabled={this.state.type === "" || this.state.message === ""}
            style={{ background: "#1179af", border: "1px solid #1179af" }}
            onClick={this.handleSubmit}
          >
            <span style={{ color: "white" }}>SUBMIT</span>
          </Button>
        </Col>
      </Row>
    );
  };

  callback = (key) => {
    this.setState({ loadingPage: true });
    setTimeout(() => {
      this.setState({ loadingPage: false }, () => {
        this.setState({ type: undefined, message: "", fileList: [] });
      });
    }, 500);
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <Row>
              <Col xs={24}>
                <PotentialHeaderTabs
                  activePath={window.location.pathname}
                  data={headOption}
                  callback={this.callback}
                />
              </Col>
            </Row>
            {this.state.loadingPage === true ? (
              <div className={"spinner"}>
                <Spin size="large" />
              </div>
            ) : (
              <div className="ts-container">
                <Row>
                  <Col span={24}>
                    {this.getTypes()}
                    {this.getMessage()}
                    {this.getFiles(
                      previewVisible,
                      previewImage,
                      fileList,
                      previewTitle,
                      uploadButton
                    )}
                    {this.getSubmitButton()}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
  addTechnicalComplaint: bindActionCreators(addTechnicalComplaint, dispatch),
});

TechnicalSupport = connect(
  mapStateToProps,
  mapDispatchToProps
)(TechnicalSupport);

export default TechnicalSupport;
