import React from "react";
import { saveAs } from "file-saver";
import "../../Clients/ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
} from "antd";
import { bindActionCreators } from "redux";
import {
  sendDynamicLinkEmail,
  getPDF,
  getPDFInvoicePayment,
  editInvoice,
  sendDynamicLinkEmailBlob,
  getEmailContent,
  setEmailTemplate,
} from "../../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { apiRefresh } from "../../services/api";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { getFilterList } from "../../Common/getEmailContentList";
const layoutSendingReceipt = {
  labelCol: { span: 8, offset: 0 },
  wrapperCol: { span: 20, offset: 2 },
};
const { Option } = Select;
class SendEmail extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      sendingReceiptEmail: "",
      loading: false,
      resultList: [],
    };
  }

  componentWillMount() {
    this.props &&
      this.props.onGetEmailContent().then((res) => {
        let resultList = getFilterList(res);
        this.setState({ resultList: resultList });
      });
  }

  componentDidMount() {
    let _toEmail =
      this.props &&
      this.props.potentialClientInfo &&
      this.props.potentialClientInfo.email;
    let setEmailData = this.props.setEmailData;

    this.setState({
      sendingReceiptEmail: setEmailData && setEmailData.htmlTemplate,
    });

    var invTitle = this.props.showInvoiceTitle ? "Invoice" : "Receipt";

    this.formRef.current.setFieldsValue({
      from: localStorage.getItem("selectedBranchName"),
      to:
        this.props && this.props.clientEmail
          ? this.props.clientEmail
          : _toEmail,
      subject: `${invTitle} # ${
        this.props.invoiceNo
      } from ${localStorage.getItem("selectedBranchName")}`,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  onFinishSendingReceipt = (values) => {
    let _from = values.from === undefined ? "" : values.from;
    let _to = values.to === undefined ? "" : values.to;
    let _cc = values.cc === undefined ? "" : values.cc;
    let _bcc = values.bcc === undefined ? "" : values.bcc;
    let _subject = values.subject === undefined ? "" : values.subject;
    const formData = new FormData();
    formData.append("Message", this.state.sendingReceiptEmail);
    formData.append("Subject", _subject);
    formData.append("From", _from);
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "client");
    formData.append("ModuleId", "10");
    formData.append("ClientId", this.props.clientId);
    formData.append(`Recipients[0].name`, _to);
    formData.append(`Recipients[0].type`, "To");
    formData.append(`IsPotential`, true);
    if (_cc !== "") {
      formData.append(`Recipients[1].name`, _cc);
      formData.append(`Recipients[1].type`, "CC");
    }
    if (_bcc !== "") {
      formData.append(`Recipients[2].name`, _bcc);
      formData.append(`Recipients[2].type`, "Bcc");
    }
    formData.append(
      `Attachments[0].type`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].type
    );
    formData.append(
      `Attachments[0].blobURL`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].url
    );
    formData.append(
      `Attachments[0].name`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].name
    );
    this.setState({ loading: true });
    let userId = localStorage.getItem("userId");
    // let clientprofileid = sessionStorage.getItem("clientprofileid");

    const draftData = {
      id: 0,
      emailMessage: this.state.sendingReceiptEmail || "",
      isRead: true,
      attachmentName:
        this.props &&
        this.props.attDataArray &&
        this.props.attDataArray[0].name,
      attachmentUrl:
        this.props && this.props.attDataArray && this.props.attDataArray[0].url,
      sizeInKB: 0,
      subject: _subject,
      from: localStorage.getItem("selectedBranchName"),

      import: false,
      to: _to,
      cc: _cc,
      bcc: _bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: false,
      emailType: 0,
      importText: "",
      potentialClientId: this.props.clientId,
      importedDate: new Date(),
      autoImport: false,
      isSent: true,
      clientEmailType: 0,
      timeStamp: new Date(),
    };

    const histOpt = {
      url: `v1/imap/PotentialClientEmail`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];

    apiRefresh.post(histOpt, draftData).then((res) => {
      formData.append(`emailId`, res);
      this.props
        .sendDynamicLinkEmail(formData)
        .then(async () => {
          message.success("Email sent successfully!");

          let _invoiceInfo = this.props && this.props.invoiceInfo;
          let _statusId;
          if (
            _invoiceInfo &&
            _invoiceInfo.invoiceTypeId === 2 &&
            _invoiceInfo.statusId === 27
          ) {
            _statusId = _invoiceInfo.statusId;
          } else {
            if (
              this.props.state.dueAmount !== 0 &&
              this.props.state.dueAmount > 0
            ) {
              if (
                this.props.state.invoiceStatuses &&
                this.props.state.invoiceStatuses.length > 0
              ) {
                _statusId = this.props.state.invoiceStatuses[1].id;
              }
            } else {
              _statusId = this.props.state.invoiceStatuses[5].id;
            }
          }

          await this.props.getSentStatus(_statusId);

          this.props
            .editInvoice(this.props.state)
            .then(() => {
              message.success("Invoice is updated successfully!");
              this.props.handleCancelSendingReceiptModal();
              this.setState({ loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  onClickPDFFile = () => {
    this.setState({ loading: true });
    let _data = {
      id: this.props && this.props.invoiceId,
      paymentId: this.props && this.props.paymentId,
    };
    if (this.props && this.props.isPDFRelatedToInvoice === true) {
      this.props
        .getPDF(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loading: false });
          saveAs(blob, `Invoice-${this.props.invoiceNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          message.error("Unable to download, please try again!");
        });
    } else {
      this.props
        .getPDFInvoicePayment(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loading: false });
          saveAs(blob, `Receipt-${this.props.invoiceNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          message.error("Unable to download, please try again!");
        });
    }
  };

  getSendingReceiptForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Row>
          <Col xs={3} offset={21}>
            <div
              className="pdf-file-btn"
              style={{ backgroundColor: "#f0ad4e" }}
            >
              <a className="pdf-file-text" onClick={this.onClickPDFFile}>
                {this.props.showInvoiceTitle
                  ? `Invoice-${this.props.invoiceNo}-.PDF`
                  : `Receipt-${this.props.invoiceNo}-.PDF`}
              </a>
            </div>
          </Col>
        </Row>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="From"
          name="from"
          rules={[
            { required: true, message: "Required!" },
            // { type: "email", message: "Invalid!" },
          ]}
        >
          <Input disabled value={localStorage.getItem("selectedBranchName")} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="To"
          name="to"
          rules={[
            { required: true, message: "Required!" },
            { type: "email", message: "Invalid!" },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="CC"
          name="cc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bcc"
          name="bcc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Subject"
          name="subject"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col xs={8} style={{ marginTop: "3vh", marginBottom: "4vh" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Select
              placeholder={"Select content type"}
              virtual={false}
              onChange={(value) => {
                let _emailTemplatePayload = {
                  templateName: value,
                  parameters: [
                    {
                      key: "ClientId",
                      value: this.props.clientId,
                    },
                    {
                      key: "CurrencyId",
                      value:
                        this.props && this.props.invoiceInfo.currencyId
                          ? this.props.invoiceInfo.currencyId.toString()
                          : "106",
                    },
                    {
                      key: "UserId",
                      value: this.props.state.userId,
                    },
                    {
                      key: "Content",
                      value: value,
                    },
                    {
                      key: "Amount",
                      value:
                        this.props && this.props.invoiceInfo.total.toString(),
                    },
                    {
                      key: "InvoiceNumber",
                      value: this.props && this.props.invoiceInfo.invoiceNo,
                    },
                    {
                      key: "DueDate",
                      value: this.props && this.props.invoiceInfo.dueDate,
                    },
                  ],
                };
                this.setState({ loading: true });
                this.props
                  .setEmailTemplate(_emailTemplatePayload)
                  .then((res) => {
                    this.setState({
                      sendingReceiptEmail: res.payload.htmlTemplate,
                      loading: false,
                    });
                  })
                  .catch(() => this.setState({ loading: false }));
              }}
            >
              <Option value={""}>{"Select"}</Option>
              {this.state &&
                this.state.resultList.map((item, index) => (
                  <Option value={item.emailType}>{item.emailType}</Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <FroalaEditorCom
              model={this.state.sendingReceiptEmail}
              onModelChange={this.getEditorValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2} offset={20} style={{ display: "flex" }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="button-blue"
              >
                Send
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="button-blue"
                onClick={props.handleCancelSendingReceiptModal}
              >
                Close
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getEditorValue = (value) => {
    this.setState({ sendingReceiptEmail: value });
  };

  render() {
    let a = this.props;

    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-sending-receipt"
          title="SEND EMAIL NOTIFICATION"
          visible={this.props.visibleSendingReceiptModal}
          onCancel={this.props.handleCancelSendingReceiptModal}
          footer={null}
          maskClosable={false}
        >
          <Spin size="large" spinning={this.state.loading}>
            <Form
              {...layoutSendingReceipt}
              onFinish={this.onFinishSendingReceipt}
              ref={this.formRef}
            >
              {this.getSendingReceiptForm(this.props)}
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  sendDynamicLinkEmail: bindActionCreators(sendDynamicLinkEmailBlob, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  getPDFInvoicePayment: bindActionCreators(getPDFInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
});
SendEmail = connect(mapStateToProps, mapDispatchToProps)(SendEmail);

export default withRouter(SendEmail);
