import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Col,
  Row,
  Select,
  Spin,
  message,
  DatePicker,
} from "antd";
import { UploadOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { apiRefresh } from "../../../../services/api";
import moment from "moment";
import AddAutoReminder from "../../../../Components/Reminder/AddAutoReminder";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

const ProfileEmployer = ({
  userDataEmp,
  onUpdateEmployerManag,
  onGetEmployerManag,
  employerManagRes,
  singleEmployeFuc,
  onAddDocWithName,
  docWithNameRes,
  onGetClientTag,
  clientTagRes,
  onSetActiveKey,
  onGetTeamMember,
  teamMembers,
  countriesData,
  onGetCountries,
  onAddProcessingPerson,
  onGetPermProcessingPerson,
  tabKey,
}) => {
  const [isSelector, setIsSelector] = useState();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [clientTags, setClientTags] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [personIds, setPersonIds] = useState([]);
  const [processingPersons, setProcessingPersons] = useState([]);
  const [users, setUsers] = useState([]);

  const [form] = Form.useForm();

  // useEffect(() => {
  //   onGetClientTag();
  //   onSetActiveKey(null);
  //   onGetTeamMember();
  //   onGetCountries();
  //   onGetPermProcessingPerson(true).then((res) => {
  //     let usersData = res.payload.users.map((item, index) => (
  //       <Option value={item.id}>{item.fullName}</Option>
  //     ));
  //     setUsers(usersData);
  //   });
  // }, []);

  useEffect(() => {
    onGetClientTag();
    onSetActiveKey(null);
    onGetTeamMember();
    onGetCountries();
    onGetPermProcessingPerson(true).then((res) => {
      let usersData = res.payload.users.map((item, index) => (
        <Option value={item.id}>{item.fullName}</Option>
      ));
      setUsers(usersData);
    });
  }, []);

  useEffect(() => {
    if (tabKey == "1") {
      onGetClientTag();
      onSetActiveKey(null);
      onGetTeamMember();
      onGetCountries();
      onGetPermProcessingPerson(true).then((res) => {
        let usersData = res.payload.users.map((item, index) => (
          <Option value={item.id}>{item.fullName}</Option>
        ));
        setUsers(usersData);
      });
    }
  }, [tabKey]);

  useEffect(() => {
    // Fields Set Form Start //
    let style = userDataEmp;
    let tags = [];
    if (userDataEmp) {
      for (var i = 0; i < userDataEmp.tags.length; i++) {
        tags.push(userDataEmp.tags[i].tagId);
      }
    }

    form.setFieldsValue({
      employer_Type: userDataEmp && userDataEmp.employerType,
      name: userDataEmp && userDataEmp.name,
      business: userDataEmp && userDataEmp.business,
      email: userDataEmp && userDataEmp.email,
      contact_no: userDataEmp && userDataEmp.contactNo,
      city: userDataEmp && userDataEmp.city,
      address: userDataEmp && userDataEmp.address,
      contact_person: userDataEmp && userDataEmp.contactPerson,
      mobile: userDataEmp && userDataEmp.mobile,
      website: userDataEmp && userDataEmp.website,
      job_sector: userDataEmp && userDataEmp.jobSector,
      nzbn: userDataEmp && userDataEmp.nzbn,
      occupation: userDataEmp && userDataEmp.occupation,
      company_size: userDataEmp && userDataEmp.numberOfEmployees,
      how_many_years: userDataEmp && userDataEmp.yearsOfBusiness,
      employerType: userDataEmp && userDataEmp.employerType,
      accredationStartDate:
        userDataEmp &&
        moment(userDataEmp.accredationStartDate).format("DD/MM/YYYY") !==
          "01/01/1900" &&
        moment(userDataEmp.accredationStartDate).format("DD/MM/YYYY") !==
          "01/01/0001"
          ? moment(userDataEmp.accredationStartDate)
          : "",
      accredationExpiryDate:
        userDataEmp &&
        moment(userDataEmp.accredationExpiryDate).format("DD/MM/YYYY") !==
          "01/01/1900" &&
        moment(userDataEmp.accredationExpiryDate).format("DD/MM/YYYY") !==
          "01/01/0001"
          ? moment(userDataEmp.accredationExpiryDate)
          : "",
      clientTags: tags,
      countryCodeId:
        userDataEmp && userDataEmp.countryCodeId
          ? userDataEmp.countryCodeId.toString()
          : "64",
    });
    setTagIds(tags);
  }, [form, userDataEmp]);

  useEffect(() => {
    if (userDataEmp) getProcessingPerson(userDataEmp.id);
  }, [userDataEmp]);

  const onFinish = (values) => {
    setLoading(true);
    var userId = localStorage.getItem("userId");
    console.log("Received values of form:", values);
    const data = {
      id: userDataEmp && userDataEmp.id,
      name: values && values.name && values.name.length > 0 ? values.name : "",
      email:
        values && values.email && values.email.length > 0 ? values.email : "",
      contactNo:
        values && values.contact_no && values.contact_no.length > 0
          ? values.contact_no
          : "",
      business:
        values && values.business && values.business.length > 0
          ? values.business
          : "",
      city: values && values.city && values.city.length > 0 ? values.city : "",
      contactPerson:
        values && values.contact_person && values.contact_person.length > 0
          ? values.contact_person
          : "",
      mobile:
        values && values.mobile && values.mobile.length > 0
          ? values.mobile
          : "",
      website:
        values && values.website && values.website.length > 0
          ? values.website
          : "",
      jobSector:
        values && values.job_sector && values.job_sector.length > 0
          ? values.job_sector
          : "",
      nzbn: values && values.nzbn ? values.nzbn : "",
      occupation:
        values && values.occupation && values.occupation.length > 0
          ? values.occupation
          : "",
      numberOfEmployees:
        values && values.company_size && values.company_size.length > 0
          ? values.company_size
          : "",
      yearsOfBusiness:
        values && values.how_many_years && values.how_many_years.length > 0
          ? values.how_many_years
          : "",
      isPotential: userDataEmp && userDataEmp.isPotential,
      employerType: values && values.employer_Type ? values.employer_Type : "",
      address:
        values && values.address && values.address.length > 0
          ? values.address
          : "",
      agreementUrl:
        docWithNameRes && docWithNameRes.fileUrl ? docWithNameRes.fileUrl : "",
      agreementFileName:
        docWithNameRes && docWithNameRes.fileName
          ? docWithNameRes.fileName
          : "",
      xeroID: userDataEmp.xeroID || "",
      jobStatus: userDataEmp.jobStatus || 0,
      clientId: "00000000-0000-0000-0000-000000000000",
      accredationStartDate:
        values.accredationStartDate || "1900-01-01T00:00:00+00",
      accredationExpiryDate:
        values.accredationExpiryDate || "1900-01-01T00:00:00+00",
      countryCodeId: values.countryCodeId ? parseInt(values.countryCodeId) : 64,
      isActive: userDataEmp.isActive,
    };

    onUpdateEmployerManag(data)
      .then(() => {
        onGetEmployerManag();
        if (
          values.accredationExpiryDate &&
          values.accredationExpiryDate != userDataEmp.accredationExpiryDate
        ) {
          let myData = {
            title: "Accreditation Expiry",
            reminderDate:
              values.accredationExpiryDate || "1900-01-01T00:00:00+00:00",
            valueA: "",
            isEmployer: true,
            profileName: userDataEmp.name,
            profileId: userDataEmp.id,
            typeId: 2,
          };
          AddAutoReminder(myData);
        }
        setLoading(false);
        message.success("Successfully Updated");
        singleEmployeFuc(userDataEmp);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    }
    if (fileList && fileList.length > 1) {
      message.warning("You can upload 1 files only");
    } else setFileList(fileList);

    let formData = new FormData();
    formData.append("BranchId", localStorage.getItem("selectedBranchId"));
    if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile =
          fileList[i] && fileList[i].originFileObj && fileList[i].originFileObj;

        formData.append("file", selectedFile);
        setLoading(true);
        onAddDocWithName(formData)
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => setLoading(false));
      }
    }
  };

  const handleSectorChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelector(value);
  };

  const onDownloadDoc = (value) => {
    var title = userDataEmp.agreementFileName;
    var blob = new Blob([value], {
      type: "application/octet-stream",
    });
    saveAs(blob, title);
  };

  const saveAttachment = (value) => {
    // this.setState({ l: true });

    const getAttachmentOpt = {
      url: `v1/document/GetDocumentBytesforAttachment/${
        value.agreementUrl
      }/${localStorage.getItem("selectedBranchId")}`,
    };
    getAttachmentOpt.types = [
      "GET_ATTACHMENT_SUCCES",
      "GET_ATTACHMENT_FAILURE",
    ];

    apiRefresh.getFile(getAttachmentOpt).then((res) => {
      onDownloadDoc(res);
    });
  };

  const getProcessingPerson = (id) => {
    const removeTagOpt = {
      url: `v1/client/processingperson/All/${id}`,
    };
    removeTagOpt.types = [
      "GET_PROCESSING_PERSON_SUCCESS",
      "GET_PROCESSING_PERSON_FAILURE",
    ];

    apiRefresh.get(removeTagOpt).then((res) => {
      let idss = [];
      for (var i = 0; i < res.length; i++) {
        idss.push(res[i].userId);
      }
      form.setFieldsValue({
        processingPersons: idss,
      });
      setPersonIds(idss);
      setProcessingPersons(res);
    });
  };

  const handleChangeClientTags = (value) => {
    let _clientTags = [];
    let ids = [...tagIds];
    let _stateClientTags = [...clientTags];
    if (value.length < ids.length) {
      var val = ids.filter((f) => !value.includes(f));
      var findId =
        userDataEmp && userDataEmp.tags.find((obj) => obj.tagId == val[0]);
      let tagData = {
        id: findId.id,
        delete: true,
      };

      const removeTagOpt = {
        url: `v1/client/AssignTag`,
      };
      removeTagOpt.types = [
        "REMOVE_CLIENT_TAG_SUCCESS",
        "REMOVE_CLIENT_TAG_FAILURE",
      ];

      apiRefresh.delete(removeTagOpt, tagData);
    } else if (value.length > ids.length) {
      var val = value.filter((f) => !ids.includes(f));
      let tagData = {
        subjectId: userDataEmp && userDataEmp.id,
        tagId: val[0],
        deletedDate: null,
      };

      const addTagOpt = {
        url: `v1/client/AssignTag`,
      };
      addTagOpt.types = ["ADD_CLIENT_TAG_SUCCESS", "ADD_CLIENT_TAG_FAILURE"];

      apiRefresh.post(addTagOpt, tagData);
    }
    if (_stateClientTags && _stateClientTags.length > 0) {
      for (let i = 0; i < _stateClientTags.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] === "string") {
            if (_stateClientTags[i].tag === value[j]) {
              _clientTags.push({
                tagId: _stateClientTags[i].tagId,
                tag: _stateClientTags[i].tag,
              });
            }
          }
          if (typeof value[j] === "number") {
            let foundDuplicate = _clientTags.some(
              (el) => el.tagId === value[j]
            );
            if (!foundDuplicate) {
              _clientTags.push({ tagId: value[j] });
            }
          }
        }
      }
    } else {
      for (let k = 0; k < value.length; k++) {
        _clientTags.push({ tagId: value[k] });
      }
    }

    let finalListTags = _clientTags;
    setTagIds(value);
    setClientTags(finalListTags);
  };

  const handleChangeProcessingPersons = (value) => {
    let _processingPersons = [];
    let ids = [...personIds];
    let _stateProcessingPersons = [...processingPersons];
    if (value.length < ids.length) {
      var val = ids.filter((f) => !value.includes(f));
      let ppId = processingPersons.find((obj) => obj.userId == val[0]);
      let tagData = {
        id: ppId.id,
        delete: true,
      };

      const removeTagOpt = {
        url: `v1/client/processingperson`,
      };
      removeTagOpt.types = [
        "REMOVE_CLIENT_TAG_SUCCESS",
        "REMOVE_CLIENT_TAG_FAILURE",
      ];

      apiRefresh.delete(removeTagOpt, tagData).then((res) => {
        getProcessingPerson(userDataEmp.id);
      });
    } else if (value.length > ids.length) {
      var val = value.filter((f) => !ids.includes(f));
      let tagData = {
        clientId: userDataEmp.id,
        userId: val[0],
      };

      onAddProcessingPerson(tagData).then((res) => {
        getProcessingPerson(userDataEmp.id);
        this.props.onGetMailBox(val[0]).then((res) => {
          let addEmailImp = {
            clientId: this.props.potentialClientInfo.id,
            isRead: true,
            isAutoImportEmail: true,
            isImportFromPrimaryEmail: true,
            isImportFromSecondaryEmail: true,
            isImportFromOtherEmail: true,
            importStartDate: new Date(),
            istTimeImport: true,
            userId: val[0],
          };
          this.props.onAutoImportEmailClient(addEmailImp);
        });
      });
    }
    if (_stateProcessingPersons && _stateProcessingPersons.length > 0) {
      for (let i = 0; i < _stateProcessingPersons.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] === "string") {
            if (
              _stateProcessingPersons[i].userName === value[j] ||
              _stateProcessingPersons[i].userId === value[j]
            ) {
              _processingPersons.push({
                userId: _stateProcessingPersons[i].userId,
                userName: _stateProcessingPersons[i].userName,
              });
            }
          }
        }
      }
      if (value.length > _stateProcessingPersons.length) {
        _processingPersons.push({ userId: value[value.length - 1] });
      }
    } else {
      for (let k = 0; k < value.length; k++) {
        _processingPersons.push({ userId: value[k] });
      }
    }

    let finalListPersons = _processingPersons;

    // this.setState({ processingPersons: finalListPersons }, () => {
    //   console.log("processing persons final", this.state);
    // });
    setPersonIds(value);
    setProcessingPersons(finalListPersons);
  };

  return (
    // resolve conflicts
    <Fragment>
      <Spin spinning={loading} size="large">
        <div className=" emp-w-box-employer  add-employer-para">
          <Form
            onFinish={onFinish}
            form={form}
            className="width-100 "
            name="main"
          >
            <Row
              // style={{ marginBottom: "12px" }}
              className="d-aligin-center"
              gutter={4}
            >
              <Col span={12}>
                <p className="emp-section-font">Processing Person</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="multi-select-option"
                  colon={false}
                  labelAlign="left"
                  // label={"Processing Person"}
                  name="processingPersons"
                >
                  <Select
                    showSearch={true}
                    optionFilterProp="children"
                    showArrow
                    virtual={false}
                    placeholder="ADD PROCESSING PERSON"
                    size="middle"
                    mode="multiple"
                    // tagRender={this.tagRender}
                    onChange={handleChangeProcessingPersons}
                  >
                    {/* {teamMembers &&
                      teamMembers.users.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.fullName}</Option>;
                      })} */}
                    {users}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row
              // style={{ marginBottom: "12px" }}
              className="d-aligin-center"
              gutter={4}
            >
              <Col span={12}>
                <p className="emp-section-font">Type</p>
              </Col>
              <Col span={12}>
                <Form.Item name="employer_Type">
                  <Select>
                    <Select.Option key="Employer">Employer</Select.Option>
                    <Select.Option key="Supplier">Supplier</Select.Option>
                    <Select.Option key="Recruiter">Recruiter</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">
                  Name<span className="required-field">*</span>
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Business/Company Name</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="business"
                  rules={[
                    { required: false },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Client Tags</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  // className={
                  //   "add-client-form-inputs pclient-input-margins multi-select-option"
                  // }
                  colon={false}
                  // labelAlign="left"
                  // label={"CLIENT TAGS"}
                  name="clientTags"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    showArrow
                    placeholder="SELECT CLIENT TAGS"
                    size="middle"
                    mode="multiple"
                    virtual={false}
                    // tagRender={this.tagRender}
                    onChange={handleChangeClientTags}
                  >
                    {clientTagRes &&
                      clientTagRes.items &&
                      clientTagRes.items.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Accreditation Approve Date</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  // className={
                  //   "add-client-form-inputs pclient-input-margins multi-select-option"
                  // }
                  colon={false}
                  // labelAlign="left"
                  // label={"CLIENT TAGS"}
                  name="accredationStartDate"
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Accreditation Expiry Date</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  // className={
                  //   "add-client-form-inputs pclient-input-margins multi-select-option"
                  // }
                  colon={false}
                  // labelAlign="left"
                  // label={"CLIENT TAGS"}
                  name="accredationExpiryDate"
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Email</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: false },
                    { type: "email" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Contact No</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contact_no"
                  rules={[
                    { required: false },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 50) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Contact No" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">City</p>
              </Col>
              <Col span={12}>
                <Form.Item name="city" required={true}>
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Address</p>
              </Col>
              <Col span={12}>
                <Form.Item name="address" required={false}>
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Contact Person</p>
              </Col>
              <Col span={12}>
                <Form.Item name="contact_person" required={false}>
                  <Input placeholder="Contact Person" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Mobile</p>
              </Col>
              <Col span={12}>
                <div className="form-mobile-number">
                  <Form.Item
                    className={"add-client-form-inputs"}
                    name="countryCodeId"
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={"Select"}
                      style={{
                        width: "150px",
                        minWidth: "150px",
                      }}
                      dropdownClassName="dropdown-options-width"
                    >
                      {countriesData &&
                        countriesData.items
                          .filter(
                            (obj) =>
                              obj.name.toLowerCase() === "new zealand" ||
                              obj.name.toLowerCase() === "australia" ||
                              obj.name.toLowerCase() === "canada"
                          )
                          .map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return (
                              <Option
                                value={data.callingCode}
                                style={{ fontSize: "14px" }}
                              >
                                +{data.callingCode} - {data.name}
                              </Option>
                            );
                          })}

                      {countriesData &&
                        countriesData.items.map((data, index) => {
                          // eslint-disable-next-line react/jsx-no-undef
                          if (
                            data.name.toLowerCase() === "new zealand" ||
                            data.name.toLowerCase() === "australia" ||
                            data.name.toLowerCase() === "canada"
                          ) {
                          } else {
                            return (
                              <Option
                                value={data.callingCode}
                                style={{ fontSize: "14px" }}
                              >
                                +{data.callingCode} - {data.name}
                              </Option>
                            );
                          }
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="mobile"
                    required={false}
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Mobile" />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Website</p>
              </Col>
              <Col span={12}>
                <Form.Item name="website" required={false}>
                  <Input placeholder="Website" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Job Sector</p>
              </Col>
              <Col span={12}>
                <Form.Item name="job_sector" required={false}>
                  <Select
                    defaultValue="Select job sector"
                    onChange={handleSectorChange}
                  >
                    <Select.Option value="Select job sector">
                      Select job sector
                    </Select.Option>
                    <Select.Option value="Administrative">
                      Administrative
                    </Select.Option>
                    <Select.Option value="AgricultureFarming">
                      Agriculture / Farming
                    </Select.Option>
                    <Select.Option value="Automotive">Automotive</Select.Option>
                    <Select.Option value="Construction">
                      Construction
                    </Select.Option>
                    <Select.Option value="Electrical">Electrical</Select.Option>
                    <Select.Option value="Engineer">Engineer</Select.Option>
                    <Select.Option value="Finance">Finance</Select.Option>
                    <Select.Option value="FMCG">FMCG</Select.Option>
                    <Select.Option value="Hospitality">
                      Hospitality
                    </Select.Option>
                    <Select.Option value="Human Resources">
                      HumanResources
                    </Select.Option>
                    <Select.Option value="Insurance">Insurance</Select.Option>
                    <Select.Option value="ICT">ICT</Select.Option>
                    <Select.Option value="Legal">Legal</Select.Option>
                    <Select.Option value="Marketing">Marketing</Select.Option>
                    <Select.Option value="Medical">Medical</Select.Option>
                    <Select.Option value="Real estate">
                      Realestate
                    </Select.Option>
                    <Select.Option value="Retail">Retail</Select.Option>
                    <Select.Option value="Sales">Sales</Select.Option>
                    <Select.Option value="Supply chain">
                      Supplychain
                    </Select.Option>
                    <Select.Option value="Teachers">Teachers</Select.Option>
                    <Select.Option value="Trades">Trades</Select.Option>
                    <Select.Option value="Not Employed">
                      NotEmployed
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">NZBN</p>
              </Col>
              <Col span={12}>
                <Form.Item name="nzbn" required={false}>
                  <Input placeholder="NZBN" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Occupation</p>
              </Col>
              <Col span={12}>
                <Form.Item name="occupation" required={false}>
                  <Input placeholder="Occupation" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">
                  Company Size (Number of Employees)
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="company_size"
                  required={false}
                  rules={[
                    {
                      max: 18,
                      message: "Entered characters should be less than 18",
                    },
                  ]}
                >
                  <Input placeholder="Company Size (Number of Employees)" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">
                  How Many Years Has This Business In Operation
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="how_many_years"
                  required={false}
                  rules={[
                    {
                      max: 18,
                      message: "Entered characters should be less than 18",
                    },
                  ]}
                >
                  <Input placeholder="How Many Years Has This Business In Operation" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-aligin-center" gutter={8}>
              <Col span={12}>
                <p className="emp-section-font">Agreement</p>
              </Col>
              <Col span={12} className="upload-button">
                <Form.Item required={false}>
                  <Upload
                    single
                    onChange={onHandleChange}
                    beforeUpload={() => false}
                    fileList={fileList}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
                <span className="d-flex">
                  <span className="agreement-data">
                    {userDataEmp && userDataEmp.agreementFileName}
                  </span>
                  {userDataEmp && userDataEmp.agreementFileName && (
                    <span onClick={() => saveAttachment(userDataEmp)}>
                      <VerticalAlignBottomOutlined
                        style={{ fontSize: "16px", cursor: "pointer" }}
                      />
                    </span>
                  )}
                </span>
              </Col>
            </Row>
            <Form.Item>
              <Button
                className="form-btn employer-btn button-blue"
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
};

export default ProfileEmployer;
