import React, { Fragment } from "react";
import { saveAs } from "file-saver";
import "./SchoolManagementStyles.css";
import { Images } from "../Themes";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Space,
  message,
  InputNumber,
} from "antd";
import { UploadOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getSchoolListing,
  getSchoolTypeListing,
  getSchoolListingForType,
  deleteSchool,
  uploadAvatar,
  addSchool,
  getSchoolInfo,
  editSchool,
  deleteContact,
  deleteLevel,
  addDocWithName,
} from "../store/Actions";
import { connect } from "react-redux";
import { apiRefresh } from "../services/api";
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 11, offset: 4 },
};

const layoutFormItem = {
  wrapperCol: { span: 24 },
};

const layoutFormItemSub = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 11, offset: 4 },
};
class SchoolForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visibleSchoolModal: false,
      visibleDeleteModalContact: false,
      schoolName: "",
      schoolType: 0,
      schoolCity: "",
      schoolAddress: "",
      schoolWebsite: "",
      schoolEmail: "",
      schoolNotes: "",
      schoolAggrementURL: "",
      schoolAggrementName: "",
      _contacts: [],
      _levels: [],
      readOnly: false,
    };
  }

  saveAttachment = () => {
    // this.setState({ l: true });
    const getAttachmentOpt = {
      url: `v1/document/GetDocumentBytesforAttachment/${
        this.props.aggrementUrl
      }/${localStorage.getItem("selectedBranchId")}`,
    };
    getAttachmentOpt.types = [
      "GET_ATTACHMENT_SUCCES",
      "GET_ATTACHMENT_FAILURE",
    ];
    // setLoading(true);
    apiRefresh
      .getFile(getAttachmentOpt)
      .then((res) => {
        // setLoading(false);
        this.onDownloadDoc(res);
      })
      .catch((err) => {});
  };

  onDownloadDoc = (value) => {
    var title = this.props.agreementFileName;
    var blob = new Blob([value], {
      type: "application/octet-stream",
    });
    saveAs(blob, title);
  };

  componentWillMount() {
    let link = this.props && this.props.aggrementUrl;
    let name = this.props && this.props.agreementFileName;

    this.setState({
      schoolAggrementURL: link,
      schoolAggrementName: name,
      _contacts: this.props && this.props.contacts,
      _levels: this.props && this.props.levels,
      schoolName: this.props.schoolName,
      schoolCity: this.props.schoolCity,
      schoolAddress: this.props.schoolAddress,
      schoolWebsite: this.props.schoolWebsite,
      schoolEmail: this.props.schoolEmail,
      schoolNotes: this.props.schoolNotes,
      schoolType: this.props.schoolType,
      schoolId: this.props.schoolId,
      readOnly: this.props.readOnly,
    });
    console.log("will mount state", this.state);
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      name: this.props.schoolName,
      city: this.props.schoolCity,
      address: this.props.schoolAddress,
      website: this.props.schoolWebsite,
      email: this.props.schoolEmail,
      notes: this.props.schoolNotes,
      type: this.props.schoolType,
    });
  }

  showModalDeleteContact = (id, index) => {
    this.setState({
      visibleDeleteModalContact: true,
      contactId: index,
      contactDbId: id,
    });
  };

  handleCancelDeleteModalContact = () => {
    this.setState({
      visibleDeleteModalContact: false,
    });
  };

  showModalDeleteLevel = (id, index) => {
    this.setState({
      visibleDeleteModalLevel: true,
      levelId: index,
      levelDbId: id,
    });
  };

  handleCancelDeleteModalLevel = () => {
    this.setState({
      visibleDeleteModalLevel: false,
    });
  };

  handleChangeSchoolName = (e) => {
    this.setState({ schoolName: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeSchoolType = (value) => {
    this.setState({ schoolType: value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeCity = (e) => {
    this.setState({ schoolCity: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeAddress = (e) => {
    this.setState({ schoolAddress: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeWebsite = (e) => {
    this.setState({ schoolWebsite: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeEmail = (e) => {
    this.setState({ schoolEmail: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeNotes = (e) => {
    this.setState({ schoolNotes: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleUploadingAggrementFile = (info) => {
    this.setState({ loading: true });
    let formData = new FormData();
    if (info && info.fileList) {
      let selectedFile = info.fileList[0] && info.fileList[0].originFileObj;
      formData.append("BranchId", localStorage.getItem("selectedBranchId"));
      formData.append("File", selectedFile);
      this.props
        .onUploadAvatar(formData)
        .then(() => {
          if (this.props.imageUploadSuccess) {
            this.setState(
              {
                schoolAggrementURL: this.props.imageUploadSuccess.fileUrl,
                schoolAggrementName: this.props.imageUploadSuccess.fileName,
                loading: false,
              },
              () => {
                console.log("aggrement", this.state);
              }
            );
          }
        })
        .catch(() => {
          this.setState(
            {
              schoolAggrementURL: this.props.imageUploadSuccess.fileUrl,
              schoolAggrementName: this.props.imageUploadSuccess.fileName,
              loading: false,
            },
            () => {
              console.log("aggrement", this.state);
            }
          );
        });
    }
  };

  getSchoolBasicForm = () => {
    const props = {
      defaultFileList: this.state.schoolAggrementURL
        ? [
            // {
            //   name: this.state.schoolAggrementURL.fileName ,
            //   url: this.state.schoolAggrementURL.fileUrl
            // }
          ]
        : [],
      showUploadList: {
        showRemoveIcon: false,
      },
    };
    return (
      <div className="border-box-modal add-employer-para">
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Type"
          name="type"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Select placeholder="Select" onChange={this.handleChangeSchoolType}>
            {this.props &&
              this.props.schoolTypeList &&
              this.props.schoolTypeList.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Required!" },
            {
              pattern: new RegExp("^[a-zA-Z_ ]*$"),
              message:
                "Field accepts only character and space in between words!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value.length > 45) {
                  return Promise.reject("Character limit exceeded!");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            disabled={this.state.readOnly === true}
            placeholder="Enter Name"
            onChange={this.handleChangeSchoolName}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="City"
          name="city"
          rules={[
            {
              pattern: new RegExp("^[a-zA-Z_ ]*$"),
              message:
                "Field accepts only character and space in between words!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value.length > 30) {
                  return Promise.reject("Character limit exceeded!");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input placeholder="Enter City" onChange={this.handleChangeCity} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Address"
          name="address"
        >
          <Input
            placeholder="Enter Address"
            onChange={this.handleChangeAddress}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Website"
          name="website"
          rules={[
            {
              pattern: new RegExp(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
              ),
              message: "Enter a valid website URL!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value.length > 50) {
                  return Promise.reject("Character limit exceeded!");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            placeholder="Enter Website"
            onChange={this.handleChangeWebsite}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Email"
          name="email"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input placeholder="Enter email" onChange={this.handleChangeEmail} />
        </Form.Item>
        <Form.Item colon={false} labelAlign="left" label="Notes" name="notes">
          <Input placeholder="Enter Notes" onChange={this.handleChangeNotes} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Agreement"
          name="aggrement"
        >
          <Upload
            {...props}
            beforeUpload={() => false}
            onChange={this.handleUploadingAggrementFile}
            fileList={[]}
            maxCount={1}
          >
            <Button loading={this.state.loading} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
          <span className="d-flex">
            <span className="agreement-data">
              {this.state && this.state.schoolAggrementName}
            </span>
            {this.props && this.props.aggrementUrl && (
              <span onClick={this.saveAttachment}>
                <VerticalAlignBottomOutlined
                  style={{ fontSize: "16px", cursor: "pointer" }}
                />
              </span>
            )}
          </span>
        </Form.Item>
      </div>
    );
  };

  getContactForm = () => {
    return (
      <div>
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <div>
              <Row className={"margin-contact-container"}>
                <Col xs={5}>
                  <div className={"display-contact-text-container"}>
                    <h3 style={{ fontSize: "14px", fontWeight: "600" }}>
                      {"Contacts"}
                    </h3>
                    <div
                      style={{
                        display:
                          this.state &&
                          this.state._contacts &&
                          this.state._contacts.length > 0
                            ? "block"
                            : "none",
                      }}
                      className={"show-container"}
                      onClick={() => {
                        this.setState({ IsContactListVisible: true });
                      }}
                    >
                      <p className={"text-style-show-container"}>
                        Show Contacts
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="add-tag-btn">
                    <Form.Item className="form-add-btn ">
                      <img
                        src={Images.addIcon}
                        className="icons-client add-icon"
                        type="primary"
                        onClick={() => add()}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col xs={24}>
                  {fields.map((field, index) => (
                    <Row
                      className="forms-styling-tabs border-box-modal-add"
                      key={index}
                    >
                      <Col xs={24}>
                        <Row>
                          <Col xs={1} offset={23}>
                            <a
                              href="javascript:"
                              onClick={() => {
                                remove(field.name);
                              }}
                            >
                              <DeleteOutlined
                                className={"positioning-delete-icon"}
                              />
                            </a>
                          </Col>
                        </Row>
                        <Form.Item key={field.key} {...layoutFormItem}>
                          <Form.Item
                            {...layoutFormItemSub}
                            {...field}
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            colon={false}
                            labelAlign="left"
                            label="Name"
                            rules={[{ required: true, message: "Required!" }]}
                          >
                            <Input placeholder="Enter Name" />
                          </Form.Item>
                          <Form.Item
                            {...layoutFormItemSub}
                            {...field}
                            name={[field.name, "email"]}
                            fieldKey={[field.fieldKey, "email"]}
                            colon={false}
                            labelAlign="left"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                              {
                                type: "email",
                                message: "Invalid!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter email" />
                          </Form.Item>
                          <Form.Item
                            {...layoutFormItemSub}
                            {...field}
                            name={[field.name, "description"]}
                            fieldKey={[field.fieldKey, "description"]}
                            colon={false}
                            labelAlign="left"
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                            ]}
                          >
                            <TextArea placeholder="Enter description" />
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
          )}
        </Form.List>
      </div>
    );
  };

  getLevelsForm = () => {
    return (
      <div style={{ marginTop: "30px" }}>
        <Form.List name="levels">
          {(fields, { add, remove }) => (
            <div>
              <Row className={"margin-contact-container"}>
                <Col xs={5}>
                  <div className={"display-contact-text-container"}>
                    <h3 style={{ fontSize: "14px", fontWeight: "600" }}>
                      {"Levels"}
                    </h3>
                    <div
                      style={{
                        display:
                          this.state &&
                          this.state._levels &&
                          this.state._levels.length > 0
                            ? "block"
                            : "none",
                      }}
                      className={"show-container"}
                      onClick={() => {
                        this.setState({ IsLevelListVisible: true });
                      }}
                    >
                      <p className={"text-style-show-container"}>Show Levels</p>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="add-tag-btn">
                    <Form.Item className="form-add-btn ">
                      <img
                        src={Images.addIcon}
                        className="icons-client add-icon"
                        type="primary"
                        onClick={() => add()}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col xs={24}>
                  {fields.map((field, index) => (
                    <div
                      className="forms-styling-tabs border-box-modal-add"
                      key={index}
                    >
                      <Row>
                        <Col xs={1} offset={23}>
                          <a
                            href="javascript:"
                            onClick={(e) => {
                              e.stopPropagation();
                              remove(field.name);
                            }}
                          >
                            <DeleteOutlined
                              className={"positioning-delete-icon"}
                            />
                          </a>
                        </Col>
                      </Row>
                      <Form.Item key={field.key} {...layoutFormItem}>
                        <Form.Item
                          {...field}
                          {...layoutFormItemSub}
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "levelName"]}
                          colon={false}
                          labelAlign="left"
                          label="Name"
                          rules={[{ required: true, message: "Required!" }]}
                        >
                          <Input placeholder="Enter Name" />
                        </Form.Item>
                        <Form.Item
                          {...layoutFormItemSub}
                          {...field}
                          name={[field.name, "description"]}
                          fieldKey={[field.fieldKey, "levelDescription"]}
                          colon={false}
                          labelAlign="left"
                          label="Description"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea placeholder="Enter description" />
                        </Form.Item>
                        <Form.Item
                          {...layoutFormItemSub}
                          {...field}
                          name={[field.name, "percentage"]}
                          fieldKey={[field.fieldKey, "levelCommission"]}
                          colon={false}
                          labelAlign="left"
                          label="Commission"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter commission %"
                            style={{ width: "269px" }}
                          />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          )}
        </Form.List>
      </div>
    );
  };

  handleChangeContactName = (e, data) => {
    let __contacts = [...this.state._contacts];
    if (__contacts && __contacts) {
      for (
        let contactIndex = 0;
        contactIndex < __contacts.length;
        contactIndex++
      ) {
        if (__contacts[contactIndex].id === data.id) {
          __contacts[contactIndex].name = e.target.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
      }
    }
    this.setState({ _contacts: __contacts }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeContactEmail = (e, data) => {
    let __contacts = [...this.state._contacts];
    if (__contacts && __contacts) {
      for (
        let contactIndex = 0;
        contactIndex < __contacts.length;
        contactIndex++
      ) {
        if (__contacts[contactIndex].id === data.id) {
          __contacts[contactIndex].email = e.target.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
      }
    }
    this.setState({ _contacts: __contacts }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeContactDescription = (e, data) => {
    let __contacts = [...this.state._contacts];
    if (__contacts && __contacts) {
      for (
        let contactIndex = 0;
        contactIndex < __contacts.length;
        contactIndex++
      ) {
        if (__contacts[contactIndex].id === data.id) {
          __contacts[contactIndex].description = e.target.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
      }
    }
    this.setState({ _contacts: __contacts }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeLevelName = (e, data) => {
    let __levels = [...this.state._levels];
    if (__levels && __levels) {
      for (let levelIndex = 0; levelIndex < __levels.length; levelIndex++) {
        if (__levels[levelIndex].id === data.id) {
          __levels[levelIndex].name = e.target.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
      }
    }
    this.setState({ _levels: __levels }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeLevelDescription = (e, data) => {
    let __levels = [...this.state._levels];
    if (__levels && __levels) {
      for (let levelIndex = 0; levelIndex < __levels.length; levelIndex++) {
        if (__levels[levelIndex].id === data.id) {
          __levels[levelIndex].description = e.target.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
      }
    }
    this.setState({ _levels: __levels }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeLevelCommission = (value, data) => {
    let __levels = [...this.state._levels];
    if (__levels && __levels) {
      for (let levelIndex = 0; levelIndex < __levels.length; levelIndex++) {
        if (__levels[levelIndex].id === data.id) {
          __levels[levelIndex].percentage = value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
      }
    }
    this.setState({ _levels: __levels }, () => {
      console.log("state", this.state);
    });
  };

  getAddedContacts = () => {
    return (
      <Row
        style={{
          display: this.state.IsContactListVisible ? "flex" : "none",
          marginTop: "30px",
        }}
      >
        <Col xs={24}>
          <div className="border-box-modal-add">
            <Row>
              <Col xs={6}>
                <div
                  className={"hide-levels-container"}
                  onClick={() => {
                    this.setState({ IsContactListVisible: false });
                  }}
                >
                  <p className={"text-style-show-container"}>Hide Contacts</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Row style={{ marginTop: "17px" }}>
                  <Col xs={7}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>{"Name"}</h4>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>{"Email"}</h4>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>{"Description"}</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.state._contacts.map((data, index) => {
              console.log("within render", data);
              return (
                <div key={data.id}>
                  <Row style={{ marginBottom: "-19px" }}>
                    <Col xs={7} style={{ marginRight: "6px" }}>
                      <Input
                        defaultValue={data.name}
                        placeholder="Enter name"
                        size="small"
                        onChange={(e) => this.handleChangeContactName(e, data)}
                      />
                    </Col>
                    <Col
                      xs={7}
                      style={{ marginTop: "-20px", marginRight: "6px" }}
                    >
                      <Form.Item
                        {...layoutFormItem}
                        name={index}
                        rules={[
                          {
                            type: "email",
                            message: "Invalid!",
                          },
                        ]}
                      >
                        <Input
                          defaultValue={data.email}
                          placeholder="Enter email"
                          size="small"
                          onChange={(e) =>
                            this.handleChangeContactEmail(e, data)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={7} style={{ marginRight: "6px" }}>
                      <Input
                        defaultValue={data.description}
                        placeholder="Enter description"
                        size="small"
                        onChange={(e) =>
                          this.handleChangeContactDescription(e, data)
                        }
                      />
                    </Col>
                    <Col xs={2}>
                      <a
                        href="javascript:"
                        onClick={(e) => {
                          this.showModalDeleteContact(data.id, index);
                        }}
                      >
                        <DeleteOutlined />
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={"border-show-contacts"}
                      style={{ marginTop: "4px" }}
                    ></Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    );
  };

  handleDeleteContact = (id, index) => {
    this.props.deleteContact(this.state.contactDbId).then(() => {
      let tempContacts = [...this.state._contacts];
      let _con = tempContacts.filter((data) => data.id !== id);

      this.setState(
        {
          _contacts: _con,
        },
        () => {
          this.setState({ visibleDeleteModalContact: false });
          console.log("state", this.state);
        }
      );
    });
  };

  getModalDeleteContact = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModalContact}
          onCancel={this.handleCancelDeleteModalContact}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete contact?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModalContact();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.handleDeleteContact(
                    this.state.contactDbId,
                    this.state.contactId
                  );
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteLevel = (levelDbId, levelId) => {
    // let tempContacts = [];
    this.props.deleteLevel(this.state.levelDbId).then(() => {
      let tempLevels = [...this.state._levels];
      let _lev = tempLevels.filter((data) => data.id !== levelDbId);

      // this.state._contacts.map((data, index) => {
      //   if (data.id !== id) {
      //     tempContacts.push(data);
      //   }
      // });
      this.setState(
        {
          _levels: _lev,
        },
        () => {
          this.setState({ visibleDeleteModalLevel: false });
          console.log("state", this.state);
        }
      );
    });
  };

  getModalDeleteLevel = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModalLevel}
          onCancel={this.handleCancelDeleteModalLevel}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete level?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModalLevel();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.handleDeleteLevel(
                    this.state.levelDbId,
                    this.state.levelId
                  );
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getAddedLevels = () => {
    return (
      <Row
        style={{
          display: this.state.IsLevelListVisible ? "flex" : "none",
          marginTop: "30px",
        }}
      >
        <Col xs={24}>
          <div className="border-box-modal-add">
            <Row>
              <Col xs={6}>
                <div
                  className={"hide-levels-container"}
                  onClick={() => {
                    this.setState({ IsLevelListVisible: false });
                  }}
                >
                  <p className={"text-style-show-container"}>Hide Levels</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Row style={{ marginTop: "17px" }}>
                  <Col xs={7}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>{"Name"}</h4>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>{"Description"}</h4>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>{"Commission"}</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.state &&
              this.state._levels &&
              this.state._levels.map((data, index) => {
                return (
                  <div key={data.id}>
                    <Row style={{ marginBottom: "-19px" }}>
                      <Col xs={7} style={{ marginRight: "6px" }}>
                        <Input
                          defaultValue={data.name}
                          placeholder="Enter name"
                          size="small"
                          onChange={(e) => this.handleChangeLevelName(e, data)}
                        />
                      </Col>
                      <Col xs={7} style={{ marginRight: "6px" }}>
                        <Input
                          defaultValue={data.description}
                          placeholder="Enter description"
                          size="small"
                          onChange={(e) =>
                            this.handleChangeLevelDescription(e, data)
                          }
                        />
                      </Col>
                      <Col xs={7} style={{ marginRight: "6px" }}>
                        <InputNumber
                          defaultValue={data.percentage}
                          placeholder="Enter commission %"
                          style={{
                            width: "171px",
                            height: "20px",
                            marginTop: "2px",
                          }}
                          size="small"
                          onChange={(value) =>
                            this.handleChangeLevelCommission(value, data)
                          }
                        />
                      </Col>
                      <Col xs={2}>
                        <a
                          href="javascript:"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.showModalDeleteLevel(data.id, index);
                          }}
                        >
                          <DeleteOutlined />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className={"border-show-contacts"}
                        style={{ marginTop: "27px" }}
                      ></Col>
                    </Row>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
    );
  };

  getButtons = () => {
    return (
      <Row>
        <Col xs={3} offset={18}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="button-blue">
              Save
            </Button>
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item>
            <Button
              className="button-blue"
              type="primary"
              onClick={this.props.handleCancelAddSchoolModal}
            >
              Close
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  onFinish = (values) => {
    let _valuesContacts = values.contacts === undefined ? [] : values.contacts;
    let _valuesLevels = values.levels === undefined ? [] : values.levels;
    if (
      this.props &&
      this.props.schoolName &&
      _valuesContacts &&
      _valuesContacts
    ) {
      {
        for (
          let contactPointer = 0;
          contactPointer < _valuesContacts.length;
          contactPointer++
        ) {
          _valuesContacts[contactPointer].id = 0;
          _valuesContacts[contactPointer].schoolId = this.state.schoolId;
        }
      }
    }
    if (this.props && this.props.schoolName && _valuesLevels && _valuesLevels) {
      {
        for (
          let levelPointer = 0;
          levelPointer < _valuesLevels.length;
          levelPointer++
        ) {
          _valuesLevels[levelPointer].id = 0;
          _valuesLevels[levelPointer].schoolId = this.state.schoolId;
        }
      }
    }
    let finalListContacts = [..._valuesContacts, ...this.state._contacts];
    let finalListLevels = [..._valuesLevels, ...this.state._levels];
    this.setState(
      {
        contacts: finalListContacts,
        levels: finalListLevels,
      },
      () => {
        if (this.props && this.props.schoolName) {
          this.props.editSchool(this.state).then(() => {
            this.props.handleCancelAddSchoolModal();
            message.success("School is updated successfully!");
            this.props.getSchoolListingForType(
              this.props && this.props.activeTabId
            );
          });
        } else {
          this.props.addSchool(this.state).then(() => {
            this.props.handleCancelAddSchoolModal();
            message.success("School is added successfully!");
            this.props.getSchoolListingForType(
              this.props && this.props.activeTabId
            );
          });
        }
      }
    );

    console.log("state", this.state);
  };

  getModalSchool = () => {
    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal"
          title={
            this.props && this.props.schoolName ? "Update School" : "Add School"
          }
          visible={this.props.visibleSchoolModal}
          onCancel={this.props.handleCancelAddSchoolModal}
          footer={null}
          maskClosable={false}
        >
          <Form {...layout} onFinish={this.onFinish} ref={this.formRef}>
            {this.getSchoolBasicForm()}
            {this.getContactForm()}
            {this.getAddedContacts()}
            {this.getModalDeleteContact()}
            {this.getLevelsForm()}
            {this.getModalDeleteLevel()}
            {this.getAddedLevels()}
            {this.getButtons()}
          </Form>
        </Modal>
      </div>
    );
  };

  render() {
    return <div>{this.getModalSchool()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    schoolTypeListing:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolTypeListing &&
      state.schoolReducer.schoolTypeListing &&
      state.schoolReducer.schoolTypeListing.items,
    imageUploadSuccess: state.employerManagReducer.docWithNameRes,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSchoolTypeListing: bindActionCreators(getSchoolTypeListing, dispatch),
  getSchoolListing: bindActionCreators(getSchoolListing, dispatch),
  getSchoolListingForType: bindActionCreators(
    getSchoolListingForType,
    dispatch
  ),
  deleteSchool: bindActionCreators(deleteSchool, dispatch),
  onUploadAvatar: bindActionCreators(addDocWithName, dispatch),
  addSchool: bindActionCreators(addSchool, dispatch),
  editSchool: bindActionCreators(editSchool, dispatch),
  getSchoolInfo: bindActionCreators(getSchoolInfo, dispatch),
  deleteContact: bindActionCreators(deleteContact, dispatch),
  deleteLevel: bindActionCreators(deleteLevel, dispatch),
});

SchoolForm = connect(mapStateToProps, mapDispatchToProps)(SchoolForm);

export default SchoolForm;
