// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

const dateFormate = "DD/MM/YYYY";

function CurrentVisa(Props) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(false);
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");
  const [visaStatus, setVisaStatus] = useState(
    Props.clientInfo && Props.clientInfo.currentVisaTypeId !== 0
      ? "Approved"
      : ""
  );

  const [selectedOption, setSelectedOption] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    if (Props.clientInfo) {
      if (Props.clientInfo.currentVisaTypeId !== 0) {
        setVisaStatus("Approved");
      }
      setCertificates(Props.clientInfo.certificates);
      setAuthorities(Props.clientInfo.authorities);

      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (Props.clientInfo.emails.length > 0) {
        for (var i = 0; i < Props.clientInfo.emails.length; i++) {
          if (Props.clientInfo.emails[i].emailTypeId === 1) {
            email = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 2) {
            secondaryEmail = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 3) {
            otherEmail = Props.clientInfo.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (Props.clientInfo.addresses.length > 0) {
        const findBillAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 2
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }

        const findAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (Props.clientInfo.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: Props.clientInfo.medicals[0].medicalIssueDate,
          medicalExpiryDate: Props.clientInfo.medicals[0].medicalExpiryDate,
          medicalGrading: Props.clientInfo.medicals[0].medicalGrading,
          xrayIssueDate: Props.clientInfo.medicals[0].xrayIssueDate,
          xrayExpiryDate: Props.clientInfo.medicals[0].xrayExpiryDate,
          xrayGrading: Props.clientInfo.medicals[0].xrayGrading,
          medicalNotes: Props.clientInfo.medicals[0].medicalNotes,
          medicalNotesDetail: Props.clientInfo.medicals[0].medicalNotesDetail,
        };
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
      };
      if (Props.clientInfo.phones.length > 0) {
        const findMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
        }
        const findSecondaryMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }

      form.setFieldsValue({
        visaCountryId: Props.clientInfo.visaCountryId,
        visaCountryType: Props.clientInfo.visaCountyType.toString(),
        title: Props.clientInfo.title,
        middleName: Props.clientInfo.middleName,
        gender: Props.clientInfo.gender,
        dateOfBirth: moment(Props.clientInfo.dateOfBirth),
        address: clientAddressData ? clientAddressData.address : "",
        nationalityId: Props.clientInfo.nationalityId,
        saleDate: moment(Props.clientInfo.saleDate),
        sourceId: Props.clientInfo.sourceId.toString(),
        jobSectorId: Props.clientInfo.jobSectorId.toString(),
        companyOptional: Props.clientInfo.companyOptional,
        clientSerial: Props.clientInfo.clientSerial,
        nationalityCountry: Props.clientInfo.nationalityCountry,
        firstName: Props.clientInfo.firstName,
        lastName: Props.clientInfo.lastName,
        age: Props.clientInfo.age,
        dealWorth: Props.clientInfo.dealWorth,
        maritalStatus: Props.clientInfo.maritalStatus,
        dependentChildren: Props.clientInfo.dependentChildren,
        sourceDescription: Props.clientInfo.sourceDescription,
        occupation: Props.clientInfo.occupation,
        visaDenied: Props.clientInfo.visaDenied,
        deniedText: Props.clientInfo.deniedText,
        visaText: Props.clientInfo.visaText,
        currentVisaTypeId:
          Props.clientInfo.currentVisaTypeId === 0
            ? ""
            : Props.clientInfo.currentVisaTypeId.toString(),
        currentNewZealandVisaExpiry:
          Props.clientInfo.currentNewZealandVisaExpiry ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.currentNewZealandVisaExpiry),
        travelConditionsValidTo:
          Props.clientInfo.travelConditionsValidTo ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.travelConditionsValidTo),
        clientNumber: Props.clientInfo.clientNumber,
        inzUserName: Props.clientInfo.inzUserName,
        inzPassword: Props.clientInfo.inzPassword,
        inzFeeDate: moment(Props.clientInfo.inzFeeDate),
        nzqaOnlineSubDate: moment(Props.clientInfo.nzqaOnlineSubDate),
        nzqaDocumentSubDate: moment(Props.clientInfo.nzqaDocumentSubDate),
        nzqaDocumentRetDate: moment(Props.clientInfo.nzqaDocumentRetDate),
        email: email,
        secondaryEmail: secondaryEmail,
        otherEmail: otherEmail,
        contactPerson: billAddressData ? billAddressData.contactPerson : "",
        flat: billAddressData ? billAddressData.flat : "",
        streetNumber: billAddressData ? billAddressData.streetNumber : "",
        suburb: billAddressData ? billAddressData.suburb : "",
        city: billAddressData ? billAddressData.city : "",
        billCountry: billAddressData ? billAddressData.country : "",
        zipcode: billAddressData ? billAddressData.zipcode : "",
        clientAddress: clientAddressData ? clientAddressData.address : "",
        mobilePhone: phoneData ? phoneData.mobile : "",
        secondaryMobile: phoneData ? phoneData.secondaryMobile : "",
        overseasMobile: phoneData ? phoneData.overseasMobile : "",
        landLine: phoneData ? phoneData.landLine : "",
        otherMobile: phoneData ? phoneData.otherMobile : "",
        medicalIssueDate: medicalData
          ? moment(medicalData.medicalIssueDate)
          : "",
        medicalExpiryDate: medicalData
          ? moment(medicalData.medicalExpiryDate)
          : "",
        medicalGrading: medicalData ? medicalData.medicalGrading : "",
        xrayIssueDate: medicalData ? moment(medicalData.xrayIssueDate) : "",
        xrayExpiryDate: medicalData ? moment(medicalData.xrayExpiryDate) : "",
        xrayGrading: medicalData ? medicalData.xrayGrading : "",
        medicalNotes: medicalData ? medicalData.medicalNotes : "",
        medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
        passportNo:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportNo
            : "",
        passportCountry:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportCountry.toString()
            : "",
        passportIssueDate:
          Props.clientInfo.passports.length > 0
            ? moment(Props.clientInfo.passports[0].passportIssueDate)
            : "",
        passportExpiryDate:
          Props.clientInfo.passports.length > 0
            ? moment(Props.clientInfo.passports[0].passportExpiryDate)
            : "",
        secondPassportNo:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportNo
            : "",
        secondPassportCountry:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportCountry.toString()
            : "",
        secondPassportIssueDate:
          Props.clientInfo.passports.length > 1
            ? moment(Props.clientInfo.passports[1].passportIssueDate)
            : "",
        secondPassportExpiryDate:
          Props.clientInfo.passports.length > 1
            ? moment(Props.clientInfo.passports[1].passportExpiryDate)
            : "",
      });
    }
  }, [Props, form]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  const country = [];
  const authorityOption = [];
  const visaTypeOption = [];
  if (Props.countriesData && Props.countriesData.items.length > 0) {
    for (let i = 0; i < Props.countriesData.items.length; i++) {
      country.push(
        <Option key={Props.countriesData.items[i].id}>
          {Props.countriesData.items[i].name}
        </Option>
      );
    }
  }

  if (Props.accessingAuthData && Props.accessingAuthData.length > 0) {
    for (let i = 0; i < Props.accessingAuthData.length; i++) {
      authorityOption.push(
        <Option key={Props.accessingAuthData[i].id}>
          {Props.accessingAuthData[i].name}
        </Option>
      );
    }
  }

  if (Props.visaTypeData && Props.visaTypeData.items.length > 0) {
    for (let i = 0; i < Props.visaTypeData.items.length; i++) {
      visaTypeOption.push(
        <Option key={Props.visaTypeData.items[i].id}>
          {Props.visaTypeData.items[i].visaTypeName}
        </Option>
      );
    }
  }

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const handleChangeCertificateCountry = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const handleChangePassportCountry = (passportCountry) => {
    // setPassportCountry(passportCountry);
  };

  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const onChangeCertificateIssueDate = (value, dateString) => {
    setCertificateIssueDate(value);
  };

  const onChangeCertificateExpiryDate = (value, dateString) => {
    setCertificateExpiryDate(value);
  };

  const onChangeAuthSubDate = (value, dateString) => {
    setAuthSubDate(value);
  };

  const onChangeAuthRetDate = (value, dateString) => {
    setAuthRetDate(value);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const changeDeclineValue = (value) => {
    console.log("onOk: ", value);
    setVisaDenied(value);
  };

  const onOkCertificateIssueDate = (value) => {
    console.log("onOk: ", value);
  };

  const addCertificate = () => {
    let data = {
      clientId: Props.clientInfo.id,
      issueDate: certificateIssueDate,
      certificateExpiryDate: certificateExpiryDate,
      country: parseInt(selectedCountry),
    };

    Props.onAddCertificate(data);
  };

  const addAuthority = () => {
    let data = [
      {
        clientId: Props.clientInfo.id,
        authority: 0,
        submittedDate: authSubDate,
        returnedDate: authRetDate,
        refNumber: authRefNo,
      },
    ];

    Props.onAddAuthority(data);
  };

  const removeAuthority = (id) => {
    let data = {
      id: id,
      delete: true,
    };

    Props.onRemoveAuthority(data);
  };

  const onContentChange = (model) => {
    setContent(model);
  };

  const onUpdatePersonalInfo = (values) => {
    Props.updatePersonalInfo(values);
  };

  const updateCurrentVisa = (values) => {
    Props.updateProfileCurrentVisa(values, Props.clientInfo);
  };

  const updateNZQA = (values) => {
    Props.updateProfileNZQA(values);
  };

  const updateInzUserDetail = (values) => {
    Props.updateProfileInzDetail(values);
  };

  const updatePassport = (values) => {
    Props.updateProfilePassport(values);
  };

  const updateMedical = (values) => {
    Props.updateProfileMedicals(values);
  };

  const updateBillingAddress = (values) => {
    Props.updateProfileBillingAddress(values);
  };

  return (
    <div>
      <Form form={form} onFinish={updateCurrentVisa}>
        <div
          className="denied-cont"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div
            className="denied-cont"
            style={{ padding: 0, alignItems: "center" }}
          >
            <div className="profile-down-arrow-cont">
              <img
                src={Images.whiteArrow}
                className="profile-down-arrow-icon"
              />
            </div>
            <span className="denied-text">Current Visa Detail</span>
          </div>
          <Form.Item>
            <Button className="button-blue" htmlType="submit">
              <span style={{ color: "#FFFFFF" }}>Update</span>
            </Button>
          </Form.Item>
        </div>

        <div className="form-container">
          <div
            className="form-cont"
            style={{ paddingBottom: 0, justifyContent: "space-between" }}
          >
            <div className="input-cont-width">
              <p>Current Visa Type</p>
              <Form.Item name="currentVisaTypeId">
                <Select showSearch optionFilterProp="children">
                  {visaTypeOption}
                </Select>
              </Form.Item>
            </div>
            <div className="input-cont-width" style={{ marginLeft: 25 }}>
              <p>Status</p>
              <Form.Item>
                <div className="profile-input-border">
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={visaStatus}
                    onChange={(e) => setVisaStatus(e.target.value)}
                  />
                </div>
              </Form.Item>
            </div>
          </div>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Visa</p>
              <div className="profile-input-border">
                <Form.Item name="visaText">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Current Visa Expiry Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="currentNewZealandVisaExpiry"
                  style={{ width: "100%" }}
                >
                  <DatePicker format={dateFormate} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Travel Conditions Valid To</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="travelConditionsValidTo"
                  style={{ width: "100%" }}
                >
                  <DatePicker format={dateFormate} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default CurrentVisa;
