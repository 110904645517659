import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  message,
  Col,
  InputNumber,
  Spin,
  Collapse,
  Modal,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addNewTemplate,
  addTemplateToClientAppliedTemplateListing,
  getAllTaxesListing,
  getClientTemplateListing,
  getTemplateListing,
  deleteTemplateForClient,
  getClientTemplateInfo,
  getTemplateInfo,
} from "../../store/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccordianTemplate from "./AccordianTemplate";
import moment from "moment";
import activityData from "../ClientActivity/ActivityData";
const { Option } = Select;
const { Panel } = Collapse;

class Balance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientTemplateListing: [],
      templateListing: [],
      templateId: undefined,
      visibleDeleteModal: false,
      activeKeysList: [],
      index: 0,
      nameForDeleteTemplateActivity: ""
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getTemplateListing()
      .then(() => {
        this.props
          .getClientTemplateListing()
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.props
          .getClientTemplateListing()
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _clientTemplateListing = nextProps && nextProps.clientTemplateListing;
    let _templateListing = nextProps && nextProps.templateListing;
    this.setState(
      {
        templateListing: _templateListing,
        clientTemplateListing: _clientTemplateListing,
      },
      () => {
        let _tempClientTemplateListing = this.state.clientTemplateListing;
        let _activeKeysList = this.state.activeKeysList;
        if (
          _tempClientTemplateListing &&
          _tempClientTemplateListing.length > 0
        ) {
          for (
            let _indicatorPoint = 0;
            _indicatorPoint < _tempClientTemplateListing.length;
            _indicatorPoint++
          ) {
            if (
              _tempClientTemplateListing[_indicatorPoint].status === "Active"
            ) {
              _activeKeysList.push(
                _tempClientTemplateListing[_indicatorPoint].id
              );
            } else {
            }
          }
        }
      }
    );
  }

  onChangeTemplateDropdownValue = (value) => {
    this.setState({ templateId: value });
  };

  onClickApply = () => {
    let profileData = JSON.parse(window.localStorage.getItem("profileData"));
    let userName = window.localStorage.getItem("userName");
    if (this.state.templateId === 0 || this.state.templateId === undefined) {
      message.error("Please select a template to add!");
    } else {
      this.setState({ loading: true });
      this.props
        .getTemplateInfo(this.state.templateId)
        .then((res) => {
          let _res = res && res.payload;
          this.props
            .addTemplateToClientAppliedTemplateListing(_res)
            .then(() => {
              message.success("Template is added successfully!");
              this.props
                .getClientTemplateListing()
                .then((res) => {
                  let myData = {
                    clientName: profileData.fullName,
                    logMessage: "Balance template" + " " +  _res.name + " " + "added by" + " " + userName,
                    date: moment(new Date()).format("DD/MM/YYYY"),
                    logType: "Client Balance",
                    invoiceId: "0",
                  };
                  activityData(myData);
                  this.setState({ loading: false });
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  showModalDelete = (id, name) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
      nameForDeleteTemplateActivity: name
    });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this template?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props
                    .deleteTemplateForClient(this.state.index)
                    .then(() => {

                          let profileData = JSON.parse(window.localStorage.getItem("profileData"));
                          let userName = window.localStorage.getItem("userName");
                          let myData = {
                            clientName: profileData.fullName,
                            logMessage: "Balance template" + " " +  this.state.nameForDeleteTemplateActivity + " " + "deleted by" + " " + userName,
                            date: moment(new Date()).format("DD/MM/YYYY"),
                            logType: "Client Balance",
                            invoiceId: "0",
                          };
                          activityData(myData);
                      message.success(
                        "Template associated with client is deleted successfully!"
                      );
                      this.props
                        .getClientTemplateListing()
                        .then(() => {
                          this.setState({ visibleDeleteModal: false }, () => {
                            this.setState({ loading: false });
                          });
                        })
                        .catch(() => {
                          this.setState({ visibleDeleteModal: false }, () => {
                            this.setState({ loading: false });
                          });
                        });
                    })
                    .catch(() => {
                      this.setState({ visibleDeleteModal: false }, () => {
                        this.setState({ loading: false });
                      });
                    });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  setLoadingTrue = () => {
    this.setState({ loading: true });
  };

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <div
              className="form-container"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <Row style={{ paddingTop: "14px" }}>
                <Col xs={23} offset={1}>
                  <span className="visa-date-text color-apply-template">
                    {"Apply Templates"}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: "6px" }}>
                <Col xs={22} offset={1}>
                  <Select
                    value={this.state.templateId}
                    virtual={false}
                    placeholder="Please Select"
                    size="medium"
                    onChange={(e) => this.onChangeTemplateDropdownValue(e)}
                  >
                    <Option value={0}>{"Please select!"}</Option>
                    {this.state &&
                      this.state.templateListing &&
                      this.state.templateListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={data.id} key={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Col>
              </Row>
              <Row style={{ marginTop: "6px" }}>
                <Col xs={23} offset={1}>
                  <Button
                    type="primary"
                    size={"small"}
                    onClick={this.onClickApply}
                    className="button-blue"
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: "17px", marginBottom: "17px" }}>
              <Col xs={24} style={{ marginLeft: 0, marginRight: 0 }}>
                {this.state &&
                  this.state.clientTemplateListing &&
                  this.state.clientTemplateListing.map((data, index) => {
                    return (
                      <div key={data.id}>
                        <AccordianTemplate
                          activeKeysList={this.state.activeKeysList}
                          setLoadingTrue={this.setLoadingTrue}
                          setLoadingFalse={this.setLoadingFalse}
                          data={data}
                          showDeleteModal={this.showModalDelete}
                        />
                      </div>
                    );
                  })}
              </Col>
            </Row>
            {this.getModalDelete()}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let f =
    state &&
    state.clientProfileReducer &&
    state.clientProfileReducer.clientTemplateListing &&
    state.clientProfileReducer.clientTemplateListing.items &&
    state.clientProfileReducer.clientTemplateListing.items;
  return {
    templateListing:
      state &&
      state.templatesReducer &&
      state.templatesReducer.templateListing &&
      state.templatesReducer.templateListing.items &&
      state.templatesReducer.templateListing.items,
    clientTemplateListing:
      state &&
      state.clientProfileReducer &&
      state.clientProfileReducer.clientTemplateListing &&
      state.clientProfileReducer.clientTemplateListing.items &&
      state.clientProfileReducer.clientTemplateListing.items,
    clientAppliedTemplateListing:
      state &&
      state.clientProfileReducer &&
      state.clientProfileReducer.clientAppliedTemplateListing &&
      state.clientProfileReducer.clientAppliedTemplateListing.items &&
      state.clientProfileReducer.clientAppliedTemplateListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTemplateListing: bindActionCreators(
    getClientTemplateListing,
    dispatch
  ),
  getTemplateListing: bindActionCreators(getTemplateListing, dispatch),
  addTemplateToClientAppliedTemplateListing: bindActionCreators(
    addTemplateToClientAppliedTemplateListing,
    dispatch
  ),
  deleteTemplateForClient: bindActionCreators(
    deleteTemplateForClient,
    dispatch
  ),
  getClientTemplateInfo: bindActionCreators(getClientTemplateInfo, dispatch),
  getTemplateInfo: bindActionCreators(getTemplateInfo, dispatch),
});

Balance = connect(mapStateToProps, mapDispatchToProps)(Balance);
export default withRouter(Balance);
