import React from "react";
import { saveAs } from "file-saver";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
} from "antd";
import { bindActionCreators } from "redux";
import {
  sendDynamicLinkEmail,
  getPDF,
  getPDFInvoicePayment,
  checkDuplicateClientInvoice,
  editInvoice,
  sendFollowUp,
  getClientTemplateListing,
  sendDynamicLinkEmailBlob,
  getParentInvoiceListing,
  getParentSchoolInvoiceListing,
  editSchoolInvoice,
  getPDFSchoolInvoice,
  getEmailContent,
  setEmailTemplate,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import activityData from "../Components/ClientActivity/ActivityData";
import { apiRefresh } from "../services/api";
import FroalaEditorCom from "../Containers/FroalaEditorCom";
import { getFilterList } from "../Common/getEmailContentList";

const layoutSendingReceipt = {
  labelCol: { span: 8, offset: 0 },
  wrapperCol: { span: 20, offset: 2 },
};
const { Option } = Select;
class SendEmailFromInvoiceListing extends React.Component {
  formRefTer = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      sendingReceiptEmail: "",
      loading: false,
      resultList: [],
    };
  }

  componentWillMount() {
    this.props &&
      this.props.onGetEmailContent().then((res) => {
        let resultList = getFilterList(res);
        this.setState({ resultList: resultList });
      });
  }

  componentDidMount() {
    let _emails =
      this.props.clientProfileData &&
      this.props.clientProfileData.emails &&
      this.props.clientProfileData.emails;
    let setEmailData = this.props.setEmailData;

    this.setState({
      sendingReceiptEmail: setEmailData && setEmailData.htmlTemplate,
    });
    let toEmail;
    if (_emails && _emails.length > 0) {
      for (let index = 0; index < _emails.length; index++) {
        if (this.props && this.props.showSecondaryEmail === true) {
          if (_emails[index].emailTypeId === 2) {
            toEmail = _emails[index].address;
          }
        } else {
          if (_emails[index].emailTypeId === 1) {
            toEmail = _emails[index].address;
          }
        }
      }
    }

    this.formRefTer.current.setFieldsValue({
      from: localStorage.getItem("selectedBranchName"),
      to:
        this.props && this.props.clientEmail ? this.props.clientEmail : toEmail,
      subject: `Invoice #${
        this.props.invoiceInfo.invoiceNo
      } from ${localStorage.getItem("selectedBranchName")}`,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  getClientTemplateListingFun = () => {
    return this.props && this.props.invoiceType === "template"
      ? setTimeout(() => {
          this.props && this.props.setLoadingTrue();
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        }, 2000)
      : null;
  };

  getParentInvoiceListing = () => {
    return this.props &&
      this.props.invoiceType !== "template" &&
      this.props &&
      this.props.invoiceType !== "school"
      ? setTimeout(() => {
          this.props && this.props.setExpandedRowKeys();
          this.props && this.props.setLoadingTrue();
          this.props
            .getParentInvoiceListing(this.props && this.props.state)
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        }, 2000)
      : this.props && this.props.invoiceType === "school"
      ? setTimeout(() => {
          this.props && this.props.setExpandedRowKeys();
          this.props && this.props.setLoadingTrue();
          this.props
            .getSchoolParentInvoiceListing(this.props && this.props.state)
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        }, 2000)
      : null;
  };

  getActivityData = (myData) => {
    return this.props && this.props.invoiceType === "client"
      ? activityData(myData)
      : null;
  };

  onFinishSendingReceipt = async (values) => {
    let _from = values.from === undefined ? "" : values.from;
    let _to = values.to === undefined ? "" : values.to;
    let _cc = values.cc === undefined ? "" : values.cc;
    let _bcc = values.bcc === undefined ? "" : values.bcc;
    let _subject = values.subject === undefined ? "" : values.subject;
    const formData = new FormData();
    formData.append("Message", this.state.sendingReceiptEmail);
    formData.append("Subject", _subject);
    formData.append("From", _from);
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "client");
    formData.append("ClientId", this.props.clientId);
    formData.append("ModuleId", "10");
    formData.append(`Recipients[0].name`, _to);
    formData.append(`Recipients[0].type`, "To");
    formData.append(`isSent`, true);
    if (this.props.isPotential) {
      formData.append("IsPotential", true);
    }
    if (_cc !== "") {
      formData.append(`Recipients[1].name`, _cc);
      formData.append(`Recipients[1].type`, "CC");
    }
    if (_bcc !== "") {
      formData.append(`Recipients[2].name`, _bcc);
      formData.append(`Recipients[2].type`, "Bcc");
    }
    formData.append(
      `Attachments[0].type`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].type
    );
    formData.append(
      `Attachments[0].blobURL`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].url
    );
    formData.append(
      `Attachments[0].name`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].name
    );
    let userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (this.props.fromSchoolManagement) {
      this.props
        .sendDynamicLinkEmail(formData)
        .then(async () => {
          message.success("Email sent successfully!");

          let _invoiceInfo = this.props && this.props.invoiceInfo;
          let _statusId;

          if (_invoiceInfo.dueAmount !== 0 && _invoiceInfo.dueAmount > 0) {
            if (this.props.statuses && this.props.statuses.length > 0) {
              _statusId = this.props.statuses[1].id;
            }
          } else {
            _statusId = this.props.statuses[5].id;
          }
          _invoiceInfo.statusId = _statusId;

          if (this.props && this.props.invoiceType === "school") {
            this.props
              .editSchoolInvoice(_invoiceInfo)
              .then(() => {
                message.success("Invoice is updated successfully!");
                this.props.handleCancelSendingReceiptModal();
                {
                  this.getClientTemplateListingFun();
                }
                {
                  this.getParentInvoiceListing();
                }

                var profileData = JSON.parse(this.props.profileDataRdx);
                let myData = {
                  clientName:
                    this.props && this.props.clientName
                      ? this.props.clientName
                      : profileData && profileData.fullName,
                  logMessage:
                    "Invoice " +
                    this.props.invoiceNo +
                    " updated and sent via email to client :" +
                    ' <Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
                    '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
                    '<div style="display: none">' +
                    this.state.sendingReceiptEmail +
                    "</div>",
                  date: moment(new Date()).format("DD/MM/YYYY"),
                  logType: "Client Invoice",
                  invoiceId: "0",
                };
                {
                  this.getActivityData(myData);
                }

                this.setState({ loading: false });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          } else {
            this.props
              .editInvoice(_invoiceInfo)
              .then(() => {
                message.success("Invoice is updated successfully!");
                this.props.handleCancelSendingReceiptModal();
                {
                  this.getClientTemplateListingFun();
                }
                {
                  this.getParentInvoiceListing();
                }

                var profileData = JSON.parse(this.props.profileDataRdx);
                let myData = {
                  clientName:
                    this.props && this.props.clientName
                      ? this.props.clientName
                      : profileData && profileData.fullName,
                  logMessage:
                    "Invoice " +
                    this.props.invoiceNo +
                    " updated and sent via email to client :" +
                    ' <Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
                    '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
                    '<div style="display: none">' +
                    this.state.sendingReceiptEmail +
                    "</div>",
                  date: moment(new Date()).format("DD/MM/YYYY"),
                  logType: "Client Invoice",
                  invoiceId: "0",
                };
                {
                  this.getActivityData(myData);
                }

                this.setState({ loading: false });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      const draftData = {
        id: 0,
        emailMessage: this.state.sendingReceiptEmail || "",
        isRead: true,
        attachmentName:
          this.props &&
          this.props.attDataArray &&
          this.props.attDataArray[0].name,
        attachmentUrl:
          this.props &&
          this.props.attDataArray &&
          this.props.attDataArray[0].url,
        sizeInKB: 0,
        subject: _subject,
        from: localStorage.getItem("selectedBranchName"),

        import: false,
        to: _to,
        cc: _cc,
        bcc: _bcc,
        notClients: false,
        clientReply: false,
        clientReplyMail: 0,
        importMessageId: "",
        draft: false,
        emailType: 0,
        importText: "",
        // clientId: clientprofileid && clientprofileid,
        importedDate: new Date(),
        autoImport: false,
        isSent: true,
        clientEmailType: 0,
        timeStamp: new Date(),
      };

      if (this.props.fromClient) {
        draftData.clientId = this.props.clientId;
      } else if (this.props.fromPotentialClient) {
        draftData.potentialClientId = this.props.clientId;
      } else if (this.props.fromEmployer) {
        draftData.employerId = this.props.clientId;
      }

      let _url;
      if (this.props.fromClient) {
        _url = `v1/imap/ClientEmail`;
      } else if (this.props.fromPotentialClient) {
        _url = `v1/imap/PotentialClientEmail`;
      } else if (this.props.fromEmployer) {
        _url = `v1/imap/EmployerEmail`;
      } else {
        _url = ``;
      }
      const histOpt = {
        url: _url,
      };
      histOpt.types = [
        "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
        "EMAIL_QUEUE_WITH_BLOB_FAILURE",
      ];

      apiRefresh.post(histOpt, draftData).then((res) => {
        formData.append(`emailId`, res);
        this.props
          .sendDynamicLinkEmail(formData)
          .then(async () => {
            message.success("Email sent successfully!");

            let _invoiceInfo = this.props && this.props.invoiceInfo;
            let _statusId;

            if (_invoiceInfo.dueAmount !== 0 && _invoiceInfo.dueAmount > 0) {
              if (this.props.statuses && this.props.statuses.length > 0) {
                _statusId = this.props.statuses[1].id;
              }
            } else {
              _statusId = this.props.statuses[5].id;
            }
            _invoiceInfo.statusId = _statusId;

            if (this.props && this.props.invoiceType === "school") {
              this.props
                .editSchoolInvoice(_invoiceInfo)
                .then(() => {
                  message.success("Invoice is updated successfully!");
                  this.props.handleCancelSendingReceiptModal();
                  {
                    this.getClientTemplateListingFun();
                  }
                  {
                    this.getParentInvoiceListing();
                  }

                  var profileData = JSON.parse(this.props.profileDataRdx);
                  let myData = {
                    clientName:
                      this.props && this.props.clientName
                        ? this.props.clientName
                        : profileData && profileData.fullName,
                    logMessage:
                      "Invoice " +
                      this.props.invoiceNo +
                      " updated and sent via email to client :" +
                      ' <Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
                      '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
                      '<div style="display: none">' +
                      this.state.sendingReceiptEmail +
                      "</div>",
                    date: moment(new Date()).format("DD/MM/YYYY"),
                    logType: "Client Invoice",
                    invoiceId: "0",
                  };
                  {
                    this.getActivityData(myData);
                  }

                  this.setState({ loading: false });
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            } else {
              this.props
                .editInvoice(_invoiceInfo)
                .then(() => {
                  message.success("Invoice is updated successfully!");
                  this.props.handleCancelSendingReceiptModal();
                  {
                    this.getClientTemplateListingFun();
                  }
                  {
                    this.getParentInvoiceListing();
                  }

                  var profileData = JSON.parse(this.props.profileDataRdx);
                  let myData = {
                    clientName:
                      this.props && this.props.clientName
                        ? this.props.clientName
                        : profileData && profileData.fullName,
                    logMessage:
                      "Invoice " +
                      this.props.invoiceNo +
                      " updated and sent via email to client :" +
                      ' <Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
                      '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
                      '<div style="display: none">' +
                      this.state.sendingReceiptEmail +
                      "</div>",
                    date: moment(new Date()).format("DD/MM/YYYY"),
                    logType: "Client Invoice",
                    invoiceId: "0",
                  };
                  {
                    this.getActivityData(myData);
                  }

                  this.setState({ loading: false });
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            }
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      });
    }
    // this.setState({ loading: true });
  };

  config = {
    key:
      "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
    // height: "auto",
    // toolbarSticky: false,
    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "undo",
          "textColor",
          "redo",
          "fullscreen",
          "print",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
      },
      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertTable",
          "emoticons",
          "fontAwesome",
          "specialCharacters",
          "embedly",
          "insertHR",
        ],
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
      },
    },
    imageEditButtons: [
      "imageDisplay",
      "imageAlign",
      "imageInfo",
      "imageRemove",
    ],
    zIndex: 999999,
    events: {
      "image.beforeUpload": function(files) {
        let editor = this;
        if (files.length) {
          // Create a File Reader.
          let reader = new FileReader();

          // Set the reader to insert images when they are loaded.
          reader.onload = function(e) {
            let result = e.target.result;

            editor.image.insert(result, null, null, editor.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      },
    },
  };

  onClickPDFFile = () => {
    let _invNo =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo.invoiceNo;
    this.setState({ loading: true });
    let _data = {
      id: this.props && this.props.invoiceInfo && this.props.invoiceInfo.id,
      paymentId: this.props && this.props.paymentId,
    };

    if (this.props && this.props.invoiceType === "school") {
      this.props
        .getPDFSchool(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loading: false });
          saveAs(blob, `Invoice-${_invNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          message.error("Unable to download, please try again!");
        });
    } else {
      this.props
        .getPDF(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loading: false });
          saveAs(blob, `Invoice-${_invNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          message.error("Unable to download, please try again!");
        });
    }
  };

  getSendingReceiptForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Row>
          <Col xs={3} offset={21}>
            <div
              className="pdf-file-btn"
              style={{ backgroundColor: "#f0ad4e" }}
            >
              <a
                className="pdf-file-text"
                onClick={this.onClickPDFFile}
              >{`INVOICE-${this.props.invoiceInfo.invoiceNo}-.PDF`}</a>
            </div>
          </Col>
        </Row>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="From"
          name="from"
          rules={[
            { required: true, message: "Required!" },
            // { type: "email", message: "Invalid!" },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="To"
          name="to"
          rules={[
            { required: true, message: "Required!" },
            { type: "email", message: "Invalid!" },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="CC"
          name="cc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bcc"
          name="bcc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Subject"
          name="subject"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col xs={8} style={{ marginTop: "3vh", marginBottom: "4vh" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Select
              placeholder={"Select content type"}
              virtual={false}
              onChange={(value) => {
                let _emailTemplatePayload = {
                  templateName: value,
                  parameters: [
                    {
                      key: "ClientId",
                      value: this.props && this.props.clientId,
                    },
                    {
                      key: "CurrencyId",
                      value:
                        this.props && this.props.invoiceInfo.currencyId
                          ? this.props.invoiceInfo.currencyId.toString()
                          : "106",
                    },
                    {
                      key: "UserId",
                      value: this.props && this.props.state.userId,
                    },
                    {
                      key: "Content",
                      value: value,
                    },
                    {
                      key: "Amount",
                      value:
                        this.props && this.props.invoiceInfo.total.toString(),
                    },
                    {
                      key: "InvoiceNumber",
                      value: this.props && this.props.invoiceInfo.invoiceNo,
                    },
                    {
                      key: "DueDate",
                      value: this.props && this.props.invoiceInfo.dueDate,
                    },
                  ],
                };
                this.setState({ loading: true });
                this.props
                  .setEmailTemplate(_emailTemplatePayload)
                  .then((res) => {
                    this.setState({
                      sendingReceiptEmail: res.payload.htmlTemplate,
                      loading: false,
                    });
                  })
                  .catch(() => this.setState({ loading: false }));
              }}
            >
              <Option value={""}>{"Select"}</Option>
              {this.state &&
                this.state.resultList.map((item, index) => (
                  <Option value={item.emailType}>{item.emailType}</Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <FroalaEditorCom
              model={this.state.sendingReceiptEmail}
              onModelChange={this.getEditorValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2} offset={20} style={{ display: "flex" }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="button-blue"
              >
                Send
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={props.handleCancelSendingReceiptModal}
                className="button-blue"
              >
                Close
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getEditorValue = (value) => {
    this.setState({ sendingReceiptEmail: value });
  };

  render() {
    let a = this.props;

    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-sending-receipt"
          title="SEND EMAIL NOTIFICATION"
          visible={this.props.visibleSendingReceiptModal}
          onCancel={this.props.handleCancelSendingReceiptModal}
          footer={null}
          maskClosable={false}
        >
          <Spin size="large" spinning={this.state.loading}>
            <Form
              {...layoutSendingReceipt}
              onFinish={this.onFinishSendingReceipt}
              ref={this.formRefTer}
            >
              {this.getSendingReceiptForm(this.props)}
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getParentInvoiceListing: bindActionCreators(
    getParentInvoiceListing,
    dispatch
  ),
  getSchoolParentInvoiceListing: bindActionCreators(
    getParentSchoolInvoiceListing,
    dispatch
  ),
  getClientTemplateListing: bindActionCreators(
    getClientTemplateListing,
    dispatch
  ),
  sendDynamicLinkEmail: bindActionCreators(sendDynamicLinkEmailBlob, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  getPDFSchool: bindActionCreators(getPDFSchoolInvoice, dispatch),
  getPDFInvoicePayment: bindActionCreators(getPDFInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  editSchoolInvoice: bindActionCreators(editSchoolInvoice, dispatch),
  sendFollowUp: bindActionCreators(sendFollowUp, dispatch),
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
});
SendEmailFromInvoiceListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendEmailFromInvoiceListing);

export default withRouter(SendEmailFromInvoiceListing);
