import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Col,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { UploadOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const ProfileEmployer = ({
  userDataEmp,
  onUpdateEmployerManag,
  onGetEmployerManag,
  employerManagRes,

  onAddDocWithName,
  docWithNameRes,
}) => {
  const [isSelector, setIsSelector] = useState();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    // Fields Set Form Start //
    form.setFieldsValue({
      employer_Type: userDataEmp && userDataEmp.employerType,
      name: userDataEmp && userDataEmp.name,
      business: userDataEmp && userDataEmp.business,
      email: userDataEmp && userDataEmp.email,
      contact_no: userDataEmp && userDataEmp.contactNo,
      city: userDataEmp && userDataEmp.city,
      address: userDataEmp && userDataEmp.address,
      contact_person: userDataEmp && userDataEmp.contactPerson,
      mobile: userDataEmp && userDataEmp.mobile,
      website: userDataEmp && userDataEmp.website,
      job_sector: userDataEmp && userDataEmp.jobSector,
      nzbn: userDataEmp && userDataEmp.nzbn,
      occupation: userDataEmp && userDataEmp.occupation,
      company_size: userDataEmp && userDataEmp.numberOfEmployees,
      how_many_years: userDataEmp && userDataEmp.yearsOfBusiness,
      employerType: userDataEmp && userDataEmp.employerType,
    });
  }, [form, userDataEmp]);

  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);
    const data = {
      id: userDataEmp && userDataEmp.id,
      name: values && values.name && values.name.length > 0 ? values.name : "",
      email:
        values && values.email && values.email.length > 0 ? values.email : "",
      contactNo:
        values && values.contact_no && values.contact_no.length > 0
          ? values.contact_no
          : "",
      business:
        values && values.business && values.business.length > 0
          ? values.business
          : "",
      city: values && values.city && values.city.length > 0 ? values.city : "",
      contactPerson:
        values && values.contact_person && values.contact_person.length > 0
          ? values.contact_person
          : "",
      mobile:
        values && values.mobile && values.mobile.length > 0
          ? values.mobile
          : "",
      website:
        values && values.website && values.website.length > 0
          ? values.website
          : "",
      jobSector:
        values && values.job_sector && values.job_sector.length > 0
          ? values.job_sector
          : "",
      nzbn: values && values.nzbn ? values.nzbn : "",
      occupation:
        values && values.occupation && values.occupation.length > 0
          ? values.occupation
          : "",
      numberOfEmployees:
        values && values.company_size && values.company_size.length > 0
          ? values.company_size
          : "",
      yearsOfBusiness:
        values && values.how_many_years && values.how_many_years.length > 0
          ? values.how_many_years
          : "",
      isPotential: userDataEmp && userDataEmp.isPotential,
      employerType: values && values.employer_Type ? values.employer_Type : "",
      address:
        values && values.address && values.address.length > 0
          ? values.address
          : "",
      agreementUrl:
        docWithNameRes && docWithNameRes ? docWithNameRes.fileURL : "",
      agreementFileName:
        docWithNameRes && docWithNameRes ? docWithNameRes.fileName : "",
      xeroID: userDataEmp.xeroID || "",
      jobStatus: userDataEmp.jobStatus || 0,
      clientId: "00000000-0000-0000-0000-000000000000",
    };

    onUpdateEmployerManag(data)
      .then(() => {
        onGetEmployerManag();
        setLoading(false);
        message.success("Successfully Updated");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    }
    if (fileList && fileList.length > 1) {
      message.warning("You can upload 1 files only");
    } else setFileList(fileList);

    let formData = new FormData();
    formData.append("BranchId", localStorage.getItem("selectedBranchId"));
    if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile =
          fileList[i] && fileList[i].originFileObj && fileList[i].originFileObj;

        formData.append("file", selectedFile);

        onAddDocWithName(formData);
      }
    }
  };

  const handleSectorChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelector(value);
  };

  const onDownloadDoc = (value) => {
    var title = userDataEmp.agreementFileName;

    saveAs(userDataEmp && userDataEmp.agreementUrl, { title });
  };

  return (
    <Fragment>
      {/* <Spin Spin={loading} size="large"> */}
      <div className=" emp-w-box  add-employer-para">
        <Form
          onFinish={onFinish}
          form={form}
          className="width-100 "
          name="main"
        >
          <Row
            // style={{ marginBottom: "12px" }}
            className="d-aligin-center"
            gutter={4}
          >
            <Col span={12}>
              <p>Type</p>
            </Col>
            <Col span={12}>
              <Form.Item name="employer_Type">
                <Select>
                  <Select.Option key="1">Employer</Select.Option>
                  <Select.Option key="2">Supplier</Select.Option>
                  <Select.Option key="2">Recruiter</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Name</p>
            </Col>
            <Col span={12}>
              <Form.Item name="name" required={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Business/Company Name</p>
            </Col>
            <Col span={12}>
              <Form.Item name="business" required={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Email</p>
            </Col>
            <Col span={12}>
              <Form.Item name="email" required={false}>
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Contact No</p>
            </Col>
            <Col span={12}>
              <Form.Item name="contact_no" required={false}>
                <Input placeholder="Contact No" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>City</p>
            </Col>
            <Col span={12}>
              <Form.Item name="city" required={true}>
                <Input placeholder="City" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Address</p>
            </Col>
            <Col span={12}>
              <Form.Item name="address" required={false}>
                <Input placeholder="Address" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Contact Person</p>
            </Col>
            <Col span={12}>
              <Form.Item name="contact_person" required={false}>
                <Input placeholder="Contact Person" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Mobile</p>
            </Col>
            <Col span={12}>
              <Form.Item name="mobile" required={false}>
                <Input placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Website</p>
            </Col>
            <Col span={12}>
              <Form.Item name="website" required={false}>
                <Input placeholder="Website" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Job Sector</p>
            </Col>
            <Col span={12}>
              <Form.Item name="job_sector" required={false}>
                <Select
                  defaultValue="Select job sector"
                  onChange={handleSectorChange}
                >
                  <Select.Option value="Select job sector">
                    Select job sector
                  </Select.Option>
                  <Select.Option value="Administrative">
                    Administrative
                  </Select.Option>
                  <Select.Option value="AgricultureFarming">
                    Agriculture / Farming
                  </Select.Option>
                  <Select.Option value="Automotive">Automotive</Select.Option>
                  <Select.Option value="Construction">
                    Construction
                  </Select.Option>
                  <Select.Option value="Electrical">Electrical</Select.Option>
                  <Select.Option value="Engineer">Engineer</Select.Option>
                  <Select.Option value="Finance">Finance</Select.Option>
                  <Select.Option value="FMCG">FMCG</Select.Option>
                  <Select.Option value="Hospitality">Hospitality</Select.Option>
                  <Select.Option value="Human Resources">
                    HumanResources
                  </Select.Option>
                  <Select.Option value="Insurance">Insurance</Select.Option>
                  <Select.Option value="ICT">ICT</Select.Option>
                  <Select.Option value="Legal">Legal</Select.Option>
                  <Select.Option value="Marketing">Marketing</Select.Option>
                  <Select.Option value="Medical">Medical</Select.Option>
                  <Select.Option value="Real estate">Realestate</Select.Option>
                  <Select.Option value="Retail">Retail</Select.Option>
                  <Select.Option value="Sales">Sales</Select.Option>
                  <Select.Option value="Supply chain">
                    Supplychain
                  </Select.Option>
                  <Select.Option value="Teachers">Teachers</Select.Option>
                  <Select.Option value="Trades">Trades</Select.Option>
                  <Select.Option value="Not Employed">
                    NotEmployed
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>NZBN</p>
            </Col>
            <Col span={12}>
              <Form.Item name="nzbn" required={false}>
                <Input placeholder="NZBN" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Occupation</p>
            </Col>
            <Col span={12}>
              <Form.Item name="occupation" required={false}>
                <Input placeholder="Occupation" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Company Size (Number of Employees)</p>
            </Col>
            <Col span={12}>
              <Form.Item name="company_size" required={false}>
                <Input placeholder="Company Size (Number of Employees)" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>How Many Years Has This Business In Operation</p>
            </Col>
            <Col span={12}>
              <Form.Item name="how_many_years" required={false}>
                <Input placeholder="How Many Years Has This Business In Operation" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-aligin-center" gutter={8}>
            <Col span={12}>
              <p>Agreement</p>
            </Col>
            <Col span={12} className="upload-button">
              <Form.Item required={false}>
                <Upload
                  single
                  onChange={onHandleChange}
                  beforeUpload={() => false}
                  fileList={fileList}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
              <span className="d-flex">
                <span className="agreement-data">
                  {userDataEmp && userDataEmp.agreementFileName}
                </span>
                {userDataEmp && userDataEmp.agreementFileName && (
                  <span onClick={onDownloadDoc}>
                    <VerticalAlignBottomOutlined
                      style={{ fontSize: "16px", cursor: "pointer" }}
                    />
                  </span>
                )}
              </span>
            </Col>
          </Row>
          <Form.Item>
            <Button
              className="form-btn employer-btn button-blue"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* </Spin> */}
    </Fragment>
  );
};

export default ProfileEmployer;
