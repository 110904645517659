// @flow
import React, { useState, useEffect } from "react";

// import OptFillQuestion from "./OptFillQuestion";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { apiRefresh } from "../services/api";
import history from "../services/history";

function getUserBranchPermissions(Props) {
  const userBranchPermissionsOpt = {
    url: `v1/user/permission`,
  };
  userBranchPermissionsOpt.types = [
    "GET_USER_BRANCH_PERMISSIONS_SUCCESS",
    "GET_USER_BRANCH_PERMISSIONS_FAILURE",
  ];

  apiRefresh
    .get(userBranchPermissionsOpt)
    .then((res) => {
      if (res && res.length > 0) {
        var systemPermissions = JSON.stringify(res[0].userSystemPermissions);
        var branchPermissions = JSON.stringify(res[0].branchPermissions);
        localStorage.setItem(
          "userSystemPermissions",
          utf8_to_b64(systemPermissions)
        );
        localStorage.setItem(
          "userBranchPermissions",
          utf8_to_b64(branchPermissions)
        );
        // history.push("/dashboardBI");
        // window.location.reload("/dashboardBI");

        if (
          localStorage.getItem("isAgent") === "true" ||
          (localStorage.getItem("userType") &&
            localStorage.getItem("userType").toLowerCase() == "sales person")
        ) {
          window.top.location = "/potential-client/potential-clients";
        } else {
          window.top.location = "/dashboardBI";
        }
      }
    })
    .catch((err) => {
      // window.location.reload();
    });

  const utf8_to_b64 = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
  };

  const b64_to_utf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
  };

  return;
}

export default getUserBranchPermissions;
