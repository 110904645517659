// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import axios from "axios";

// import OptFillQuestion from "./OptFillQuestion";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function activityData(Props) {
  //   const [show, setShow] = useState(false);
  const labelArray = [
    "", // 0
    "Client Document", // 1
    "Client Admission", // 2
    "Client FileNote", // 3
    "Client Visa", // 4
    "Client Invoice", // 5
    "Client Email", // 6
    "Client Information", // 7
    "Client Tasks", // 8
    "Client Questionnaire", // 9
    "Client Tag", // 10
    "Client Employer", // 11
    "Client Jobs", // 12
    "Client Qualification", // 13
    "Client Balance", // 14
    "Client Chat", // 15
  ];

  // var logTypeName = labelArray[Props.logType];

  let clientprofileid = sessionStorage.getItem("clientprofileid");

  var token = localStorage.getItem("token");
  var activityData = {
    clientLogId: 0,
    clientId: Props.notClientPart ? Props.linkedClient : clientprofileid,
    clientLogNote:
      '<div><span style="color: #34b0e1; font-size: 12px">Log For ' +
      Props.clientName +
      '</span></div><span style="color: #1280b8;font-size: 10px;padding: 3px 0px 5px;font-weight: 600;">' +
      Props.logMessage +
      '</span></div></div><div style="display: flex"><span style="color: #53626c;margin-left: 0px;font-size: 9px;margin-top: 3px">' +
      Props.date +
      "</span></div>",
    logType: Props.logType,
    invoiceId: Props.invoiceId,
  };
  // const baseURL = "https://uatapi.ezymigrate.co.nz/";
  // const baseURL = "https://api-stage.ezymigrate.co.nz/";
  const baseURL = "https://beta-api.ezymigrate.co.nz/";

  axios({
    method: "POST",
    url: `${baseURL}v1/client/ClientLog`,
    data: activityData,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {})
    .catch((err) => {});
}

export default activityData;
