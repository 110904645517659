import React from "react";
// import Select from "react-select";
import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { message, Button, Radio, Select, Row, Col, Table, Spin } from "antd";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import ProgressBar from "../Components/Shared/Progressbar";

import VisaTypeActions from "../Redux/SuperUserSettingRedux/VisaTypeRedux";
import { LeftCircleFilled } from "@ant-design/icons";
import { apiRefresh } from "../services/api";

const { Option } = Select;
class VisaBranchCountry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      chineseName: "",
      loading: true,
      countryName: "NEW ZEALAND",
      isUpdate: false,
      id: "",
      selectedCountry: "",
      countriesList: [],
      selectedRow: null,
      columns: [
        {
          title: "Country Name",
          dataIndex: "countryName",
          key: "countryName",
          width: "100px",
          render: (text, record) => (
            <p
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "initial",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {record.countryName}
            </p>
          ),
        },
        {
          title: "Action",
          dataIndex: "action",
          width: "50px",
          render: (text, record) => {
            return (
              <>
                <div className="table-action" style={{ alignItems: "center" }}>
                  <div style={{ cursor: "pointer" }}>
                    <img
                      src={Images.deleteBlue}
                      style={{
                        width: 15,
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleOpenVisibleModal(record)}
                    />
                  </div>
                </div>
              </>
            );
          },
        },
      ],
    };
    this.getBranchVisaCountries();
    this.props.onGetCountries().then((res) => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps) {}

  getBranchVisaCountries = () => {
    const getVisaCountryOpt = {
      url: `v1/BranchCountryLinking/ByBranchId`,
    };
    getVisaCountryOpt.types = [
      "GET_VISA_COUNTRY_SUCCESS",
      "GET_VISA_COUNTRY_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh.get(getVisaCountryOpt).then((res) => {
      this.setState({ loading: false, countriesList: res });
    });
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      name: "",
    });
  };

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (e) => {
    this.setState({
      countryName: e.target.value,
    });
  };

  openEditModal = (item) => {
    this.setState({
      id: item.id,
      name: item.visaTypeName,
      chineseName: item.chName,
      countryName: item.countryName,
      isUpdate: true,
    });
    this.openModal();
  };

  handleOpenVisibleModal = (data) => {
    this.setState({ visibleModal: true, selectedRow: data });
  };
  handleCancelVisibleModal = () => {
    this.setState({ visibleModal: false });
  };

  getModalAlert = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={this.state.visibleModal}
          onCancel={this.handleCancelVisibleModal}
          height="150"
          width="360"
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                {" "}
                <span
                  style={{ fontSize: 12, marginTop: "4vh", marginLeft: "2vw" }}
                >
                  {`Are you sure, you want to delete this?`}
                </span>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "7vh" }}>
            <Col span={6} offset={13} style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  this.handleCancelVisibleModal();
                }}
                style={{ borderRadius: 5 }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  if (this.state.selectedRow.id === 0) {
                    let data = {
                      countryId: this.state.selectedRow.countryId,
                    };
                    const delVisaCountryOpt = {
                      url: `v1/BranchCountryLinking/HideByBranchId`,
                    };
                    delVisaCountryOpt.types = [
                      "DELETE_VISA_COUNTRY_SUCCESS",
                      "DELETE_VISA_COUNTRY_FAILURE",
                    ];
                    this.setState({ loading: true });
                    apiRefresh
                      .post(delVisaCountryOpt, data)
                      .then(() => {
                        message.success("Country deleted successfully!");
                        this.getBranchVisaCountries();

                        this.setState({ visibleModal: false, loading: false });
                      })
                      .catch((err) => {
                        this.setState({ visibleModal: false, loading: false });
                      });
                  } else {
                    let data = {
                      id: this.state.selectedRow.id,
                    };
                    const delVisaCountryOpt = {
                      url: `v1/BranchCountryLinking`,
                    };

                    delVisaCountryOpt.types = [
                      "DELETE_VISA_COUNTRY_SUCCESS",
                      "DELETE_VISA_COUNTRY_FAILURE",
                    ];
                    this.setState({ loading: true });
                    apiRefresh
                      .delete(delVisaCountryOpt, data)
                      .then(() => {
                        message.success("Country deleted successfully!");
                        this.getBranchVisaCountries();

                        this.setState({ visibleModal: false, loading: false });
                      })
                      .catch((err) => {
                        console.log(err);

                        this.setState({ loading: false, visibleModal: false });
                        message.error(err.response && err.response.data);
                      });
                  }
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getAddModal = (loading) => {
    return (
      <div>
        <Modal
          visible={this.state.visible}
          width="500"
          height="220"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div style={{ padding: 40 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="sus-modal-label">
                <span className="sus-modal-label-text">VISA COUNTRY</span>
              </div>
              <div onClick={this.closeModal}>
                <img src={Images.crossRed} style={{ width: 20 }} />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="medical-label"
                style={{ fontSize: 11, marginLeft: 15 }}
              >
                Country:
              </p>
              <div className="profile-input-border" style={{ width: "60%" }}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="PLEASE SELECT"
                  value={this.state.selectedCountry}
                  onChange={(e) => {
                    this.setState({ selectedCountry: e });
                  }}
                >
                  {this.props.countriesData &&
                    this.props.countriesData.items.map((data) => {
                      return <Option value={data.id}>{data.name}</Option>;
                    })}
                </Select>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 30,
              }}
            >
              <div></div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={this.closeModal}
                  type="primary"
                  className="sus-add-btn"
                >
                  CLOSE
                </Button>
                <Button
                  onClick={this.onSubmit}
                  loading={loading}
                  type="primary"
                  className="sus-add-btn"
                  style={{ marginLeft: 10 }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  onSubmit = () => {
    if (this.state.selectedCountry) {
      let visaTypeData = {
        countryId: this.state.selectedCountry
          ? parseInt(this.state.selectedCountry)
          : 0,
      };

      const addVisaCountryOpt = {
        url: `v1/BranchCountryLinking`,
      };
      addVisaCountryOpt.types = [
        "ADD_VISA_COUNTRY_SUCCESS",
        "ADD_VISA_COUNTRY_FAILURE",
      ];
      this.setState({ loading: true });

      apiRefresh
        .post(addVisaCountryOpt, visaTypeData)
        .then(() => {
          this.setState({ loading: false });
          this.closeModal();
          this.getBranchVisaCountries();
          this.setState({
            selectedCountry: "",
          });
          message.success("Visa Country added successfully");
        })
        .catch(() => {
          this.setState({ loading: false });
          message.error("Adding visa country failed!");
        });
    } else {
      message.warning("Please select country!");
    }
  };

  render() {
    const { countriesData, onSetActiveInnerTab } = this.props;
    const { loading, selectedCountry, countriesList, columns } = this.state;

    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div>
                <div style={{ paddingRight: 45 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="sus-bottom-btn-cont"
                      style={{ paddingTop: 0, marginLeft: 20 }}
                    >
                      <div className="sus-bottom-icon-cont">
                        <img
                          src={Images.visaCountry}
                          className="sus-bottom-icon"
                        />
                      </div>
                      <span className="sus-bottom-text">VISA COUNTRY</span>
                    </div>
                    <LeftCircleFilled
                      onClick={() => onSetActiveInnerTab("")}
                      className="ac-back-icon"
                    />
                  </div>
                  <div
                    className="sus-form-container"
                    style={{
                      width: "100%",
                      minHeight: 130,
                      paddingLeft: 55,
                      paddingRight: 55,
                      border: 1,
                      borderStyle: "solid",
                      borderColor: "#D9D9D9",
                    }}
                  >
                    <div
                      className="sus-add-cont-row"
                      style={{ marginTop: 10, marginRight: -40 }}
                    >
                      <div></div>
                      <div onClick={this.openModal}>
                        <img src={Images.plusIcon} style={{ width: 20 }} />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: -40,
                        marginRight: -40,
                      }}
                    >
                      <Table
                        // expandedRowKeys={expandedRowsEntriesKeys}
                        pagination={false}
                        className="job-table table-head employer-tables border-3 school-table"
                        dataSource={countriesList}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.getAddModal(loading)}
          {this.getModalAlert()}
        </Spin>
      </div>
    );
  }
}

export default VisaBranchCountry;
