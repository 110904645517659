// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import AddAutoReminder from "../Reminder/AddAutoReminder";

function Passport(Props) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(false);
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");
  const [loadAddCertificate, setLoadAddCertificate] = useState(false);
  const [loadAddAuthority, setLoadAddAuthority] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const [form] = Form.useForm();
  const [policeCertificateForm] = Form.useForm();
  const [authorityRef] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    if (Props.clientInfo) {
      setCertificates(Props.clientInfo.certificates);
      setAuthorities(Props.clientInfo.authorities);

      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (Props.clientInfo.emails.length > 0) {
        for (var i = 0; i < Props.clientInfo.emails.length; i++) {
          if (Props.clientInfo.emails[i].emailTypeId === 1) {
            email = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 2) {
            secondaryEmail = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 3) {
            otherEmail = Props.clientInfo.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (Props.clientInfo.addresses.length > 0) {
        const findBillAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 2
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }

        const findAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (Props.clientInfo.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: Props.clientInfo.medicals[0].medicalIssueDate,
          medicalExpiryDate: Props.clientInfo.medicals[0].medicalExpiryDate,
          medicalGrading: Props.clientInfo.medicals[0].medicalGrading,
          xrayIssueDate: Props.clientInfo.medicals[0].xrayIssueDate,
          xrayExpiryDate: Props.clientInfo.medicals[0].xrayExpiryDate,
          xrayGrading: Props.clientInfo.medicals[0].xrayGrading,
          medicalNotes: Props.clientInfo.medicals[0].medicalNotes,
          medicalNotesDetail: Props.clientInfo.medicals[0].medicalNotesDetail,
        };
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
      };
      if (Props.clientInfo.phones.length > 0) {
        const findMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
        }
        const findSecondaryMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }

      form.setFieldsValue({
        visaCountryId: Props.clientInfo.visaCountryId.toString(),
        visaCountryType: Props.clientInfo.visaCountyType.toString(),
        title: Props.clientInfo.title,
        middleName: Props.clientInfo.middleName,
        gender: Props.clientInfo.gender,
        dateOfBirth: moment(Props.clientInfo.dateOfBirth),
        address: clientAddressData ? clientAddressData.address : "",
        nationalityId: Props.clientInfo.nationalityId,
        saleDate: moment(Props.clientInfo.saleDate),
        sourceId: Props.clientInfo.sourceId.toString(),
        jobSectorId: Props.clientInfo.jobSectorId.toString(),
        companyOptional: Props.clientInfo.companyOptional,
        clientSerial: Props.clientInfo.clientSerial,
        nationalityCountry: Props.clientInfo.nationalityCountry,
        firstName: Props.clientInfo.firstName,
        lastName: Props.clientInfo.lastName,
        age: Props.clientInfo.age,
        dealWorth: Props.clientInfo.dealWorth,
        maritalStatus: Props.clientInfo.maritalStatus,
        dependentChildren: Props.clientInfo.dependentChildren,
        sourceDescription: Props.clientInfo.sourceDescription,
        occupation: Props.clientInfo.occupation,
        visaDenied: Props.clientInfo.visaDenied,
        deniedText: Props.clientInfo.deniedText,
        visaText: Props.clientInfo.visaText,
        currentVisaTypeId: Props.clientInfo.currentVisaTypeId.toString(),
        currentNewZealandVisaExpiry: moment(
          Props.clientInfo.currentNewZealandVisaExpiry
        ),
        travelConditionsValidTo: moment(
          Props.clientInfo.travelConditionsValidTo
        ),
        clientNumber: Props.clientInfo.clientNumber,
        inzUserName: Props.clientInfo.inzUserName,
        inzPassword: Props.clientInfo.inzPassword,
        inzFeeDate: moment(Props.clientInfo.inzFeeDate),
        nzqaOnlineSubDate: moment(Props.clientInfo.nzqaOnlineSubDate),
        nzqaDocumentSubDate: moment(Props.clientInfo.nzqaDocumentSubDate),
        nzqaDocumentRetDate: moment(Props.clientInfo.nzqaDocumentRetDate),
        email: email,
        secondaryEmail: secondaryEmail,
        otherEmail: otherEmail,
        contactPerson: billAddressData ? billAddressData.contactPerson : "",
        flat: billAddressData ? billAddressData.flat : "",
        streetNumber: billAddressData ? billAddressData.streetNumber : "",
        suburb: billAddressData ? billAddressData.suburb : "",
        city: billAddressData ? billAddressData.city : "",
        billCountry: billAddressData ? billAddressData.country : "",
        zipcode: billAddressData ? billAddressData.zipcode : "",
        clientAddress: clientAddressData ? clientAddressData.address : "",
        mobilePhone: phoneData ? phoneData.mobile : "",
        secondaryMobile: phoneData ? phoneData.secondaryMobile : "",
        overseasMobile: phoneData ? phoneData.overseasMobile : "",
        landLine: phoneData ? phoneData.landLine : "",
        otherMobile: phoneData ? phoneData.otherMobile : "",
        medicalIssueDate: medicalData
          ? moment(medicalData.medicalIssueDate)
          : "",
        medicalExpiryDate: medicalData
          ? moment(medicalData.medicalExpiryDate)
          : "",
        medicalGrading: medicalData ? medicalData.medicalGrading : "",
        xrayIssueDate: medicalData ? moment(medicalData.xrayIssueDate) : "",
        xrayExpiryDate: medicalData ? moment(medicalData.xrayExpiryDate) : "",
        xrayGrading: medicalData ? medicalData.xrayGrading : "",
        medicalNotes: medicalData ? medicalData.medicalNotes : "",
        medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
        passportNo:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportNo
            : "",
        passportCountry:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passports[0].passportCountry !== 0
            ? Props.clientInfo.passports[0].passportCountry
            : "",
        passportIssueDate:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passports[0].passportIssueDate !==
            "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[0].passportIssueDate)
            : "",
        passportExpiryDate:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passports[0].passportExpiryDate !==
            "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[0].passportExpiryDate)
            : "",
        secondPassportNo:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportNo
            : "",
        secondPassportCountry:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passports[1].passportCountry !== 0
            ? Props.clientInfo.passports[1].passportCountry
            : "",
        secondPassportIssueDate:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passports[1].passportIssueDate !==
            "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[1].passportIssueDate)
            : "",
        secondPassportExpiryDate:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passports[1].passportExpiryDate !==
            "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[1].passportExpiryDate)
            : "",
      });
    }
  }, [Props, form]);

  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  const country = [];
  const authorityOption = [];
  const visaTypeOption = [];
  if (Props.countriesData && Props.countriesData.items.length > 0) {
    for (let i = 0; i < Props.countriesData.items.length; i++) {
      country.push(
        <Option key={Props.countriesData.items[i].id}>
          {Props.countriesData.items[i].name}
        </Option>
      );
    }
  }

  if (Props.accessingAuthData && Props.accessingAuthData.length > 0) {
    for (let i = 0; i < Props.accessingAuthData.length; i++) {
      authorityOption.push(
        <Option key={Props.accessingAuthData[i].id}>
          {Props.accessingAuthData[i].name}
        </Option>
      );
    }
  }

  if (Props.visaTypeData && Props.visaTypeData.items.length > 0) {
    for (let i = 0; i < Props.visaTypeData.items.length; i++) {
      visaTypeOption.push(
        <Option key={Props.visaTypeData.items[i].id}>
          {Props.visaTypeData.items[i].visaTypeName}
        </Option>
      );
    }
  }

  const handleChangeCertificateCountry = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const onChangeCertificateIssueDate = (value, dateString) => {
    setCertificateIssueDate(value);
  };

  const onChangeCertificateExpiryDate = (value, dateString) => {
    setCertificateExpiryDate(value);
  };

  const onChangeAuthSubDate = (value, dateString) => {
    setAuthSubDate(value);
  };

  const onChangeAuthRetDate = (value, dateString) => {
    setAuthRetDate(value);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const onOkCertificateIssueDate = (value) => {
    console.log("onOk: ", value);
  };

  const addCertificate = () => {
    setLoadAddCertificate(true);
    let data = {
      clientId: Props.clientInfo.id,
      issueDate: certificateIssueDate,
      certificateExpiryDate: certificateExpiryDate,
      country: parseInt(selectedCountry),
    };
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    Props.onAddCertificate(data)
      .then(() => {
        var findCountry =
          Props.countriesData &&
          Props.countriesData.items.find((obj) => obj.id == selectedCountry);
        message.success("Police Certificate added successfully");
        Props.onGetProfile();
        setCertificateIssueDate("");
        setSelectedCountry("");
        policeCertificateForm.resetFields();
        setLoadAddCertificate(false);
        if (certificateExpiryDate) {
          let myData = {
            title: "Police Certificate Expiry",
            reminderDate: certificateExpiryDate || "1900-01-01T00:00:00+00:00",
            valueA: findCountry ? findCountry.name : "",
            typeId: 6,
          };
          AddAutoReminder(myData);
        }
        setCertificateExpiryDate("");
      })
      .catch((err) => {
        console.log("show errrorrrr ", err);
        setLoadAddCertificate(false);
        message.error("Failed to add certificate");
      });

    // Props.onAddCertificate(data);
  };

  const addAuthority = (values) => {
    if (Props.clientInfo.memberType.toLowerCase() == "client")
      setLoadAddAuthority(true);
    let data = [
      {
        clientId: Props.clientInfo.id,
        authority: parseInt(values.selectedAuthority),
        submittedDate: values.authSubDate,
        returnedDate: values.authRetDate,
        refNumber: values.authRefNo,
      },
    ];

    // Props.onAddAuthority(data);
    Props.onAddAuthority(data)
      .then(() => {
        setLoadAddAuthority(false);
        message.success("Accessing Authority added successfully");
        Props.onGetProfile();

        authorityRef.resetFields();
      })
      .catch(() => {
        setLoadAddAuthority(false);
        message.error("Fialed to add authority!");
      });
  };

  const removeCertificate = (id) => {
    let data = {
      id: id,
      delete: true,
    };

    Props.onRemoveCertificate(data);
  };

  const removeAuthority = (id) => {
    let data = {
      id: id,
      delete: true,
    };

    Props.onRemoveAuthority(data);
  };

  const updatePassport = (values) => {
    Props.updateProfilePassport(values, Props.clientInfo);
  };

  return (
    <div>
      <div className="denied-cont">
        <div className="profile-down-arrow-cont">
          <img src={Images.whiteArrow} className="profile-down-arrow-icon" />
        </div>
        <span className="denied-text">Police Certificate Info</span>
      </div>

      <div className="form-container">
        <Form onFinish={addCertificate} form={policeCertificateForm}>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Issue Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="certificateIssueDate"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select certificate issue date!",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: "100%" }}
                    value={certificateIssueDate}
                    onChange={onChangeCertificateIssueDate}
                    onOk={onOkCertificateIssueDate}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Expiry Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="certificateExpiryDate"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select certificate expiry date!",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: "100%" }}
                    value={certificateExpiryDate}
                    onChange={onChangeCertificateExpiryDate}
                    onOk={onOk}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div
            className="form-cont"
            style={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <div style={{ width: "100%" }}>
              <p>Country</p>
              <Form.Item
                name="selectedCountry"
                rules={[
                  {
                    required: true,
                    message: "Please select certificate country!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  value={selectedCountry}
                  onChange={handleChangeCertificateCountry}
                >
                  {Props.countriesData &&
                    Props.countriesData.items
                      .filter(
                        (obj) =>
                          obj.name.toLowerCase() === "new zealand" ||
                          obj.name.toLowerCase() === "australia" ||
                          obj.name.toLowerCase() === "canada"
                      )
                      .map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.name}</Option>;
                      })}

                  {Props.countriesData &&
                    Props.countriesData.items.map((data, index) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      if (
                        data.name.toLowerCase() === "new zealand" ||
                        data.name.toLowerCase() === "australia" ||
                        data.name.toLowerCase() === "canada"
                      ) {
                      } else {
                        return <Option value={data.id}>{data.name}</Option>;
                      }
                    })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="button-org-cont">
            <Button
              loading={loadAddCertificate}
              className="button-org"
              htmlType="submit"
              // onClick={addCertificate}
            >
              <span
                style={{ color: "#FFFFFF", fontSize: 12, fontWeight: "600" }}
              >
                ADD
              </span>
            </Button>
          </div>
        </Form>

        <div
          className="visa-table"
          style={{ paddingBottom: 0, paddingTop: 10 }}
        >
          <div
            className="main-table-cont"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="visa-table-header">
              <div className="header-tab-10">
                <span className="header-font" style={{ fontSize: 11 }}>
                  Sr #
                </span>
              </div>
              <div className="header-tab-30">
                <span className="header-font" style={{ fontSize: 11 }}>
                  Police Certificate Date
                </span>
              </div>
              <div className="header-tab-15">
                <span className="header-font" style={{ fontSize: 11 }}>
                  Expiry Date
                </span>
              </div>
              <div className="header-tab-20">
                <span className="header-font" style={{ fontSize: 11 }}>
                  Country
                </span>
              </div>
              <div className="header-tab-15">
                <span className="header-font" style={{ fontSize: 11 }}>
                  Action
                </span>
              </div>
            </div>
            {certificates &&
              certificates.map((item, index) => {
                return (
                  <div className="table-content" key={index}>
                    <div className="content-index-10">
                      <span className="content-text" style={{ fontSize: 10 }}>
                        {index + 1}
                      </span>
                    </div>
                    <div className="content-index-30">
                      <span className="content-text" style={{ fontSize: 10 }}>
                        {item.issueDate === "1900-01-01T00:00:00+00:00"
                          ? ""
                          : moment(item.issueDate).format("DD/MM/YYYY")}
                      </span>
                    </div>
                    <div className="content-index-15">
                      <span className="content-text" style={{ fontSize: 10 }}>
                        {item.certificateExpiryDate ===
                        "1900-01-01T00:00:00+00:00"
                          ? ""
                          : moment(item.certificateExpiryDate).format(
                              "DD/MM/YYYY"
                            )}
                      </span>
                    </div>
                    <div className="content-index-20">
                      <span className="content-text" style={{ fontSize: 10 }}>
                        {item.countryName}
                      </span>
                    </div>
                    <div className="content-index-15">
                      <div
                        className="remove-cont"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeCertificate(item.id)}
                      >
                        <span className="remove-text" style={{ fontSize: 10 }}>
                          REMOVE
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Form form={form} onFinish={updatePassport}>
        <div
          className="denied-cont"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div
            className="denied-cont"
            style={{ padding: 0, alignItems: "center" }}
          >
            <div className="profile-down-arrow-cont">
              <img
                src={Images.whiteArrow}
                className="profile-down-arrow-icon"
              />
            </div>
            <span className="denied-text">Passport Detail</span>
          </div>
          <Form.Item>
            <Button className="button-blue" htmlType="submit">
              <span style={{ color: "#FFFFFF" }}>Update</span>
            </Button>
          </Form.Item>
        </div>
        <div className="form-container">
          <div
            className="form-cont"
            style={{
              paddingBottom: 0,
              justifyContent: "space-between",
            }}
          >
            <div className="input-cont-width">
              <p>Passport Number</p>
              <div className="profile-input-border">
                <Form.Item name="passportNo">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Country Of Issue</p>
              <Form.Item name="passportCountry">
                <Select showSearch optionFilterProp="children">
                  {Props.countriesData &&
                    Props.countriesData.items
                      .filter(
                        (obj) =>
                          obj.name.toLowerCase() === "new zealand" ||
                          obj.name.toLowerCase() === "australia" ||
                          obj.name.toLowerCase() === "canada"
                      )
                      .map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.name}</Option>;
                      })}

                  {Props.countriesData &&
                    Props.countriesData.items.map((data, index) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      if (
                        data.name.toLowerCase() === "new zealand" ||
                        data.name.toLowerCase() === "australia" ||
                        data.name.toLowerCase() === "canada"
                      ) {
                      } else {
                        return <Option value={data.id}>{data.name}</Option>;
                      }
                    })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Passport Issue Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item name="passportIssueDate" style={{ width: "100%" }}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Passport Expiry Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item name="passportExpiryDate" style={{ width: "100%" }}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className="denied-cont">
          <div className="profile-down-arrow-cont">
            <img src={Images.whiteArrow} className="profile-down-arrow-icon" />
          </div>
          <span className="denied-text">
            Second Passport Detail (If Client Is Dual)
          </span>
        </div>
        <div className="form-container">
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Second Passport Number</p>
              <div className="profile-input-border">
                <Form.Item name="secondPassportNo">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Second Country Of Issue</p>
              <Form.Item name="secondPassportCountry">
                <Select showSearch optionFilterProp="children">
                  {Props.countriesData &&
                    Props.countriesData.items
                      .filter(
                        (obj) =>
                          obj.name.toLowerCase() === "new zealand" ||
                          obj.name.toLowerCase() === "australia" ||
                          obj.name.toLowerCase() === "canada"
                      )
                      .map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.name}</Option>;
                      })}

                  {Props.countriesData &&
                    Props.countriesData.items.map((data, index) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      if (
                        data.name.toLowerCase() === "new zealand" ||
                        data.name.toLowerCase() === "australia" ||
                        data.name.toLowerCase() === "canada"
                      ) {
                      } else {
                        return <Option value={data.id}>{data.name}</Option>;
                      }
                    })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Secondary Passport Issue Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="secondPassportIssueDate"
                  style={{ width: "100%" }}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Second Passport Expiry Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="secondPassportExpiryDate"
                  style={{ width: "100%" }}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>

      <div className="denied-cont">
        <div className="profile-down-arrow-cont">
          <img src={Images.whiteArrow} className="profile-down-arrow-icon" />
        </div>
        <span className="denied-text">Accessing Authorities (Aus)</span>
      </div>
      <div className="form-container">
        <Form onFinish={addAuthority} ref={authorityRef}>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Authority</p>
              <Form.Item
                name="selectedAuthority"
                rules={[
                  {
                    required: true,
                    message: "Please select authority!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                >
                  {authorityOption}
                </Select>
              </Form.Item>
            </div>

            <div className="input-cont-width">
              <p>Submitted Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="authSubDate"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select authority submitted date!",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: "100%" }}
                    value={authSubDate}
                    onChange={onChangeAuthSubDate}
                    onOk={onOk}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <div className="input-cont-width">
              <p>Returned Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                }}
              >
                <Form.Item
                  name="authRetDate"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select authority returned date!",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: "100%" }}
                    value={authRetDate}
                    onChange={onChangeAuthRetDate}
                    onOk={onOk}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Ref #</p>
              <div className="profile-input-border">
                <Form.Item
                  name="authRefNo"
                  rules={[
                    {
                      required: true,
                      message: "Please select authority ref #!",
                    },
                  ]}
                >
                  <input
                    style={{ width: "100%" }}
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={authRefNo}
                    onChange={(e) => setAuthRefNo(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="button-org-cont">
            <Button
              loading={loadAddAuthority}
              className="button-org"
              htmlType="submit"
            >
              <span
                style={{ color: "#FFFFFF", fontSize: 12, fontWeight: "600" }}
              >
                ADD
              </span>
            </Button>
          </div>
        </Form>

        <div
          className="visa-table"
          style={{ paddingBottom: 0, paddingTop: 10 }}
        >
          <div
            className="main-table-cont"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="visa-table-header">
              <div className="header-tab-10">
                <span className="authority-table-head-font">Sr #</span>
              </div>
              <div className="header-tab-30">
                <span className="authority-table-head-font">Authority</span>
              </div>
              <div className="header-tab-20">
                <span className="authority-table-head-font">
                  Submitted Date
                </span>
              </div>
              <div className="header-tab-20">
                <span className="authority-table-head-font">Returned Date</span>
              </div>
              <div className="header-tab-10">
                <span className="authority-table-head-font">Ref #</span>
              </div>
              <div className="header-tab-15">
                <span className="authority-table-head-font">Action</span>
              </div>
            </div>
            {authorities &&
              authorities.map((item, index) => {
                var authName =
                  Props.accessingAuthData &&
                  Props.accessingAuthData.length > 0 &&
                  Props.accessingAuthData.find(
                    (obj) => obj.id == item.authority
                  );
                return (
                  <div className="table-content" key={index}>
                    <div className="content-index-10">
                      <span className="auth-text">{index + 1}</span>
                    </div>
                    <div className="content-index-30">
                      <span className="auth-text">
                        {authName ? authName.name : ""}
                      </span>
                    </div>
                    <div className="content-index-20">
                      <span className="auth-text">
                        {item.submittedDate === "1900-01-01T00:00:00+00:00"
                          ? ""
                          : moment(item.submittedDate).format("DD/MM/YYYY")}
                      </span>
                    </div>
                    <div className="content-index-20">
                      <span className="auth-text">
                        {item.returnedDate === "1900-01-01T00:00:00+00:00"
                          ? ""
                          : moment(item.returnedDate).format("DD/MM/YYYY")}
                      </span>
                    </div>
                    <div className="content-index-10">
                      <span className="auth-text">{item.refNumber}</span>
                    </div>
                    <div className="content-index-15">
                      <div
                        className="remove-cont"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeAuthority(item.id)}
                      >
                        <span className="remove-text">REMOVE</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Passport;
