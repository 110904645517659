// @flow
import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  Checkbox,
  Row,
  Col,
  Button,
  Upload,
  Select,
  TimePicker,
  message,
  Spin,
} from "antd";

import { Images } from "../../Themes";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { apiRefresh } from "../../services/api";
import getUserBranchPermissions from "../getUserBranchPermissions";

function PermissionCard(Props) {
  const [loading, setLoading] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [pushValues, setPushValues] = useState(true);
  const [name, setName] = useState(Props.user.user.fullName);
  const [isOwnerCheckBox, setIsOwnerCheckBox] = useState(
    Props.user.user.isOwner
  );
  const [email, setEmail] = useState(Props.user.user.alternateEmail);
  const [isButtonClicked, setClickButton] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(
    Props.user.userPermissions &&
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userloginPermissions
      ? Props.user.userPermissions[0].userloginPermissions.timeZone
      : ""
  );
  const [companyDesignation, setCompanyDesignation] = useState(
    Props.user.user.title
  );
  const [role, setRole] = useState("");
  const [isManager, setIsManager] = useState(Props.user.user.isManager);
  const [isOwner, setIsOwner] = useState(Props.user.user.isOwner);
  const [salesPerson, setSalesPerson] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    Props.imageUrl ? Props.imageUrl : Props.user.user.image
  );
  const [selectedBranches, setSelectedBranch] = useState([]);
  const [newPassword, setNewPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [copySelectedBranches, setCopypSelectedBranch] = useState([]);
  const [deleteBranch, setDeleteBranch] = useState([]);
  const [isEnabled, setEnabled] = useState(false);
  const [branchuserLoginPermission, setBranchUserLoginPermission] = useState(
    Props.user.userPermissions &&
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userloginPermissions
      ? Props.user.userPermissions[0].userloginPermissions.days
      : []
  );
  const [branchuserSystemPermission, setBranchUserSystemPermission] = useState(
    Props.user.userPermissions && Props.user.userPermissions.length > 0
      ? Props.user.userPermissions[0].userSystemPermissions
      : []
  );
  const [isLock, setIsLock] = useState(Props.user.user.isLock);
  const [startTime, setStartTime] = useState(
    Props.user.userPermissions &&
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userloginPermissions
      ? Props.user.userPermissions[0].userloginPermissions.timeStart
      : null
  );
  const [endTime, setEndTime] = useState(
    Props.user.userPermissions &&
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userloginPermissions
      ? Props.user.userPermissions[0].userloginPermissions.timeEnd
      : null
  );
  // const [startTimeString, setStartTimeString] = useState(
  //   Props.user.userPermissions &&
  //     Props.user.userPermissions.length > 0 &&
  //     Props.user.userPermissions[0].userloginPermissions.timeStart
  //     ? Props.user.userPermissions[0].userloginPermissions.timeStart
  //     : ""
  // );
  // const [endTimeString, setEndTimeString] = useState(
  //   Props.user.userPermissions &&
  //     Props.user.userPermissions.length > 0 &&
  //     Props.user.userPermissions[0].userloginPermissions.timeEnd
  //     ? moment(Props.user.userPermissions[0].userloginPermissions.timeEnd)
  //     : ""
  // );
  // const [timeZone, settimeZone] = useState(
  //   Props.user.userPermissions && Props.user.userPermissions.length > 0
  //     ? Props.user.userPermissions[0].userloginPermissions.timeZone
  //     : null
  // );
  const [selectedOption, setSelectedOption] = useState(
    Props.user.user.roleId == 3
      ? "User"
      : Props.user.user.roleId === 2
      ? "Manager"
      : Props.user.user.roleId === 1
      ? "Super User"
      : null
  );

  var loginPermissions = branchuserLoginPermission || [];
  var systemPermissions = branchuserSystemPermission || [];

  const onClickDisable = () => {
    let state = isLock;
    let disableData = {
      userId: Props.user.user.id,
      isLock: !isLock,
    };
    setLoading(true);
    Props.onDisable(disableData)
      .then((res) => {
        setIsLock(disableData.isLock);

        if (disableData.isLock) {
          message.success("User disabled successfully!");
        } else {
          message.success("User enabled successfully!");
        }
        setLoading(false);
      })
      .catch(() => {
        message.error("Failed!");
        setLoading(false);
      });
  };

  useEffect(() => {
    setName(Props.user.user.fullName);
    setEmail(Props.user.user.alternateEmail);
    setCompanyDesignation(Props.user.user.title);
    setImageUrl(Props.imageUrl ? Props.imageUrl : Props.user.user.image);
    setImageUrl(Props.imageUrl ? Props.imageUrl : Props.user.user.image);
    // if (Props.user.userPermissions[0].userloginPermissions.days) {
    //   for (
    //     var i = 0;
    //     i < Props.user.userPermissions[0].userloginPermissions.days.length;
    //     i++
    //   ) {
    //     if (Props.user.userPermissions[0].userloginPermissions.days[i].status) {
    //       loginPermissions.push(
    //         Props.user.userPermissions[0].userloginPermissions.days[i].day
    //       );
    //       console.log("login permission assign value", loginPermissions);
    //     }
    //   }
    // for(var i=0; i<loginPermissions.length; i++)
    setBranchUserLoginPermission(loginPermissions);

    // }
    // else {
    //   setBranchUserLoginPermission(...loginPermissions)
    //
    // }

    // if (Props.user.userPermissions[0].userSystemPermissions.length > 0) {
    //   for (
    //     var i = 0;
    //     i < Props.user.userPermissions[0].userSystemPermissions.length;
    //     i++
    //   ) {
    //     if (Props.user.userPermissions[0].userSystemPermissions[i].status) {
    //       systemPermissions.push(
    //         Props.user.userPermissions[0].userSystemPermissions[i].role
    //       );
    //     }
    //     console.log("login permission assign value", systemPermissions);
    //
    //   }
    //
    setBranchUserSystemPermission([...systemPermissions]);
    // } else {
    //   setBranchUserSystemPermission([...systemPermissions])
    //
    // }
  }, []);

  useEffect(() => {
    let findSalePerson =
      Props.user.userPermissions &&
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userType
        ? true
        : false;
    setSalesPerson(findSalePerson);
  }, [Props.user]);

  const { Option } = Select;

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  if (pushValues) {
    if (
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userloginPermissions &&
      Props.user.userPermissions[0].userloginPermissions.days
    ) {
      for (
        var i = 0;
        i < Props.user.userPermissions[0].userloginPermissions.days.length;
        i++
      ) {
        if (
          Props.user.userPermissions[0].userloginPermissions.days[i].status == 1
        ) {
          loginPermissions.push(
            Props.user.userPermissions[0].userloginPermissions.days[i].day
          );
        }
        if (loginPermissions.length > 0)
          setBranchUserLoginPermission(loginPermissions);
      }
    }

    if (
      Props.user.userPermissions.length > 0 &&
      Props.user.userPermissions[0].userSystemPermissions
    ) {
      for (
        var i = 0;
        i < Props.user.userPermissions[0].userSystemPermissions.length;
        i++
      ) {
        if (Props.user.userPermissions[0].userSystemPermissions[i].status) {
          systemPermissions.push(
            Props.user.userPermissions[0].userSystemPermissions[i].role
          );
        }
      }
      if (systemPermissions.length > 0)
        setBranchUserSystemPermission(systemPermissions);
    }

    setPushValues(false);
  }

  const branch = [];
  if (Props.branchData && Props.branchData.length > 0) {
    for (let i = 0; i < Props.branchData.length; i++) {
      branch.push(
        <Option key={Props.branchData[i].branchId}>
          {Props.branchData[i].branchName}
        </Option>
      );
    }
  }

  if (
    pushValues &&
    Props.user.user.userBranches &&
    Props.user.user.userBranches.length > 0
  ) {
    for (var i = 0; i < Props.user.user.userBranches.length; i++) {
      if (Props.user.user.userBranches[i].status == 1)
        selectedBranches.push(Props.user.user.userBranches[i].branchId);
    }
    setCopypSelectedBranch(selectedBranches);
    setDeleteBranch(selectedBranches.concat(deleteBranch));
    setPushValues(false);
  }

  const handleChangeBranch = (selectedBranches) => {
    setSelectedBranch(selectedBranches);
    setDeleteBranch(copySelectedBranches);

    if (selectedBranches.length < copySelectedBranches.length) {
      for (var i = 0; i < selectedBranches.length; i++) {
        for (var j = 0; j < deleteBranch.length; j++) {
          if (selectedBranches[i] === deleteBranch[j]) {
            deleteBranch.splice(j, 1);
            break;
          }
        }
      }
      let data = {
        branchId: deleteBranch[0],
        status: 0,
        userId: Props.user.user.id,
        branchDesignation: Props.user.user.title,
      };
      Props.onUnassignBranch(data);
    } else {
      let data = {
        branchId: selectedBranches[selectedBranches.length - 1],
        status: 1,
        userId: Props.user.user.id,
        branchDesignation: Props.user.user.title,
      };
      Props.onAssignBranch(data);
    }
  };

  const onChangeStartTime = (time, timeString) => {
    setStartTime(timeString);
  };

  const onChangeEndTime = (time, timeString) => {
    setEndTime(timeString);
  };

  const myChangeHandler = (value) => {
    setCheckboxValue(value);
  };

  const onChange = (checkedValues) => {
    loginPermissions = checkedValues;
  };

  const onAccessChange = (checkedAccessValues) => {
    systemPermissions = checkedAccessValues;
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  function updateTeamMember() {
    setLoading(true);
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!name) {
      message.warning("User name must be required!");
    } else {
      let userData = {
        Id: Props.user.user.id,
        fullName: name,
        alternateEmail: email,
        companyDesignation: "",
        isManager: isManager,
        isOwner: isOwner,
        RoleId: 0,
        Title: companyDesignation,
        Image: Props.imageUrl ? Props.imageUrl : imageUrl,
        ForgotPasswordBit: false,
        LastSignedIn: new Date(),
        Signature: "",
        TimeZone: "",
        comission: 0,
        agreementUrl: "",
        description: "",
        SignatureImage: "",
      };

      let userBranchData = {
        isManager: isManager,
        branchId: Props.user.branchId,
        status: Props.user.status,
        userId: Props.user.userId,
        branchDesignation: Props.user.branchDesignation,
      };

      const ownerCountOpt = {
        url: `v1/users/OwnerCount`,
      };
      ownerCountOpt.types = [
        "GET_OWNER_COUNT_SUCCES",
        "GET_OWNER_COUNT_FAILURE",
      ];

      apiRefresh.get(ownerCountOpt).then((response) => {
        if (response > 1 || userData.isOwner) {
          Props.onUpdateManager(userData)
            .then((res) => {
              if (res && res.payload === "This email is already taken") {
                //donothing
                message.warning("This email is already taken!");
              } else {
                message.success("Updated!");
                Props.getUpdatedScreenOnSave(true);
              }

              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
            });

          const updUserOpt = {
            url: `v1/user/Branch`,
          };
          updUserOpt.types = [
            "UPD_USER_BRANCH_SUCCESS",
            "UPD_USER_BRANCH_FAILURE",
          ];

          apiRefresh
            .put(updUserOpt, userBranchData)
            .then((res) => {})
            .catch((err) => {});

          if (userData.isManager && !userData.isOwner) {
            let _data = {
              id:
                Props.user &&
                Props.user.userPermissions[0] &&
                Props.user.userPermissions[0].id &&
                Props.user.userPermissions[0].id,
              userId: Props.user.userId,
              branchId: Props.user.branchId,
              userSystemPermissions: [
                {
                  role: "Client Database",
                  status: 1,
                },
                {
                  role: "Client Database (Update)",
                  status: 1,
                },
                {
                  role: "Potential Clients",
                  status: 1,
                },
                {
                  role: "Add A Client",
                  status: 1,
                },
                {
                  role: "Potential Clients (Update)",
                  status: 1,
                },
                {
                  role: "Accounts",
                  status: 1,
                },
                {
                  role: "Visa Forms",
                  status: 1,
                },
                {
                  role: "Reports",
                  status: 1,
                },
                {
                  role: "Mail",
                  status: 1,
                },
                {
                  role: "Case Management",
                  status: 1,
                },
                {
                  role: "Agent Portal",
                  status: 1,
                },
                {
                  role: "Agent Portal Accounts",
                  status: 1,
                },
                {
                  role: "School Management",
                  status: 1,
                },
                {
                  role: "School Management Account",
                  status: 1,
                },
                {
                  role: "Employer Management",
                  status: 1,
                },
                {
                  role: "Employer Management Account",
                  status: 1,
                },
                {
                  role: "Custom Questionnaire",
                  status: 1,
                },
                {
                  role: "Deals",
                  status: 1,
                },
                {
                  role: "Meetings",
                  status: 1,
                },
                {
                  role: "Time Tracking",
                  status: 1,
                },
                {
                  role: "Company Level Settings",
                  status: 1,
                },
                {
                  role: "Xero",
                  status: 1,
                },
                // {
                //   role: "Potential Client Account",
                //   status: 1
                // }
              ],
              userloginPermissions: {
                timeStart: "",
                timeEnd: "",
                timeZone: "",
                days: [
                  {
                    day: "Monday",
                    status: 1,
                  },
                  {
                    day: "Tuesday",
                    status: 1,
                  },
                  {
                    day: "Wednesday",
                    status: 1,
                  },
                  {
                    day: "Thursday",
                    status: 1,
                  },
                  {
                    day: "Friday",
                    status: 1,
                  },
                  {
                    day: "Saturday",
                    status: 1,
                  },
                  {
                    day: "Sunday",
                    status: 1,
                  },
                ],
              },
              userType: salesPerson ? "Sales Person" : "",
            };
            Props && Props.onUpdateUserPermission(_data);
          } else if (!userData.isManager && !userData.isOwner) {
            let _data = {
              id:
                Props.user &&
                Props.user.userPermissions[0] &&
                Props.user.userPermissions[0].id &&
                Props.user.userPermissions[0].id,
              userId: Props.user.userId,
              branchId: Props.user.branchId,
              userSystemPermissions: [
                {
                  role: "Client Database",
                  status: 0,
                },
                {
                  role: "Client Database (Update)",
                  status: 1,
                },
                {
                  role: "Potential Clients",
                  status: 0,
                },
                {
                  role: "Add A Client",
                  status: 0,
                },
                {
                  role: "Potential Clients (Update)",
                  status: 1,
                },
                {
                  role: "Accounts",
                  status: 0,
                },
                {
                  role: "Visa Forms",
                  status: 0,
                },
                {
                  role: "Reports",
                  status: 0,
                },
                {
                  role: "Mail",
                  status: 0,
                },
                {
                  role: "Case Management",
                  status: 0,
                },
                {
                  role: "Agent Portal",
                  status: 0,
                },
                {
                  role: "Agent Portal Accounts",
                  status: 0,
                },
                {
                  role: "School Management",
                  status: 0,
                },
                {
                  role: "School Management Account",
                  status: 0,
                },
                {
                  role: "Employer Management",
                  status: 0,
                },
                {
                  role: "Employer Management Account",
                  status: 0,
                },
                {
                  role: "Custom Questionnaire",
                  status: 0,
                },
                {
                  role: "Deals",
                  status: 0,
                },
                {
                  role: "Meetings",
                  status: 0,
                },
                {
                  role: "Time Tracking",
                  status: 0,
                },
                {
                  role: "Company Level Settings",
                  status: 0,
                },
                {
                  role: "Xero",
                  status: 0,
                },

                // {
                //   role: "Potential Client Account",
                //   status: 1
                // }
              ],
              userloginPermissions: {
                timeStart: "",
                timeEnd: "",
                timeZone: "",
                days: [
                  {
                    day: "Monday",
                    status: 0,
                  },
                  {
                    day: "Tuesday",
                    status: 0,
                  },
                  {
                    day: "Wednesday",
                    status: 0,
                  },
                  {
                    day: "Thursday",
                    status: 0,
                  },
                  {
                    day: "Friday",
                    status: 0,
                  },
                  {
                    day: "Saturday",
                    status: 0,
                  },
                  {
                    day: "Sunday",
                    status: 0,
                  },
                ],
              },
              userType: salesPerson ? "Sales Person" : "",
            };
            Props && Props.onUpdateUserPermission(_data);
          } else {
          }
        } else if (userData.isOwner == Props.user.user.isOwner) {
          Props.onUpdateManager(userData)
            .then((res) => {
              if (res && res.payload === "This email is already taken") {
                //donothing
              } else {
                message.success("Updated!");
                Props.getUpdatedScreenOnSave(true);
              }

              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
            });

          const updUserOpt = {
            url: `v1/user/Branch`,
          };
          updUserOpt.types = [
            "UPD_USER_BRANCH_SUCCESS",
            "UPD_USER_BRANCH_FAILURE",
          ];

          apiRefresh
            .put(updUserOpt, userBranchData)
            .then((res) => {})
            .catch((err) => {});
          if (userData.isManager && !userData.isOwner) {
            let _data = {
              id:
                Props.user &&
                Props.user.userPermissions[0] &&
                Props.user.userPermissions[0].id &&
                Props.user.userPermissions[0].id,
              userId: Props.user.userId,
              branchId: Props.user.branchId,
              userSystemPermissions: [
                {
                  role: "Client Database",
                  status: 1,
                },
                {
                  role: "Client Database (Update)",
                  status: 1,
                },
                {
                  role: "Potential Clients",
                  status: 1,
                },
                {
                  role: "Add A Client",
                  status: 1,
                },
                {
                  role: "Potential Clients (Update)",
                  status: 1,
                },
                {
                  role: "Accounts",
                  status: 1,
                },
                {
                  role: "Visa Forms",
                  status: 1,
                },
                {
                  role: "Reports",
                  status: 1,
                },
                {
                  role: "Mail",
                  status: 1,
                },
                {
                  role: "Case Management",
                  status: 1,
                },
                {
                  role: "Agent Portal",
                  status: 1,
                },
                {
                  role: "Agent Portal Accounts",
                  status: 1,
                },
                {
                  role: "School Management",
                  status: 1,
                },
                {
                  role: "School Management Account",
                  status: 1,
                },
                {
                  role: "Employer Management",
                  status: 1,
                },
                {
                  role: "Employer Management Account",
                  status: 1,
                },
                {
                  role: "Custom Questionnaire",
                  status: 1,
                },
                {
                  role: "Deals",
                  status: 1,
                },
                {
                  role: "Meetings",
                  status: 1,
                },
                {
                  role: "Time Tracking",
                  status: 1,
                },
                {
                  role: "Company Level Settings",
                  status: 1,
                },
                {
                  role: "Xero",
                  status: 1,
                },
                // {
                //   role: "Potential Client Account",
                //   status: 1
                // }
              ],
              userloginPermissions: {
                timeStart: "",
                timeEnd: "",
                timeZone: "",
                days: [
                  {
                    day: "Monday",
                    status: 1,
                  },
                  {
                    day: "Tuesday",
                    status: 1,
                  },
                  {
                    day: "Wednesday",
                    status: 1,
                  },
                  {
                    day: "Thursday",
                    status: 1,
                  },
                  {
                    day: "Friday",
                    status: 1,
                  },
                  {
                    day: "Saturday",
                    status: 1,
                  },
                  {
                    day: "Sunday",
                    status: 1,
                  },
                ],
              },
              userType: salesPerson ? "Sales Person" : "",
            };
            Props && Props.onUpdateUserPermission(_data);
          } else if (!userData.isManager && !userData.isOwner) {
            let _data = {
              id:
                Props.user &&
                Props.user.userPermissions[0] &&
                Props.user.userPermissions[0].id &&
                Props.user.userPermissions[0].id,
              userId: Props.user.userId,
              branchId: Props.user.branchId,
              userSystemPermissions: [
                {
                  role: "Client Database",
                  status: 0,
                },
                {
                  role: "Client Database (Update)",
                  status: 1,
                },
                {
                  role: "Potential Clients",
                  status: 0,
                },
                {
                  role: "Add A Client",
                  status: 0,
                },
                {
                  role: "Potential Clients (Update)",
                  status: 1,
                },
                {
                  role: "Accounts",
                  status: 0,
                },
                {
                  role: "Visa Forms",
                  status: 0,
                },
                {
                  role: "Reports",
                  status: 0,
                },
                {
                  role: "Mail",
                  status: 0,
                },
                {
                  role: "Case Management",
                  status: 0,
                },
                {
                  role: "Agent Portal",
                  status: 0,
                },
                {
                  role: "Agent Portal Accounts",
                  status: 0,
                },
                {
                  role: "School Management",
                  status: 0,
                },
                {
                  role: "School Management Account",
                  status: 0,
                },
                {
                  role: "Employer Management",
                  status: 0,
                },
                {
                  role: "Employer Management Account",
                  status: 0,
                },
                {
                  role: "Custom Questionnaire",
                  status: 0,
                },
                {
                  role: "Deals",
                  status: 0,
                },
                {
                  role: "Meetings",
                  status: 0,
                },
                {
                  role: "Time Tracking",
                  status: 0,
                },
                {
                  role: "Company Level Settings",
                  status: 0,
                },
                {
                  role: "Xero",
                  status: 0,
                },
                // {
                //   role: "Potential Client Account",
                //   status: 1
                // }
              ],
              userloginPermissions: {
                timeStart: "",
                timeEnd: "",
                timeZone: "",
                days: [
                  {
                    day: "Monday",
                    status: 0,
                  },
                  {
                    day: "Tuesday",
                    status: 0,
                  },
                  {
                    day: "Wednesday",
                    status: 0,
                  },
                  {
                    day: "Thursday",
                    status: 0,
                  },
                  {
                    day: "Friday",
                    status: 0,
                  },
                  {
                    day: "Saturday",
                    status: 0,
                  },
                  {
                    day: "Sunday",
                    status: 0,
                  },
                ],
              },
              userType: salesPerson ? "Sales Person" : "",
            };
            Props && Props.onUpdateUserPermission(_data);
          } else {
          }
        } else {
          setLoading(false);
          message.error("Alleast one owner is mendatory!");
        }
      });
    }
  }

  let passwordData = {
    userId: Props.user.user.id,
    email: email,
    password: newPassword,
    confirmPassword: confirmPassword,
  };

  const addUserPermissons = (
    loginPermissions,
    systemPermissions,
    userId,
    permissionId,
    startTime,
    endTime,
    t_zone,
    userBranchId
  ) => {
    const daysArray = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const systemPermissionsArray = [
      "Client Database",
      "Client Database (Update)",
      "Add A Client",
      "Potential Clients",
      "Potential Clients (Update)",
      "Accounts",
      "Visa Forms",
      "Reports",
      "Mail",
      "Case Management",
      "Agent Portal",
      "Agent Portal Accounts",
      "School Management",
      "School Management Account",
      "Employer Management",
      "Employer Management Account",
      "Custom Questionnaire",
      "Deals",
      "Meetings",
      "Time Tracking",
      "Company Level Settings",
      "Xero",
      // "Potential Client Account"
    ];

    var userloginPermissions = [];
    var userSystemPermissions = [];
    for (var i = 0; i < daysArray.length; i++) {
      var findIndex = loginPermissions.find((obj) => obj === daysArray[i]);
      if (findIndex) {
        userloginPermissions.push({ day: findIndex, status: 1 });
      } else {
        userloginPermissions.push({ day: daysArray[i], status: 0 });
      }
    }
    for (var i = 0; i < systemPermissionsArray.length; i++) {
      var findIndex = systemPermissions.find(
        (obj) => obj === systemPermissionsArray[i]
      );
      if (findIndex) {
        // if (findIndex == "Client Database") {
        //   userSystemPermissions.push({
        //     role: "Potential Clients",
        //     status: 1,
        //   });
        // }
        userSystemPermissions.push({
          role: systemPermissionsArray[i],
          status: 1,
        });
      } else {
        if (systemPermissionsArray[i] === "Client Database (Update)") {
          userSystemPermissions.push({
            role: systemPermissionsArray[i],
            status: 1,
          });
          // } else if (systemPermissionsArray[i] === "Potential Clients (Update)") {
          //   userSystemPermissions.push({
          //     role: systemPermissionsArray[i],
          //     status: 1,
          //   });
        } else {
          userSystemPermissions.push({
            role: systemPermissionsArray[i],
            status: 0,
          });
        }
      }
    }

    // var findClientDb = systemPermissions.find(
    //   (obj) => obj == "Client Database"
    // );
    // if (findClientDb) {
    //   var ind = userSystemPermissions.findIndex(
    //     (obj) => obj.role == "Potential Clients"
    //   );
    //   if (ind != -1) {
    //     userSystemPermissions[ind].status = 1;
    //   }
    // } else {
    //   var ind = userSystemPermissions.findIndex(
    //     (obj) => obj.role == "Potential Clients"
    //   );
    //   if (ind != -1) {
    //     userSystemPermissions[ind].status = 0;
    //   }
    // }

    let newdata = {
      id: permissionId,
      userId: userId,
      branchId: userBranchId,
      userSystemPermissions: userSystemPermissions,
      userloginPermissions: {
        timeStart: startTime,
        timeEnd: endTime,
        timeZone: t_zone,
        days: userloginPermissions,
      },
      userType: salesPerson ? "Sales Person" : "",
    };
    Props.onUpdateUserPermission(newdata)
      .then(() => {
        message.success("User permissions updated successfully");
        // getUserBranchPermissions();
      })
      .catch(() => {
        message.error("Give permission access failed!");
      });
  };

  const onResetLoginTime = () => {
    let data = {
      id:
        Props.user.userPermissions.length > 0
          ? Props.user.userPermissions[0].id
          : 0,
      userId: Props.user.userId,
      branchId: Props.user.branchId,
      userSystemPermissions: [
        {
          role: "Client Database",
          status: 1,
        },
        {
          role: "Client Database (Update)",
          status: 1,
        },
        {
          role: "Potential Clients (Update)",
          status: 1,
        },
        {
          role: "Add A Client",
          status: 1,
        },
        {
          role: "Potential Clients",
          status: 1,
        },
        {
          role: "Accounts",
          status: 1,
        },
        {
          role: "Visa Forms",
          status: 1,
        },
        {
          role: "Reports",
          status: 1,
        },
        {
          role: "Mail",
          status: 1,
        },
        {
          role: "Case Management",
          status: 1,
        },
        {
          role: "Agent Portal",
          status: 1,
        },
        {
          role: "Agent Portal Accounts",
          status: 1,
        },
        {
          role: "School Management",
          status: 1,
        },
        {
          role: "School Management Account",
          status: 1,
        },
        {
          role: "Employer Management",
          status: 1,
        },
        {
          role: "Employer Management Account",
          status: 1,
        },
        {
          role: "Custom Questionnaire",
          status: 1,
        },
        {
          role: "Deals",
          status: 1,
        },
        {
          role: "Meetings",
          status: 1,
        },
        {
          role: "Time Tracking",
          status: 1,
        },
        {
          role: "Company Level Settings",
          status: 1,
        },
        {
          role: "Xero",
          status: 1,
        },
        // {
        //   role: "Potential Client Account",
        //   status: 1
        // }
      ],
      userloginPermissions: {
        timeStart: "",
        timeEnd: "",
        timeZone: "",
        days: [
          {
            day: "Monday",
            status: 1,
          },
          {
            day: "Tuesday",
            status: 1,
          },
          {
            day: "Wednesday",
            status: 1,
          },
          {
            day: "Thursday",
            status: 1,
          },
          {
            day: "Friday",
            status: 1,
          },
          {
            day: "Saturday",
            status: 1,
          },
          {
            day: "Sunday",
            status: 1,
          },
        ],
      },
      userType: salesPerson ? "Sales Person" : "",
    };
    setLoading(true);
    Props.onUpdateUserPermission(data)
      .then(() => {
        setLoading(false);
        setStartTime("");
        setEndTime("");
        setSelectedTimezone("");
        message.success("Login time reset successfully");
        // getUserBranchPermissions();
      })
      .catch(() => {
        setLoading(false);
        message.error("Login time reset failed!");
      });
  };

  const updateCompanyUserData = (userData) => {
    setLoading(true);
    Props.onUpdateManager(userData)
      .then(() => {
        Props.onGetTeamMember()
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const uploadImage = (info) => {
    let formData = new FormData();
    formData.append("File", info);

    Props.onUploadAvatar(formData).then((res) => {
      setImageUrl(res.payload);
    });
  };

  const updateUsersPassword = (passwordData) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    if (!strongRegex.test(passwordData.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (passwordData.password !== passwordData.confirmPassword) {
      message.warning("New password and confirm password must be same.");
    } else {
      setLoading(true);
      let data = {
        userId: passwordData.userId,
        email: passwordData.email,
        password: passwordData.password,
      };
      Props.onChangeUsersPassword(data)
        .then(() => {
          setLoading(false);
          message.success("User's password updated successfully");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  let userOwner = localStorage.getItem("userOwner");
  return (
    <div>
      <div>
        <div
          className="sus-permission-card-cont"
          style={{
            height: "auto",
            // marginLeft: 45,
            borderRadius: 0,
            backgroundColor: "#F2F2F2",
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              paddingTop: 20,
              paddingTop: 5,
              paddingBottom: 0,
              display: "block",
              width: "100%",
              // marginLeft: -30,
              // marginRight: -30,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div className="sus-profile-cont">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={(info) => uploadImage(info)}
                    onChange={Props.handleChangeImage}
                  >
                    {Props.imageUrl ? (
                      <img
                        src={Props.imageUrl}
                        alt="avatar"
                        style={{ width: 105, height: 105 }}
                      />
                    ) : imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: 105, height: 105 }}
                      />
                    ) : (
                      <img
                        src={Images.dummyUserImage}
                        className="sus-profile-img"
                      />
                    )}
                  </Upload>
                </div>
              </div>
              <div style={{ width: "56%" }}>
                <div className="profile-input-border">
                  <input
                    className="profile-input"
                    placeholder="advisor"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="profile-input-border" style={{ marginTop: 10 }}>
                  <input
                    className="profile-input"
                    placeholder="testadvisor@ezymigrate.com"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="profile-input-border" style={{ marginTop: 10 }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={companyDesignation}
                    onChange={(e) => setCompanyDesignation(e.target.value)}
                  />
                </div>

                {userOwner === "True" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      // marginRight: -50,
                    }}
                  >
                    <div className="sus-checkbox-cont">
                      <input
                        type="checkbox"
                        className="sus-checkbox"
                        onChange={() => setIsManager(!isManager)}
                        defaultChecked={isManager}
                      />
                      <span className="sus-disable-text">Branch Manager</span>
                    </div>
                    {isOwnerCheckBox ? (
                      <div className="sus-checkbox-cont">
                        <input
                          // disabled={userOwner == "True" ? false : true}
                          disabled={true}
                          type="checkbox"
                          className="sus-checkbox"
                          onChange={() => setIsOwner(!isOwner)}
                          defaultChecked={isOwner}
                        />
                        <span className="sus-disable-text">Owner</span>
                      </div>
                    ) : null}
                    <div className="sus-checkbox-cont">
                      <input
                        // disabled={userOwner == "True" ? false : true}
                        // disabled={true}
                        type="checkbox"
                        className="sus-checkbox"
                        disabled={isOwner}
                        onChange={(e) => {
                          setSalesPerson(e.target.checked);
                          if (e.target.checked) {
                            setIsOwner(false);
                            setIsManager(false);
                          }
                        }}
                        checked={salesPerson}
                      />
                      <span className="sus-disable-text">Sales Person</span>
                    </div>
                  </div>
                )}
                {Props.user.userId !== localStorage.getItem("userId") ||
                  (localStorage.getItem("userManager") && isOwnerCheckBox && (
                    <div
                      className="sus-disable-cont"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // setIsLock(!isLock);
                        setClickButton(!isButtonClicked);
                        onClickDisable();
                      }}
                    >
                      <span className="sus-disable-text">
                        {isLock ? "Enable Team Member" : "Disable Team Member"}
                      </span>
                      <img src={Images.disable} className="sus-disable-icon" />
                    </div>
                  ))}
                <Button
                  disabled={
                    Props.user.userId !== localStorage.getItem("userId") &&
                    localStorage.getItem("userManager") &&
                    isOwnerCheckBox
                  }
                  onClick={() => updateTeamMember()}
                  type="primary"
                  className="sus-add-btn"
                  style={{ marginTop: 20 }}
                >
                  SAVE
                </Button>
              </div>
            </div>
            {true && (
              <div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <span className="sus-cp-text" style={{ fontSize: 12 }}>
                    CHANGE PASSWORD
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="profile-input-border"
                    style={{ width: "47%" }}
                  >
                    <input
                      className="profile-input"
                      placeholder="New Password"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div
                    className="profile-input-border"
                    style={{ width: "47%" }}
                  >
                    <input
                      className="profile-input"
                      placeholder="Confirm Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <Button
                    disabled={
                      Props.user.userId !== localStorage.getItem("userId") &&
                      localStorage.getItem("userManager") &&
                      isOwnerCheckBox
                    }
                    onClick={() => updateUsersPassword(passwordData)}
                    type="primary"
                    className="sus-save-btn"
                    style={{ marginTop: 20 }}
                  >
                    CHANGE PASSWORD
                  </Button>
                </div>

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <span className="sus-cp-text" style={{ fontSize: 12 }}>
                    ACTIVE TIME
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 15,
                    // marginRight: -50,
                  }}
                >
                  <div style={{ width: "31%" }}>
                    <div className="select-options" style={{ border: 0 }}>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        placeholder="Start Time"
                        onChange={onChangeStartTime}
                        style={{ width: "100%" }}
                        value={
                          startTime
                            ? moment(startTime, "h:mm a")
                            : moment("00:00:00", "h:mm a")
                        }
                      />
                    </div>
                  </div>
                  <div style={{ width: "31%" }}>
                    <div className="select-options" style={{ border: 0 }}>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        placeholder="End Time"
                        onChange={onChangeEndTime}
                        style={{ width: "100%" }}
                        value={
                          endTime
                            ? moment(endTime, "h:mm a")
                            : moment("00:00:00", "h:mm a")
                        }
                      />
                    </div>
                  </div>
                  <div style={{ width: "31%" }}>
                    <div
                      className="permission-time-zone"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      {/* <Select
                          defaultValue={selectedOption}
                          bordered={false}
                          placeholder="Time Zone"
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        >
                          <Option key={"(UTC+12:00)"}>
                            (UTC+12:00) Auckland, Wellington
                          </Option>
                        </Select> */}
                      {/* <TimezoneSelect
                          value={selectedTimezone}
                          onChange={setSelectedTimezone}
                        /> */}
                      <TimezonePicker
                        absolute={false}
                        value={selectedTimezone}
                        placeholder="Select timezone..."
                        onChange={setSelectedTimezone}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <span className="sus-cp-text" style={{ fontSize: 12 }}>
                    ACTIVE DAYS
                  </span>
                </div>

                <Checkbox.Group
                  style={{ width: "100%" }}
                  defaultValue={
                    branchuserLoginPermission &&
                    branchuserLoginPermission.length > 0
                      ? branchuserLoginPermission
                      : null
                  }
                  onChange={onChange}
                  disabled={
                    Props.user.userId == localStorage.getItem("userId") ||
                    (localStorage.getItem("userManager") && isOwnerCheckBox)
                  }
                >
                  <div className="sus-days-checkbox-row">
                    <div className="sus-days-checkbox-cont">
                      <Checkbox value="Monday" className="sus-checkbox-label">
                        MONDAY
                      </Checkbox>
                    </div>
                    <div className="sus-days-checkbox-cont">
                      <Checkbox value="Tuesday" className="sus-checkbox-label">
                        TUESDAY
                      </Checkbox>
                    </div>
                    <div className="sus-days-checkbox-cont">
                      <Checkbox
                        value="Wednesday"
                        className="sus-checkbox-label"
                      >
                        WEDNESDAY
                      </Checkbox>
                    </div>
                    <div className="sus-days-checkbox-cont">
                      <Checkbox value="Thursday" className="sus-checkbox-label">
                        THURSDAY
                      </Checkbox>
                    </div>
                    <div className="sus-days-checkbox-cont">
                      <Checkbox value="Friday" className="sus-checkbox-label">
                        FRIDAY
                      </Checkbox>
                    </div>

                    <div className="sus-days-checkbox-cont">
                      <Checkbox value="Saturday" className="sus-checkbox-label">
                        SATURDAY
                      </Checkbox>
                    </div>
                    <div className="sus-days-checkbox-cont">
                      <Checkbox value="Sunday" className="sus-checkbox-label">
                        SUNDAY
                      </Checkbox>
                    </div>
                  </div>
                </Checkbox.Group>

                <div style={{ display: "flex", marginTop: 10 }}>
                  <Button
                    type="primary"
                    className="sus-save-btn"
                    disabled={
                      Props.user.userId == localStorage.getItem("userId") ||
                      (localStorage.getItem("userManager") && isOwnerCheckBox)
                    }
                    onClick={() => {
                      addUserPermissons(
                        loginPermissions,
                        systemPermissions,
                        Props.user.user.id,
                        Props.user.userPermissions.length > 0
                          ? Props.user.userPermissions[0].id
                          : 0,
                        startTime,
                        endTime,
                        selectedTimezone,
                        Props.user.branchId
                      );
                    }}
                  >
                    UPDATE LOGIN TIME
                  </Button>
                  <Button
                    disabled={
                      Props.user.userId == localStorage.getItem("userId") ||
                      (localStorage.getItem("userManager") && isOwnerCheckBox)
                    }
                    type="primary"
                    className="sus-save-btn"
                    style={{ marginLeft: 10 }}
                    onClick={onResetLoginTime}
                  >
                    RESET LOGIN TIME
                  </Button>
                </div>

                <div style={{ marginTop: 30, marginBottom: 10 }}>
                  <span className="sus-cp-text" style={{ fontSize: 12 }}>
                    ACCESS
                  </span>
                </div>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  defaultValue={
                    branchuserSystemPermission &&
                    branchuserSystemPermission.length > 0
                      ? branchuserSystemPermission
                      : []
                  }
                  onChange={onAccessChange}
                  disabled={
                    Props.user.userId == localStorage.getItem("userId") ||
                    (localStorage.getItem("userManager") && isOwnerCheckBox)
                  }
                >
                  <div className="sus-checkbox-row" style={{ marginTop: 30 }}>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Client Database"
                        className="sus-checkbox-label"
                      >
                        CLIENT
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Potential Clients"
                        className="sus-checkbox-label"
                      >
                        POTENTIAL CLIENTS
                      </Checkbox>
                    </div>
                    {/*<div className="sus-checkbox-cont">*/}
                    {/*  <Checkbox*/}
                    {/*    value="Client Database (Update)"*/}
                    {/*    className="sus-checkbox-label"*/}
                    {/*  >*/}
                    {/*    CLIENT DATABASE UPDATE*/}
                    {/*  </Checkbox>*/}
                    {/*</div>*/}
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Add A Client"
                        className="sus-checkbox-label"
                      >
                        ADD A CLIENT
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Visa Forms"
                        className="sus-checkbox-label"
                      >
                        VISA FORMS
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox value="Reports" className="sus-checkbox-label">
                        REPORTS
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Potential Clients (Update)"
                        className="sus-checkbox-label"
                      >
                        POTENTIAL CLIENTS (UPDATE)
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox value="Accounts" className="sus-checkbox-label">
                        ACCOUNTS
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox value="Mail" className="sus-checkbox-label">
                        MAIL
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Case Management"
                        className="sus-checkbox-label"
                      >
                        CASE MANAGEMENT
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Agent Portal"
                        className="sus-checkbox-label"
                      >
                        AGENT PORTAL
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Agent Portal Accounts"
                        className="sus-checkbox-label"
                      >
                        AGENT PORTAL ACCOUNTS
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="School Management"
                        className="sus-checkbox-label"
                      >
                        SCHOOL MANAGEMENT
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="School Management Account"
                        className="sus-checkbox-label"
                      >
                        SCHOOL MANAGEMENT ACCOUNT
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Employer Management Account"
                        className="sus-checkbox-label"
                      >
                        EMPLOYER MANAGEMENT ACCOUNT
                      </Checkbox>
                    </div>

                    {/*<div className="sus-checkbox-row">*/}

                    {/*  <div className="sus-checkbox-cont">*/}
                    {/*    <Checkbox*/}
                    {/*      value="Supplier Management"*/}
                    {/*      className="sus-checkbox-label"*/}
                    {/*    >*/}
                    {/*      SUPPLIER MANAGEMENT*/}
                    {/*    </Checkbox>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="sus-checkbox-cont">*/}
                    {/*  <Checkbox*/}
                    {/*    value="Referral Management"*/}
                    {/*    className="sus-checkbox-label"*/}
                    {/*  >*/}
                    {/*    REFERRAL MANAGEMENT*/}
                    {/*  </Checkbox>*/}
                    {/*</div>*/}
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Custom Questionnaire"
                        className="sus-checkbox-label"
                      >
                        CUSTOM QUESTIONNAIRE
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox value="Deals" className="sus-checkbox-label">
                        DEALS
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox value="Meetings" className="sus-checkbox-label">
                        MEETINGS
                      </Checkbox>
                    </div>
                  </div>

                  <div className="sus-checkbox-row">
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Time Tracking"
                        className="sus-checkbox-label"
                      >
                        TIME TRACKING
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Employer Management"
                        className="sus-checkbox-label"
                      >
                        EMPLOYER MANAGEMENT
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox
                        value="Company Level Settings"
                        className="sus-checkbox-label"
                      >
                        COMPANY LEVEL SETTINGS
                      </Checkbox>
                    </div>
                    <div className="sus-checkbox-cont">
                      <Checkbox value="Xero" className="sus-checkbox-label">
                        Xero
                      </Checkbox>
                    </div>
                    {/*<div className="sus-checkbox-cont">*/}
                    {/*  <Checkbox value="CPD" className="sus-checkbox-label">*/}
                    {/*    CPD*/}
                    {/*  </Checkbox>*/}
                    {/*</div>*/}
                  </div>

                  {/*<div className="sus-checkbox-row">*/}
                  {/*  /!*<div className="sus-checkbox-cont">*!/*/}
                  {/*  /!*  <Checkbox*!/*/}
                  {/*  /!*    value="Potential Client Account"*!/*/}
                  {/*  /!*    className="sus-checkbox-label"*!/*/}
                  {/*  /!*  >*!/*/}
                  {/*  /!*    POTENTIAL CLIENT ACCOUNT*!/*/}
                  {/*  /!*  </Checkbox>*!/*/}
                  {/*  /!*</div>*!/*/}
                  {/*  <div className="sus-checkbox-cont">*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </Checkbox.Group>

                <div
                  style={{
                    display: "flex",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <Button
                    onClick={() => {
                      addUserPermissons(
                        loginPermissions,
                        systemPermissions,
                        Props.user.user.id,
                        Props.user.userPermissions.length > 0
                          ? Props.user.userPermissions[0].id
                          : 0,
                        startTime,
                        endTime,
                        selectedTimezone,
                        Props.user.branchId
                      );
                    }}
                    disabled={
                      Props.user.userId == localStorage.getItem("userId") ||
                      (localStorage.getItem("userManager") && isOwnerCheckBox)
                    }
                    type="primary"
                    className="sus-save-btn"
                    style={{ marginTop: 20 }}
                  >
                    GIVE ACCESS
                  </Button>
                </div>
              </div>
            )}

            {!true && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: -50,
                }}
              >
                <div></div>
                <div className="sus-show-btn">
                  <span className="sus-save-btn-text">SHOW</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermissionCard;
