import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UpdateQuestionnaire from "../CustomQuestionnaire/UpdateQuestionnaire";

import {
  getQuestionnaire,
  updateQuestionnaire,
  getMappingParent,
  getMappingChild
} from "../store/Actions";

const mapStateToProps = state => {
  return {
    questionnaireData: state.questionnaireReducer.questionnaireData,
    mappParentData: state.questionnaireReducer.mappParentData,
    mappChildData: state.questionnaireReducer.mappChildData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
    onUpdateQuestionnaire: bindActionCreators(updateQuestionnaire, dispatch),
    onGetMappingParent: bindActionCreators(getMappingParent, dispatch),
    onGetMappingChild: bindActionCreators(getMappingChild, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateQuestionnaire);
