import React, { useState, useEffect } from "react";
import "./SidebarStyles.css";
import dashboardBI from "../svgs/menu-icons/dashboardBI.svg";
import dashboardClient from "../svgs/menu-icons/dashboard.svg";
import caseManagement from "../svgs/menu-icons/case-management.svg";
import meeting from "../svgs/menu-icons/meeting.svg";
import taskComplete from "../svgs/menu-icons/task-complete.svg";
import playButton from "../svgs/menu-icons/play-button.svg";
import userGroup from "../svgs/menu-icons/users-group.svg";
import addContact from "../svgs/menu-icons/add-contact.svg";
import group from "../svgs/menu-icons/group.svg";
import report from "../svgs/menu-icons/report.svg";
import account from "../svgs/menu-icons/calculator.svg";
import xero from "../svgs/menu-icons/xero.svg";
import email from "../svgs/menu-icons/black-back-closed-envelope-shape.svg";
import agent from "../svgs/menu-icons/insurance-agent.svg";
import schoolManagement from "../svgs/menu-icons/college-graduation.svg";
import supplierManagement from "../svgs/menu-icons/manager.svg";
import questionnaires from "../svgs/menu-icons/question-speech-bubble.svg";
import deals from "../svgs/menu-icons/handshake.svg";
import timeTracking from "../svgs/menu-icons/clock.svg";
import visaForms from "../svgs/menu-icons/forms.svg";
import techSupport from "../svgs/menu-icons/admin-with-cogwheels.svg";
import settings from "../svgs/menu-icons/settings.svg";
import superUserSettings from "../svgs/menu-icons/super_user_settings.svg";
import cpdPlan from "../svgs/menu-icons/CPD-plan.svg";
import usefulLink from "../svgs/menu-icons/usefull-link.svg";
import chat from "../svgs/menu-icons/chat.svg";
import { Tabs, Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getPotentialClientUpdateBol,
  addNewClientNull,
  setMailDataEmpty,
} from "../store/Actions";
import { LockOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;
const Sidebar = () => {
  var userOwner = localStorage.getItem("userOwner");
  var userManager = JSON.parse(localStorage.getItem("userManager"));
  var admintoken = localStorage.getItem("admintoken");
  var isSupervisor = localStorage.getItem("isSupervisor") === "True";
  var isAgent = localStorage.getItem("isAgent") === "true" ? true : false;
  var userType =
    localStorage.getItem("userType") == "Sales Person" ? true : false;

  const [currentKey, setKey] = useState("1");
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentKey == "1" && window.location.pathname === "/profile")
      setKey(null);
  }, [window.location.pathname]);

  return isSupervisor ? (
    <Menu
      className="supervisorSideBar"
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["1"]}
    >
      <Menu.Item key="1" icon={<img src={dashboardBI} />}>
        <Link to="/supervisor/batches">Batches</Link>
      </Menu.Item>
    </Menu>
  ) : (
    <Menu
      className="dashboardSideBar"
      style={{
        paddingBottom: 100,
        height: isAgent || userType == "Sales Person" ? "100%" : "auto",
      }}
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["1"]}
      selectedKeys={[currentKey]}
      onClick={(data) => setKey(data.key)}
    >
      {!isAgent && !userType ? (
        <Menu.Item key="1" icon={<img src={dashboardBI} />}>
          <Link to="/dashboardBI">Dashboard (BI)</Link>
        </Menu.Item>
      ) : null}
      {!isAgent && !userType ? (
        <Menu.Item key="2" icon={<img src={dashboardClient} />}>
          <Link to="/dashboard">Dashboard (Client)</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {admintoken ? (
        <SubMenu
          key="sub2"
          icon={
            <img
              src={caseManagement}
              style={{ width: "27px", marginRight: "21px" }}
            />
          }
          title="Case Management"
        >
          <Menu.Item key="sub2-1" className={"padding"}>
            <Link to="/Case-Management-All">Cases</Link>
          </Menu.Item>
          <Menu.Item key="sub2-2" className={"padding"}>
            <Link to="/AddChecklist">Add Checklist</Link>
          </Menu.Item>
          <Menu.Item key="sub2-3" className={"padding"}>
            <Link to="/Checklists">Checklist(S)</Link>
          </Menu.Item>
        </SubMenu>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find((x) => x.name.toLowerCase() == "casemanagement")
          .status ? (
        localStorage.getItem("userSystemPermissions") ? (
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "case management").status ==
            1 &&
          !isAgent &&
          !userType ? (
            <SubMenu
              key="sub2"
              icon={
                <img
                  src={caseManagement}
                  style={{ width: "27px", marginRight: "21px" }}
                />
              }
              title="Case Management"
            >
              <Menu.Item key="sub2-1" className={"padding"}>
                <Link to="/Case-Management-All">Cases</Link>
              </Menu.Item>
              <Menu.Item key="sub2-2" className={"padding"}>
                <Link to="/AddChecklist">Add Checklist</Link>
              </Menu.Item>
              <Menu.Item key="sub2-3" className={"padding"}>
                <Link to="/Checklists">Checklist(S)</Link>
              </Menu.Item>
            </SubMenu>
          ) : (
            ""
          )
        ) : (
          ""
        )
      ) : (
        <SubMenu
          key="sub2"
          icon={
            <img
              src={caseManagement}
              style={{ width: "27px", marginRight: "21px" }}
            />
          }
          title="Case Management"
        >
          <Menu.Item key="sub2-1" className={"padding"}>
            <Link to="/not-allowed">Cases</Link>
          </Menu.Item>
          <Menu.Item key="sub2-2" className={"padding"}>
            <Link to="/not-allowed">Add Checklist</Link>
          </Menu.Item>
          <Menu.Item key="sub2-3" className={"padding"}>
            <Link to="/not-allowed">Checklist(S)</Link>
          </Menu.Item>
        </SubMenu>
      )}

      {/* {admintoken ? (
        <Menu.Item key="4" icon={<img src={meeting} />}>
          <Link to="/meetings">Meetings</Link>
        </Menu.Item>
      ) : localStorage.getItem("userSystemPermissions") ? (
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "meetings").status == 1 ? (
          <Menu.Item key="4" icon={<img src={meeting} />}>
            <Link to="/meetings">Meetings</Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        ""
      )} */}

      {!isAgent && !userType ? (
        <Menu.Item key="5" icon={<img src={taskComplete} />}>
          <Link to="/tasks-and-reminders/tasks-to-do">
            My Daily Tasks / Reminders
          </Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {!isAgent && !userType ? (
        <Menu.Item key="7" icon={<img src={userGroup} />}>
          <Link to="/all-clients">All Clients</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "add a client").status == 1 &&
        !isAgent &&
        !userType && (
          <Menu.Item key="8" icon={<img src={addContact} />}>
            <Link
              to="/add-new-client"
              onClick={() => {
                dispatch(getPotentialClientUpdateBol(true));
                dispatch(addNewClientNull());
              }}
            >
              Add New Client
            </Link>
          </Menu.Item>
        )}
      {admintoken ? (
        <SubMenu
          key="sub1"
          icon={
            <img src={group} style={{ width: "27px", marginRight: "21px" }} />
          }
          title="Potential Clients"
        >
          <Menu.Item key="9" className={"padding"}>
            <Link to="/potential-client/potential-clients">Inquiry</Link>
          </Menu.Item>

          <Menu.Item key="10" className={"padding"}>
            <Link to="/web-assessment">Web Assessment</Link>
          </Menu.Item>

          <Menu.Item key="11" className={"padding"}>
            <Link to="/agent-clients">Agent Clients</Link>
          </Menu.Item>
          <Menu.Item key="12" className={"padding"}>
            <Link to="/potential-client-questionnaire">
              Custom Questionnaires
            </Link>
          </Menu.Item>
        </SubMenu>
      ) : (
        <SubMenu
          key="sub1"
          icon={
            <img src={group} style={{ width: "27px", marginRight: "21px" }} />
          }
          title="Potential Clients"
        >
          <Menu.Item key="9" className={"padding"}>
            <Link to="/potential-client/potential-clients">Inquiry</Link>
          </Menu.Item>
          {isAgent || userType ? (
            ""
          ) : localStorage.getItem("userBranchPermissions") &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userBranchPermissions"))
                )
              )
            ).branchPermission.find(
              (x) => x.name.toLowerCase() == "customquestionnaire"
            ).status ? (
            <Menu.Item key="10" className={"padding"}>
              <Link to="/web-assessment">Web Assessment</Link>
            </Menu.Item>
          ) : (
            <Menu.Item key="10" className={"padding"}>
              <Link to="/not-allowed">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Web Assessment </span>
                  <LockOutlined style={{ color: "#fff" }} />
                </div>
              </Link>
            </Menu.Item>
          )}
          {/* <Menu.Item key="11" className={"padding"}>
            <Link to="/agent-clients">Agent Clients</Link>
          </Menu.Item> */}
          {isAgent || userType ? (
            ""
          ) : localStorage.getItem("userBranchPermissions") &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userBranchPermissions"))
                )
              )
            ).branchPermission.find(
              (x) => x.name.toLowerCase() == "customquestionnaire"
            ).status ? (
            <Menu.Item key="12" className={"padding"}>
              <Link to="/potential-client-questionnaire">
                Custom Questionnaires
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item key="12" className={"padding"}>
              <Link to="/not-allowed">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Custom Questionnaires </span>
                  <LockOutlined style={{ color: "#fff" }} />
                </div>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {admintoken ? (
        <Menu.Item key="17" icon={<img src={email} />}>
          <Link
            onClick={() => {
              dispatch(setMailDataEmpty());
            }}
            to="/email"
          >
            Mail
          </Link>
        </Menu.Item>
      ) : localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "mail").status == 1 &&
        !isAgent &&
        !userType ? (
        <Menu.Item key="17" icon={<img src={email} />}>
          <Link
            onClick={() => {
              dispatch(setMailDataEmpty());
            }}
            to="/email"
          >
            Mail
          </Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {/* <Menu.Item key="29" icon={<img src={chat} />}>
        <Link to="/chats">Chats</Link>
      </Menu.Item> */}
      {admintoken ? (
        <Menu.Item key="14" icon={<img src={account} />}>
          <Link to="/accounts">Accounts</Link>
        </Menu.Item>
      ) : localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "accounts").status == 1 &&
        !isAgent &&
        !userType ? (
        <Menu.Item key="14" icon={<img src={account} />}>
          <Link to="/accounts">Accounts</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {!isAgent &&
      !userType &&
      ((localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "xero") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "xero").status == 1) ||
        userOwner == "True") ? (
        <Menu.Item key="16" icon={<img src={xero} />}>
          <Link to="/xero-sync">XERO</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {admintoken ? (
        <Menu.Item key="18" icon={<img src={agent} />}>
          <Link to="/agents">Agents</Link>
        </Menu.Item>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find((x) => x.name.toLowerCase() == "agent")
          .status ? (
        localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "agent portal").status == 1 &&
        !isAgent ? (
          <Menu.Item key="18" icon={<img src={agent} />}>
            <Link to="/agents">Agents</Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        <Menu.Item key="18" icon={<img src={agent} />}>
          <Link to="/not-allowed">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Agents </span>
              <LockOutlined style={{ color: "#fff" }} />
            </div>
          </Link>
        </Menu.Item>
      )}

      {admintoken ? (
        <Menu.Item key="19" icon={<img src={schoolManagement} />}>
          <Link to="/school-management">School Management</Link>
        </Menu.Item>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find((x) => x.name.toLowerCase() == "school")
          .status ? (
        localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "school management").status ==
          1 &&
        !isAgent &&
        !userType ? (
          <Menu.Item key="19" icon={<img src={schoolManagement} />}>
            <Link to="/school-management">School Management</Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        <Menu.Item key="19" icon={<img src={schoolManagement} />}>
          <Link to="/not-allowed">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>School Management </span>
              <LockOutlined style={{ color: "#fff" }} />
            </div>
          </Link>
        </Menu.Item>
      )}

      {admintoken ? (
        <Menu.Item key="20" icon={<img src={supplierManagement} />}>
          <Link to="/employer-management">Employer Management</Link>
        </Menu.Item>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find((x) => x.name.toLowerCase() == "employer")
          .status ? (
        localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find(
          (x) =>
            x.role.toLowerCase() == "employer management" ||
            x.role.toLowerCase() == "supplier management"
        ).status == 1 &&
        !isAgent &&
        !userType ? (
          <Menu.Item key="20" icon={<img src={supplierManagement} />}>
            <Link to="/employer-management">Employer Management</Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        <Menu.Item key="20" icon={<img src={supplierManagement} />}>
          <Link to="/not-allowed">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Employer Management </span>
              <LockOutlined style={{ color: "#fff" }} />
            </div>
          </Link>
        </Menu.Item>
      )}

      {admintoken ? (
        <Menu.Item key="13" icon={<img src={report} />}>
          <Link to="/reports">Reports</Link>
        </Menu.Item>
      ) : localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "reports").status == 1 &&
        !isAgent &&
        !userType ? (
        <Menu.Item key="13" icon={<img src={report} />}>
          <Link to="/reports">Reports</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {admintoken ? (
        <Menu.Item key="22" icon={<img src={deals} />}>
          <Link to="/deals">Deals</Link>
        </Menu.Item>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find((x) => x.name.toLowerCase() == "deals")
          .status ? (
        localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "deals").status == 1 &&
        !isAgent &&
        !userType ? (
          <Menu.Item key="22" icon={<img src={deals} />}>
            <Link to="/deals">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Deals </span>
              </div>
            </Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        <Menu.Item key="22" icon={<img src={deals} />}>
          <Link to="/not-allowed">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Deals </span>
              <LockOutlined style={{ color: "#fff" }} />
            </div>
          </Link>
        </Menu.Item>
      )}

      {admintoken ? (
        <Menu.Item key="23" icon={<img src={timeTracking} />}>
          <Link to="/time-tracking">Time Tracking</Link>
        </Menu.Item>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find((x) => x.name.toLowerCase() == "timetracking")
          .status ? (
        localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "time tracking").status == 1 &&
        !isAgent &&
        !userType ? (
          <Menu.Item key="23" icon={<img src={timeTracking} />}>
            <Link to="/time-tracking">Time Tracking</Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        <Menu.Item key="23" icon={<img src={timeTracking} />}>
          <Link to="/not-allowed">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Time Tracking </span>
              <LockOutlined style={{ color: "#fff" }} />
            </div>
          </Link>
        </Menu.Item>
      )}

      {admintoken ? (
        <Menu.Item key="24" icon={<img src={visaForms} />}>
          <Link to="/nz-form-list">Visa Forms</Link>
        </Menu.Item>
      ) : localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "visa forms").status == 1 &&
        !isAgent &&
        !userType ? (
        <Menu.Item key="24" icon={<img src={visaForms} />}>
          <Link to="/nz-form-list">Visa Forms</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {/* <Menu.Item key="27" icon={<img src={cpdPlan} />}>
        CPD Plan
      </Menu.Item> */}
      {!isAgent && !userType ? (
        <Menu.Item key="6" icon={<img src={playButton} />}>
          <Link to="/video-tutorials">Video Tutorials</Link>
        </Menu.Item>
      ) : (
        ""
      )}
      {/*<Menu.Item key="28" icon={<img src={usefulLink} />}>*/}
      {/*  <Link to="/useful-links">Useful Links</Link>*/}
      {/*</Menu.Item>*/}
      {!isAgent && !userType ? (
        <Menu.Item key="25" icon={<img src={techSupport} />}>
          <Link to="/technical-support">Technical Support</Link>
        </Menu.Item>
      ) : (
        ""
      )}
      {admintoken ? (
        <Menu.Item key="21" icon={<img src={questionnaires} />}>
          <Link to="/questionnaire">Custom Questionnaires</Link>
        </Menu.Item>
      ) : localStorage.getItem("userBranchPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userBranchPermissions")))
          )
        ).branchPermission.find(
          (x) => x.name.toLowerCase() == "customquestionnaire"
        ).status ? (
        localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find(
          (x) =>
            x.role.toLowerCase() == "custom questionnaire" ||
            x.role.toLowerCase() == "custom questionnaires"
        ).status == 1 &&
        !isAgent &&
        !userType ? (
          <Menu.Item key="21" icon={<img src={questionnaires} />}>
            <Link to="/questionnaire">Custom Questionnaires</Link>
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        <Menu.Item key="21" icon={<img src={questionnaires} />}>
          <Link to="/not-allowed">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Custom Questionnaires </span>
              <LockOutlined style={{ color: "#fff" }} />
            </div>
          </Link>
        </Menu.Item>
      )}

      {!isAgent && !userType ? (
        <Menu.Item key="26" icon={<img src={settings} />}>
          <Link to="/account-settings">Account Settings</Link>
        </Menu.Item>
      ) : (
        ""
      )}
      {userOwner == "True" && !isAgent && !userType && (
        <Menu.Item
          style={{ marginBottom: 60 }}
          key="30"
          icon={<img src={superUserSettings} />}
        >
          <Link to="/super-user-setting?activeTab=company-information">
            Super User Settings
          </Link>
        </Menu.Item>
      )}
      {userOwner != "True" && !isAgent && !userType && userManager && (
        <Menu.Item
          style={{ marginBottom: 60 }}
          key="30"
          icon={<img src={superUserSettings} />}
        >
          <Link to="/branch-settings">Super User Settings</Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default Sidebar;
