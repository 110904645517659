import { put, takeLatest, all } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import AppAccountActions from "../Redux/AppAccountRedux";
import LoginActions from "../Redux/LoginRedux/LoginRedux";
import AccountSettingsActions from "../Redux/SuperUserSettingRedux/AccountSettingsRedux";
import UserContactActions from "../Redux/SuperUserSettingRedux/UserContactRedux";
import UserAddressActions from "../Redux/SuperUserSettingRedux/UserAddressRedux";
import UserCompanyActions from "../Redux/SuperUserSettingRedux/UserCompanyRedux";
import TeamMemberActions from "../Redux/SuperUserSettingRedux/TeamMemberRedux";

// const baseUrl = "https://linuxapi-stage.ezymigrate.co.nz/";
const baseUrl = "https://uatapi.ezymigrate.co.nz/";

var bearer = "";

// get token function

function getToken() {
  console.log("in token get function");
  var token = localStorage.getItem("token");
  bearer = "Bearer " + token;
}

// Lognin api call

function* login(data) {
  let loginData = data.data;
  const requestOptions = {
    method: "POST",
    headers: {},
    body: loginData,
  };
  const json = yield fetch(
    "https://ezm-ultra-is-sts.azurewebsites.net/connect/token",
    requestOptions
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  });
  if (json) {
    // localStorage.setItem("token", json.access_token);

    // var token = localStorage.getItem("token");
    // var jwtToken = token + " jwt token";
    // var jwtDecoded = jwt_decode(token);

    // localStorage.setItem("userId", jwtDecoded.sub);

    yield put(AppAccountActions.login(json.access_token));
    yield put(LoginActions.loginSuccess(true));
  }
}

// get client profile data

function* getClientProfile() {
  console.log("on call sagas clint profile");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/case/GetAllCases",
    requestOptions
  ).then((response) => {
    console.log("response client information api", response);
    response.json();
  });
  yield put({ type: "GET_CLIENT_PROFILE_SUCCESS", json: json });
}

// update client profile

function* updClientProfile() {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  };
  const json = yield fetch(baseUrl + "v1/employees", requestOptions).then(
    (response) => {
      console.log("response update client info api", response);
      response.json();
    }
  );
  yield put({ type: "UPD_CLIENT_INFO_SUCCESS", json: json });
}

// Get visa info

function* getVisaInfo() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const json = yield fetch(baseUrl + "v1/employees", requestOptions).then(
    (response) => {
      console.log("response site api", response);
      response.json();
    }
  );
  yield put({ type: "GET_VISA_INFO_SUCCESS", json: json });
}

// Add visa info

function* addVisaInfo() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const json = yield fetch(baseUrl + "v1/employees", requestOptions).then(
    (response) => {
      console.log("response site api", response);
      response.json();
    }
  );
  yield put({ type: "ADD_VISA_INFO_SUCCESS", json: json });
}

// get open case management all cases

function* getAllCases() {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/case/GetAllCases",
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "GET_OCM_SUCCESS", json: json });
}

// get employer

function* getEmployer(id) {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(baseUrl + "v1/employer/" + id, requestOptions).then(
    (response) => {
      console.log("response client cases api", response);
      response.json();
    }
  );
  yield put({ type: "GET_EMPLOYER_SUCCESS", json: json });
}

// get all employer

function* getAllEmployer(id) {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/All" + id,
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "GET_ALL_EMPLOYER_SUCCESS", json: json });
}

// add employer

function* addEmployer(data, token) {
  console.log("on call sagas add employer", data);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: bearer,
    },
    body: data.data,
  };
  const json = yield fetch(baseUrl + "v1/employer", requestOptions).then(
    (response) => {
      console.log("response add employer api", response);
      response.json();
    }
  );
  yield put({ type: "ADD_EMPLOYER_SUCCESS", json: json });
}

// update employer

function* editEmployer() {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(baseUrl + "v1/employer", requestOptions).then(
    (response) => {
      console.log("response client cases api", response);
      response.json();
    }
  );
  yield put({ type: "UPD_EMPLOYER_SUCCESS", json: json });
}

// delete employer

function* deleteEmployer() {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(baseUrl + "v1/employer", requestOptions).then(
    (response) => {
      console.log("response client cases api", response);
      response.json();
    }
  );
  yield put({ type: "DLT_EMPLOYER_SUCCESS", json: json });
}

// get employer file notes

function* getEmpFileNotes(id) {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/filenote" + id,
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "GET_EMP_FILE_NOTE_SUCCESS", json: json });
}

// get all employer file notes

function* getAllEmpFileNotes(id) {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/filenote/All" + id,
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "GET_ALL_EMP_FILE_NOTE_SUCCESS", json: json });
}

// add employer file notes

function* addEmpFileNotes(data, token) {
  console.log("on call sagas add employer", data);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: bearer,
    },
    body: data.data,
  };
  const json = yield fetch(baseUrl + "v1/employer", requestOptions).then(
    (response) => {
      console.log("response add employer api", response);
      response.json();
    }
  );
  yield put({ type: "ADD_EMP_FILE_NOTE_SUCCESS", json: json });
}

// edit employer file notes

function* editEmpFileNotes() {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/filenote",
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "UPD_EMP_FILE_NOTE_SUCCESS", json: json });
}

// delete employer file notes

function* deleteEmpFileNotes() {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/filenote",
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "DLT_EMP_FILE_NOTE_SUCCESS", json: json });
}

// get employer contact

function* getEmpContacts(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/contact" + id,
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "GET_EMP_FILE_NOTE_SUCCESS", json: json });
}

// get all employer contacts

function* getAllEmpContact(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/contact/All" + id,
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "GET_ALL_EMP_CONTACT_SUCCESS", json: json });
}

// add employer contact

function* addEmpContact(data, token) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: bearer,
    },
    body: data.data,
  };
  const json = yield fetch(
    baseUrl + "v1/employer/contact",
    requestOptions
  ).then((response) => {
    console.log("response add employer api", response);
    response.json();
  });
  yield put({ type: "ADD_EMP_CONTACT_SUCCESS", json: json });
}

// edit employer contact

function* editEmpContact(data) {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    body: data,
  };
  const json = yield fetch(
    baseUrl + "v1/employer/contact",
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "UPD_EMP_CONTACT_SUCCESS", json: json });
}

// delete employer contact

function* deleteEmpContact() {
  console.log("on call sagas clint cases");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/employer/contact",
    requestOptions
  ).then((response) => {
    console.log("response client cases api", response);
    response.json();
  });
  yield put({ type: "DLT_EMP_CONTACT_SUCCESS", json: json });
}

// get user personal info

function* getSusPiInfo() {
  getToken();
  var userId = localStorage.getItem("userId");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(baseUrl + "v1/users/" + userId, requestOptions).then(
    (response) => {
      console.log("response ", response);
      if (response.ok) {
        localStorage.setItem("userRecordFound", true);
        return response.json();
      } else if (response.status == 404) {
        localStorage.setItem("userRecordFound", false);
      }
    }
  );
  if (json) {
    yield put(AccountSettingsActions.getSusPiInfoSuccess(json));
    yield put(UserContactActions.getUserContactRequest());
  }
}

// add user personal info

function* addSusPiInfo(data) {
  getToken();
  let formData = data.data;
  var userFound = localStorage.getItem("userRecordFound");
  const requestOptions = {
    method: userFound ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    body: JSON.stringify(formData),
  };
  const json = yield fetch(baseUrl + "v1/users", requestOptions).then(
    (response) => {
      if (response.ok) {
        console.log("response,", response);
        return true;
      }
    }
  );
  if (json) {
    yield put(
      AccountSettingsActions.addSusPiSuccess("User data saved successful")
    );
  }
}

// add user personal info ********* Not using right now

function* updSusPiInfo() {
  getToken();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(baseUrl + "v1/users", requestOptions).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    }
  );
  if (json) {
    yield put(AccountSettingsActions.addSusPiSuccess(json));
  }
}

// get user personal info

function* getSusUserContact() {
  getToken();
  var userId = localStorage.getItem("userId");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/user/contact/" + userId,
    requestOptions
  ).then((response) => {
    console.log("response ", response);
    if (response.ok) {
      return response.json();
    }
  });
  yield put(UserAddressActions.getUserAddressRequest());
  if (json.length > 0) {
    localStorage.setItem("userContactFound", true);
    yield put(UserContactActions.getUserContactSuccess(json));
  } else {
    localStorage.setItem("userContactFound", false);
  }
}

// add user contact

function* addUserContact(data) {
  getToken();
  let formData = data.data;
  var contactFound = localStorage.getItem("userContactFound");
  const requestOptions = {
    method: contactFound ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    body: JSON.stringify(formData),
  };
  const json = yield fetch(baseUrl + "v1/user/contact", requestOptions).then(
    (response) => {
      if (response.ok) {
        console.log("response,", response);
        return true;
      }
    }
  );
  if (json) {
    yield put(
      UserContactActions.addUserContactSuccess(
        "User contact saved successfully"
      )
    );
  }
}

// get user address

function* getSusUserAddress() {
  getToken();
  var userId = localStorage.getItem("userId");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(baseUrl + "v1/user/address", requestOptions).then(
    (response) => {
      console.log("response ", response);
      if (response.ok) {
        return response.json();
      } else if (response.status == 404) {
      }
    }
  );
  if (json.length > 0) {
    localStorage.setItem("userAddressFound", true);
    yield put(UserAddressActions.getUserAddressSuccess(json));
  } else {
    localStorage.setItem("userAddressFound", false);
  }
}

// add user address

function* addUserAddress(data) {
  getToken();
  let formData = data.data;
  var contactFound = localStorage.getItem("userContactFound");
  const requestOptions = {
    method: contactFound ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    body: JSON.stringify(formData),
  };
  const json = yield fetch(baseUrl + "v1/user/contact", requestOptions).then(
    (response) => {
      if (response.ok) {
        console.log("response,", response);
        return true;
      }
    }
  );
  if (json) {
    yield put(
      UserAddressActions.addUserAddressSuccess(
        "User address saved successfully"
      )
    );
  }
}

// get team member

function* getSusTeamMember() {
  getToken();
  var userId = localStorage.getItem("userId");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/user/permission/" + userId,
    requestOptions
  ).then((response) => {
    console.log("response ", response);
    if (response.ok) {
      return response.json();
    }
  });
  if (json) {
    yield put(TeamMemberActions.getTeamMemberSuccess(json));
  }
}

// Add team member

function* addSusTeamMember(data) {
  getToken();
  var userId = localStorage.getItem("userId");
  let userData = JSON.stringify(data.data);
  const requestOptions = {
    method: "POST",
    headers: {
      Origin: "http://localhost:3000",
      Authorization: bearer,
    },
    body: userData,
  };

  const json = yield fetch(
    "https://ezm-ultra-is-sts.azurewebsites.net/v1/user",
    requestOptions
  ).then((response) => {
    console.log("response ", response);
    if (response.ok) {
      return response.json();
    }
  });

  // const json = yield fetch(
  //   baseUrl + "v1/user/permission/" + userId,
  //   requestOptions
  // ).then(response => {
  //   console.log('response ', response)
  //   if(response.ok){
  //     return response.json();
  //   }
  // });
  console.log("show json ", json);
  if (json) {
    yield put(TeamMemberActions.getTeamMemberSuccess(json));
  }
}

// get user company

function* getSusUserCompany() {
  getToken();
  var userId = localStorage.getItem("userId");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/user/company/" + userId,
    requestOptions
  ).then((response) => {
    console.log("response ", response);
    if (response.ok) {
      return response.json();
    }
  });
  if (json) {
    yield put(UserCompanyActions.getUserCompanySuccess(json));
  }
}

// add user address

function* addUserCompany(data) {
  getToken();
  let formData = data.data;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    body: JSON.stringify(formData),
  };
  const json = yield fetch(baseUrl + "v1/user/contact", requestOptions).then(
    (response) => {
      if (response.ok) {
        console.log("response,", response);
        return true;
      }
    }
  );
  if (json) {
    yield put(
      UserCompanyActions.addUserCompanySuccess(
        "User company saved successfully"
      )
    );
  }
}

// get branch settings

function* getSusBranch() {
  getToken();
  var userId = localStorage.getItem("userId");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  };
  const json = yield fetch(
    baseUrl + "v1/branch/" + userId,
    requestOptions
  ).then((response) => {
    console.log("response ", response);
    if (response.ok) {
      return response.json();
    }
  });
  if (json) {
    yield put(UserCompanyActions.getUserCompanySuccess(json));
  }
}

function* actionWatcher() {
  yield takeLatest("LOGIN_REQUEST", login);

  yield takeLatest("GET_CLIENT_PROFILE_REQUEST", getClientProfile);
  yield takeLatest("UPD_CLIENT_INFO_REQUEST", updClientProfile);
  yield takeLatest("GET_VISA_INFO_REQUEST", getVisaInfo);
  yield takeLatest("ADD_VISA_INFO_REQUEST", addVisaInfo);
  yield takeLatest("GET_OCM_REQUEST", getAllCases);

  yield takeLatest("GET_EMPLOYER_REQUEST", getEmployer);
  yield takeLatest("GET_ALL_EMPLOYER_REQUEST", getAllEmployer);
  yield takeLatest("ADD_EMPLOYER_REQUEST", addEmployer);
  yield takeLatest("UPD_EMPLOYER_REQUEST", editEmployer);
  yield takeLatest("DLT_EMPLOYER_REQUEST", deleteEmployer);

  yield takeLatest("GET_EMP_FILE_NOTE_REQUEST", getEmpFileNotes);
  yield takeLatest("GET_ALL_EMP_FILE_NOTE_REQUEST", getAllEmpFileNotes);
  yield takeLatest("ADD_EMP_FILE_NOTE_REQUEST", addEmpFileNotes);
  yield takeLatest("UPD_EMP_FILE_NOTE_REQUEST", editEmpFileNotes);
  yield takeLatest("DLT_EMP_FILE_NOTE_REQUEST", deleteEmpFileNotes);

  yield takeLatest("GET_EMP_CONTACT_REQUEST", getEmpContacts);
  yield takeLatest("GET_ALL_EMP_CONTACT_REQUEST", getAllEmpContact);
  yield takeLatest("ADD_EMP_CONTACT_REQUEST", addEmpContact);
  yield takeLatest("UPD_EMP_CONTACT_REQUEST", editEmpContact);
  yield takeLatest("DLT_EMP_CONTACT_REQUEST", deleteEmpContact);

  yield takeLatest("GET_SUS_PI_INFO_REQUEST", getSusPiInfo);
  yield takeLatest("ADD_SUS_PI_REQUEST", addSusPiInfo);

  yield takeLatest("GET_USER_CONTACT_REQUEST", getSusUserContact);
  yield takeLatest("ADD_USER_CONTACT_REQUEST", addUserContact);

  yield takeLatest("GET_USER_ADDRESS_REQUEST", getSusUserAddress);
  yield takeLatest("ADD_USER_ADDRESS_REQUEST", addUserAddress);

  yield takeLatest("GET_USER_COMPANY_REQUEST", getSusUserCompany);
  yield takeLatest("ADD_USER_COMPANY_REQUEST", addUserCompany);

  yield takeLatest("GET_TEAM_MEMBER_REQUEST", getSusTeamMember);
  yield takeLatest("ADD_TEAM_MEMBER_REQUEST", addSusTeamMember);

  yield takeLatest("GET_BRANCH_REQUEST", getSusBranch);
}
export default function* rootSaga() {
  yield all([actionWatcher()]);
}
