import React from "react";
import "./ReportsStyles.css";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { DatePicker, Select, Form, Button, Spin } from "antd";

import TimeTrackingReportChild from "../Components/Reports/TimeTrackingReport";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const headOption = [
  { tabName: "Reports", linkName: "/reports" },
  { tabName: "Visa Reports", linkName: "/visa-report" },
  // // { tabName: "Case Management", linkName: "/" },
  // { tabName: "Potential Clients", linkName: "/potential-clients-report" },
  { tabName: "Time Tracking", linkName: "/time-tracking-report" },
  // { tabName: "Employer Report", linkName: "/employer-reports" },
];

const { Option } = Select;

class TimeTrackingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 20,
      visaTypeId: 0,
      serviceTypeId: 0,
      visaStatusName: "",
      dateTo: "",
      dateFrom: "",
      loading: false,
      userId: "",
    };
    this.props.onGetVisaType();
    this.props.onGetServiceType();
    this.props.onGetVisaStatus();
    this.props.onGetTeamMember();
  }

  onFinish = (values) => {
    this.setState({
      visaTypeId: values.visaTypeId || 0,
      serviceTypeId: values.serviceTypeId || 0,
      visaStatusName: this.state.visaStatusName || "",
      pageNumber: values.pageNumber || this.state.pageNumber,
      pageSize: values.pageSize || this.state.pageSize,
      dateTo: values.dateTo || this.state.dateTo,
      dateFrom: values.dateFrom || this.state.dateFrom,
      userId: values.userId || this.state.userId,
    });

    let data = {
      userId: values.userId || "00000000-0000-0000-0000-000000000000",
      dateFrom: values.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: values.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: values.pageSize || this.state.pageSize,
      pageNumber: values.pageNumber || this.state.pageNumber,
      visaTypeId: values.visaTypeId || 0,
      serviceTypeId: values.serviceTypeId || 0,
      visaStatusName: this.state.visaStatusName || "",
    };
    this.setState({ loading: true });
    this.props
      .onGetTimeTrackingReport(data)
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  render() {
    const { selectedOption, loading } = this.state;
    const { timeTrackingReportRes } = this.props;
    return (
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs
                data={headOption}
                activeTab="Time Tracking"
              />

              <div className="report-container">
                <div>
                  <div>
                    <div className="pciq-top-div">
                      <span
                        className="pc-top-div-text"
                        style={{ color: "#0A3C5D" }}
                      >
                        TIME TRACKING FILTERS
                      </span>
                    </div>
                    <Form onFinish={this.onFinish}>
                      <div className="ca-gray-cont" style={{ border: 0 }}>
                        <div style={{ display: "flex", marginLeft: 20 }}>
                          <div style={{ width: "48%" }}>
                            <Form.Item name="dateFrom">
                              <DatePicker placeholder="Date From" />
                            </Form.Item>
                          </div>
                          <div
                            style={{
                              marginLeft: 20,
                              width: "48%",
                            }}
                          >
                            <Form.Item name="dateTo">
                              <DatePicker placeholder="Date To" />
                            </Form.Item>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginLeft: 20,
                            marginTop: 10,
                          }}
                        >
                          <div
                            className="pc-select-width"
                            style={{ marginLeft: 0, width: "48%" }}
                          >
                            <Form.Item name="visaTypeId">
                              <Select
                                placeholder="Visa Type"
                                onChange={() => {
                                  this.setState({ pageNumber: 1 });
                                }}
                              >
                                <Option value="">Select Visa Type</Option>
                                {this.props.visaTypeData &&
                                  this.props.visaTypeData.items.map(
                                    (visaType, ind) => (
                                      <Option value={visaType.id}>
                                        {visaType.visaTypeName}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          </div>

                          <div
                            className="pc-select-width"
                            style={{ marginLeft: 20, width: "48%" }}
                          >
                            <Form.Item name="visaStatusId">
                              <Select
                                placeholder="Visa Status"
                                value={selectedOption}
                                onChange={(val) => {
                                  let statusObj =
                                    this.props.visaStatusData &&
                                    this.props.visaStatusData.items.find(
                                      (obj) => obj.id == val
                                    );
                                  this.setState({
                                    visaStatusName: statusObj
                                      ? statusObj.name
                                      : "",
                                    pageNumber: 1,
                                  });
                                }}
                              >
                                <Option value="">Select Visa Status</Option>
                                {this.props.visaStatusData &&
                                  this.props.visaStatusData.items.map(
                                    (status, ind) => (
                                      <Option value={status.id}>
                                        {status.name}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginLeft: 20,
                            marginTop: 10,
                          }}
                        >
                          <div
                            className="pc-select-width"
                            style={{ marginLeft: 0, width: "48%" }}
                          >
                            <Form.Item name="serviceTypeId">
                              <Select
                                placeholder="Service Type"
                                onChange={() => {
                                  this.setState({ pageNumber: 1 });
                                }}
                              >
                                <Option value="">Select Service Type</Option>
                                {this.props.serviceTypeRes &&
                                  this.props.serviceTypeRes.items &&
                                  this.props.serviceTypeRes.items.length > 0 &&
                                  this.props.serviceTypeRes.items.map(
                                    (item, ind) => (
                                      <Option value={item.id}>
                                        {item.serviceName}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className="pc-select-width"
                            style={{ marginLeft: 20, width: "48%" }}
                          >
                            <Form.Item name="userId">
                              <Select
                                placeholder="Select User"
                                onChange={() => {
                                  this.setState({ pageNumber: 1 });
                                }}
                              >
                                <Option value="">Select User</Option>
                                {this.props.teamMembers &&
                                  this.props.teamMembers.users &&
                                  this.props.teamMembers.users.length > 0 &&
                                  this.props.teamMembers.users.map(
                                    (item, ind) => (
                                      <Option value={item.id}>
                                        {item.fullName}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginRight: 20,
                            justifyContent: "space-between",
                            marginLeft: 20,
                            marginTop: 10,
                          }}
                        >
                          <div />

                          <div style={{ display: "flex" }}>
                            <div
                              className="pc-select-width"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Form.Item>
                                <Button
                                  className="button-blue"
                                  htmlType="submit"
                                >
                                  Show
                                </Button>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>

                    {/* <div
                    className="rep-print-row-cont"
                    style={{ marginRight: 20, marginTop: 20 }}
                  >
                    <div
                      className="pciq-top-div"
                      style={{ marginBottom: 5, marginTop: 0 }}
                    >
                      <span
                        className="pc-top-div-text"
                        style={{ color: "#0A3C5D", fontSize: 12 }}
                      >
                        CASE DETAIL
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className="pc-btn-cont"
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          borderRadius: 3,
                          marginLeft: 15,
                        }}
                      >
                        <span className="pc-btn-text">EXPORT</span>
                      </div>
                    </div>
                  </div> */}

                    {/* <table
                    className="ca-invoice-table-cont"
                    style={{ borderSpacing: 1, border: 0, marginTop: 10 }}
                  >
                    <tbody>
                      <tr style={{ backgroundColor: "#F8F9FB" }}>
                        <th className="ca-table-heading">Sn</th>
                        <th className="ca-table-heading">Client</th>
                        <th className="ca-table-heading">Visa Type</th>
                        <th className="ca-table-heading">Service Type</th>
                        <th className="ca-table-heading">User</th>
                        <th className="ca-table-heading">Time Spent(min)</th>
                        <th className="ca-table-heading">Description</th>
                        <th className="ca-table-heading">Work Type</th>
                        <th className="ca-table-heading">Visa Status</th>
                      </tr>
                      <tr style={{ backgroundColor: "#FFFFFF" }}>
                        <td className="report-table-content-text">1</td>
                        <td className="report-table-content-text">asdads</td>
                        <td className="report-table-content-text">Work Visa</td>
                        <td className="report-table-content-text">asdasf</td>
                        <td className="report-table-content-text">asdfss</td>
                        <td className="report-table-content-text">
                          0 hour(s) and 0 minute(s)
                        </td>
                        <td className="report-table-content-text">asdf</td>
                        <td className="report-table-content-text">ewfd</td>
                        <td className="report-table-content-text">Active</td>
                      </tr>
                    </tbody>
                  </table> */}

                    {timeTrackingReportRes &&
                      timeTrackingReportRes.items.length > 0 && (
                        <TimeTrackingReportChild
                          fileNotesReportRes={
                            timeTrackingReportRes && timeTrackingReportRes.items
                          }
                          displayText={"Visa Report"}
                          reportsCount={
                            timeTrackingReportRes && timeTrackingReportRes.count
                          }
                          totalTime={
                            timeTrackingReportRes &&
                            timeTrackingReportRes.totalTime
                          }
                          onTimeTrackingReport={this.onFinish}
                          requestData={this.state}
                        />
                      )}

                    {/* <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div />
                    <div style={{ display: "flex" }}>
                      <div
                        className="pciq-top-div"
                        style={{ marginBottom: 5, marginTop: 0 }}
                      >
                        <span
                          className="pc-top-div-text"
                          style={{ color: "#0A3C5D", fontSize: 12 }}
                        >
                          Total Time Spent:{" "}
                        </span>
                      </div>

                      <div
                        className="pciq-top-div"
                        style={{ marginBottom: 5, marginTop: 0 }}
                      >
                        <span
                          className="pc-top-div-text"
                          style={{ color: "#0A3C5D", fontSize: 12 }}
                        >
                          0 hour(s) and 0 minute(s)
                        </span>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default TimeTrackingReport;
