import React from "react";
import Select from "react-select";
import { Spin, Button, message } from "antd";
import { saveAs } from "file-saver";

import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";
import profile from "../images/profile-pic.png";
import settings from "../svgs/settings.svg";
import exportImg from "../svgs/export.svg";
import search from "../svgs/search.svg";
import mehronCross from "../svgs/mehron-cross.svg";
import grayCross from "../svgs/gray-cross.svg";
import personFill from "../svgs/person-fill.svg";
import downArrow from "../svgs/down-arrow.svg";
import rightArrow from "../svgs/right-arrow.svg";
import downArrowGray from "../svgs/down-arrow-gray.svg";
import arrowBlue from "../svgs/arrow-blue.svg";

import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import BranchCard from "../Components/SuperUserSetting/BranchCard";

import ProgressBar from "../Components/Shared/Progressbar";

const options = [{ value: "dollar", label: "Dollar" }];

var userOptions = [];

const headOption = [
  {
    tabName: "Organization Settings",
    linkName: "/super-user-setting?activeTab=company-information",
  },
  // { tabName: "ADD TEAM MEMBER", linkName: "/team-member" },
  { tabName: "Branch Settings", linkName: "/branch-settings" },
];

const headManagerOption = [
  {
    tabName: "Branch Settings",
    linkName: "/branch-settings",
  },
];

var findIndex = null;

class BranchSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeInfoTab: "personalInfo",
      selectedOption: null,
      activeTopTab: "accountSettings",
      checked: false,
      show: false,
      visible: false,
      exportLoading: false,
      branchId: "",

      name: "",
      email: "",
      country: "",
      city: "",
      address: "",
      phone: "",
      bnkAccTitle: "",
      bnkAccNumber: "",
      gstNumber: "",
      gstPercent: "",
      DefaultCurrency: "",
      managerId: null,
      saveLoad: false,
      loading: true,
    };

    this.props
      .onGetUserBranch()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        // message.error(this.props.branchError);
      });
    this.props.onGetTeamMember();
    // this.props.onGetManager();
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.updBranchSuccess !== this.props.updBranchSuccess) {
      this.props.onGetBranch();
    }
    if (PrevProps.teamMembers !== this.props.teamMembers) {
      if (
        this.props.teamMembers.users &&
        this.props.teamMembers.users.length > 0
      )
        userOptions = [];
      for (var i = 0; i < this.props.teamMembers.users.length; i++) {
        userOptions.push({
          value: this.props.teamMembers.users[i].id,
          label: this.props.teamMembers.users[i].fullName,
        });
        console.log("========== ", userOptions);
      }
    }
    if (
      PrevProps.assignBranchSuccess !== this.props.assignBranchSuccess ||
      PrevProps.unAssignBranchSuccess !== this.props.unAssignBranchSuccess
    ) {
      this.props.onGetBranch();
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeTab = (value) => {
    this.setState({ activeInfoTab: value });
  };

  onChangeTopTab = (value) => {
    this.setState({ activeTopTab: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  handleManagerChange = (managerId) => {
    this.setState({ managerId }, () => {
      console.log(`Option selected:`, this.state.managerId);
      findIndex = this.props.managerData.users.filter(
        (obj) => obj.id === this.state.managerId.value
      );
    });
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  assignUser = (data) => {
    this.props.onAssignBranch(data);
  };

  unassignUser = (data) => {
    this.props.onUnassignBranch(data);
  };

  updateCompanyUserData = (userData, isManager, branchUser) => {
    this.setState({ imageUploadSuccess: null });
    let userId = localStorage.getItem("userId");

    this.props.onUpdateManager(userData).then((res) => {
      let data = {
        branchId: branchUser.branchId,
        status: 1,
        userId: branchUser.userId,
        branchDesignation: branchUser.user.title,
        isManager: isManager,
      };
      if (res && res.payload === "This email is already taken") {
        //donothing
      } else {
        message.success("Updated!");
      }

      this.props.onUpdAssignBranch(data);
      this.props.onGetTeamMember();
    });
  };

  onSubmit = () => {
    var userId = localStorage.getItem("userId");
    var companyId = localStorage.getItem("companyId");
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(this.state.email)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!this.state.managerId) {
      message.warning("Please select manager first!");
    } else {
      this.setState({
        saveLoad: true,
      });
      let data = {
        name: this.state.name,
        description: "",
        address: this.state.address,
        addressLine1: "",
        addressLine2: "",
        city: this.state.city,
        country: this.state.country,
        zipcode: "",
        ownerId: 0,
        phone1: this.state.phone,
        phone2: "",
        mobile: "",
        fax: "",
        email: this.state.email,
        logo: "",
        status: "",
        currency: "dollar",
        allowedUsers: 0,
        allowSupervisor: true,
        contractSignedDate: "1900-01-01T00:00:00+00",
        contractExpiryDate: "1900-01-01T00:00:00+00",
        contractUrl: "",
        storageLimit: 0,
        notes: "",
        managerId: this.state.managerId ? this.state.managerId.value : null,
        bankAccountTitle: this.state.bnkAccTitle,
        bankAccountNumber: this.state.bnkAccNumber,
        gstNumber: this.state.gstNumber,
        gstPercent: this.state.gstPercent,
      };

      this.props
        .onAddBranch(data)
        .then((res) => {
          message.success("Branch added successfully!");
          let data = {
            branchId: res.payload.id,
            status: 1,
            userId: this.state.managerId.value,
            branchDesignation: findIndex[0].title,
            isManager: true,
          };

          this.props.onAssignBranch(data);
          this.props.onGetBranch();
          this.closeModal();
          this.setState({
            name: "",
            email: "",
            country: "",
            city: "",
            address: "",
            phone: "",
            bnkAccTitle: "",
            bnkAccNumber: "",
            gstNumber: "",
            gstPercent: "",
            DefaultCurrency: "",
            managerId: null,
            selectedOption: null,
            saveLoad: false,
          });
        })
        .catch(() => {
          this.setState({ saveLoad: false });
          message.error("Failed to add branch. Please try again.");
        });
    }
  };

  updateBranch = (branchData) => {
    let data = {
      id: branchData.id,
      name: branchData.name || "",
      description: "",
      address: branchData.address,
      addressLine1: "",
      addressLine2: "",
      city: branchData.city || "",
      country: branchData.country || "",
      zipcode: "",
      ownerId: 0,
      phone1: branchData.phone1 || "",
      phone2: "",
      mobile: "",
      fax: "",
      email: branchData.email || "",
      logo: branchData.logo,
      status: "",
      currency: branchData.currency || "",
      allowedUsers: 0,
      allowSupervisor: true,
      contractSignedDate: "1900-01-01T00:00:00+00",
      contractExpiryDate: "1900-01-01T00:00:00+00",
      contractUrl: "",
      storageLimit: 0,
      notes: "",
      gstNumber: branchData.gstNumber,
      gstPercent: branchData.gstPercent,
      // managerId: branchData.managerId,
      bankAccountTitle: branchData.bankAccountTitle || "",
      bankAccountNumber: branchData.bankAccountNumber || "",
      defaultCountry: branchData.defaultCountry || 0,
    };

    this.props
      .onUpdBranch(data)
      .then((res) => {
        localStorage.setItem(
          "defaultCountryId",
          branchData.defaultCountry || 0
        );
        message.success("Branch updated successfully!");
      })
      .catch(() => {
        message.error("Failed to update branch. Please try again.");
      });
  };

  disableTeamMember = (disableUserData) => {
    this.props.onDisableUser(disableUserData).then(() => {
      if (disableUserData.isLock) {
        message.success("User disabled successfully!");
      } else {
        message.success("User enabled successfully!");
      }
      this.props.onGetTeamMember();
    });
  };

  uploadImage = (info, id) => {
    this.setState({ imageUpdateId: id });
    let formData = new FormData();
    formData.append("File", info);
    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        this.setState({ imageUrl: this.props.imageUploadSuccess });
      }
    });
  };

  exportData = () => {
    this.setState({ exportLoading: true });
    this.props
      .onExportData()
      .then(function(response) {
        this.setState({ exportLoading: false });
        var blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Ezymigrate.zip");
      })
      .catch((e) => {
        this.setState({ exportLoading: false });
        message.error("Unable to export data, please try again!");
      });
  };

  render() {
    const {
      selectedOption,
      managerId,
      name,
      email,
      city,
      country,
      address,
      phone,
      bnkAccTitle,
      bnkAccNumber,
      gstNumber,
      gstPercent,
      DefaultCurrency,
      saveLoad,
      exportLoading,
      loading,
    } = this.state;
    const {
      userBranchData,
      teamMembers,
      managerData,
      imageUploadSuccess,
      storageData,
      onUnassignBranch,
      onAssignBranch,
      onDisableUser,
      onGetTeamMember,
      onGetBranch,
      onUpdateUserPermission,
      onUpdateManager,
      onUploadAvatar,
      onChangeUsersPassword,
      onGetCountries,
      countriesData,
      getAllCurrencies,
      currencyListing,
    } = this.props;

    const userOwner = localStorage.getItem("userOwner");
    // console.log("show branch data", userBranchData, teamMembers, managerData);
    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs
                data={userOwner === "True" ? headOption : headManagerOption}
                activeTab={"Branch Settings"}
              />

              <div style={{ paddingBottom: 80 }}>
                <div className="sus-content-card-container">
                  <div className="sus-team-memb-setting">
                    {/* <div className="sus-cb-row">
                    <div>
                      <span className="sus-cb-text">CREATE BRANCH</span>
                    </div>
                    <div className="sus-cb-hr-line" />
                    <div onClick={this.openModal}>
                      <img src={Images.plusIcon} style={{ width: 20 }} />
                    </div>
                  </div> */}
                    {userBranchData &&
                      userBranchData.length > 0 &&
                      userBranchData.map((item, index) => {
                        let imageUrl = "";
                        // var findUser = item.branchUser.filter(
                        //   (obj) => obj.userId === this.state.imageUpdateId
                        // );
                        // if (findUser.length > 0) {
                        //   imageUrl = imageUploadSuccess;
                        // }

                        return (
                          <>
                            {(localStorage.getItem("userOwner") == "True" ||
                              item.isManager) && (
                              <BranchCard
                                branch={item}
                                users={teamMembers}
                                onAssignUser={onAssignBranch}
                                onUnassignUser={onUnassignBranch}
                                onDisable={onDisableUser}
                                onGetBranch={onGetBranch}
                                onUpdateUserPermission={onUpdateUserPermission}
                                onUpdateManager={onUpdateManager}
                                onGetTeamMember={onGetTeamMember}
                                onUploadAvatar={onUploadAvatar}
                                branchData={userBranchData}
                                onChangeUsersPassword={onChangeUsersPassword}
                                onUpdate={(userData, isManager, branchUser) =>
                                  this.updateCompanyUserData(
                                    userData,
                                    isManager,
                                    branchUser
                                  )
                                }
                                uploadImage={this.uploadImage}
                                imageUrl={imageUrl}
                                parentMethod={this.updateBranch}
                                onGetCountries={onGetCountries}
                                countriesData={countriesData}
                                imageUploadSuccess={imageUploadSuccess}
                                getAllCurrencies={getAllCurrencies}
                                currencyListing={currencyListing}
                              />
                            )}
                          </>
                        );
                      })}
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <div className="sus-bs-right-card-cont">
                      <div className="sus-card-cont">
                        <div className="sus-card-header">
                          <div>
                            <span className="sus-card-header-text">
                              STORAGE
                            </span>
                          </div>
                          <div>
                            <span className="sus-card-header-small-text">
                              HIDE
                            </span>
                          </div>
                        </div>
                        <div className="sus-bar-cont">
                          <ProgressBar
                            bgcolor="#05B8E6"
                            completed={storageData ? storageData.used : 0}
                          />
                        </div>
                        <div className="sus-card-content-cont">
                          <div style={{ width: 100 }}>
                            <span className="sus-card-content-text">Used:</span>
                          </div>
                          <div style={{ width: 100 }}>
                            <span className="sus-card-content-text">
                              {storageData ? storageData.used : 0} MB
                            </span>
                          </div>
                        </div>
                        <div
                          className="sus-card-content-cont"
                          style={{ paddingTop: 0 }}
                        >
                          <div style={{ width: 100 }}>
                            <span className="sus-card-content-text">
                              Remaining:
                            </span>
                          </div>
                          <div style={{ width: 100 }}>
                            <span className="sus-card-content-text">
                              {storageData ? storageData.available : 0} MB
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="sus-card-cont" style={{ marginTop: 40 }}>
                        <div className="sus-card-header">
                          <div>
                            <span className="sus-card-header-text">
                              DATA EXPORTS
                            </span>
                          </div>
                          <div>
                            <span className="sus-card-header-small-text">
                              HIDE
                            </span>
                          </div>
                        </div>
                        <div
                          className="sus-card-content-cont"
                          style={{ paddingTop: 25 }}
                        >
                          <span
                            className="sus-card-content-text"
                            style={{ color: "#6C6C6C" }}
                          >
                            EXPORT DATA
                          </span>
                        </div>
                        <div
                          className="sus-card-content-cont"
                          style={{ paddingTop: 0 }}
                        >
                          <span
                            className="sus-card-content-text"
                            style={{ color: "#41AAD2" }}
                          >
                            Export All Data to CSV
                          </span>
                        </div>

                        <div
                          style={{
                            width: "47%",
                            display: "flex",
                            marginLeft: 35,
                            marginTop: 5,
                          }}
                        >
                          <Button
                            className="sus-cp-btn"
                            loading={exportLoading}
                            onClick={this.exportData}
                          >
                            <span className="sus-cp-btn-text">EXPORT</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ======== Add Branch Popup ======== */}

                <Modal
                  visible={this.state.visible}
                  width="700"
                  height="600"
                  effect="fadeInUp"
                  onClickAway={() => this.closeModal()}
                >
                  <div style={{ padding: 40 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="sus-modal-label">
                        <span className="sus-modal-label-text">
                          ADD BRANCH INFORMATION
                        </span>
                      </div>
                      <div onClick={this.closeModal}>
                        <img src={Images.crossRed} style={{ width: 20 }} />
                      </div>
                    </div>
                    <div className="sus-modal-scroll">
                      <div style={{ display: "flex" }}>
                        <div
                          className="ca-gray-cont"
                          style={{
                            display: "flex",
                            width: "100%",
                            border: 0,
                            borderRadius: 5,
                            paddingRight: 20,
                          }}
                        >
                          <div style={{ width: "100%", marginLeft: 20 }}>
                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Branch Name*
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Branch Name"
                                  type="text"
                                  value={name}
                                  onChange={(e) =>
                                    this.myChangeHandler("name", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Branch Email*
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Branch Email"
                                  type="text"
                                  value={email}
                                  onChange={(e) =>
                                    this.myChangeHandler("email", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Country
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Country"
                                  type="text"
                                  value={country}
                                  onChange={(e) =>
                                    this.myChangeHandler("country", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                City
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="City"
                                  type="text"
                                  value={city}
                                  onChange={(e) =>
                                    this.myChangeHandler("city", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Address
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Address"
                                  type="text"
                                  value={address}
                                  onChange={(e) =>
                                    this.myChangeHandler("address", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Phone
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Phone"
                                  type="text"
                                  value={phone}
                                  onChange={(e) =>
                                    this.myChangeHandler("phone", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Bank Account Title
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Bank Account Title"
                                  type="text"
                                  value={bnkAccTitle}
                                  onChange={(e) =>
                                    this.myChangeHandler("bnkAccTitle", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Bank Account Number
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="Bank Account Number"
                                  type="text"
                                  value={bnkAccNumber}
                                  onChange={(e) =>
                                    this.myChangeHandler("bnkAccNumber", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                GST Number
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="GST Number"
                                  type="text"
                                  value={gstNumber}
                                  onChange={(e) =>
                                    this.myChangeHandler("gstNumber", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                GST Percentage
                              </p>
                              <div
                                className="profile-input-border"
                                style={{ width: "47%" }}
                              >
                                <input
                                  className="profile-input"
                                  placeholder="GST Percentage"
                                  type="text"
                                  value={gstPercent}
                                  onChange={(e) =>
                                    this.myChangeHandler("gstPercent", e)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Default Currency
                              </p>
                              <div
                                class="select-options"
                                style={{ width: "47%" }}
                              >
                                <Select
                                  value={selectedOption}
                                  onChange={this.handleChange}
                                  options={options}
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="medical-label"
                                style={{ fontSize: 11 }}
                              >
                                Add Branch Manager*
                              </p>
                              <div
                                class="select-options"
                                style={{ width: "47%" }}
                              >
                                <Select
                                  value={managerId}
                                  onChange={this.handleManagerChange}
                                  options={userOptions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 30,
                        }}
                      >
                        <div></div>
                        <div style={{ display: "flex" }}>
                          <Button
                            onClick={this.onSubmit}
                            loading={saveLoad}
                            type="primary"
                            className="sus-add-btn"
                          >
                            SAVE
                          </Button>
                          <Button
                            onClick={this.closeModal}
                            type="primary"
                            className="sus-add-btn"
                            style={{ marginLeft: 10 }}
                          >
                            CLOSE
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default BranchSettings;
