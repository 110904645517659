// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import activityData from "../ClientActivity/ActivityData";

function UpdateEmployerForm(Props) {
  const [loading, setLoading] = useState(false);
  // const [employerJobOptions, setEmployerJobOptions] = useState([]);
  const [employerJobs, setEmployerJobs] = useState([]);
  const [jobStatuses, setJobStatuses] = useState(Props.jobStatuses);

  const [selectedOption, setSelectedOption] = useState("");

  const [form] = Form.useForm();
  var employerJobOptions = [];

  useEffect(() => {
    form.setFieldsValue(
      {
        name: Props.employer.name,
        business: Props.employer.business,
        address: Props.employer.address,
        contactPerson: Props.employer.contactPerson,
        email: Props.employer.email,
        mobile: Props.employer.mobile,
        contactNo: Props.employer.contactNo,
        website: Props.employer.website,
        jobSector: Props.employer.jobSector,
        jobStatus:
          Props.employer.jobStatus !== 0
            ? Props.employer.jobStatus.toString()
            : "",
        occupation: Props.employer.occupation,
        nzbn: Props.employer.nzbn,
      },
      [Props]
    );
  }, [Props.employer]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;

  if (Props.employer.jobs && Props.employer.jobs.items) {
    for (var i = 0; i < Props.employer.jobs.items.length > 0; i++) {
      employerJobOptions.push(
        <Option value={Props.employer.jobs.items[i].id}>
          {Props.employer.jobs.items[i].jobTitle}
        </Option>
      );
    }
  }

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const addQualification = (values) => {
    Props.addQualification(values);
  };

  const removeClientEmployer = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let data = {
      employerId: Props.employer.id,
      delete: true,
      clientId: clientprofileid,
    };
    Props.onRemoveClientEmployer(data)
      .then((res) => {
        message.success("Employer removed successfully!");
        Props.onGetClientEmployer(clientprofileid);
      })
      .catch((err) => {
        message.error("Failed to remove employer!");
      });
  };

  const onChange = (e) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const create = window.confirm("Are you sure you want to add this job?");
    // var checkJobHistory = Props.employerJobHistoryRes.find(obj => obj.jobId === parseInt(e))
    // if(checkJobHistory) {
    //   message.warning("Job already exist in job history!")
    // } else
    if (Props.employer.jobStatus === 0) {
      message.warning("Please udpate job status first!");
    } else {
      if (create) {
        var findJob = Props.employer.jobs.items.find(
          (obj) => obj.id === parseInt(e)
        );
        if (findJob) {
          let jobHistoryData = {
            clientId: clientprofileid,
            employerId: Props.employer.id,
            jobId: findJob.id,
            jobTitle: findJob.jobTitle || "",
            startDate: new Date(),
            endDate: new Date(),
            employerName: Props.employer.name || "",
            employerAddress: Props.employer.address || "",
            payDetails: "",
            phone: Props.employer.contactNo || "",
            managerName: "",
            managerEmail: "",
            managerMobile: "",
            positionOffered: findJob.position || "",
            jobOffer: "",
            companyRole: "",
            mentionHoursInWeek: "",
            anzscoCode: findJob.anzscoCode || "",
            skillLevel: findJob.skillLevel || "",
            businessNumber: "",
            isCurrent: false,
            tradingName: "",
            workStay: "",
            businessType: "",
            employementType: Props.employer.employerType || "",
            streetNumber: "",
            streetName: "",
            suburb: "",
            city: "",
            state: "",
            zip: "",
            country: 0,
            website: Props.employer.website || "",
            annualsalary: "",
            ratePerHour: "",
            activityBasedRemuneration: "",
            workExperince: "",
            domicile: "",
            accountDuties: "",
            arrivalDate: new Date(),
            contactPersonJobTitle: Props.employer.contactPerson || "",
            jobStatusId: Props.employer.jobStatus,
          };
          Props.onAddEmployerJobHistory(jobHistoryData)
            .then((res) => {
              message.success("Job history added successfully!");
              let userName = localStorage.getItem("userName");
              var profileData = JSON.parse(
                sessionStorage.getItem("profileData")
              );
              let myData = {
                clientName: profileData.fullName,
                logMessage:
                  "Job " + findJob.jobTitle + " assigned by " + userName,
                date: moment(new Date()).format("DD/MM/YYYY"),
                logType: "Client Information",
                invoiceId: "0",
              };
              activityData(myData);
              Props.onGetClientEmployer(clientprofileid);
            })
            .catch((err) => {
              message.error("Failed to add job history!");
            });
        }
      }
    }
  };

  const onFinish = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    var checkCurrentEmployer = Props.allEmployers.find(
      (obj) => obj.jobStatus === 1
    );
    if (
      values.jobStatus === "1" &&
      checkCurrentEmployer &&
      checkCurrentEmployer.id !== Props.employer.id
    ) {
      message.warning("Employer already exist with current job status!");
      Props.onGetClientEmployer(clientprofileid);
    } else {
      setLoading(true);
      let data = {
        id: Props.employer.id,
        name: values.name || "",
        email: values.email || "",
        contactNo: values.contactNo || "",
        business: values.business || "",
        city: Props.employer.city,
        contactPerson: Props.employer.contactPerson,
        mobile: values.mobile || "",
        website: values.website || "",
        jobSector: values.jobSector || "",
        nzbn: values.nzbn || "",
        occupation: values.occupation || "",
        numberOfEmployees: Props.employer.numberOfEmployees,
        yearsOfBusiness: Props.employer.yearsOfBusiness,
        isPotential: Props.employer.isPotential,
        employerType: Props.employer.employerType,
        address: values.address || "",
        agreementUrl: Props.employer.agreementUrl,
        xeroID: Props.employer.xeroID,

        jobStatus: parseInt(values.jobStatus),
        clientId: clientprofileid,
        accredationStartDate:
          Props.employer.accredationStartDate || "1900-01-01T00:00:00+00",
        accredationExpiryDate:
          Props.employer.accredationExpiryDate || "1900-01-01T00:00:00+00",
        isActive: Props.employer.isActive,
      };
      Props.onUpdClientEmployer(data)
        .then((res) => {
          setLoading(false);
          message.success("Employer updated successfully!");
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(sessionStorage.getItem("profileData"));
          let myData = {
            clientName: profileData.fullName,
            logMessage: "employer " + values.name + " updated by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
          Props.onGetClientEmployer(clientprofileid);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.payload.message);
        });
    }
  };

  return (
    <div
      className="emp-middle-cont"
      style={{ marginTop: 20, marginLeft: 0 }}
      key={Props.index}
    >
      <div className="form-cont" style={{ alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <p className="emp-ace-bold-text">
            {Props.employer.name.toUpperCase()}
          </p>
        </div>
        <CloseCircleFilled
          style={{ fontSize: 17, cursor: "pointer" }}
          onClick={removeClientEmployer}
        />
      </div>
      <div className="emp-ace-cont">
        <Form initialValues={Props.employer} form={form} onFinish={onFinish}>
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingLeft: 0,
              paddingRight: 0,
              display: "block",
              width: "100%",
            }}
          >
            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Name*
              </p>
              <div className="emp-field-div">
                <Form.Item
                  style={{ width: "100%" }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter employer name!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    className="profile-input emp-input-border"
                    placeholder=""
                  />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Legal Company Name
              </p>
              <div className="emp-input-border">
                <Form.Item name="business">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Address
              </p>
              <div className="emp-input-border">
                <Form.Item name="address">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Contact Person
              </p>
              <div className="emp-input-border">
                <Form.Item name="contactPerson">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Email
              </p>
              <div className="emp-field-div">
                <Form.Item
                  style={{ width: "100%" }}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please input email!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    className="profile-input emp-input-border"
                    placeholder=""
                  />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Mobile
              </p>
              <div className="emp-input-border">
                <Form.Item name="mobile">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Phone
              </p>
              <div className="emp-input-border">
                <Form.Item name="contactNo">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Website
              </p>
              <div className="emp-input-border">
                <Form.Item name="website">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Job Sector
              </p>
              <div style={{ paddingLeft: 1, width: "65%" }}>
                <Form.Item name="jobSector">
                  <Select showSearch>
                    <Option value="Administrative">Administrative</Option>
                    <Option value="AgricultureFarming">
                      Agriculture / Farming
                    </Option>
                    <Option value="Automotive">Automotive</Option>
                    <Option value="Construction">Construction</Option>
                    <Option value="Electrical">Electrical</Option>
                    <Option value="Engineer">Engineer</Option>
                    <Option value="Finance">Finance</Option>
                    <Option value="FMCG">FMCG</Option>
                    <Option value="Hospitality">Hospitality</Option>
                    <Option value="Human Resources">Human Resources</Option>
                    <Option value="Insurance">Insurance</Option>
                    <Option value="ICT">ICT</Option>
                    <Option value="Legal">Legal</Option>
                    <Option value="Marketing">Marketing</Option>
                    <Option value="Medical">Medical</Option>
                    <Option value="Real estate">Real estate</Option>
                    <Option value="Retail">Retail</Option>
                    <Option value="Sales">Sales</Option>
                    <Option value="Supply chain">Supply chain</Option>
                    <Option value="Teachers">Teachers</Option>
                    <Option value="Trades">Trades</Option>
                    <Option value="Not Employed">Not Employed</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p class="medical-label" style={{ fontSize: 11 }}>
                Job Status
              </p>
              <div style={{ paddingLeft: 1, width: "65%" }}>
                <Form.Item name="jobStatus">
                  <Select showSearch placeholder="Select Job Status">
                    {Props.jobStatuses}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                Occupation
              </p>
              <div className="emp-input-border">
                <Form.Item name="occupation">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div className="ce-field-row">
              <p className="medical-label" style={{ fontSize: 11 }}>
                NZBN
              </p>
              <div className="emp-input-border">
                <Form.Item name="nzbn">
                  <Input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div />
              <div
                style={{
                  marginTop: 30,
                }}
              >
                {/* <div className="emp-blue-btn">
                              <span style={{ color: "#FFFFFF", fontSize: 12 }}>
                                ADD
                              </span>
                            </div> */}
                <Button
                  loading={loading}
                  htmlType="submit"
                  name="update"
                  className="emp-blue-btn"
                  style={{ marginRight: 10, color: "#FFFFFF" }}
                >
                  UPDATE
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <div>
          <div className="emp-line"></div>
          <div className="ce-field-row">
            <p className="medical-label" style={{ fontSize: 11 }}>
              Jobs
            </p>
            <div style={{ paddingLeft: 1, width: "65%" }}>
              <Select showSearch onChange={onChange}>
                <Option value="">Please Select</Option>
                {employerJobOptions}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateEmployerForm;
