import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Spin,
  Select,
  Table,
  DatePicker,
  Modal,
  message,
  Tooltip,
  Menu,
  Dropdown,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Fragment } from "react";
import { Images } from "./../../Themes";
import { apiRefresh } from "../../services/api";
import { LinkQuestionnaire } from "../../Containers";
import moment from "moment";

let userId = localStorage.getItem("userId");

const dataSource = [
  {
    key: "1",
    name: "Mike",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: "2",
    name: "John",
    age: 42,
    address: "10 Downing Street",
  },
];

const QuestionnaireList = ({
  onGetAllQuestionnaire,
  questionnaireList,
  onCreateDynamicLink,
  dynamicLinkSuccess,
  onGetFilledQuestionnaire,
  filledQuestionnaireRes,
  addNewClient,
  onUpdMergeToClient,
  addPotentialClient,
  onUpdMergeToPClient,
  onGetDynamicLink,
  onGetCountries,
  onSubmitQuestionnaire,
  onGetQuestionnaire,
  dynamicLinkData,
  countriesData,
  questionnaireData,
  onAddEmployerManag,
  onGetVisaType,
  visaTypeData,
  clientTagRes,
  getClientTag,
}) => {
  // const [loading, setLoading] = useState(false);

  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [onDelete, setOnDelete] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionnaireOpt, setQuesitonnaireOpt] = useState([]);
  const [dynamicLinkState, setDynamicLinkState] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [questionnaireObjList, setQuestionnaireObjList] = useState([]);
  const [questionnaireId, setQuestionnaireId] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [filledId, setFilledId] = useState(0);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [moveClient, setMoveClient] = useState(false);
  const [movePotential, setMovePotential] = useState(false);
  const [moveEmployer, setMoveEmployer] = useState(false);
  const { Option } = Select;
  // const menu = null;

  useEffect(() => {
    onGetAllQuestionnaire().then((res) => {});
    onGetQuestionnaires();
    getClientTag().then((res) => {});
  }, []);

  const onGetQuestionnaires = () => {
    const filledQuestionnaireOpt = {
      url: `v1/filledquestionnaire/All/0`,
    };
    filledQuestionnaireOpt.types = [
      "GET_FILLED_ALL_QUESTIONNAIRE_SUCCESS",
      "GET_FILLED_ALL_QUESTIONNAIRE_FAILURE",
    ];
    setLoading(true);

    apiRefresh
      .get(filledQuestionnaireOpt)
      .then((res) => {
        setLoading(false);
        setShowTable(true);

        setQuestionnaireObjList(res);
      })
      .catch((err) => {
        setLoading(false);
        setShowTable(true);
      });
  };

  useEffect(() => {
    if (questionnaireList) {
      var list = [];

      for (var i = 0; i < questionnaireList.length; i++) {
        if (questionnaireList[i].showInPublic) {
          list.push(
            <Option value={questionnaireList[i].questionnaireId}>
              {questionnaireList[i].name}
            </Option>
          );
        }
      }
      setQuesitonnaireOpt(list);
    }
  }, [questionnaireList]);

  useEffect(() => {
    if (dynamicLinkSuccess) {
      setDynamicLinkState(dynamicLinkSuccess);
    } else {
      setDynamicLinkState(null);
    }
  }, [dynamicLinkSuccess]);

  // useEffect(() => {
  //   if (filledQuestionnaireRes) {
  //     setQuestionnaireObjList([filledQuestionnaireRes]);
  //   } else {
  //     setQuestionnaireObjList([]);
  //   }
  // }, [filledQuestionnaireRes]);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdDate",
      ellipsis: true,
      render: (text, record) => {
        return (
          <span style={{ cursor: "pointer" }}>
            {moment(text).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      title: "Questionnaire",
      dataIndex: "questionnaireName",
      key: "questionnaireName",
      ellipsis: true,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "contactNo",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      // ellipsis: true,
      // width: "100px",
      render: (text, record) => (
        <>
          <div style={{ display: "inline-block" }}>
            <div
              className="quesitonnaire-action-buttons"
              onClick={() => onGetDetail(record)}
            >
              <span>Detail</span>
            </div>
            <div
              className="quesitonnaire-action-buttons"
              onClick={() => {
                setSelectedQuestionnaire(record);
                setModal3Visible(true);
                setMovePotential(false);
                setMoveClient(true);
                setMoveEmployer(false);
              }}
            >
              <span>Move To Client</span>
            </div>
            <div
              className="quesitonnaire-action-buttons"
              onClick={() => {
                setSelectedQuestionnaire(record);
                setModal3Visible(true);
                setMovePotential(true);
                setMoveClient(false);
                setMoveEmployer(false);
              }}
            >
              <span>Move To Potential Client</span>
            </div>
            <div
              className="quesitonnaire-action-buttons"
              onClick={() => {
                setSelectedQuestionnaire(record);
                setModal3Visible(true);
                setMovePotential(false);
                setMoveClient(false);
                setMoveEmployer(true);
                // onMoveToEmployer(record)
              }}
            >
              <span>Move To Employer</span>
            </div>
            <div
              className="quesitonnaire-action-buttons"
              onClick={() => onRemoveFilledQuestionnaire(record.id)}
            >
              <span>Delete</span>
            </div>
          </div>
        </>
      ),
    },
  ];

  const onChangeQuestionnaireLink = (value) => {
    setShowDetail(false);
    setLoading(true);
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    setLoading(true);
    console.log(`selected ${value}`);
    let questionnaireData = {
      clientId: "00000000-0000-0000-0000-000000000000",
      branchId: selectedBranchId,
      questionnaireId: parseInt(value),
      isGrouped: false,
      groupId: 0,
      isPotential: true,
      userId: localStorage.getItem("userId"),
    };

    onCreateDynamicLink(questionnaireData)
      .then((res) => {
        setLoading(false);
        setShowTable(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const openQuestionnaire = () => {
    window.open("c_q?para=" + dynamicLinkSuccess, "_blank");
  };

  const onChangeQuestionnaire = (value) => {
    if (value == "") {
      value = 0;
    }
    setShowDetail(false);
    setLoading(true);
    const filledQuestionnaireOpt = {
      url: `v1/filledquestionnaire/All/${value}`,
    };
    filledQuestionnaireOpt.types = [
      "GET_FILLED_ALL_QUESTIONNAIRE_SUCCESS",
      "GET_FILLED_ALL_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .get(filledQuestionnaireOpt)
      .then((res) => {
        setLoading(false);
        setShowTable(true);

        setQuestionnaireObjList(res);
      })
      .catch((err) => {
        setLoading(false);
        setShowTable(true);
      });
  };

  const onMoveToClient = (questionnaire) => {
    setLoading(true);
    let clientData = questionnaire.clientName.split(" ");
    var firstName = "";
    var lastName = "";
    if (clientData && clientData.length > 0) {
      firstName = clientData[0];
    }
    if (clientData && clientData.length > 1) {
      lastName = clientData[1];
    }
    let client = {
      firstName: firstName,
      lastName: lastName,
    };
    addNewClient(client)
      .then((res) => {
        let tagData = {
          subjectId: res.payload,
          tagId: selectedTag,
          deletedDate: null,
        };

        const addTagOpt = {
          url: `v1/client/AssignTag`,
        };
        addTagOpt.types = ["ADD_CLIENT_TAG_SUCCESS", "ADD_CLIENT_TAG_FAILURE"];

        apiRefresh.post(addTagOpt, tagData);
        let mergeData = [
          {
            id: questionnaire.id,
            questionnaireId: questionnaire.questionnaireId,
            clientId: res.payload,
            isPotential: questionnaire.isPotential,
            isPublic: questionnaire.isPublic,
            clientName: questionnaire.clientName,
          },
        ];

        onSaveQuestionnairedocument(
          mergeData[0],
          questionnaire.questionnaireName
        );

        const filledQuestionnaireOpt = {
          url: `v1/filledquestionnaire`,
        };
        filledQuestionnaireOpt.types = [
          "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
          "UPD_FILLED_QUESTIONNAIRE_FAILURE",
        ];

        apiRefresh.put(filledQuestionnaireOpt, mergeData).then((resp) => {
          let branchId = localStorage.getItem("selectedBranchId");
          let data = {
            clientId: res.payload,
            branchId: branchId,
            questionnaireId: questionnaire.questionnaireId,
            excluded: [0],
          };
          onUpdMergeToClient(data)
            .then((resp) => {
              onGetQuestionnaires();
              setLoading(false);
              setModal3Visible(false);
            })
            .catch((err) => {
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSaveQuestionnairedocument = (payload, name) => {
    let data = {
      id: payload.id,
      questionnaireId: payload.questionnaireId,
      clientId: payload.clientId,
      name: name,
    };
    const saveDocumentOpt = {
      url: `v1/questionnaire/SaveInDocument`,
    };
    saveDocumentOpt.types = [
      "SAVE_QUESTIONNAIRE_DOCUMENT_SUCCESS",
      "SAVE_QUESTIONNAIRE_DOCUMENT_FAILURE",
    ];

    apiRefresh.post(saveDocumentOpt, data);
  };

  const onMoveToPotentialClient = (questionnaire) => {
    setLoading(true);
    let clientData = questionnaire.clientName.split(" ");
    var firstName = "";
    var lastName = "";
    if (clientData && clientData.length > 0) {
      firstName = clientData[0];
    }
    if (clientData && clientData.length > 1) {
      lastName = clientData[1];
    }
    let client = {
      firstName: firstName,
      lastName: lastName,
    };
    addPotentialClient(client)
      .then((res) => {
        if (selectedTag) {
          let tagData = {
            subjectId: res.payload,
            tagId: selectedTag,
            deletedDate: null,
          };

          const addTagOpt = {
            url: `v1/client/AssignTag`,
          };
          addTagOpt.types = [
            "ADD_CLIENT_TAG_SUCCESS",
            "ADD_CLIENT_TAG_FAILURE",
          ];

          apiRefresh.post(addTagOpt, tagData);
        }
        let mergeData = [
          {
            id: questionnaire.id,
            questionnaireId: questionnaire.questionnaireId,
            clientId: res.payload,
            isPotential: questionnaire.isPotential,
            isPublic: questionnaire.isPublic,
            clientName: questionnaire.clientName,
          },
        ];

        onSaveQuestionnairedocument(
          mergeData[0],
          questionnaire.questionnaireName
        );

        const filledQuestionnaireOpt = {
          url: `v1/filledquestionnaire`,
        };
        filledQuestionnaireOpt.types = [
          "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
          "UPD_FILLED_QUESTIONNAIRE_FAILURE",
        ];

        apiRefresh.put(filledQuestionnaireOpt, mergeData).then((resp) => {
          let branchId = localStorage.getItem("selectedBranchId");
          let data = {
            clientId: res.payload,
            branchId: branchId,
            questionnaireId: questionnaire.id,
            excluded: [0],
          };
          onUpdMergeToPClient(data)
            .then((resp) => {
              onGetQuestionnaires();
              setLoading(false);
              setModal3Visible(false);
            })
            .catch((err) => {
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onMoveToEmployer = (questionnaire) => {
    setLoading(true);
    let clientData = questionnaire.clientName.split(" ");
    var firstName = "";
    var lastName = "";
    if (clientData && clientData.length > 0) {
      firstName = clientData[0];
    }
    if (clientData && clientData.length > 1) {
      lastName = clientData[1];
    }
    let client = {
      name: questionnaire.clientName,
    };
    onAddEmployerManag(client)
      .then((res) => {
        let tagData = {
          subjectId: res.payload,
          tagId: selectedTag,
          deletedDate: null,
        };

        const addTagOpt = {
          url: `v1/client/AssignTag`,
        };
        addTagOpt.types = ["ADD_CLIENT_TAG_SUCCESS", "ADD_CLIENT_TAG_FAILURE"];

        apiRefresh.post(addTagOpt, tagData);

        let mergeData = [
          {
            id: questionnaire.id,
            questionnaireId: questionnaire.questionnaireId,
            clientId: res.payload,
            isPotential: questionnaire.isPotential,
            isEmployer: questionnaire.isEmployer || true,
            isPublic: questionnaire.isPublic,
            clientName: questionnaire.clientName,
          },
        ];

        onSaveQuestionnairedocument(
          mergeData[0],
          questionnaire.questionnaireName
        );

        const filledQuestionnaireOpt = {
          url: `v1/filledquestionnaire`,
        };
        filledQuestionnaireOpt.types = [
          "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
          "UPD_FILLED_QUESTIONNAIRE_FAILURE",
        ];

        apiRefresh.put(filledQuestionnaireOpt, mergeData).then((resp) => {
          let branchId = localStorage.getItem("selectedBranchId");
          let data = {
            clientId: res.payload,
            branchId: branchId,
            questionnaireId: questionnaire.id,
            excluded: [0],
          };
          onUpdMergeToPClient(data)
            .then((resp) => {
              onGetQuestionnaires();
              setLoading(false);
              setModal3Visible(false);
            })
            .catch((err) => {
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onRemoveFilledQuestionnaire = (id) => {
    setLoading(true);
    const filledQuestionnaireOpt = {
      url: `v1/filledquestionnaire`,
    };
    filledQuestionnaireOpt.types = [
      "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
      "UPD_FILLED_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .delete(filledQuestionnaireOpt, { id: id })
      .then((res) => {
        setLoading(false);
        onGetQuestionnaires();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onGetDetail = (data) => {
    setQuestionnaireId(data.questionnaireId);
    setFilledId(data.id);
    setShowTable(false);
    setShowDetail(true);
  };

  const handleChangeClientTags = (value) => {
    setSelectedTag(value);
  };

  return (
    <Fragment>
      <div className="p-emp-container" style={{ marginTop: "20px" }}>
        <div className="d-end space-between"></div>
        <div className="p-emp-table">
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Select
                className={"width-selects-filters remove-select-border"}
                placeholder="Questionnaire Link"
                virtual={false}
                size="middle"
                dropdownClassName="questionnaire-option-width"
                onChange={onChangeQuestionnaireLink}
              >
                <Option value={""}>{"Questionnaire List"}</Option>
                {questionnaireOpt}
              </Select>
            </Col>
            <Col style={{ marginLeft: 20 }}>
              <Select
                className={"width-selects-filters remove-select-border"}
                placeholder="Questionnaire"
                virtual={false}
                size="middle"
                dropdownClassName="questionnaire-option-width"
                onChange={onChangeQuestionnaire}
              >
                <Option value={""}>{"Questionnaire"}</Option>
                {questionnaireOpt}
                {/* {this.state &&
                  this.state.potentialClientDataStatuses &&
                  this.state.potentialClientDataStatuses.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.name}>{data.name}</Option>;
                  })} */}
              </Select>
            </Col>
          </Row>
          {!showTable && !showDetail ? (
            <Row style={{ marginTop: 20 }}>
              {dynamicLinkState && (
                <div>
                  <div className="pc-normal-text-cont">
                    <span
                      className="cv-normal-text"
                      style={{ color: "#797979" }}
                    >
                      Please send below link to client
                    </span>
                  </div>
                  <div className="pc-bottom-org-box-cont">
                    <div className="pciq-org-box" style={{ borderRadius: 5 }}>
                      <span className="pciq-org-box-text">
                        Questionnaire Link
                      </span>
                    </div>
                  </div>

                  <div className="pc-link-main-cont">
                    <div
                      className="pc-link-color-box"
                      onClick={openQuestionnaire}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="pc-link-text">
                        {window.location.origin +
                          "/c_q?para=" +
                          dynamicLinkState}
                      </span>
                    </div>
                    <div style={{ marginLeft: 20, cursor: "pointer" }}>
                      {/* <CopyOutlined
                              onClick={() => {
                                navigator.clipboard.writeText(data.host);
                                message.info("Copied!");
                              }}
                            /> */}
                      <Button
                        style={{
                          fontSize: 10,
                          fontWeight: "500",
                          padding: 5,
                          borderRadius: 5,
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            window.location.origin +
                              "/c_q?para=" +
                              dynamicLinkState
                          );
                          message.info("Copied!");
                        }}
                      >
                        Copy Link
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Row>
          ) : (
            showTable && (
              <Row style={{ marginTop: "20px" }}>
                <Col className={"school-table"}>
                  <Spin size="large" spinning={loading}>
                    <Table
                      dataSource={questionnaireObjList}
                      columns={columns}
                    />
                  </Spin>
                </Col>
              </Row>
            )
          )}
          {showDetail && (
            <LinkQuestionnaire
              questionnaireId={questionnaireId}
              onGetDynamicLink={onGetDynamicLink}
              onGetCountries={onGetCountries}
              onSubmitQuestionnaire={onSubmitQuestionnaire}
              onGetQuestionnaire={onGetQuestionnaire}
              onGetFilledQuestionnaire={onGetFilledQuestionnaire}
              dynamicLinkData={dynamicLinkData}
              countriesData={countriesData}
              questionnaireData={questionnaireData}
              filledQuestionnaireRes={filledQuestionnaireRes}
              notLink={true}
              isPotential={true}
              filledId={filledId}
              onGetVisaType={onGetVisaType}
              visaTypeData={visaTypeData}
              isWeb={true}
            />
          )}
        </div>
      </div>

      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal1Visible}
        // onOk={() => removeEmploye()}
        // onCancel={() => setModal1Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to delete this ?</h4>
      </Modal>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal2Visible}
        // onOk={() => moveEmployer()}
        // onCancel={() => setModal2Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to move this ?</h4>
      </Modal>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal3Visible}
        onOk={() => {
          if (moveClient) {
            onMoveToClient(selectedQuestionnaire);
          } else if (movePotential) {
            onMoveToPotentialClient(selectedQuestionnaire);
          } else {
            onMoveToEmployer(selectedQuestionnaire);
          }
        }}
        onCancel={() => setModal3Visible(false)}
        Header={false}
      >
        <div>
          <p>Client Tags</p>
          <Select
            showSearch
            optionFilterProp="children"
            showArrow
            placeholder="Select Client Tag"
            size="middle"
            // mode="multiple"
            virtual={false}
            // tagRender={this.tagRender}
            onChange={handleChangeClientTags}
          >
            {clientTagRes &&
              clientTagRes.items &&
              clientTagRes.items.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </div>
      </Modal>
    </Fragment>
  );
};

export default QuestionnaireList;
