// @flow
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import "./HeaderBarStyles.css";
import { useHistory } from "react-router";

import {
  Layout,
  Button,
  Select,
  Upload,
  Row,
  Col,
  Input,
  Image,
  Typography,
  Checkbox,
  AutoComplete,
  Drawer,
  Space,
  Spin,
  message,
  Radio,
  DatePicker,
} from "antd";
import navMenu from "../../images/nav-collaps.png";
import search from "../../svgs/search.svg";
import { Images } from "../../Themes";
import {
  Router,
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import { User } from "../../Containers";
import { bindActionCreators } from "redux";
import { closeAndOpenSidebar } from "./../../store/Actions";
import history from "../../services/history";
import { useDispatch } from "react-redux";
import {
  setSelectedBranchId,
  setClientProfileLoader,
} from "../../store/Actions";
import moment from "moment";
import { BellOutlined } from "@ant-design/icons";
import getUserBranchPermissions from "../getUserBranchPermissions";
import { apiRefresh } from "../../services/api";
const { Header } = Layout;
const { Search } = Input;
const Option = Select.Option;
const { Paragraph } = Typography;
let notSearch = true;
const languageOptions = [
  { value: "Eng", label: "Eng" },
  { value: "Arabic", label: "Arabic" },
  { value: "Swedish", label: "Swedish" },
];

const companyOptions = [
  { value: "Branch 1", label: "Branch 1" },
  { value: "Branch 2", label: "Branch 2" },
];

const getRandomInt = (max, min = 0) => {
  return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
};

var userName = "";
var isSupervisor = localStorage.getItem("supervisorData");
var searchResults = [];
var currentTab = -1;
function HeaderBar(Props) {
  const history = useHistory();
  const [selectedBranch, setSelectedBranch] = useState();
  const [storeUserName, setUsername] = useState(
    localStorage.getItem("userName")
  );
  // const [searchClient, setSearchClient] = useState("");
  const [isSearchPotential, setSearchPotential] = useState("client");
  const [options, setOptions] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [SearchDisable, setSearchDisable] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [onAutoFocus, setOnAutoFocus] = useState(false);
  const [userActivity, setUserActivity] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const [loadActivity, setLoadActivity] = useState(false);
  const [searchOnEnter, setSearchOnEnter] = useState(false);
  const [processingPerson, setProcessingPerson] = useState(
    localStorage.getItem("userId")
  );
  const [selectedValue, setSelectedValue] = useState("");
  const [isDob, setIsDob] = useState(false);
  const [dob, setDob] = useState(null);
  const [dobStr, setDobStr] = useState(null);

  let searchClient = "";
  const inp = useRef();

  const dispatch = useDispatch();
  const { Option } = Select;
  const branchOptions = [];

  const ref = useRef(null);

  useEffect(() => {
    if (branchOptions && branchOptions.length && !selectedBranch) {
      setSelectedBranch(localStorage.getItem("selectedBranchId"));
    }
  }, [branchOptions]);

  var findSelectedBranch =
    Props.branchData &&
    Props.branchData.find(
      (obj) => obj.branchId && obj.branchId == selectedBranch
    );

  if (findSelectedBranch) {
    localStorage.setItem("selectedBranchName", findSelectedBranch.branchName);
  }
  if (Props.branchData && Props.branchData.length > 0) {
    for (let i = 0; i < Props.branchData.length; i++) {
      branchOptions.push(
        <Option key={Props.branchData[i].branchId}>
          {Props.branchData[i].branchName}
        </Option>
      );
    }
  }

  var today = new Date();
  var date =
    today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate();

  if (storeUserName) {
    if (userName.length > 15) {
      var name = storeUserName.split(" ");
      userName = name[0];
    } else {
      userName = storeUserName;
    }
  }

  const handleChangeBranch = (selectedBranch) => {
    let userId = localStorage.getItem("userId");

    let data = {
      refreshToken: localStorage.getItem("refreshToken"),
      branchId: selectedBranch,
    };
    dispatch(setClientProfileLoader(true));
    const chngBranchToken = {
      url: `v1/user/identity/ChangeBranchInToken`,
    };
    chngBranchToken.types = [
      "CHANGE_BRANCH_TOKEN_SUCCESS",
      "CHANGE_BRANCH_TOKEN_FAILURE",
    ];
    apiRefresh
      .post(chngBranchToken, data)
      .then((resp) => {
        setSelectedBranch(selectedBranch);
        dispatch(setClientProfileLoader(false));
        // let userId = localStorage.getItem("userId");
        var findSelectedBranch = Props.branchData.find(
          (obj) => obj.branchId == selectedBranch
        );
        if (findSelectedBranch) {
          localStorage.setItem(
            "selectedBranchName",
            findSelectedBranch.branchName
          );
        }
        dispatch(setSelectedBranchId(selectedBranch));
        localStorage.setItem("selectedBranchId", selectedBranch);
        localStorage.setItem("token", resp.accessToken);
        localStorage.setItem("refreshToken", resp.refreshToken);
        setTimeout(() => {
          getUserBranchPermissions();
          // window.top.location = "/dashboardBI";
          message.success(resp.reponseMessage);
        }, 500);
      })
      .catch((err) => {
        message.error("Unauthorized!");
      });
  };

  const onChangeValue = (e) => {
    console.log(e.target.value);
    if (e.target.value.length > 2) {
      if (isSearchPotential) {
        setTimeout(() => {
          Props.onSearchPotentialClient(e.target.value);
        }, 1000);
      } else {
        setTimeout(() => {
          Props.onSearchClient(e.target.value);
        }, 1000);
      }
    }
  };

  const handleClick = (_) => {
    if (isSearchPotential == "potential") {
      Props.getPotentialClientInfo(_.id).then(() => {
        Props.getPotentialClientUpdateBol(false);
        history.push("/update-potential-client");
      });
    } else if (isSearchPotential == "employer") {
      history.push({
        pathname: "/employer-management",
        state: { userData: _ },
      });
    } else {
      localStorage.setItem("familyId", _.familyId);
      sessionStorage.setItem("familyId", _.familyId);
      if (_.memberType.toLowerCase() === "client") {
        localStorage.setItem("clientprofileid", JSON.stringify(_.id));
        sessionStorage.setItem("clientprofileid", _.id);
        localStorage.setItem("clientProfileIdMain", _.clientId);
        sessionStorage.setItem("clientProfileIdMain", _.clientId);
        localStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("familyId", _.familyId);
        console.log("history", history);

        if (history.location.pathname === "/profile") {
          Props.history.push({
            pathname: "/profile",
            state: { id: _.id },
          });
          window.location.reload();
        } else {
          Props.history.push({
            pathname: "/profile",
            state: { id: _.id },
          });
        }
      } else if (_.memberType.toLowerCase() === "spouse") {
        localStorage.setItem("clientProfileIdMain", _.clientId);
        sessionStorage.setItem("clientProfileIdMain", _.clientId);
        localStorage.setItem("clientprofileid", JSON.stringify(_.id));
        sessionStorage.setItem("clientprofileid", _.id);
        localStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("familyId", _.familyId);
        if (history.location.pathname === "/partner-profile") {
          Props.history.push({
            pathname: "/partner-profile",
            state: { id: _.clientId, familyId: _.familyId },
          });
          window.location.reload();
        } else {
          Props.history.push({
            pathname: "/partner-profile",
            state: { id: _.clientId, familyId: _.familyId },
          });
        }
      } else if (
        _.memberType.toLowerCase() === "child" ||
        _.memberType.toLowerCase() === "familymember"
      ) {
        localStorage.setItem("clientProfileIdMain", _.clientId);
        sessionStorage.setItem("clientProfileIdMain", _.clientId);
        localStorage.setItem("clientprofileid", JSON.stringify(_.id));
        sessionStorage.setItem("clientprofileid", _.id);
        localStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("memberType", "child");
        if (history.location.pathname === "/client-family-member") {
          Props.history.push({
            pathname: "/client-family-member",
            state: {
              id: _.clientId,
              familyId: _.familyId,
              clickBy: "search",
              memberId: _.id,
            },
          });
          window.location.reload();
        } else {
          Props.history.push({
            pathname: "/client-family-member",
            state: {
              id: _.clientId,
              familyId: _.familyId,
              clickBy: "search",
              memberId: _.id,
            },
          });
        }
      } else if (_.memberType.toLowerCase() === "partner") {
        localStorage.setItem("clientProfileIdMain", _.clientId);
        localStorage.setItem("clientprofileid", JSON.stringify(_.id));
        localStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("clientProfileIdMain", _.clientId);
        sessionStorage.setItem("clientprofileid", JSON.stringify(_.id));
        sessionStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("memberType", "spouse");
        if (history.location.pathname === "/partner-profile") {
          Props.history.push({
            pathname: "/partner-profile",
            state: { id: _.clientId, familyId: _.familyId },
          });
          window.location.reload();
        } else {
          Props.history.push({
            pathname: "/partner-profile",
            state: { id: _.clientId, familyId: _.familyId },
          });
        }
      } else if (_.memberType.toLowerCase() === "child") {
        localStorage.setItem("clientProfileIdMain", _.clientId);
        sessionStorage.setItem("clientProfileIdMain", _.clientId);
        localStorage.setItem("clientprofileid", JSON.stringify(_.id));
        sessionStorage.setItem("clientprofileid", _.id);
        localStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("familyId", _.familyId);
        sessionStorage.setItem("memberType", "child");
        if (history.location.pathname === "/client-family-member") {
          Props.history.push({
            pathname: "/client-family-member",
            state: { id: _.clientId, familyId: _.familyId },
          });
          window.location.reload();
        } else {
          Props.history.push({
            pathname: "/client-family-member",
            state: { id: _.clientId, familyId: _.familyId },
          });
        }
      }
    }
    setOpenDropdown(false);
  };

  const handleKeyDown = (event, _) => {
    const keyCode = event.keyCode;
    if (keyCode == 40 && searchResults.length - 1 > currentTab) {
      currentTab += 1;
    }
    if (keyCode == 40 && searchResults.length - 1 === currentTab) {
      currentTab = 0;
    }
    if (keyCode == 38 && currentTab >= 1) {
      currentTab -= 1;
    }
    if (keyCode == 38 && (currentTab === 0 || currentTab === -1)) {
      currentTab = searchResults.length - 1;
    }

    if (event.key === "Enter" && onAutoFocus) {
      // setSearchOnEnter(false);
      handleClick(searchResults[currentTab]);
    }
  };

  const searchResult = (searchedResult) =>
    searchedResult.map((_, idx) => {
      let category;
      if (isSearchPotential == "potential") {
        category = _.label;
      }
      if (isSearchPotential == "employer") {
        category = _.name;
      } else if (isSearchPotential == "client") {
        category = `${_.firstName} ${_.lastName}`;
      }
      return {
        value: `${category} - ${idx + 1}`,
        label: (
          <div
            className="search-client-card-cont"
            ref={ref}
            tabIndex={idx}
            onClick={() => handleClick(_)}
            // onKeyDown={(e) => {
            //   handleKeyDown(e);
            // }}
          >
            <div>
              <div class="image-search-result">
                {isSearchPotential ? (
                  <img
                    src={Images.dummyUserImage}
                    class="profile-img"
                    style={{ width: 60, height: 60 }}
                  />
                ) : (
                  <img
                    src={_.imageBlobUrl || Images.dummyUserImage}
                    class="profile-img"
                    style={{ width: 60, height: 60 }}
                  />
                )}
              </div>
            </div>
            {isSearchPotential != "client" ? (
              <div>
                <span>{`${category} - ${idx + 1}`}</span>
                {_.dob && (
                  <div style={{ display: "flex" }}>
                    <div>
                      <span class="date-text">DOB :</span>
                    </div>
                    <div>
                      <span class="date-text">&nbsp;{_.dob ? _.dob : ""}</span>
                    </div>
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  <div>
                    <span class="date-text">EZM ID :</span>
                  </div>
                  <div>
                    <span class="date-text">&nbsp;{_.ezmId}</span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <span class="date-text">Email :</span>
                  </div>
                  <div>
                    <span class="date-text">&nbsp;{_.email}</span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <span class="date-text">Mobile :</span>
                  </div>
                  <div>
                    <span class="date-text">
                      &nbsp;{_.mobile || _.contactNo}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <span>{`${category} - ${idx + 1}`}</span>
                <div style={{ display: "flex" }}>
                  {_.clientPermission && _.clientPermission.active ? (
                    <div>
                      <span class="ActiveSpan">Active</span>
                    </div>
                  ) : (
                    <div>
                      <span class="InactiveSpan">Inactive</span>
                    </div>
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <span class="date-text">EZM ID :</span>
                  </div>
                  <div>
                    <span class="date-text">&nbsp;{_.ezmId}</span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <span class="date-text">INZ ID :</span>
                  </div>
                  <div>
                    <span class="date-text">&nbsp;{_.clientNumber}</span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <span class="date-text">DOB :</span>
                  </div>
                  <div>
                    <span class="date-text">
                      &nbsp;{moment(_.dateOfBirth).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block" }}>
                    <span class="date-text">Email :</span>
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <span class="date-text">&nbsp;{_.email}</span>
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div>
                    <span class="date-text">Mobile :</span>
                  </div>
                  <div>
                    <span class="date-text">&nbsp;{_.mobile}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ),
      };
    });

  const onSearch = (value) => {
    console.log(value);
  };

  const onLogout = () => {
    Props.onUserLogout()
      .then((res) => {
        var token = localStorage.setItem("token", "");
        let notificationToken = localStorage.getItem("notificationToken");
        let subjectId = localStorage.getItem("userId");
        Props.onRemoveNotificationToken(subjectId, notificationToken).then(
          (res) => {
            localStorage.setItem("notificationToken", "");
            window.location.assign("/login");
          }
        );
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      });
  };

  const BackToAdminPortal = () => {
    let admintoken = localStorage.getItem("_admintoken");
    let userEmail = localStorage.getItem("_userEmail");
    let userName = localStorage.getItem("_userName");
    let userId = localStorage.getItem("_userId");
    let refreshToken = localStorage.getItem("_refreshToken");

    localStorage.setItem("admintoken", admintoken);
    localStorage.setItem("userEmail", userEmail);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userId", userId);

    localStorage.removeItem("_admintoken");
    localStorage.removeItem("_userEmail");
    localStorage.removeItem("_userName");
    localStorage.removeItem("_userId");
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userOwner");
    localStorage.removeItem("selectedBranchId");
    localStorage.removeItem("companyId");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    localStorage.setItem("admintoken", admintoken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("userEmail", userEmail);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userId", userId);
    window.location = "/companies";
  };

  const handleBar = () => {
    setIsModalVisible(!isModalVisible);
    Props.onCloseAndOpenSidebar(isModalVisible);
  };

  const onChange = (e) => {
    setSearchPotential(e);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const handleSearch = (value) => {
    searchClient = value.trimStart();
    if (value.length > 1 && notSearch) {
      notSearch = false;
      setTimeout(() => {
        if (isSearchPotential == "potential") {
          dispatch(setClientProfileLoader(true));
          setSearchDisable(true);
          searchResults = [];
          setClientList([]);
          setOptions(searchResult([]));
          Props.onSearchPotentialClient(searchClient)
            .then((res) => {
              notSearch = true;
              setSearchDisable(false);
              setOnAutoFocus(true);
              setOpenDropdown(true);
              dispatch(setClientProfileLoader(false));
              if (res.payload.count === 0) {
                // message.warning("Searched record not found");
              } else {
                searchResults = res.payload.potentialClients;
                setClientList(res.payload.potentialClients);
                setOptions(searchResult(res.payload.potentialClients));
              }
              // var el1 = document.querySelector(".el1");

              inp.current.focus();
            })
            .catch((err) => {
              notSearch = true;
              setSearchDisable(false);
              setOpenDropdown(true);
              searchResults = [];
              setClientList([]);
              dispatch(setClientProfileLoader(false));
              setOptions(searchResult([]));
            });
        } else if (isSearchPotential == "employer") {
          dispatch(setClientProfileLoader(true));
          setSearchDisable(true);
          searchResults = [];
          setClientList([]);
          setOptions(searchResult([]));
          Props.onSearchEmployerMain(searchClient)
            .then((res) => {
              notSearch = true;
              setSearchDisable(false);
              setOnAutoFocus(true);
              setOpenDropdown(true);
              dispatch(setClientProfileLoader(false));
              if (res.payload.items && res.payload.items.length > 0) {
                // message.warning("searched record not found");
                searchResults = res.payload.items;
                setClientList(res.payload.items);
                setOptions(searchResult(res.payload.items));
              }
              // var el1 = document.querySelector("el1");

              inp.current.focus();
            })
            .catch((err) => {
              notSearch = true;
              setSearchDisable(false);
              setOpenDropdown(true);
              searchResults = [];
              setClientList([]);
              dispatch(setClientProfileLoader(false));
              setOptions(searchResult([]));
            });
        } else {
          dispatch(setClientProfileLoader(true));
          setSearchDisable(true);
          searchResults = [];
          setClientList([]);
          setOptions(searchResult([]));
          Props.onSearchClient(searchClient)
            .then((res) => {
              notSearch = true;
              setSearchDisable(false);
              setOnAutoFocus(true);
              setOpenDropdown(true);
              dispatch(setClientProfileLoader(false));
              if (res.payload.count === 0) {
                // message.warning("searched record not found");
              } else {
                searchResults = res.payload.clients;
                setClientList(res.payload.clients);
                setOptions(searchResult(res.payload.clients));
              }
              // var el1 = document.querySelector("el1");

              inp.current.focus();
            })
            .catch((err) => {
              notSearch = true;
              setSearchDisable(false);
              setOpenDropdown(true);
              searchResults = [];
              setClientList([]);
              dispatch(setClientProfileLoader(false));
              setOptions(searchResult([]));
            });
        }
      }, 1500);
    }
  };

  const onSelect = (value, options) => {
    console.log("onSelect", value, history);
    let name = value.split("-");
    // setSelectedValue(name[0]);
    searchClient = name[0];
  };

  const onSearchActivity = () => {
    // let processingIds = "";
    // if (processingPerson.length > 0) {
    //   for (var i = 0; i < processingPerson.length; i++) {
    //     if (i === 0) {
    //       processingIds = processingPerson[i];
    //     } else {
    //       processingIds += "," + processingPerson[i];
    //     }
    //   }
    // }
    let data = {
      id: processingPerson,
      pageSize: 10,
      pageNumber: 1,
    };
    setLoadActivity(true);

    Props.onGetUserActivity(data)
      .then((res) => {
        // setActivityCount(res.payload.count);
        setLoadActivity(false);
        setUserActivity(res.payload.logs);
      })
      .catch((err) => {
        setLoadActivity(false);
      });
  };

  const callUserActivity = () => {
    let processingIds = "";
    // if (processingPerson.length > 0) {
    //   for (var i = 0; i < processingPerson.length; i++) {
    //     if (i === 0) {
    //       processingIds = processingPerson[i];
    //     } else {
    //       processingIds += "," + processingPerson[i];
    //     }
    //   }
    // } else {
    //   processingIds = localStorage.getItem("userId");
    // }
    let number = userActivity.length / 50 + 1;
    let data = {
      id: processingPerson,
      pageSize: 50,
      pageNumber: parseInt(number),
    };
    setLoadActivity(true);

    Props.onGetUserActivity(data)
      .then((res) => {
        setActivityCount(res.payload.count);
        setLoadActivity(false);
        setUserActivity((prevState) => [...prevState, ...res.payload.logs]);
      })
      .catch((err) => {
        setLoadActivity(false);
      });
  };

  const handleChange = (value) => {
    setProcessingPerson(value);
  };
  const backToSupervisor = () => {
    let data = JSON.parse(isSupervisor);
    localStorage.clear();
    sessionStorage.clear();
    data.map((el, i) => {
      localStorage.setItem(el[0], el[1]);
      if (data.length - 1 === i) {
        Props.history.push("/supervisor/batches/students");
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <BrowserRouter>
        <Switch>
          {history.location && (
            <>
              <Route exact path="/login" component={User} />
            </>
          )}
        </Switch>
      </BrowserRouter>
      <Row>
        <Col xs={16}>
          <Row>
            <Col xs={24}>
              <Row>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      Props.history.push("/dashboardBI");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Image src={Images.logo} preview={false} />
                  </div>
                </Col>
                <Col xs={2} offset={1}>
                  <Image
                    style={{ cursor: "pointer" }}
                    width={17}
                    src={navMenu}
                    preview={false}
                    onClick={() => handleBar()}
                  />
                </Col>
                <Col xs={4}>
                  <Select
                    placeholder={"Select Branch"}
                    style={{ width: "-webkit-fill-available" }}
                    onChange={handleChangeBranch}
                    value={selectedBranch}
                  >
                    {branchOptions}
                  </Select>
                </Col>
                <Col xs={isDob ? 6 : 5} className="header-search-col">
                  {/* <Search
                    style={{ width: "270px", marginLeft: "10px" }}
                    placeholder="Search Client..."
                    onSearch={onSearch}
                    onChange={onChangeValue}
                    enterButton
                  /> */}
                  {isDob ? (
                    <>
                      {/* <DatePicker
                        format="DD/MM/YYYY"
                        value={dob}
                        style={{ marginTop: 3 }}
                        onChange={(date, dateString) => {
                          
                          setDob(date);
                          setDobStr(moment(date).format("YYYY-MM-DD"));
                          // handleSearch(moment(date).format("YYYY-MM-DD"));
                        }}
                      /> */}

                      <AutoComplete
                        onSearch={handleSearch}
                        options={options}
                        dropdownMatchSelectWidth={350}
                        style={{ height: 30 }}
                        onSelect={onSelect}
                        disabled={SearchDisable}
                        notFoundContent="Not Found"
                        open={!SearchDisable && openDropdown}
                        ref={inp}
                        onFocus={() => {
                          setSearchOnEnter(true);
                          if (clientList.length > 0) {
                            setOpenDropdown(true);
                          }
                        }}
                        onBlur={() => {
                          setSearchOnEnter(false);
                          setOpenDropdown(false);
                          setOnAutoFocus(false);
                        }}
                        autoFocus={onAutoFocus}
                        id="el1"
                      >
                        <Input.Search
                          placeholder="Search date"
                          enterButton
                          type="date"
                          style={{ paddingBottom: 6 }}
                        />
                      </AutoComplete>
                    </>
                  ) : (
                    <AutoComplete
                      dropdownMatchSelectWidth={350}
                      style={{ height: 30 }}
                      options={options}
                      onSelect={onSelect}
                      onSearch={handleSearch}
                      disabled={SearchDisable}
                      notFoundContent="Not Found"
                      open={!SearchDisable && openDropdown}
                      // value={searchClient}
                      // onChange={(val) => {
                      //   searchClient = val;
                      //   // setSelectedValue(val);
                      // }}
                      ref={inp}
                      onFocus={() => {
                        setSearchOnEnter(true);
                        if (clientList.length > 0) {
                          setOpenDropdown(true);
                        }
                      }}
                      onBlur={() => {
                        setSearchOnEnter(false);
                        setOpenDropdown(false);
                        setOnAutoFocus(false);
                      }}
                      autoFocus={onAutoFocus}
                      id="el1"
                    >
                      <Input.Search
                        size="large"
                        placeholder="Search Client..."
                        enterButton
                      />
                    </AutoComplete>
                  )}
                </Col>
                <Col
                  xs={3}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Checkbox value={isSearchPotential} onChange={onChange}>
                    Potential
                  </Checkbox> */}
                  {/* <Radio.Group onChange={onChange} value={isSearchPotential}>
                    <Radio value={"potential"}>Potential</Radio>
                    <Radio value={"employer"}>Employer</Radio>
                  </Radio.Group> */}
                  <Select
                    onChange={onChange}
                    value={isSearchPotential}
                    dropdownClassName="dropdown-options-width-search"
                  >
                    <Option value="client">Client</Option>
                    <Option value="potential">Potential</Option>
                    {localStorage.getItem("admintoken") ||
                    (localStorage.getItem("userBranchPermissions") &&
                      JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "employer"
                      ).status &&
                      JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userSystemPermissions")
                            )
                          )
                        )
                      ).find(
                        (x) =>
                          x.role.toLowerCase() == "employer management" ||
                          x.role.toLowerCase() == "supplier management"
                      ).status == 1) ? (
                      <Option value="employer">Employer</Option>
                    ) : null}
                  </Select>
                </Col>
                <Col xs={2} style={{ marginLeft: 5 }}>
                  <Checkbox
                    checked={isDob}
                    onChange={(e) => {
                      setIsDob(e.target.checked);
                    }}
                  >
                    DOB
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={8}>
          {/* <Row>
            <Col xs={24}> */}
          <Row gutter={8}>
            {/* <Col span={2}></Col> */}
            <Col
              span={1}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <BellOutlined
                onClick={() => {
                  setLoadActivity(true);
                  let data = {
                    id: processingPerson,
                    pageSize: 10,
                    pageNumber: 1,
                  };

                  // Props.onGetTeamMember();
                  Props.onGetPermProcessingPerson();
                  Props.onGetUserActivity(data)
                    .then((res) => {
                      setLoadActivity(false);
                      setActivityCount(res.payload.count);
                      setUserActivity(res.payload.logs);
                    })
                    .catch((err) => {
                      setLoadActivity(false);
                    });
                  setDrawerVisible(true);
                }}
              />
            </Col>
            <Col span={6}>
              <Select
                defaultValue="Eng"
                style={{ width: "-webkit-fill-available" }}
              >
                {languageOptions.map((data) => {
                  return <Option>{data.label}</Option>;
                })}
              </Select>
            </Col>

            <Col span={8}>
              <div className="header-name-cont">
                {storeUserName && (
                  <>
                    <div>
                      <span className="header-name-text-style">Hello</span>
                    </div>
                    <div className="header-name-text">
                      <span className="header-name-text-style">{userName}</span>
                    </div>
                  </>
                )}
              </div>
            </Col>

            <Col
              span={9}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {(() => {
                if (localStorage.getItem("_admintoken")) {
                  return (
                    <Button type="primary" onClick={BackToAdminPortal}>
                      Admin Portal
                    </Button>
                  );
                } else if (isSupervisor) {
                  return (
                    <Button
                      className="button-blue"
                      type="primary"
                      onClick={backToSupervisor}
                      style={{ marginLeft: "7px" }}
                    >
                      Back To Supervisor
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      className="button-blue"
                      type="primary"
                      onClick={onLogout}
                      style={{ marginLeft: "7px" }}
                    >
                      Logout
                    </Button>
                  );
                }
              })()}
            </Col>
          </Row>
          <Drawer
            title="Activity Log"
            placement="right"
            width={500}
            onClose={onCloseDrawer}
            visible={drawerVisible}
            extra={
              <Space>
                <Button onClick={onCloseDrawer}>Cancel</Button>
                <Button type="primary" onClick={onCloseDrawer}>
                  OK
                </Button>
              </Space>
            }
          >
            <div style={{ margin: -24 }}>
              <Spin spinning={loadActivity}>
                <div>
                  <div
                    className="pc-select-width"
                    style={{
                      width: "100%",
                      marginLeft: 0,
                      padding: 20,
                      display: "flex",
                    }}
                  >
                    <div
                      className="multi-select-option"
                      style={{ width: `calc(100% - 80px)` }}
                    >
                      <Select
                        placeholder="Please select"
                        // value={processingPerson}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      >
                        {Props.teamMembers &&
                          Props.teamMembers.users.map((user, userInd) => (
                            <Option value={user.id}>{user.fullName}</Option>
                          ))}
                      </Select>
                    </div>
                    <Button
                      className="button-blue"
                      type="primary"
                      onClick={onSearchActivity}
                      style={{ marginLeft: "7px" }}
                    >
                      Search
                    </Button>
                  </div>
                  {userActivity &&
                    userActivity.length > 0 &&
                    userActivity.map((activity, index) => {
                      let createdDate = moment(activity.createdDate).format(
                        "DD/MM/YYYY"
                      );
                      return (
                        <div
                          className="user-activity-log"
                          // style={{
                          //   backgroundColor:
                          //     date == createdDate ? "#E7E7E7" : "#fafafa",
                          // }}
                        >
                          <div style={{ marginRight: 10 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: activity.clientLogNote,
                              }}
                            />
                          </div>
                          <div className="cl-act-org-btn-cont">
                            <div className="client-doc">
                              <span className="cl-act-org-btn-text">
                                {activity.logType}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {activityCount != userActivity.length && (
                    <div className="show-more-cont">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={callUserActivity}
                      >
                        <span className="show-more-text">Show More</span>
                      </div>
                    </div>
                  )}
                </div>
              </Spin>
            </div>
          </Drawer>
          {/* </Col>
          </Row> */}
        </Col>
      </Row>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     sideBarRes: state.employerManagReducer.sideBarRes,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   onCloseAndOpenSidebar: bindActionCreators(closeAndOpenSidebar, dispatch),
// });

// HeaderBar = connect(mapStateToProps, mapDispatchToProps)(HeaderBar);

export default HeaderBar;
