import React, { Fragment, useEffect, useState } from "react";
import EmployerManagementTable from "./EmployerManagementTable";
import { Form, Input, Button, Select, Col, Row } from "antd";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

const EmployerManagement = ({
  onGetEmployerManag,
  employerManagRes,
  expandedRowsEntriesKeys,
  onAddEmployerManag,
  showModal,
  employerTabble,
  setEmployerTabble,

  onDeleteEmployer,

  onSearchEmployer,

  onGetEmployerData,
  singleEmployerRes,

  singleEmployeFuc,

  userDataEmp,

  setLoading,
  loading,

  searchEmployerRes,

  userId,

  onGetLetterTemplates,

  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetEmployerJob,
  employerJobRes,
  LetterTemplatesRes,
  handleCancel,

  singleEmployeIcons,

  onUpdateEmployerFile,

  onRemoveEmployerFile,

  onAddEmployerContact,

  onAddAnyTamplate,

  handleSetDefault,
  setIsSearch,
  isSearch,

  onGetClientByEmpId,
  empClientEmpRes,
  onGetImapForAll,
  imapForAllRes,
  onUpdateEmployerManag,
  onGetClientTag,
  clientTagRes,
  onGetEmployerCase,
  onGetAllFilledQuestionnaire,
  allFilledQuestionnaireRes,
}) => {
  const [isSelectType, setIsSelectType] = useState();
  const [isSelector, setIsSelector] = useState();
  const [showTable, setShowTable] = useState(true);
  const [exportEmp, setExportEmp] = useState(false);

  useEffect(() => {
    onGetClientTag();
  }, []);

  const [form] = Form.useForm();
  const onSearch = (values) => {
    setLoading(true);

    console.log("valuesvaluesvalues", values, employerManagRes);
    const data = {
      name: values && values.name ? values.name : "",
      business:
        values && values.business_company ? values.business_company : "",
      city: values && values.city ? values.city : "",
      jobSector: isSelector && isSelector ? isSelector : "",
      occupation: values && values.occupation ? values.occupation : "",
      yearsOfBusiness: "",
      isPotential: false,
      employerType: isSelectType && isSelectType ? isSelectType : "",
      pageSize: 20,
      pageNumber: 0,
      isActive:
        values.employerStatus != undefined
          ? values.employerStatus.toString()
          : "",
      employerTags: values.employerTag ? values.employerTag.toString() : "",
      aggregateddate: values.isAccredited
        ? new Date()
        : "1900-01-01T00:00:00+00:00",
      isAggregated: values.isAccredited || "",
    };

    if (exportEmp) {
      data.pageSize = 0;
      const printEmplyerOpt = {
        url: `v1/employer/PrintEmployers`,
      };

      printEmplyerOpt.types = [
        "PRINT_EMPLOYER_SUCCESS",
        "PRINT_EMPLOYER_FAILURE",
      ];
      setLoading(true);
      apiRefresh
        .postWithFileRes(printEmplyerOpt, data)
        .then((res) => {
          setExportEmp(false);
          setLoading(false);
          var blob = new Blob([res], {
            type: "application/octet-stream",
          });
          saveAs(blob, "EmployersList.xlsx");
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setIsSearch(true);
      onSearchEmployer(data)
        .then(() => {
          onGetEmployerManag();
          // form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelectType(value);
  };

  const handleSectorChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelector(value);
  };

  console.log("searchEmployerRessearchEmployerRes==>>", searchEmployerRes);

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div className="client-tag">
            <div className="employer-manag">
              <div className="client-tag-form"></div>
              <div className="">
                <div className="client-tag-table ">
                  <Form
                    form={form}
                    onFinish={onSearch}
                    className="w-80-t"
                    // {...layout}
                    name="employer-form"
                    // initialValues={{ remember: true }}
                    colon={false}
                  >
                    {" "}
                    <div className="reminder-set-form text-style margin-top-34">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Business/Company Name"
                            name="business_company"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Type"
                            className="d-block"
                            name="type"
                          >
                            <Select
                              defaultValue="Employers"
                              onChange={handleChange}
                            >
                              <Select.Option value="Employer">
                                Employers
                              </Select.Option>
                              <Select.Option value="Supplier">
                                Supplier
                              </Select.Option>
                              <Select.Option value="Recruiter">
                                Recruiters
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="reminder-set-form text-style margin-top-12">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <Form.Item
                            label="Job Sector"
                            name="jobSector"
                            className="d-block"
                          >
                            <Select
                              defaultValue="Select job sector"
                              onChange={handleSectorChange}
                            >
                              <Select.Option value="Select job sector">
                                Select job sector
                              </Select.Option>
                              <Select.Option value="Administrative">
                                Administrative
                              </Select.Option>
                              <Select.Option value="AgricultureFarming">
                                Agriculture / Farming
                              </Select.Option>
                              <Select.Option value="Automotive">
                                Automotive
                              </Select.Option>
                              <Select.Option value="Construction">
                                Construction
                              </Select.Option>
                              <Select.Option value="Electrical">
                                Electrical
                              </Select.Option>
                              <Select.Option value="Engineer">
                                Engineer
                              </Select.Option>
                              <Select.Option value="Finance">
                                Finance
                              </Select.Option>
                              <Select.Option value="FMCG">FMCG</Select.Option>
                              <Select.Option value="Hospitality">
                                Hospitality
                              </Select.Option>
                              <Select.Option value="Human Resources">
                                HumanResources
                              </Select.Option>
                              <Select.Option value="Insurance">
                                Insurance
                              </Select.Option>
                              <Select.Option value="ICT">ICT</Select.Option>
                              <Select.Option value="Legal">Legal</Select.Option>
                              <Select.Option value="Marketing">
                                Marketing
                              </Select.Option>
                              <Select.Option value="Medical">
                                Medical
                              </Select.Option>
                              <Select.Option value="Real estate">
                                Realestate
                              </Select.Option>
                              <Select.Option value="Retail">
                                Retail
                              </Select.Option>
                              <Select.Option value="Sales">Sales</Select.Option>
                              <Select.Option value="Supply chain">
                                Supplychain
                              </Select.Option>
                              <Select.Option value="Teachers">
                                Teachers
                              </Select.Option>
                              <Select.Option value="Trades">
                                Trades
                              </Select.Option>
                              <Select.Option value="Not Employed">
                                NotEmployed
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Occupation"
                            name="occupation"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="City"
                            name="city"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="reminder-set-form text-style margin-top-12">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <Form.Item
                            label="Employer Status"
                            name="employerStatus"
                            className="d-block"
                          >
                            <Select
                              defaultValue="Select Status"
                              // onChange={handleSectorChange}
                            >
                              <Select.Option value="">
                                Select Status
                              </Select.Option>
                              <Select.Option value={true}>Active</Select.Option>
                              <Select.Option value={false}>
                                InActive
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Employer Tag"
                            name="employerTag"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Select
                              defaultValue="Select Client Tag"
                              // onChange={handleSectorChange}
                            >
                              <Select.Option value="">
                                Select Client Tag
                              </Select.Option>
                              {clientTagRes &&
                                clientTagRes.items.map((tag, index) => (
                                  <Select.Option value={tag.id}>
                                    {tag.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Accreditation Status"
                            name="isAccredited"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Select
                              defaultValue="Select Accreditation"
                              // onChange={handleSectorChange}
                            >
                              <Select.Option value="">
                                Accreditation Status
                              </Select.Option>
                              <Select.Option value="true">
                                Accredited
                              </Select.Option>
                              <Select.Option value="false">
                                Unaccredited
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        marginBottom: 45,
                        zIndex: 2,
                        position: "relative",
                        marginTop: 22,
                      }}
                      className="reminder-set-form  d-flex float-right"
                    >
                      <Row gutter={12}>
                        <Col>
                          <Form.Item>
                            <Button
                              onClick={() => {
                                handleSetDefault();
                                form.resetFields();
                                setIsSelector("");
                                setIsSelectType();
                                setTimeout(() => {
                                  onSearch({});
                                }, 500);
                              }}
                              className="employer-btn button-blue"
                              type="primary"
                            >
                              Set Default
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              className="employer-btn button-blue"
                              htmlType="submit"
                              type="primary"
                              onClick={() => {
                                setShowTable(true);
                                setExportEmp(false);
                              }}
                            >
                              Search
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              style={{ marginLeft: 10 }}
                              htmlType="submit"
                              type="primary"
                              onClick={() => {
                                // setShowTable(false);
                                setExportEmp(true);
                              }}
                              className="button-blue"
                            >
                              Export
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              onClick={() => showModal("add-new")}
                              className="green-btn employer-btn"
                              // htmlType="submit"
                              type="primary"
                              style={{ borderRadius: 5 }}
                            >
                              Add New
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                  <EmployerManagementTable
                    _expandedRowsEntriesKeys={expandedRowsEntriesKeys}
                    onGetEmployerManag={onGetEmployerManag}
                    employerManagRes={employerManagRes}
                    employerTabble={employerTabble}
                    setEmployerTabble={setEmployerTabble}
                    onDeleteEmployer={onDeleteEmployer}
                    singleEmployeFuc={singleEmployeFuc}
                    userDataEmp={userDataEmp}
                    onGetEmployerData={onGetEmployerData}
                    singleEmployerRes={singleEmployerRes}
                    userId={userId}
                    onGetLetterTemplates={onGetLetterTemplates}
                    onGetEmployerFile={onGetEmployerFile}
                    employerFileRes={employerFileRes}
                    onAddEmployerFile={onAddEmployerFile}
                    onGetEmployerJob={onGetEmployerJob}
                    employerJobRes={employerJobRes}
                    LetterTemplatesRes={LetterTemplatesRes}
                    handleCancel={handleCancel}
                    singleEmployeIcons={singleEmployeIcons}
                    setLoading={setLoading}
                    loading={loading}
                    onUpdateEmployerFile={onUpdateEmployerFile}
                    onRemoveEmployerFile={onRemoveEmployerFile}
                    searchEmployerRes={searchEmployerRes}
                    isSearch={isSearch}
                    onAddAnyTamplate={onAddAnyTamplate}
                    onGetClientByEmpId={onGetClientByEmpId}
                    empClientEmpRes={empClientEmpRes}
                    onGetImapForAll={onGetImapForAll}
                    imapForAllRes={onGetImapForAll}
                    onUpdateEmployerManag={onUpdateEmployerManag}
                    onGetAllFilledQuestionnaire={onGetAllFilledQuestionnaire}
                    allFilledQuestionnaireRes={allFilledQuestionnaireRes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EmployerManagement;
