import React from "react";
import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import { Spin, message, Button } from "antd";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import Modal from "react-awesome-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class PotentialClientStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isUpdate: false,
      name: "",
      loading: false,
    };
    this.props.onGetPotentialClientStatus();
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  openEditModal = (item) => {
    this.setState({
      id: item.id,
      name: item.name,
      isUpdate: true,
    });
    this.openModal();
  };

  updateVisaStatus = () => {
    if (this.state.name.length < 3) {
      message.warning(
        "Potential client status name should be more than three characters!"
      );
      return;
    }
    this.setState({ loading: true });
    let data = {
      id: this.state.id,
      name: this.state.name,
    };
    this.props
      .onUpdPotentialClientStatus(data)
      .then(() => {
        this.setState({
          loading: false,
          name: "",
          isUpdate: false,
        });
        this.closeModal();
        message.success("Potential client status updated successfully");
        this.props.onGetPotentialClientStatus();
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Update potential client status failed!");
      });
  };

  onSubmit = () => {
    if (this.state.name.length < 3) {
      message.warning(
        "Potential client status name should be more than three characters!"
      );
      return;
    }
    this.setState({ loading: true });
    let data = {
      name: this.state.name,
    };
    this.props
      .onAddPotentialClientStatus(data)
      .then(() => {
        this.setState({
          loading: false,
          name: "",
        });
        this.closeModal();
        message.success("Potential client status added successfully");
        this.props.onGetPotentialClientStatus();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { potentialClientData } = this.props;
    const { name } = this.state;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div>
              <div style={{ paddingRight: 45 }}>
                <div
                  className="sus-bottom-btn-cont"
                  style={{ paddingTop: 60, marginLeft: 20 }}
                >
                  <div className="sus-bottom-icon-cont">
                    <img
                      src={Images.potentialClients}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span className="sus-bottom-text">
                    POTENTIAL CLIENT STATUS
                  </span>
                </div>
                <div
                  class="sus-form-container"
                  style={{
                    width: "100%",
                    minHeight: 130,
                    paddingLeft: 55,
                    paddingRight: 55,
                    border: 1,
                    borderStyle: "solid",
                    borderColor: "#D9D9D9",
                  }}
                >
                  <div
                    className="sus-add-cont-row"
                    style={{ marginTop: 10, marginRight: -40 }}
                  >
                    <div></div>
                    <div onClick={this.openModal}>
                      <img src={Images.plusIcon} style={{ width: 20 }} />
                    </div>
                  </div>
                  <div
                    style={{ marginTop: 10, marginLeft: -40, marginRight: -40 }}
                  >
                    <div className="sus-table">
                      <div style={{ margin: 20 }}>
                        <div
                          className="sus-table-header"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div className="sus-head-text-cont">
                            <span className="sus-head-text">Name</span>
                          </div>

                          <div className="sus-head-text-cont">
                            <span className="sus-head-text">Action</span>
                          </div>
                        </div>
                        {potentialClientData &&
                          potentialClientData.items.map((item, index) => {
                            return (
                              <div
                                className="sus-table-content"
                                style={{
                                  borderBottomWidth: 1,
                                  borderBottomStyle: "solid",
                                  borderColor: "#D3D3D3",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                <div style={{ width: 270 }}>
                                  <span className="sus-content-text">
                                    {item.name}
                                  </span>
                                </div>

                                <div
                                  className="sus-head-text-cont"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.openEditModal(item)}
                                >
                                  <img
                                    src={Images.editBorderBlue}
                                    style={{ width: 15 }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            visible={this.state.visible}
            width="500"
            height="200"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div style={{ padding: 40 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="sus-modal-label">
                  <span className="sus-modal-label-text">
                    POTENTIAL CLIENT STATUS
                  </span>
                </div>
                <div onClick={this.closeModal}>
                  <img src={Images.crossRed} style={{ width: 20 }} />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  class="medical-label"
                  style={{ fontSize: 11, marginLeft: 15 }}
                >
                  Name:
                </p>
                <div class="profile-input-border" style={{ width: "60%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={name}
                    onChange={(e) => this.myChangeHandler("name", e)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 30,
                }}
              >
                <div></div>
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={this.closeModal}
                    type="primary"
                    className="sus-add-btn"
                  >
                    CLOSE
                  </Button>
                  <Button
                    onClick={
                      this.state.isUpdate
                        ? () => this.updateVisaStatus()
                        : this.onSubmit
                    }
                    loading={this.state.loading}
                    type="primary"
                    className="sus-add-btn"
                    style={{ marginLeft: 10 }}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default PotentialClientStatus;
