import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Col,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
// import AddNewMultipleContact from "./AddNewMultipleContact";

const AddNewEmployerForm = ({
  onAddEmployerManag,
  onGetEmployerManag,

  employerManagRes,

  handleCancel,

  setLoading,
  loading,

  onAddDocWithName,
  docWithNameRes,

  // onAddEmployerContact,

  // ongetEmployerContact,

  // userDataEmp,

  // onUploadAvatar,

  // onUploadFile,
  // addUploadFileRes,

  // handleSetDefault,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isSelector, setIsSelector] = useState();

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form:", values);

    setLoading(true);

    const data = {
      clientId: "00000000-0000-0000-0000-000000000000",
      name: values && values.name && values.name.length > 0 ? values.name : "",
      email:
        values && values.email && values.email.length > 0 ? values.email : "",
      contactNo:
        values && values.contact_no && values.contact_no.length > 0
          ? values.contact_no
          : "",
      business:
        values && values.business && values.business.length > 0
          ? values.business
          : "",
      city: values && values.city && values.city.length > 0 ? values.city : "",
      contactPerson:
        values && values.contact_person && values.contact_person.length > 0
          ? values.contact_person
          : "",
      mobile:
        values && values.mobile && values.mobile.length > 0
          ? values.mobile
          : "",
      website:
        values && values.website && values.website.length > 0
          ? values.website
          : "",
      jobSector:
        values && values.job_sector && values.job_sector.length > 0
          ? values.job_sector
          : "",
      nzbn: values && values.nzbn ? values.nzbn : "",
      occupation:
        values && values.occupation && values.occupation.length > 0
          ? values.occupation
          : "",
      numberOfEmployees:
        values && values.company_size && values.company_size.length > 0
          ? values.company_size
          : "",
      yearsOfBusiness:
        values && values.how_many_years && values.how_many_years.length > 0
          ? values.how_many_years
          : "",
      isPotential: true,
      employerType:
        values && values.employer_Type ? values.employer_Type : "Employer",
      address:
        values && values.address && values.address.length > 0
          ? values.address
          : "",
      agreementUrl:
        docWithNameRes && docWithNameRes ? docWithNameRes.fileURL : "",
      agreementFileName:
        docWithNameRes && docWithNameRes ? docWithNameRes.fileName : "",
      xeroID: "00000000-0000-0000-0000-000000000000",
      jobStatus: 0,
      isPEmp: true,
      accredationStartDate: "1900-01-01T00:00:00+00",
      accredationExpiryDate: "1900-01-01T00:00:00+00",
      countryCodeId: 0,
    };
    onAddEmployerManag(data)
      .then(() => {
        onGetEmployerManag();
        setLoading(false);
        // handleSetDefault();
        message.success("Successfully Added!");
        handleCancel();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    }
    if (fileList && fileList.length > 1) {
      message.warning("You can upload 1 files only");
    } else setFileList(fileList);

    let formData = new FormData();
    formData.append("BranchId", localStorage.getItem("selectedBranchId"));
    if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile =
          fileList[i] && fileList[i].originFileObj && fileList[i].originFileObj;

        formData.append("file", selectedFile);

        onAddDocWithName(formData);
      }
    }
  };

  const handleSectorChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelector(value);
  };

  console.log(
    "docWithNameResdocWithNameResdocWithNameResdocWithNameResdocWithNameRes===>>>123",
    docWithNameRes
  );
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          onFinish={onFinish}
          form={form}
          className="width-100  "
          name="main"
        >
          <div className="border-box-add-emp add-employer-para add-emp-form">
            <Form.Item style={{ padding: "0px" }}>
              <Row gutter={8}>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  span={12}
                >
                  <p style={{ fontSize: "13px" }}>Type</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="employer_Type">
                    <Select defaultValue="Employer">
                      <Select.Option key="Employer">Employer</Select.Option>
                      <Select.Option key="Supplier">Supplier</Select.Option>
                      <Select.Option key="Recruiter">Recruiter</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <p>
                    Name<span className="required-field">*</span>
                  </p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Name" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="business" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Business/Company Name</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Business" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                },
              ]}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <p>Email</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Email" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="contact_no" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Contact No</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Contact No" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="city" required={true}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>City</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="City" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="address" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Address</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Address" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="contact_person" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Contact Person</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Contact Person" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="mobile" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Mobile</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Mobile" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="website" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Website</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Website" />
                </Col>
              </Row>
            </Form.Item>

            <Row gutter={8}>
              <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                <p style={{ fontSize: "13px" }}>Job Sector</p>
              </Col>
              <Col span={12}>
                <Form.Item name="job_sector" required={false}>
                  <Select
                    defaultValue="Select job sector"
                    onChange={handleSectorChange}
                  >
                    <Select.Option value="Select job sector">
                      Select job sector
                    </Select.Option>
                    <Select.Option value="Administrative">
                      Administrative
                    </Select.Option>
                    <Select.Option value="AgricultureFarming">
                      Agriculture / Farming
                    </Select.Option>
                    <Select.Option value="Automotive">Automotive</Select.Option>
                    <Select.Option value="Construction">
                      Construction
                    </Select.Option>
                    <Select.Option value="Electrical">Electrical</Select.Option>
                    <Select.Option value="Engineer">Engineer</Select.Option>
                    <Select.Option value="Finance">Finance</Select.Option>
                    <Select.Option value="FMCG">FMCG</Select.Option>
                    <Select.Option value="Hospitality">
                      Hospitality
                    </Select.Option>
                    <Select.Option value="Human Resources">
                      Human Resources
                    </Select.Option>
                    <Select.Option value="Insurance">Insurance</Select.Option>
                    <Select.Option value="ICT">ICT</Select.Option>
                    <Select.Option value="Legal">Legal</Select.Option>
                    <Select.Option value="Marketing">Marketing</Select.Option>
                    <Select.Option value="Medical">Medical</Select.Option>
                    <Select.Option value="Real estate">
                      Realestate
                    </Select.Option>
                    <Select.Option value="Retail">Retail</Select.Option>
                    <Select.Option value="Sales">Sales</Select.Option>
                    <Select.Option value="Supply chain">
                      Supply chain
                    </Select.Option>
                    <Select.Option value="Teachers">Teachers</Select.Option>
                    <Select.Option value="Trades">Trades</Select.Option>
                    <Select.Option value="Not Employed">
                      Not Employed
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                <p style={{ fontSize: "13px" }}>NZBN</p>
              </Col>
              <Col span={12}>
                <Form.Item name="nzbn" required={false}>
                  <Input placeholder="NZBN" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="occupation" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Occupation</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Occupation" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="company_size" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Company Size (Number of Employees)</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Company Size (Number of Employees)" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="how_many_years" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>How Many Years Has This Business In Operation</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="How Many Years Has This Business In Operation" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <p>Agreement</p>
                </Col>
                <Col span={12} className="upload-button">
                  <Upload
                    single
                    onChange={onHandleChange}
                    beforeUpload={() => false}
                    fileList={fileList}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Col>
              </Row>
            </Form.Item>
            {/* <AddNewMultipleContact
            onAddEmployerContact={onAddEmployerContact}
            ongetEmployerContact={ongetEmployerContact}
            handleCancel={handleCancel}
            userDataEmp={userDataEmp}
          /> */}
          </div>
          <div className="add-emp-btn d-flex flex-end">
            <Form.Item>
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                className="login-form-button save-btn button-blue"
                htmlType="submit"
              >
                SAVE
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="login-form-button save-btn button-blue"
                onClick={handleCancel}
              >
                CLOSE
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default AddNewEmployerForm;
