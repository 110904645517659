import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const timeTrackingReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TIME_TRACKINGS_SUCCESS:
      return { ...state, timeTrackingRes: action.payload };
    case types.GET_TIME_TRACKINGS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_TIME_TRACKINGS_POPUP_SUCCESS:
      return { ...state, timeTrackingPopupRes: action.payload };
    case types.GET_TIME_TRACKINGS_POPUP_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_TIME_TRACKINGS_SUCCESS:
      return { ...state, addTimeTrackingRes: action.payload };
    case types.ADD_TIME_TRACKINGS_FAILURE:
      return { ...state, addTimeTrackingRes: null };

    case types.GET_VISA_WORK_TYPE_SUCCESS:
      return { ...state, visaWorkTypeRes: action.payload };
    case types.GET_VISA_WORK_TYPE_FAILURE:
      return { ...state, visaWorkTypeRes: null };

    case types.GET_VISA_TYPE_PRICE_SUCCESS:
      return { ...state, visaTypePriceRes: action.payload };
    case types.GET_VISA_TYPE_PRICE_FAILURE:
      return { ...state, visaTypePriceRes: null };

    case types.GET_SERVICE_TYPE_SUCCESS:
      return { ...state, serviceTypeRes: action.payload };
    case types.GET_SERVICE_TYPE_FAILURE:
      return { ...state, serviceTypeRes: null };

    default:
      return state;
  }
};
