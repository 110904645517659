import React, { Fragment, useEffect, useState, useParams } from "react";
import {
  Table,
  Button,
  Space,
  Spin,
  Form,
  Select,
  Modal,
  message,
  Tooltip,
} from "antd";
import DocumentChecklistForm from "../../../Components/DocumentChecklist/DocumentChecklistForm.js";
import EmailDocumentChecklistForm from "../../../Components/DocumentChecklist/EmailDocumentChecklistForm";
import { Link } from "react-router-dom";
import { Images } from "../../../Themes/index.js";
import { apiRefresh } from "../../../services/api.js";
import { CopyOutlined } from "@ant-design/icons";

const { Option } = Select;
var URL = "";

const DocumentCheckList = ({
  onGetDocumentChecklist,
  docChecklistRes,
  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,
}) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");

  var http = window.location.host == "localhost:3000" ? "http://" : "https://";
  URL = http + window.location.host + "/CheckList/index.htm?param=";
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [selectdata, setSelectdata] = useState([]);
  const [linkdata, setLinkdata] = useState([]);
  const [checklistItem, setChecklistItem] = useState([]);
  const [checklistName, setChecklistName] = useState("");
  const [linkInfo, setLinkInfo] = useState(null);
  const [url, setUrl] = useState("");

  // const { id } = useParams();

  // const handleProceed = (e) => {
  //
  //   history.push(`/document-link-client/${id}`);
  // };

  // const [linkId, setLinkId] = useState("");

  useEffect(() => {
    setLoading(true);

    onGetDocumentChecklist().then(() => {
      setLoading(false);
    });
  }, [onGetDocumentChecklist]);

  useEffect(() => {
    setLoading(true);

    onGetEmployerCheckList(clientprofileid)
      .then((res) => {
        //  setChecklistItem(res.payload.items);
        setLoading(false);
      })
      .catch((err) => {
        setChecklistItem([]);
        setLoading(false);
      });
  }, [onGetEmployerCheckList]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  const getUrl = (data) => {
    setUrl(data);
  };

  const showModal = (value, data, name, linkInfo) => {
    if (value == "send-email") {
      setLinkdata(data);
      setLinkInfo(URL + data);
    } else {
      setSelectdata(data);
    }
    setChecklistName(name);
    setIsModalVisible(true);
    setCreateMode(value);
    setLinkInfo(linkInfo);
  };
  const RenderChecklist = (value) => {
    if (docChecklistRes.items) {
      var item = docChecklistRes.items.find((x) => x.id == value);
      showModal("add-document", item, item.name);
    }
  };

  const onLinkClient = (record) => {
    window.open(URL + record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Link Client",
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <div>
          Please{" "}
          <span
            style={{ cursor: "pointer", color: "#0E8CFC" }}
            onClick={() => onLinkClient(record && record.link)}
          >
            Click Here{" "}
          </span>
          to open
        </div>
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <Space size="middle">
          {URL + text}
          {/* <Button
            className="copy-link"
            type="default"
            onClick={() => {
              navigator.clipboard.writeText(URL + text);
              message.info("Copied");
            }}
          >
            Copy Link
          </Button> */}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (text, record) => (
        <Space className="send-btn" size="middle">
          <Tooltip title="Copy Link" style={{ display: "inline-block" }}>
            <CopyOutlined
              style={{
                cursor: "pointer",
                color: "#458ccc",
                fontSize: 16,
                marginTop: 6,
              }}
              onClick={() => {
                navigator.clipboard.writeText(URL + record.link);
                message.info("Copied");
              }}
            />
          </Tooltip>
          <img
            onClick={() => {
              record.checkListItems = record.items;
              showModal("update-document", record, record.name);
            }}
            src={Images.editBorderBlue}
            style={{
              width: 15,
              marginRight: "1vw",
            }}
          />
          <Button
            onClick={() => {
              let data = {
                id: record.id,
                isBlock: !record.isBlock,
              };
              const isBlockOpt = {
                url: `v1/document/checklist/IsBlock`,
              };
              setLoading(true);
              isBlockOpt.types = ["IS_BLOCK_SUCCESS", "IS_BLOCK_FAILURE"];
              apiRefresh
                .put(isBlockOpt, data)
                .then((res) => {
                  onGetEmployerCheckList(clientprofileid);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
            }}
            type="primary"
            className="button-blue"
          >
            {record.isBlock ? "Unblock" : "Block"}
          </Button>
          <Button
            onClick={() => {
              showModal(
                "send-email",
                record.link,
                record.name,
                URL + record.link
              );
            }}
            type="primary"
            className="button-blue"
          >
            Send
          </Button>

          {/* <img
            // onClick={() => this.openEditModal(item)}
            src={Images.sendBlue}
            style={{
              width: 15,
              marginRight: "1vw",
            }}
          /> */}
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <div style={{ width: "25%" }} className="mar-top-1 mb-6rem ">
        <Form>
          <Form.Item name="gender" rules={[{ required: true }]}>
            <Select
              mode="single"
              showArrow
              checked={false}
              placeholder="Select"
              style={{ width: "100%" }}
              onChange={(value) => RenderChecklist(value)}
            >
              {docChecklistRes &&
                docChecklistRes.items &&
                docChecklistRes.items.map((item, index) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="mar-top-1 mb-6rem">
        <Spin size="large" spinning={loading}>
          <div className={"school-table"}>
            <Table
              bordered
              columns={columns}
              dataSource={checklistItem}
              pagination={false}
              scroll={{ x: "auto" }}
            />
          </div>
        </Spin>
      </div>
      {isModalVisible && (
        <Modal
          className="reminder-model-main"
          title={"Document Checklist"}
          visible={isModalVisible}
          onCancel={handleCancel}
          header={false}
          footer={false}
          width={800}
        >
          {(createMode === "add-document" ||
            createMode == "update-document") && (
            <DocumentChecklistForm
              getUrl={getUrl}
              handleCancel={handleCancel}
              createMode={createMode}
              view={"client"}
              Mode={selectdata}
              setLoading={setLoading}
              loading={loading}
              checklistName={checklistName}
              onAddDocumentChecklist={onAddEmployerCheckList}
              onGetDocumentChecklist={onGetEmployerCheckList}
              showModal={showModal}
              clientprofileid={clientprofileid}
            />
          )}
          {createMode === "send-email" && (
            <EmailDocumentChecklistForm
              url={url}
              handleCancel={handleCancel}
              onSendEmailLink={onSendEmailLink}
              clientprofileid={clientprofileid}
              linkdata={linkdata}
              checklistName={checklistName}
              emailUrl="ClientEmail"
              fromClient={true}
              linkInfo={linkInfo}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default DocumentCheckList;
