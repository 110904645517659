import React from "react";
import Select from "react-select";
import {
  message,
  Spin,
  Button,
  Upload,
  Form,
  Checkbox,
  Input,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FileDownload from "js-file-download";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import queryString from "query-string";

import "./QuestionnaireStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";

import UpdateSectionCard from "../Components/Questionnaire/UpdateSectionCard";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

var stopLoader = false;

// var optionsArray = [];
var optionsSectionArray = [];

class UpdateQuestionnaire extends React.Component {
  constructor(props) {
    const initialQueryValue =
      props.location && props.location.search
        ? queryString.parse(props.location.search)
        : 0;
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: false,
      previewLoading: false,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",

      questionNumbers: 1,
      clickedButton: "",

      multiSections: [],
      questionnaireDataUpd: null,

      getLoading: false,

      optionsArray: [],
    };
    var questionnaireId = initialQueryValue
      ? initialQueryValue.id
      : props.questionnaireId;

    this.setState({ getLoading: true });
    this.props.onGetMappingParent();
    this.props
      .onGetQuestionnaire(questionnaireId)
      .then((res) => {
        this.setState({ questionnaireDataUpd: res.payload, getLoading: false });
      })
      .catch((err) => {
        this.setState({ getLoading: false });
      });
  }

  formRef = React.createRef();

  componentDidUpdate(PrevProps) {
    if (PrevProps.questionnaireData !== this.props.questionnaireData) {
      this.setState({ questionnaireDataUpd: this.props.questionnaireData });
    }
    if (
      PrevProps.questionnaireId &&
      PrevProps.questionnaireId != this.props.questionnaireId
    ) {
      this.setState({ questionnaireDataUpd: null, getLoading: true });

      this.props
        .onGetQuestionnaire(this.props.questionnaireId)
        .then((res) => {
          this.setState({
            questionnaireDataUpd: res.payload,
            getLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ getLoading: false });
        });
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addQuestion = (value) => {
    this.setState({ questionNumbers: value });
  };

  onSubmit = () => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.email)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!strongRegex.test(this.state.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else {
      this.setState({ loading: true });

      let data = {
        roleId: 0,
        title: "",
        image: this.state.addUserImageUrl,
        forgotPasswordBit: false,
        lastSignedIn: new Date(),
        signature: "",
        signatureImage: "",
        timeZone: "",
        comission: 0,
        agreementUrl: "",
        description: "",
        fullName: this.state.fullName,
        alternateEmail: this.state.email,
        status: 0,
        isManager: false,
        isOwner: false,
      };

      console.log(data);

      // this.props
      //   .onAddTeamMember(data)
      //   .then(() => {
      //     this.setState({ loading: false });
      //     if (this.props.addTeamMemberSuccess.id === "Email exists") {
      //       message.error("User email already exist!");
      //     } else {
      //       // this.props.onGetTeamMember();
      //       let createPassword = {
      //         userId: this.props.addTeamMemberSuccess.id,
      //         email: this.state.email,
      //         password: this.state.password
      //       };
      //       this.props.onAddPassword(createPassword);
      //       message.success("User added successfully");
      //       this.setState({
      //         fullName: "",
      //         email: "",
      //         password: "",
      //         addUserImageUrl: ""
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.setState({ loading: false });
      //   });
    }
  };

  makeOptionsArray = (options) => {
    this.setState({ optionsArray: [...this.state.optionsArray, options] });
  };

  makeCheckboxArray = (options) => {
    // optionsArray.push(options);
  };

  makeDropdownArray = (options) => {
    // optionsArray.push(options);
  };

  addedOptionSection = (sectionOptions) => {
    if (this.state.multiSections.length === 0) {
      this.setState({
        multiSections: this.state.multiSections.concat(sectionOptions),
      });
    } else {
      for (var i = 0; i < sectionOptions.length; i++) {
        var findSameIndex = this.state.multiSections.filter(
          (obj) =>
            obj.mainQuestionIndex === sectionOptions[i].mainQuestionIndex &&
            obj.mainSectionIndex === sectionOptions[i].mainSectionIndex &&
            obj.sectionInd === sectionOptions[i].sectionInd
        );
        if (findSameIndex.length === 0) {
          this.setState({
            multiSections: this.state.multiSections.concat(sectionOptions[i]),
          });
        }
        console.log(this.state.multiSections);
      }
    }
    optionsSectionArray = this.state.multiSections;
    // optionsSectionArray = sectionOptions;
  };

  onValueChange = (changedValue, allValues) => {
    for (var i = 0; i < allValues.sections.length; i++) {
      if (changedValue.sections[i].questions) {
        for (var j = 0; j < allValues.sections[i].questions.length; j++) {
          if (changedValue.sections[i].questions[j]) {
            for (
              var k = 0;
              k < allValues.sections[i].questions[j].questionOptions.length;
              k++
            ) {
              if (changedValue.sections[i].questions[j].questionOptions[k]) {
                for (
                  var l = 0;
                  l <
                  allValues.sections[i].questions[j].questionOptions[k]
                    .optionalQuestions.length;
                  l++
                ) {
                  if (
                    changedValue.sections[i].questions[j].questionOptions[k]
                      .optionalQuestions[l]
                  ) {
                    for (
                      var m = 0;
                      m <
                      allValues.sections[i].questions[j].questionOptions[k]
                        .optionalQuestions[l].questions.length;
                      m++
                    ) {
                      if (
                        changedValue.sections[i].questions[j].questionOptions[k]
                          .optionalQuestions[l].questions[m]
                      ) {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  isHTML = (str) => {
    const fragment = document.createRange().createContextualFragment(str);

    // remove all non text nodes from fragment
    fragment
      .querySelectorAll("*")
      .forEach((el) => el.parentNode.removeChild(el));

    // if there is textContent, then not a pure HTML
    return !(fragment.textContent || "").trim();
  };

  removeOptionSection = (index, number) => {
    this.setState({
      multiSections: this.state.multiSections.splice(index, number),
    });
  };

  readOptionalSections = (options) => {
    let userId = localStorage.getItem("userId");
    var optionalQuestionsList = [];
    var mappParent = "";
    var property = "";

    var optionValuesList = [];

    var optionSectionList = [];
    for (var k = 0; k < options.length; k++) {
      if (options[k].optionalQuestions) {
        for (var l = 0; l < options[k].optionalQuestions.length; l++) {
          if (options[k].optionalQuestions[l].questions) {
            for (
              var m = 0;
              m < options[k].optionalQuestions[l].questions.length;
              m++
            ) {
              var findOptionalOptions = [];
              var optionalOptionArray = [];
              console.log(
                "======optional options",
                options[k].optionalQuestions[l].questions[m]
              );
              if (
                options[k].optionalQuestions[l].questions[m].questionOptions &&
                options[k].optionalQuestions[l].questions[m].questionOptions
                  .length > 0
              ) {
                optionalOptionArray = this.readOptionalSections(
                  options[k].optionalQuestions[l].questions[m].questionOptions
                );
              }

              var optRequired = false;
              var optCascading = false;
              var optMultiAnswers = false;
              // if (
              //   options[k].optionalQuestions[l].questions[m].checkedOptions &&
              //   options[k].optionalQuestions[l].questions[m].checkedOptions
              //     .length > 0
              // ) {
              //
              //   for (
              //     var a = 0;
              //     a <
              //     options[k].optionalQuestions[l].questions[m].checkedOptions
              //       .length;
              //     a++
              //   ) {
              //     if (
              //       options[k].optionalQuestions[l].questions[m].checkedOptions[
              //         a
              //       ] === "required"
              //     ) {
              //       optRequired = true;
              //     }
              //     if (
              //       options[k].optionalQuestions[l].questions[m].checkedOptions[
              //         a
              //       ] === "multiAnswers"
              //     ) {
              //       optMultiAnswers = true;
              //     }
              //     if (
              //       options[k].optionalQuestions[l].questions[m].checkedOptions[
              //         a
              //       ] === "cascading"
              //     ) {
              //       optCascading = true;
              //     }
              //   }
              // } else if (options[k].optionalQuestions[l].questions[m].id) {
              //
              //   optRequired =
              //     options[k].optionalQuestions[l].questions[m].isRequired;
              //   optMultiAnswers =
              //     options[k].optionalQuestions[l].questions[m].isMultiple;
              //   optCascading =
              //     options[k].optionalQuestions[l].questions[m].isCascading;
              // }

              var checkIsHtml = this.isHTML(
                options[k].optionalQuestions[l].questions[m].question
              );

              if (checkIsHtml) {
                optionalQuestionsList.push({
                  question: options[k].optionalQuestions[l].questions[m]
                    .question
                    ? options[k].optionalQuestions[l].questions[m].question
                    : options[k].optionalQuestions[l].questions[
                        m
                      ].question.toHTML() || " ",
                  answerType: 0,
                  position: 0,
                  isRequired: optRequired,
                  isCascading: optCascading,
                  isMultiple: optMultiAnswers,
                  mapped: "",
                  mappedProperty: "",
                  mappedText: "",
                  createdBy: userId,
                  questionOptions: [],
                });
              } else {
                if (options[k].optionalQuestions[l].questions[m].mapped) {
                  if (
                    options[k].optionalQuestions[l].questions[
                      m
                    ].mapped.includes('"id":')
                  ) {
                    mappParent = JSON.parse(
                      options[k].optionalQuestions[l].questions[m].mapped
                    );
                  } else {
                    mappParent =
                      options[k].optionalQuestions[l].questions[m].mapped;
                  }
                } else {
                  mappParent = "";
                }

                if (
                  options[k].optionalQuestions[l].questions[m].mappedProperty
                ) {
                  if (
                    options[k].optionalQuestions[l].questions[
                      m
                    ].mappedProperty.includes('"id":')
                  ) {
                    property = JSON.parse(
                      options[k].optionalQuestions[l].questions[m]
                        .mappedProperty
                    );
                  } else {
                    property =
                      options[k].optionalQuestions[l].questions[m]
                        .mappedProperty;
                  }
                } else {
                  property = "";
                }

                optionalQuestionsList.push({
                  questionnaireId: 0,
                  question:
                    options[k].optionalQuestions[l].questions[m].question ||
                    " ",
                  answerType: options[k].optionalQuestions[l].questions[m]
                    .answerType
                    ? parseInt(
                        options[k].optionalQuestions[l].questions[m].answerType
                      )
                    : 1,
                  position: 0,
                  isRequired:
                    options[k].optionalQuestions[l].questions[m].isRequired ||
                    false,
                  isCascading:
                    options[k].optionalQuestions[l].questions[m].isCascading ||
                    false,
                  isMultiple:
                    options[k].optionalQuestions[l].questions[m].isMultiple ||
                    false,
                  sectionId: 0,
                  mapped:
                    mappParent && mappParent.name
                      ? mappParent.name
                      : mappParent,
                  mappedProperty:
                    property && property.name
                      ? property.name
                      : property && property.friendlyName
                      ? property.friendlyName
                      : property,
                  mappedText: "",
                  createdBy: userId,
                  questionOptions: optionalOptionArray,
                });
              }

              optionalOptionArray = [];
            }
          }
          optionSectionList.push({
            name: options[k].optionalQuestions[l].name
              ? options[k].optionalQuestions[l].name.trim()
              : "",
            allowMultiple:
              options[k].optionalQuestions[l].allowMultiple || false,
            position: 0,
            questions: optionalQuestionsList,
          });
          optionalQuestionsList = [];
          // }
        }
      }
      optionValuesList.push({
        option: options[k].option || "",
        optionalQuestions: optionSectionList,
      });
      optionSectionList = [];
    }

    return optionValuesList;
  };

  onFinish = (values) => {
    if (this.props.questionnaireData.hasFilledAnswers) {
      message.error(
        "Questionnaire has filled answers. You can't update this questionnaire!"
      );
      return;
    }
    console.log(this.props.questionnaireData);
    if (this.state.clickedButton === "update") {
      this.setState({ loading: true });
    } else {
      this.setState({ previewLoading: true });
    }
    var userId = localStorage.getItem("userId");
    var sectionList = [];
    var questionsList = [];
    var optionValuesList = [];
    var required = false;
    var cascading = false;
    var multiAnswers = false;
    var mappParent = "";
    var property = "";

    if (values.sections && values.sections.length > 0) {
      for (var i = 0; i < values.sections.length; i++) {
        if (values.sections[i].questions) {
          for (var j = 0; j < values.sections[i].questions.length; j++) {
            if (values.sections[i].questions[j].questionOptions) {
              optionValuesList = this.readOptionalSections(
                values.sections[i].questions[j].questionOptions
              );
            }
            // if (
            //   values.sections[i].questions[j].checkedOptions &&
            //   values.sections[i].questions[j].checkedOptions.length > 0
            // ) {
            //   for (
            //     var a = 0;
            //     a < values.sections[i].questions[j].checkedOptions.length;
            //     a++
            //   ) {
            //     if (
            //       values.sections[i].questions[j].checkedOptions[a] ===
            //       "required"
            //     ) {
            //       required = true;
            //     } else if (
            //       values.sections[i].questions[j].checkedOptions[a] ===
            //       "multiAnswers"
            //     ) {
            //       multiAnswers = true;
            //     } else if (
            //       values.sections[i].questions[j].checkedOptions[a] ===
            //       "cascading"
            //     ) {
            //       cascading = true;
            //     }
            //
            //   }
            // } else {
            //   if (
            //     this.props.questionnaireData &&
            //     this.props.questionnaireData.sections.length > i
            //   ) {
            //     if (
            //       this.props.questionnaireData.sections[i].questions.length > j
            //     ) {
            //
            //       required = this.props.questionnaireData.sections[i].questions[
            //         j
            //       ].isRequired;
            //       multiAnswers = this.props.questionnaireData.sections[i]
            //         .questions[j].isMultiple;
            //       cascading = this.props.questionnaireData.sections[i]
            //         .questions[j].isCascading;
            //     }
            //   }
            // }

            var checkIsHtml = this.isHTML(
              values.sections[i].questions[j].question
            );

            if (checkIsHtml) {
              questionsList.push({
                question: values.sections[i].questions[j].question
                  ? values.sections[i].questions[j].question
                  : values.sections[i].questions[j].question.toHTML() || " ",
                answerType: 0,
                position: 0,
                isRequired: false,
                isCascading: false,
                isMultiple: false,
                mapped: "",
                mappedProperty: "",
                mappedText: "",
                createdBy: userId,
                questionOptions: [],
              });
            } else {
              if (values.sections[i].questions[j].mapped) {
                if (values.sections[i].questions[j].mapped.includes('"id":')) {
                  mappParent = JSON.parse(
                    values.sections[i].questions[j].mapped
                  );
                } else {
                  mappParent = values.sections[i].questions[j].mapped;
                }
              } else {
                mappParent = "";
              }
              if (values.sections[i].questions[j].mappedProperty) {
                if (
                  values.sections[i].questions[j].mappedProperty.includes(
                    '"id":'
                  )
                ) {
                  property = JSON.parse(
                    values.sections[i].questions[j].mappedProperty
                  );
                } else {
                  property = values.sections[i].questions[j].mappedProperty;
                }
              } else {
                property = "";
              }

              questionsList.push({
                id: values.sections[i].questions[j].id || 0,
                questionnaireId:
                  values.sections[i].questions[j].questionnaireId || 0,
                sectionId: values.sections[i].questions[j].sectionId || 0,
                question: values.sections[i].questions[j].question || " ",
                answerType: parseInt(
                  values.sections[i].questions[j].answerType
                    ? values.sections[i].questions[j].answerType
                    : values.sections[i].questions[j].answerType === 0
                    ? values.sections[i].questions[j].answerType
                    : 1
                ),
                position: 0,
                isRequired: values.sections[i].questions[j].isRequired || false,
                isCascading:
                  values.sections[i].questions[j].isCascading || false,
                isMultiple: values.sections[i].questions[j].isMultiple || false,
                mapped:
                  mappParent && mappParent.name ? mappParent.name : mappParent,
                mappedProperty:
                  property && property.name
                    ? property.name
                    : property && property.friendlyName
                    ? property.friendlyName
                    : property,
                mappedText: "",
                createdBy: userId,
                questionOptions: optionValuesList,
              });
            }

            cascading = false;
            required = false;
            multiAnswers = false;
            optionValuesList = [];
          }
        }

        sectionList.push({
          id: values.sections[i].id,
          questionnaireId: values.sections[i].questionnaireId,
          name: values.sections[i].name,
          allowMultiple: values.sections[i].allowMultiple || false,
          position: 0,
          questions: questionsList,
        });
        questionsList = [];
      }
    }

    var allowAttachments = false;
    var deceleration = false;

    if (values.questionnaireOptions && values.questionnaireOptions.length > 0) {
      for (var i = 0; i < values.questionnaireOptions.length; i++) {
        if (values.questionnaireOptions[i] === "allowAttachments") {
          allowAttachments = true;
        } else {
          deceleration = true;
        }
      }
    }

    let data = {
      questionnaireId: this.props.questionnaireData.questionnaireId,
      name: values.name,
      allowAttachments: values.allowAttachments || false,
      deceleration: values.deceleration || false,
      showInPotential: this.props.questionnaireData.showInPotential,
      showInClient: this.props.questionnaireData.showInClient,
      showInPublic: this.props.questionnaireData.showInPublic,
      questionnaireEmail: this.props.questionnaireData.questionnaireEmail,
      declarationText: this.props.questionnaireData.declarationText,
      clientId: this.props.questionnaireData.clientId,
      parentQuestionnaireId: this.props.questionnaireData.parentQuestionnaireId,
      isPotential: this.props.questionnaireData.isPotential,
      sections: sectionList,
    };
    if (JSON.stringify(data) === JSON.stringify(this.props.questionnaireData)) {
      console.log("duplicate");
    }
    console.log(JSON.stringify(data));

    this.props
      .onUpdateQuestionnaire(data)
      .then((res) => {
        if (this.state.clickedButton === "update") {
          this.setState({ loading: false });
          message.success("Questionnaire updated successfully");
          // this.props.onGetQuestionnaire(
          //   this.props.questionnaireData.questionnaireId
          // );
        } else {
          this.setState({ previewLoading: false, loading: false });
          message.success("Questionnaire updated successfully");
          // this.props.onGetQuestionnaire(
          //   this.props.questionnaireData.questionnaireId
          // );
          window.open(
            "questionnaire-form?id=" +
              this.props.questionnaireData.questionnaireId,
            "_blank"
          );
        }
      })
      .catch((err) => {
        message.error("Update questionnaire failed");
        this.setState({ loading: false, previewLoading: false });
      });
  };

  render() {
    const { questionnaireDataUpd, getLoading, optionsArray } = this.state;
    const {
      questionnaireData,
      mappParentData,
      mappChildData,
      onGetMappingChild,
      questionnaireId,
    } = this.props;

    return (
      <div>
        <Spin spinning={getLoading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div
                className={questionnaireId ? "" : "sus-container"}
                style={{ paddingBottom: 80 }}
              >
                <div style={{ marginTop: 20 }}>
                  {!questionnaireId && (
                    <div className="heading-container">
                      <span className="heading-text">CUSTOM QUESTIONNAIRE</span>
                    </div>
                  )}

                  <Spin size="large" spinning={getLoading}>
                    {!questionnaireId && (
                      <div className="title-container">
                        <span className="heading-text">
                          {questionnaireDataUpd && questionnaireDataUpd.name}
                        </span>
                      </div>
                    )}

                    {questionnaireDataUpd && (
                      <Form
                        onFinish={this.onFinish}
                        initialValues={questionnaireDataUpd}
                        ref={this.formRef}
                      >
                        <div className="cq-container">
                          <div
                            className="cq-form-cont"
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              marginTop: 0,
                              paddingLeft: 30,
                              paddingRight: 30,
                              paddingBottom: 30,
                            }}
                          >
                            <div
                              className="form-cont"
                              style={{
                                justifyContent: "space-between",
                                paddingTop: 20,
                                paddingBottom: 0,
                                display: "block",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 15,
                                }}
                              >
                                <div style={{ width: "100%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p className="cq-label-text">Name*</p>
                                    <div
                                      className="profile-input-border"
                                      style={{ width: "60%" }}
                                    >
                                      <Form.Item
                                        name="name"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required!",
                                          },
                                        ]}
                                      >
                                        <Input
                                          className="profile-input"
                                          placeholder="QUESTIONNAIRE NAME"
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div style={{ marginTop: 15 }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: 20,
                                        width: "68%",
                                      }}
                                    >
                                      <div className="sus-checkbox-cont">
                                        <Form.Item
                                          name="allowAttachments"
                                          valuePropName="checked"
                                        >
                                          <Checkbox className="sus-checkbox-label">
                                            Allow Attachments
                                          </Checkbox>
                                        </Form.Item>
                                      </div>
                                      <div className="sus-checkbox-cont">
                                        <Form.Item
                                          name="deceleration"
                                          valuePropName="checked"
                                        >
                                          <Checkbox className="sus-checkbox-label">
                                            Add Declaration
                                          </Checkbox>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Form.List
                            name="sections"
                            // rules={[
                            //   {
                            //     validator: async (_, names) => {
                            //       if (!names || names.length < 2) {
                            //         return Promise.reject(new Error('At least 2 passengers'));
                            //       }
                            //     },
                            //   },
                            // ]}
                          >
                            {(sections, { add, remove, move }, { errors }) => (
                              <>
                                <div
                                  className="cq-form-cont"
                                  style={{ marginTop: -30 }}
                                >
                                  <Form.Item>
                                    <Button
                                      onClick={() => add()}
                                      type="dashed"
                                      className="cq-primary-btn"
                                      style={{ marginTop: 20 }}
                                    >
                                      <span className="cq-btn-text">
                                        Add Section
                                      </span>
                                    </Button>
                                  </Form.Item>
                                </div>
                                {sections.map((section, index) => {
                                  console.log(section);
                                  return (
                                    <Form.Item
                                      required={false}
                                      key={section.key}
                                    >
                                      <UpdateSectionCard
                                        section={section}
                                        sectionData={
                                          questionnaireData.sections.length >
                                          section.key
                                            ? questionnaireData.sections[
                                                section.key
                                              ]
                                            : null
                                        }
                                        sectionIndex={index}
                                        sectionMove={move}
                                        addOptionValues={this.makeOptionsArray}
                                        addCheckboxValues={
                                          this.makeOptionsArray
                                        }
                                        addDropdownValues={
                                          this.makeOptionsArray
                                        }
                                        sectionAdd={() => add()}
                                        sectionRemove={remove}
                                        addSectionOptions={
                                          this.addedOptionSection
                                        }
                                        mappParentData={mappParentData}
                                        mappChildData={mappChildData}
                                        getMappChild={onGetMappingChild}
                                        // sectionData={
                                        //   questionnaireData.sections[index]
                                        // }
                                        optionsArray={optionsArray}
                                        optionsSectionArray={
                                          this.state.multiSections
                                        }
                                        removeOptionSection={
                                          this.removeOptionSection
                                        }
                                        formRef={this.formRef}
                                      />
                                    </Form.Item>
                                  );
                                })}
                              </>
                            )}
                          </Form.List>
                          {/*<div style={{ display: "flex" }}>
                      <Form.Item>
                        <Button
                          loading={this.state.loading}
                          type="primary"
                          name="save"
                          className="cq-primary-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          SAVE
                        </Button>
                        <Button
                          loading={this.state.loading}
                          htmlType="submit"
                          type="primary"
                          name="preview"
                          className="cq-primary-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          PREVIEW
                        </Button>
                          <Button
                            loading={this.state.loading}
                            type="primary"
                            className="cq-primary-btn"
                            style={{ marginTop: 20 }}
                          >
                            CLOSE
                          </Button>
                        </Form.Item>
                    </div>*/}
                          <Form.Item>
                            <div style={{ display: "flex" }}>
                              <Button
                                loading={this.state.loading}
                                onClick={() =>
                                  this.setState({ clickedButton: "update" })
                                }
                                type="primary"
                                htmlType="submit"
                                name="save"
                                className="cq-save-btn"
                                style={{ marginTop: 20, marginRight: 10 }}
                              >
                                UPDATE
                              </Button>
                              <Button
                                loading={this.state.previewLoading}
                                onClick={() =>
                                  this.setState({ clickedButton: "preview" })
                                }
                                htmlType="submit"
                                type="primary"
                                name="preview"
                                className="cq-save-btn"
                                style={{ marginTop: 20, marginRight: 10 }}
                              >
                                PREVIEW
                              </Button>
                              <Button
                                type="primary"
                                className="cq-close-btn"
                                style={{ marginTop: 20 }}
                              >
                                CLOSE
                              </Button>
                            </div>
                          </Form.Item>
                        </div>
                      </Form>
                    )}
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default UpdateQuestionnaire;
