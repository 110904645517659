import { api } from "../../services/api";
import * as types from "../Constants";

export const getEmployerManag = (id) => {
  const options = {
    url: `v1/employer/All`,
  };

  options.types = [
    types.GET_EMPLOYER_MANAG_SUCCESS,
    types.GET_EMPLOYER_MANAG_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerManag = (data) => {
  var empData = {
    clientId: "00000000-0000-0000-0000-000000000000",
    name: data.name || "",
    email: data.email || "",
    contactNo: data.contactNo || "",
    business: data.business || "",
    city: data.city || "",
    contactPerson: data.contactPerson || "",
    mobile: data.mobile || "",
    website: data.website || "",
    jobSector: data.jobSector || "",
    nzbn: data.nzbn || "",
    occupation: data.occupation || "",
    numberOfEmployees: data.numberOfEmployees || "",
    yearsOfBusiness: data.yearsOfBusiness || "",
    isPotential: data.isPEmp,
    employerType: data.employerType || "Employer",
    address: data.address || "",
    agreementUrl: data.agreementURL || "",
    agreementFileName: data.agreementFileName || "",
    xeroID: "00000000-0000-0000-0000-000000000000",
    jobStatus: 0,
    accredationStartDate: data.accredationStartDate || "1900-01-01T00:00:00+00",
    accredationExpiryDate:
      data.accredationExpiryDate || "1900-01-01T00:00:00+00",
    countryCodeId: data.countryCodeId || 64,
    isActive: data.isActive || true,
  };
  const options = {
    url: "v1/employer",
  };

  options.types = [
    types.ADD_EMPLOYER_MANAG_SUCCESS,
    types.ADD_EMPLOYER_MANAG_FAILURE,
  ];

  return api.post(options, empData);
};

export const updateEmployerManag = (data) => {
  const options = {
    url: "v1/employer",
  };

  options.types = [
    types.UPDATE_EMPLOYER_MANAG_SUCCESS,
    types.UPDATE_EMPLOYER_MANAG_FAILURE,
  ];

  return api.put(options, data);
};

export const deleteEmployerManag = (data) => {
  const options = {
    url: "v1/employer",
  };

  options.types = [
    types.DELETE_EMPLOYER_MANAG_SUCCESS,
    types.DELETE_EMPLOYER_MANAG_FAILURE,
  ];

  return api.delete(options, data);
};

export const getEmployerContact = (id) => {
  const options = {
    url: `v1/employer/contact/All/${id}`,
  };

  options.types = [
    types.GET_EMPLOYER_CONTACTS_SUCCESS,
    types.GET_EMPLOYER_CONTACTS_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerContact = (data) => {
  const options = {
    url: "v1/employer/contact",
  };

  options.types = [
    types.ADD_EMPLOYER_CONTACTS_SUCCESS,
    types.ADD_EMPLOYER_CONTACTS_FAILURE,
  ];

  return api.post(options, data);
};

export const updateEmployerContact = (data) => {
  const options = {
    url: "v1/employer/contact",
  };

  options.types = [
    types.UPDATE_EMPLOYER_CONTACTS_SUCCESS,
    types.UPDATE_EMPLOYER_CONTACTS_FAILURE,
  ];

  return api.put(options, data);
};

export const removeEmployerContact = (data) => {
  const options = {
    url: "v1/employer/contact",
  };

  options.types = [
    types.DELETE_EMPLOYER_CONTACT_SUCCESS,
    types.DELETE_EMPLOYER_CONTACT_FAILURE,
  ];

  return api.delete(options, data);
};

export const getEmployerJob = (id) => {
  const options = {
    url: `v1/employer/job/All/${id}`,
  };

  options.types = [
    types.GET_EMPLOYER_JOB_SUCCESS,
    types.GET_EMPLOYER_JOB_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerJob = (data) => {
  const options = {
    url: "v1/employer/job",
  };

  options.types = [
    types.ADD_EMPLOYER_JOB_SUCCESS,
    types.ADD_EMPLOYER_JOB_FAILURE,
  ];

  return api.post(options, data);
};

export const updateEmployerJob = (data) => {
  const options = {
    url: "v1/employer/job",
  };

  options.types = [
    types.UPDATE_EMPLOYER_JOB_SUCCESS,
    types.UPDATE_EMPLOYER_JOB_FAILURE,
  ];

  return api.put(options, data);
};

export const removeEmployerJob = (data) => {
  const options = {
    url: "v1/employer/job",
  };

  options.types = [
    types.DELETE_EMPLOYER_JOB_SUCCESS,
    types.DELETE_EMPLOYER_JOB_FAILURE,
  ];

  return api.delete(options, data);
};

// FILE NOTES

export const getEmployerFile = (id) => {
  const options = {
    url: `v1/employer/filenote/All/${id}`,
  };

  options.types = [
    types.GET_EMPLOYER_FILE_SUCCESS,
    types.GET_EMPLOYER_FILE_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerFile = (data) => {
  const options = {
    url: "v1/employer/filenote",
  };

  options.types = [
    types.ADD_EMPLOYER_FILE_SUCCESS,
    types.ADD_EMPLOYER_FILE_FAILURE,
  ];

  return api.post(options, data);
};

export const updateEmployerFile = (data) => {
  const options = {
    url: "v1/employer/filenote",
  };

  options.types = [
    types.UPDATE_EMPLOYER_FILE_SUCCESS,
    types.UPDATE_EMPLOYER_FILE_FAILURE,
  ];

  return api.put(options, data);
};

export const removeEmployerFile = (data) => {
  const options = {
    url: "v1/employer/filenote",
  };

  options.types = [
    types.DELETE_EMPLOYER_FILE_SUCCESS,
    types.DELETE_EMPLOYER_FILE_FAILURE,
  ];

  return api.delete(options, data);
};

export const searchEmployer = (data) => {
  const options = {
    url: "v1/employer/SearchEmployers",
  };

  options.types = [
    types.ADD_EMPLOYER_SEARCH_SUCCESS,
    types.ADD_EMPLOYER_SEARCH_FAILURE,
  ];

  return api.post(options, data);
};

export const getEmployerData = (id) => {
  const options = {
    url: `v1/employer/${id}`,
  };

  options.types = [
    types.GET_SINGLE_EMPLOYER_SUCCESS,
    types.GET_SINGLE_EMPLOYER_FAILURE,
  ];

  return api.get(options);
};

export const getEmployerDocument = (data) => {
  var url = "";
  if (data.IsVisa) {
    url = `v1/document/AllByVisa/${data.id}/${data.docType}/${data.CaseId}`;
  } else {
    url = `v1/document/AllByType/${data.id}/${data.docType}`;
  }
  const options = {
    url: url,
  };

  options.types = [
    types.GET_DOCUMENT_EMPLOYER_SUCCESS,
    types.GET_DOCUMENT_EMPLOYER_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerDocument = (data) => {
  const options = {
    url: "v1/document",
  };

  options.types = [
    types.ADD_DOCUMENT_EMPLOYER_SUCCESS,
    types.ADD_DOCUMENT_EMPLOYER_FAILURE,
  ];

  return api.post(options, data);
};

export const removeEmployerDocument = (data) => {
  const options = {
    url: "v1/document",
  };

  options.types = [
    types.DELETE_DOCUMENT_EMPLOYER_SUCCESS,
    types.DELETE_DOCUMENT_EMPLOYER_FAILURE,
  ];

  return api.delete(options, data);
};

export const updateEmployerDocument = (data) => {
  const options = {
    url: "v1/document/UpdateDocumentTitle",
  };

  options.types = [
    types.UPDATE_DOCUMENT_EMPLOYER_SUCCESS,
    types.UPDATE_DOCUMENT_EMPLOYER_FAILURE,
  ];

  return api.post(options, data);
};

export const getEmployerCheckList = (id) => {
  let nId = id || "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/document/checklist/All/${nId}`,
  };

  options.types = [
    types.GET_EMPLOYER_CHECKLIST_SUCCESS,
    types.GET_EMPLOYER_CHECKLIST_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerCheckList = (data) => {
  const options = {
    url: "v1/document/checklist",
  };

  options.types = [
    types.ADD_EMPLOYER_CHECKLIST_SUCCESS,
    types.ADD_EMPLOYER_CHECKLIST_FAILURE,
  ];

  return api.post(options, data);
};

export const getEmployerList = (id) => {
  const options = {
    url: `v1/document/checklist/${id}`,
  };

  options.types = [
    types.GET_EMPLOYER_LIST_SUCCESS,
    types.GET_EMPLOYER_LIST_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerEmail = (data) => {
  const options = {
    url: "v1/imap/EmployerEmail",
  };

  options.types = [
    types.ADD_EMPLOYER_EMAIL_SUCCESS,
    types.ADD_EMPLOYER_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const getEmployerHistory = (
  employerid,
  familyid,
  EmailType,
  PageSize,
  PageNumber,
  VisaId
) => {
  const options = {
    url: `v1/imap/EmployerEmailHistory/${employerid}/${familyid}/${EmailType}/${PageSize}/${PageNumber}/${VisaId}`,
  };

  options.types = [
    types.GET_EMPLOYER_HISTORY_SUCCESS,
    types.GET_EMPLOYER_HISTORY_FAILURE,
  ];

  return api.get(options);
};

export const getAutoEmailImport = (EmployerId) => {
  const options = {
    url: `v1/emailimport/EmployerImportSettings/${EmployerId}`,
  };

  options.types = [
    types.GET_EMAIL_IMPORT_SUCCESS,
    types.GET_EMAIL_IMPORT_FAILURE,
  ];

  return api.get(options);
};

export const addAutoEmailImport = (data) => {
  const options = {
    url: "v1/emailimport/EmployerImportSettings",
  };

  options.types = [
    types.ADD_EMAIL_IMPORT_SUCCESS,
    types.ADD_EMAIL_IMPORT_FAILURE,
  ];

  if (data.employerImportSettingsId == 0) return api.post(options, data);
  else return api.put(options, data);
};

export const addDocumentTittle = (data) => {
  const options = {
    url: "v1/document/UpdateDocumentTitle",
  };

  options.types = [
    types.ADD_DOCUMENT_TITTLE_SUCCESS,
    types.ADD_DOCUMENT_TITTLE_FAILURE,
  ];

  return api.post(options, data);
};

export const getClientFile = (id) => {
  const options = {
    url: `v1/client/filenote/All/${id}`,
  };

  options.types = [
    types.GET_CFILE_NOTES_SUCCESS,
    types.GET_CFILE_NOTES_FAILURE,
  ];

  return api.get(options);
};

export const addClientFile = (data) => {
  const options = {
    url: "v1/client/filenote",
  };

  options.types = [
    types.ADD_CFILE_NOTES_SUCCESS,
    types.ADD_CFILE_NOTES_FAILURE,
  ];

  return api.post(options, data);
};

export const updateClientFile = (data) => {
  const options = {
    url: "v1/client/filenote",
  };

  options.types = [
    types.UPDATE_CFILE_NOTES_SUCCESS,
    types.UPDATE_CFILE_NOTES_FAILURE,
  ];

  return api.put(options, data);
};

export const deleteClientFile = (data) => {
  const options = {
    url: "v1/client/filenote",
  };

  options.types = [
    types.DELETE_CFILE_NOTES_SUCCESS,
    types.DELETE_CFILE_NOTES_FAILURE,
  ];

  return api.delete(options, data);
};

export const closeAndOpenSidebar = (value) => {
  const data = {
    type: types.SIDEBAR_ACTION_OPEN_AND_CLOSE,
    payload: value,
  };

  return data;
};

export const addAnyTamplate = (data) => {
  const options = {
    url: "v1/HtmlTemplate/SetAnyTemplate",
  };

  options.types = [
    types.ADD_ANY_TEMPLATE_SUCCESS,
    types.ADD_ANY_TEMPLATE_FAILURE,
  ];

  return api.post(options, data);
};

export const uploadFile = (data) => {
  const options = {
    url: "v1/document/Upload",
  };

  options.types = [
    types.ADD_UPLOAD_FILE_SUCCESS,
    types.ADD_UPLOAD_FILE_FAILURE,
  ];

  return api.post(options, data);
};

export const setActiveKey = (value) => {
  const data = {
    type: types.SET_ACTIVE_KEY_SUCCESS,
    payload: value,
  };

  return data;
};

export const setClientTab = (value) => {
  const data = {
    type: types.SET_CLIENT_TAB_SUCCESS,
    payload: value,
  };

  return data;
};

export const deleteCreateEmail = (data) => {
  const options = {
    url: `v1/imap/EmployerEmail`,
  };

  options.types = [
    types.DELETE_CREATE_EMAIL_SUCCESS,
    types.DELETE_CREATE_EMAIL_FAILURE,
  ];

  return api.delete(options, data);
};

export const getClientSearch = (SearchQuery) => {
  const options = {
    url: `v1/client/SearchClientMain/${SearchQuery}`,
  };

  options.types = [
    types.GET_CLIENT_SEARCH_SUCCESS,
    types.GET_CLIENT_SEARCH_FAILURE,
  ];

  return api.get(options);
};

export const getClientEmp = (id) => {
  const options = {
    url: `v1/clientemployer/All/${id}`,
  };

  options.types = [types.GET_CLIENT_EMP_SUCCESS, types.GET_CLIENT_EMP_FAILURE];

  return api.get(options);
};

export const getClientByEmpId = (data) => {
  const options = {
    url: "v1/client/GetAllClientsByEmpliyerId",
  };

  options.types = [types.ADD_CLIENT_EMP_SUCCESS, types.ADD_CLIENT_EMP_FAILURE];

  return api.post(options, data);
};

export const linkEmpWithClient = (data) => {
  const options = {
    url: "v1/clientemployer/LinkEmployers",
  };

  options.types = [
    types.LINK_EMP_CLIENT_SUCCESS,
    types.LINK_EMP_CLIENT_FAILURE,
  ];

  return api.post(options, data);
};

export const addDocWithName = (data) => {
  const options = {
    url: "v1/document/UploadWithFileName",
  };
  options.types = [
    types.ADD_DOCUMENT_WITHNAME_SUCCESS,
    types.ADD_DOCUMENT_WITHNAME_FAILURE,
  ];

  return api.post(options, data);
};

export const getClientJobHis = (id) => {
  const options = {
    url: `v1/client/jobhistory/All/${id}`,
  };

  options.types = [
    types.GET_CLIENT_JOB_HISTORY_SUCCESS,
    types.GET_CLIENT_JOB_HISTORY_FAILURE,
  ];

  return api.get(options);
};

export const getSingleClientHis = (data) => {
  const options = {
    url: "v1/client/GetAllClientsByJobId",
  };
  options.types = [
    types.ADD_SINGLE_JOBHIS_SUCCESS,
    types.ADD_SINGLE_JOBHIS_FAILURE,
  ];

  return api.post(options, data);
};

export const movePotentialEmployer = (data) => {
  const options = {
    url: "v1/employer/MoveToEmployer",
  };

  options.types = [
    types.UPDATE_POTENTIAL_EMPLOYER_SUCCESS,
    types.UPDATE_POTENTIAL_EMPLOYER_FAILURE,
  ];

  return api.put(options, data);
};

export const getEmployerCase = (data) => {
  const options = {
    url: "v1/subject/case/All/Subject/" + data,
  };

  options.types = [
    types.GET_EMPLOYER_CASE_SUCCESS,
    types.GET_EMPLOYER_CASE_FAILURE,
  ];

  return api.get(options);
};
