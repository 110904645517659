import React from "react";
import "./SuperUserSettingStyles.css";

import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import { message, Button, Radio, Col, Row, Table, Spin, Modal } from "antd";
import { CompactPicker } from "react-color";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import {
  DeleteOutlined,
  LeftCircleFilled,
  MenuOutlined,
} from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  SortStart,
} from "react-sortable-hoc";
import arrayMove from "array-move";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class VisaStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      name: "",
      position: 1,
      color: "",
      loading: false,
      id: "",
      isUpdate: false,
      visaStatusHideData: [],
      countryName: "NEW ZEALAND",
      visibleModal: false,
      isHide: false,
    };

    this.props.onGetVisaStatusWithHide().then((res) => {
      // if (res.payload.items.length > 0) {
      //   this.setState({ position: res.payload.items.length + 1 });
      // } else {
      //   this.setState({ position: 1 });
      // }
    });
    this.props.onGetCountries();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let filterList = [];
    if (
      nextProps &&
      nextProps.visaStatusHideData &&
      nextProps.visaStatusHideData.items &&
      nextProps.visaStatusHideData.items.length > 0
    ) {
      nextProps &&
        nextProps.visaStatusHideData &&
        nextProps.visaStatusHideData.items &&
        nextProps.visaStatusHideData.items &&
        nextProps.visaStatusHideData.items.map((data, index) => {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        });

      this.setState({ visaStatusHideData: filterList, loading: false });
    } else {
      this.setState({ visaStatusHideData: [], loading: false });
    }
  }

  componentDidUpdate(PrevProps) {
    // if(PrevProps.visaStatusHideData && PrevProps.visaStatusHideData !== this.props.visaStatusHideData) {
    //   if(this.props.visaStatusHideData.items.length > 0) {
    //     this.setState({ position: this.Props.visaStatusHideData.items.length + 1})
    //   }
    // }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (e) => {
    this.setState({
      countryName: e.target.value,
    });
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      name: "",
      position: null,
      color: "",
    });
  };

  Columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      render: (text, record) => {
        return record.color;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <>
            <div className="table-action">
              {!record.isReadOnly && (
                <img
                  src={Images.editBorderBlue}
                  style={{ width: 15, marginRight: 10, cursor: "pointer" }}
                  onClick={() => this.openEditModal(record)}
                />
              )}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    visibleModal: true,
                    TypeId: record.id,
                    isHide: record.isHide,
                  });
                }}
              >
                <span style={{ color: "#0E65FC" }}>
                  {record.isHide ? "Unhide" : "Hide"}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  openEditModal = (item) => {
    this.setState({
      id: item.id,
      name: item.name,
      color: item.color,
      position: item.position,
      isUpdate: true,
      // countryName: item.countryName,
    });
    this.openModal();
  };

  updateVisaStatus = () => {
    var countryId = "";
    if (this.state.name.length < 3) {
      message.warning("Visa status name should be more than three characters!");
      return;
    }
    this.setState({ loading: true });
    var findCountryId =
      this.props.countriesData &&
      this.props.countriesData.items.find(
        (obj) => obj.name === this.state.countryName
      );
    if (findCountryId) {
      countryId = findCountryId.id;
    }

    this.setState({ loading: true });
    let data = {
      id: this.state.id,
      name: this.state.name,
      position: this.state.position,
      color: this.state.color,
      isAusi: false,
      isApproved: true,
      countryId: 0,
    };
    this.props
      .onUpdVisaStatus(data)
      .then(() => {
        this.setState({
          loading: false,
          name: "",
          chineseName: "",
          isUpdate: false,
        });
        this.closeModal();
        message.success("Visa Status updated successfully");
        this.props.onGetVisaStatusWithHide().then((res) => {
          if (res.payload.items.length > 0)
            this.setState({ position: res.payload.items.length + 1 });
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Update visa status failed!");
      });
  };

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ loading: true }, () => {
      if (oldIndex !== newIndex) {
        const newData = arrayMove(
          [].concat(this.state.visaStatusHideData),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        console.log("Sorted items: ", newData);

        this.setState({}, () => {
          let data = {
            id: this.state.visaStatusHideData[oldIndex].id,
            oldOrder: oldIndex,
            newOrder: newIndex,
          };

          this.props
            .VisaStatusReOrder(data)
            .then(() => {
              this.props.onGetVisaStatusWithHide().then(() => {});
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index =
      this.state.visaStatusHideData &&
      this.state.visaStatusHideData.findIndex(
        (x) => x.index === restProps["data-row-key"]
      );
    return <SortableItem index={index} {...restProps} />;
  };

  DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      rowSelection
      helperClass="row-dragging"
      SortStart={() => {
        this.setState({ loading: false });
      }}
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  onSubmit = () => {
    // var countryId = "";
    if (this.state.name.length < 3) {
      message.warning("Visa status name should be more than three characters!");
      return;
    }
    this.setState({ loading: true });
    // var findCountryId =
    //   this.props.countriesData &&
    //   this.props.countriesData.items.find(
    //     (obj) => obj.name === this.state.countryName
    //   );
    // if (findCountryId) {
    //   countryId = findCountryId.id;
    // }

    this.setState({ loading: true });
    let data = {
      name: this.state.name,
      color: this.state.color,
      position: parseInt(this.state.position),
      isApproved: true,
      isAusi: false,
      countryId: 0,
    };

    console.log("show data of status", data);

    this.props
      .onAddVisaStatus(data)
      .then((res) => {
        if (
          this.props.addVisaStatusSuccess ==
          "Position Id is taken, please select another one."
        ) {
          this.setState({ loading: false });
          message.error(this.props.addVisaStatusSuccess);
        } else {
          this.closeModal();
          message.success("Visa status added successfully");
          this.props.onGetVisaStatusWithHide().then((res) => {
            if (res.payload.items.length > 0)
              this.setState({ position: res.payload.items.length + 1 });
          });
          this.setState({
            name: "",
            position: null,
            color: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleCancelVisibleModal = () => {
    this.setState({ visibleModal: false });
  };

  render() {
    const { name, position, color } = this.state;
    const { visaStatusHideData, onSetActiveInnerTab } = this.props;
    console.log("show visa status", visaStatusHideData);
    return (
      <Spin spinning={this.state.loading}>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div>
              <div style={{ paddingRight: 45 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="sus-bottom-btn-cont"
                    style={{ paddingTop: 0, marginLeft: 20 }}
                  >
                    <div className="sus-bottom-icon-cont">
                      <img
                        src={Images.visaStatus}
                        className="sus-bottom-icon"
                      />
                    </div>
                    <span className="sus-bottom-text">VISA STATUSES</span>
                  </div>
                  <LeftCircleFilled
                    onClick={() => onSetActiveInnerTab("")}
                    className="ac-back-icon"
                  />
                </div>

                <div
                  class="sus-form-container"
                  style={{
                    width: "100%",
                    minHeight: 130,
                    paddingLeft: 55,
                    paddingRight: 55,
                    border: 1,
                    borderStyle: "solid",
                    borderColor: "#D9D9D9",
                  }}
                >
                  <div
                    className="sus-add-cont-row"
                    style={{ marginTop: 10, marginRight: -40 }}
                  >
                    <div></div>
                    <div onClick={this.openModal} style={{ cursor: "pointer" }}>
                      <img src={Images.plusIcon} style={{ width: 20 }} />
                    </div>
                  </div>
                  <Table
                    pagination={false}
                    dataSource={
                      this.state &&
                      this.state.visaStatusHideData &&
                      this.state.visaStatusHideData
                        ? this.state.visaStatusHideData
                        : []
                    }
                    columns={this.Columns}
                    width="100"
                    rowKey="index"
                    components={{
                      body: {
                        wrapper: this.DraggableContainer,
                        row: this.DraggableBodyRow,
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <Modal
              visible={this.state.visible}
              width={500}
              height={390}
              effect="fadeInUp"
              // onClickAway={() => this.closeModal()}
              footer={null}
              onCancel={this.closeModal}
            >
              <div style={{ padding: 20 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="sus-modal-label">
                    <span className="sus-modal-label-text">VISA STATUS</span>
                  </div>
                  {/* <div onClick={this.closeModal} style={{ cursor: "pointer" }}>
                    <img src={Images.crossRed} style={{ width: 20 }} />
                  </div> */}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    class="medical-label"
                    style={{ fontSize: 11, marginLeft: 15 }}
                  >
                    Name:
                  </p>
                  <div class="profile-input-border" style={{ width: "60%" }}>
                    <input
                      className="profile-input"
                      placeholder=""
                      type="text"
                      value={name}
                      onChange={(e) => this.myChangeHandler("name", e)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 15,
                  }}
                >
                  <p
                    class="medical-label"
                    style={{ fontSize: 11, marginLeft: 15 }}
                  >
                    Color:
                  </p>
                  <div class="profile-input-border" style={{ width: "60%" }}>
                    <input
                      className="profile-input"
                      placeholder=""
                      type="text"
                      value={color}
                      onChange={(e) => this.myChangeHandler("color", e)}
                    />
                  </div>
                </div>
                <div className="color-picker-container">
                  <CompactPicker
                    color={color}
                    onChangeComplete={this.handleChangeComplete}
                  />
                </div>

                {/* <Radio.Group
                  style={{ marginTop: 20 }}
                  onChange={this.onChange}
                  value={this.state.countryName}
                >
                  <Radio value={"AUSTRALIA"}>Australia</Radio>
                  <Radio value={"CANADA"} style={{ marginLeft: 50 }}>
                    Canada
                  </Radio>
                  <Radio value={"NEW ZEALAND"} style={{ marginLeft: 50 }}>
                    New Zealand
                  </Radio>
                </Radio.Group> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div></div>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={this.closeModal}
                      type="primary"
                      className="sus-add-btn"
                    >
                      CLOSE
                    </Button>
                    <Button
                      onClick={
                        this.state.isUpdate
                          ? () => this.updateVisaStatus()
                          : this.onSubmit
                      }
                      loading={this.state.loading}
                      type="primary"
                      className="sus-add-btn"
                      style={{ marginLeft: 10 }}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              title="Confirmation"
              visible={this.state.visibleModal}
              onCancel={this.handleCancelVisibleModal}
              height={150}
              width={360}
              footer={null}
              maskClosable={false}
            >
              <Row>
                <Col span={24}>
                  <Row>
                    <span
                      style={{
                        fontSize: 12,
                        marginTop: "4vh",
                        marginLeft: "2vw",
                      }}
                    >
                      {`Are you sure, you want to ${
                        this.state.isHide ? "unhide" : "hide"
                      }?`}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: "7vh" }}>
                <Col span={6} offset={13} style={{ marginRight: "10px" }}>
                  <Button
                    onClick={() => {
                      this.handleCancelVisibleModal();
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    className={"button button-blue"}
                    onClick={() => {
                      let data = {
                        id: this.state.TypeId,
                        isHide: !this.state.isHide,
                      };
                      this.props.onHideUnhideVisaStatus(data).then(() => {
                        message.success("Changes are updated successfully!");
                        this.props.onGetVisaStatusWithHide().then(() => {
                          this.setState({ visibleModal: false });
                        });
                      });
                    }}
                  >
                    OK
                  </Button>
                </Col>
              </Row>
            </Modal>
          </div>
        </div>
      </Spin>
    );
  }
}

export default VisaStatus;
