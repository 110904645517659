import React from "react";

import { Select, DatePicker, message, Spin } from "antd";
import { Link } from "react-router-dom";

import "./ReportsStyles.css";

import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ComponentToPrint from "../Components/Reports/ClientSourceReportPrint";
import SaleHistoryReport from "../Components/Reports/SaleHistoryReport";
import ProcessingPersonReport from "../Components/Reports/ProcessingPersonReport";
import TaskPerformanceReport from "../Components/Reports/TaskPerformanceReport";
import BirthdayReport from "../Components/Reports/BirthdayReport";
import FileNotesReport from "../Components/Reports/FileNotesReport";
import ActiveCasesReport from "../Components/Reports/ActiveCasesReport";
import VisaExpiryReport from "../Components/Reports/VisaExpiryReport";
import ClientEmployerReport from "../Components/Reports/ClientEmployerReport";
import DocChecklistReport from "../Components/Reports/DocCheckListReport";
import ServiceMngmntReport from "../Components/Reports/ServiceMngmntReport";

const headOption = [
  { tabName: "Reports", linkName: "/reports" },
  { tabName: "Visa Reports", linkName: "/visa-report" },
  // // { tabName: "Case Management", linkName: "/" },
  // { tabName: "Potential Clients", linkName: "/potential-clients-report" },
  { tabName: "Time Tracking", linkName: "/time-tracking-report" },
  // { tabName: "Employer Report", linkName: "/employer-reports" },
];

const { Option } = Select;
let searchClient = "";

const columns = [
  {
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      if (index == 0) {
        return <a>{text}</a>;
      }
      return {
        children: <a>{text}</a>,
        props: {
          colSpan: 6,
        },
      };
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Invoiced Amount",
    dataIndex: "total",
    key: "invoicedAmount",
  },

  {
    title: "Received Amount",
    dataIndex: "received",
    key: "receivedAmount",
  },
];

var totalInvoiced = 0;
var totalReceived = 0;

const dateFormat = "DD/MM/YYYY";

var notSearch = [];

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMainTab: "",
      activeTab: "",
      extend: false,
      dateFrom: "",
      dateTo: "",
      processingPerson: [],
      loadReports: false,
      pageNumber: 1,
      pageSize: 20,
      processingIds: "",
      showClientField: false,
      clientsList: [],
      selectedClient: "",
      clientStatus: "All",
    };

    this.props.onGetTeamMember();
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.saleHistoryRes !== this.props.saleHistoryRes) {
      totalInvoiced = 0;
      totalReceived = 0;
      if (this.props.saleHistoryRes)
        for (var i = 0; i < this.props.saleHistoryRes.list.length; i++) {
          totalInvoiced += parseInt(this.props.saleHistoryRes.list[i].total);
          totalReceived += parseInt(this.props.saleHistoryRes.list[i].received);
        }
    }

    if (
      PrevProps.processingPersonReportRes !==
      this.props.processingPersonReportRes
    ) {
      totalInvoiced = 0;
      totalReceived = 0;
      if (this.props.processingPersonReportRes)
        for (
          var i = 0;
          i < this.props.processingPersonReportRes.list.length;
          i++
        ) {
          totalInvoiced += parseInt(
            this.props.processingPersonReportRes.list[i].total
          );
          totalReceived += parseInt(
            this.props.processingPersonReportRes.list[i].received
          );
        }
    }
  }

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeMainTab = (value) => {
    this.setState({ activeMainTab: value });
  };

  onChangeTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (value) => {
    this.setState({ processingPerson: value });
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onChangeDateFrom = (date, dateString) => {
    this.setState({ dateFrom: date });
  };

  onChangeDateTo = (date, dateString) => {
    this.setState({ dateTo: date });
  };

  onSaleHistoryReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    // if (processingIds === "") {
    //   this.setState({ loadReports: false });
    //   message.warning("Please select atleast one processing person!");
    //   return;
    // }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onSaleHistoryReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("SALES HISTORY");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onClientSourceReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onClientSourceReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("CLIENT SOURCE");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onClientProcessingPerson = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onProcessingPersonReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("PROCESSING PERSONS");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onTaskReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onTaskReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("TASK PERFORMANCE");
      })
      .catch((err) => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onBirthdayReport = () => {
    var processingIds = "";
    if (!this.state.dateTo || !this.state.dateFrom) {
      message.warning("Please date ranges for birthday reports!");
      return;
    }
    this.setState({ loadReports: true });

    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onBirthdayReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("BIRTHDAY REPORT");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onFileNotesReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onFileNotesReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("FILE NOTES");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onActiveCasesReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onActiveCasesReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("ACTIVE CASES");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onVisaExpiryReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onVisaExpiryReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("VISA EXPIRING");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onClientEmployerReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props
      .onClientEmployerReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("CLIENT EMPLOYERS");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onclickDocChecklist = () => {
    this.setState({ showClientField: true });
    this.onDocChecklistReport();
  };

  onclickServiceMngmnt = () => {
    this.onServiceMngmntReport();
  };

  onSearchClient = (value) => {
    searchClient = value.trimStart();
    if (value.length > 1 && notSearch) {
      notSearch = false;
      setTimeout(() => {
        this.props
          .onSearchClient(searchClient)
          .then((res) => {
            notSearch = true;
            let clients = [];
            res.payload.clients.map((client, ind) => {
              clients.push(
                <Option
                  value={client.id}
                >{`${client.firstName} ${client.lastName}`}</Option>
              );
            });
            this.setState({ clientsList: clients });
            // var el1 = document.querySelector("el1");
          })
          .catch((err) => {
            notSearch = true;
          });
      }, 1500);
    }
  };

  onSelectClient = (value) => {
    this.setState({ selectedClient: value });
  };

  onDocChecklistReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
      subjectId:
        this.state.selectedClient || "00000000-0000-0000-0000-000000000000",
      clientStatus: this.state.clientStatus,
    };
    this.props
      .onDocChecklistReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("DOCUMENT CHECKLIST");
      })
      .catch(() => {
        this.setState({ loadReports: false });
        message.error("Failed!");
      });
  };

  onServiceMngmntReport = (paginate) => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      signedStartDate: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      signedEndDate: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: (paginate && paginate.pageSize) || this.state.pageSize,
      pageNumber: (paginate && paginate.pageNumber) || this.state.pageNumber,
    };
    this.props
      .onServMngmntReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("SERVICE AGREEMENT");
      })
      .catch((err) => {
        this.setState({ loadReports: false });

        if (
          err.payload.response &&
          err.payload.response.data &&
          err.payload.response.data.status !== 404
        ) {
          message.error(
            err.payload.response &&
              err.payload.response.data &&
              err.payload.response.data.title
          );
        }
      });
  };

  onFileNoteReport = (paginate) => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: (paginate && paginate.pageSize) || this.state.pageSize,
      pageNumber: (paginate && paginate.pageNumber) || this.state.pageNumber,
    };
    this.props
      .onGetFileNotesReport(data)
      .then(() => {
        this.setState({ loadReports: false });
        this.onChangeTab("FILE NOTES");
      })
      .catch((err) => {
        this.setState({ loadReports: false });

        if (
          err.payload.response &&
          err.payload.response.data &&
          err.payload.response.data.status !== 404
        ) {
          message.error(
            err.payload.response &&
              err.payload.response.data &&
              err.payload.response.data.title
          );
        }
      });
  };

  print = () => {
    window.print();
  };
  render() {
    const {
      dateFrom,
      dateTo,
      processingPerson,
      activeTab,
      loadReports,
      clientStatus,
      selectedClient,
    } = this.state;
    const {
      teamMembers,
      saleHistoryRes,
      clientSourceReportRes,
      processingPersonReportRes,
      taskReportRes,
      onSaleHistoryReport,
      onClientSourceReport,
      onProcessingPersonReport,
      onTaskReport,
      onBirthdayReport,
      birthdayReportRes,
      onFileNotesReport,
      fileNotesReportRes,
      onActiveCasesReport,
      activeCasesReportRes,
      onVisaExpiryReport,
      visaExpiryReportRes,
      onClientEmployerReport,
      clientEmployerReportRes,
      docChecklistReportRes,
      onDocChecklistReport,
      servMngmntReportRes,
      onServMngmntReport,
      onServMngmntReportExport,
      getFileNotesReportRes,
      onGetFileNotesReport,
    } = this.props;
    return (
      <div>
        <Spin spinning={loadReports}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs data={headOption} activeTab="Reports" />
              <div className="report-container">
                <div>
                  <div>
                    <div className="pciq-top-div">
                      <span
                        className="pc-top-div-text"
                        style={{ color: "#0A3C5D" }}
                      >
                        Report Filter
                      </span>
                    </div>

                    <div className="ca-gray-cont" style={{ border: 0 }}>
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: 20,
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            width: "31%",
                            margin: 5,
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              onChange={this.onChangeDateFrom}
                              value={dateFrom}
                              format={dateFormat}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            margin: 5,
                            display: "inline-block",
                            width: "31%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              onChange={this.onChangeDateTo}
                              value={dateTo}
                              format={dateFormat}
                            />
                          </div>
                        </div>

                        <div
                          className="pc-select-width multi-select-option"
                          style={{
                            margin: 5,
                            marginLeft: 0,
                            width: "31%",
                            display: "inline-block",
                          }}
                        >
                          <div>
                            <Select
                              mode="multiple"
                              placeholder="Please select"
                              value={processingPerson}
                              onChange={this.handleChange}
                              style={{ width: "100%", margin: 5 }}
                            >
                              {teamMembers &&
                                teamMembers.users.map((user, userInd) => (
                                  <Option value={user.id}>
                                    {user.fullName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>

                        {this.state.showClientField && (
                          <div style={{ display: "flex" }}>
                            <div
                              className="pc-select-width multi-select-option"
                              style={{
                                margin: 5,
                                marginTop: 10,
                                width: "31%",
                                display: "inline-block",
                              }}
                            >
                              <div>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  placeholder="Select Client"
                                  value={selectedClient}
                                  onSearch={this.onSearchClient}
                                  onChange={this.onSelectClient}
                                  style={{ width: "100%" }}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="">Search Client</Option>
                                  {this.state.clientsList}
                                </Select>
                              </div>
                            </div>
                            <div
                              className="pc-select-width multi-select-option"
                              style={{
                                margin: 5,
                                marginTop: 10,
                                width: "31%",
                                display: "inline-block",
                              }}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                defaultValue={"All"}
                                value={clientStatus}
                                onChange={(value) =>
                                  this.setState({ clientStatus: value })
                                }
                              >
                                <Select.Option value="All">All</Select.Option>
                                <Select.Option value={"true"}>
                                  Active
                                </Select.Option>
                                <Select.Option value={"false"}>
                                  Inactive
                                </Select.Option>
                              </Select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="report-btn-tab-row">
                      {/* <div
                        className={
                          activeTab === "SALES HISTORY"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onSaleHistoryReport();
                        }}
                      >
                        <span className="pc-btn-text">SALES HISTORY</span>
                      </div> */}

                      {/* <div
                        className={
                          activeTab === "CLIENT SOURCE"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onClientSourceReport();
                        }}
                      >
                        <span className="pc-btn-text">CLIENT SOURCE</span>
                      </div> */}

                      {/* <div
                        className={
                          activeTab === "PROCESSING PERSONS"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onClientProcessingPerson();
                        }}
                      >
                        <span className="pc-btn-text">PROCESSING PERSONS</span>
                      </div> */}

                      {/* <div
                        className={
                          activeTab === "BIRTHDAY REPORT"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onBirthdayReport();
                        }}
                      >
                        <span className="pc-btn-text">BIRTHDAY REPORT</span>
                      </div> */}

                      {/* <div
                        className={
                          activeTab === "TASK PERFORMANCE"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onTaskReport();
                        }}
                      >
                        <span className="pc-btn-text">TASK PERFORMANCE</span>
                      </div> */}

                      {/* <div
                        className={
                          activeTab === "FILE NOTES"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onFileNotesReport();
                        }}
                      >
                        <span className="pc-btn-text">FILE NOTES</span>
                      </div> */}
                      {/* <div
                        className={
                          activeTab === "ACTIVE CASES"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onActiveCasesReport();
                        }}
                      >
                        <span className="pc-btn-text">ACTIVE CASES</span>
                      </div> */}

                      <div
                        className={
                          activeTab === "VISA EXPIRING"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onVisaExpiryReport();
                          this.setState({ showClientField: false });
                        }}
                      >
                        <span className="pc-btn-text">CURRENT VISA EXPIRY</span>
                      </div>

                      <div
                        className={
                          activeTab === "CLIENT EMPLOYERS"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onClientEmployerReport();
                          this.setState({ showClientField: false });
                        }}
                      >
                        <span className="pc-btn-text">CLIENT EMPLOYERS</span>
                      </div>
                      <div
                        className={
                          activeTab === "DOCUMENT CHECKLIST"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onclickDocChecklist();
                        }}
                      >
                        <span className="pc-btn-text">DOCUMENT CHECKLIST</span>
                      </div>
                      <div
                        className={
                          activeTab === "SERVICE AGREEMENT"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onclickServiceMngmnt();
                        }}
                      >
                        <span className="pc-btn-text">SERVICE AGREEMENT</span>
                      </div>
                      <div
                        className={
                          activeTab === "FILE NOTES"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={() => {
                          this.onFileNoteReport();
                        }}
                      >
                        <span className="pc-btn-text">FILE NOTES</span>
                      </div>
                    </div>

                    {/* ===== Design from Sales history report Screen ===== */}

                    {true && (
                      <div>
                        {activeTab === "SALES HISTORY" && (
                          <SaleHistoryReport
                            saleHistoryRes={
                              saleHistoryRes && saleHistoryRes.list
                            }
                            reportsCount={
                              saleHistoryRes && saleHistoryRes.count
                            }
                            displayText={"Sale History Report"}
                            totalInvoiced={totalInvoiced}
                            totalReceived={totalReceived}
                            onSaleHistoryReport={onSaleHistoryReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "CLIENT SOURCE" && (
                          <div>
                            <ComponentToPrint
                              clientSourceReportRes={
                                clientSourceReportRes &&
                                clientSourceReportRes.list
                              }
                              displayText={"Client Source Report"}
                              reportsCount={
                                clientSourceReportRes &&
                                clientSourceReportRes.count
                              }
                              onClientSourceReport={onClientSourceReport}
                              requestData={this.state}
                            />
                          </div>
                        )}

                        {activeTab === "PROCESSING PERSONS" && (
                          <ProcessingPersonReport
                            processingPersonReportRes={
                              processingPersonReportRes &&
                              processingPersonReportRes.list
                            }
                            displayText={"Processing Person Report"}
                            totalInvoiced={totalInvoiced}
                            totalReceived={totalReceived}
                            reportsCount={
                              processingPersonReportRes &&
                              processingPersonReportRes.count
                            }
                            onProcessingPersonReport={onProcessingPersonReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "BIRTHDAY REPORT" && (
                          <BirthdayReport
                            birthdayReportRes={
                              birthdayReportRes && birthdayReportRes.list
                            }
                            displayText={"Birthday Report"}
                            totalInvoiced={totalInvoiced}
                            totalReceived={totalReceived}
                            reportsCount={
                              birthdayReportRes && birthdayReportRes.count
                            }
                            onBirthdayReport={onBirthdayReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "TASK PERFORMANCE" && (
                          <TaskPerformanceReport
                            taskReportRes={taskReportRes && taskReportRes.list}
                            displayText={"Task Performance"}
                            reportsCount={taskReportRes && taskReportRes.count}
                            onTaskReport={onTaskReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "FILE NOTES" && (
                          <FileNotesReport
                            fileNotesReportRes={
                              getFileNotesReportRes &&
                              getFileNotesReportRes.items
                            }
                            displayText={"File Notes"}
                            reportsCount={
                              getFileNotesReportRes &&
                              getFileNotesReportRes.count
                            }
                            onFileNotesReport={onGetFileNotesReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "VISA EXPIRING" && (
                          <VisaExpiryReport
                            fileNotesReportRes={
                              visaExpiryReportRes && visaExpiryReportRes.list
                            }
                            displayText={"Visa Expiry Report"}
                            reportsCount={
                              visaExpiryReportRes && visaExpiryReportRes.count
                            }
                            onFileNotesReport={onVisaExpiryReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "ACTIVE CASES" && (
                          <ActiveCasesReport
                            fileNotesReportRes={
                              activeCasesReportRes && activeCasesReportRes.list
                            }
                            displayText={"Active Cases Report"}
                            reportsCount={
                              activeCasesReportRes && activeCasesReportRes.count
                            }
                            onFileNotesReport={onActiveCasesReport}
                            requestData={this.state}
                          />
                        )}

                        {activeTab === "CLIENT EMPLOYERS" && (
                          <ClientEmployerReport
                            clientEmployerReportRes={
                              clientEmployerReportRes &&
                              clientEmployerReportRes.list
                            }
                            displayText={"Client Employer Report"}
                            reportsCount={
                              clientEmployerReportRes &&
                              clientEmployerReportRes.count
                            }
                            onBirthdayReport={onClientEmployerReport}
                            requestData={this.state}
                          />
                        )}
                        {activeTab === "DOCUMENT CHECKLIST" && (
                          <DocChecklistReport
                            clientEmployerReportRes={
                              docChecklistReportRes &&
                              docChecklistReportRes.list
                            }
                            displayText={"Client Employer Report"}
                            reportsCount={
                              docChecklistReportRes &&
                              docChecklistReportRes.count
                            }
                            onDocChecklistReport={onDocChecklistReport}
                            requestData={this.state}
                          />
                        )}
                        {activeTab === "SERVICE AGREEMENT" && (
                          <ServiceMngmntReport
                            clientEmployerReportRes={
                              servMngmntReportRes && servMngmntReportRes.items
                            }
                            displayText={"Service Agreement Report"}
                            reportsCount={
                              servMngmntReportRes && servMngmntReportRes.count
                            }
                            onBirthdayReport={onServMngmntReport}
                            requestData={this.state}
                            onServMngmntReportExport={onServMngmntReportExport}
                          />
                        )}

                        <div className="report-total-main-cont">
                          <div />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Reports;
