// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { apiRefresh } from "../../services/api";
import history from "../../services/history";

function PersonalInformation(Props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(
    Props.clientInfo && Props.clientInfo.notes
  );
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");
  const [coutriesFiltered, setCountriesFiltered] = useState([]);
  const [jobSectorArray, setJobSectoreArray] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const { TextArea } = Input;

  const [selectedOption, setSelectedOption] = useState("");
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  var email = "";
  const yearsDiff = (date) => {
    let date1 = new Date();
    let date2 = new Date(date);

    var ends = moment(date1);
    var starts = moment(date2);
    var years = ends.diff(starts, "year");
    starts.add(years, "years");
    var months = ends.diff(starts, "months");
    starts.add(months, "months");
    var days = ends.diff(starts, "days");
    let yearsDiff =
      years + " years, " + months + " months and " + days + " days";
    return yearsDiff;
  };

  useEffect(() => {
    if (Props.clientInfo) {
      setCertificates(Props.clientInfo.certificates);
      setAuthorities(Props.clientInfo.authorities);
      setVisaDenied(Props.clientInfo.visaDenied);
      setContent(Props.clientInfo.notes);
      var ageByDate = 0;
      if (Props.clientInfo.dateOfBirth !== "1900-01-01T00:00:00+00:00")
        ageByDate = yearsDiff(Props.clientInfo.dateOfBirth);

      var secondaryEmail = "";
      var otherEmail = "";
      if (Props.clientInfo.emails.length > 0) {
        for (var i = 0; i < Props.clientInfo.emails.length; i++) {
          if (Props.clientInfo.emails[i].emailTypeId === 1) {
            email = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 2) {
            secondaryEmail = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 3) {
            otherEmail = Props.clientInfo.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (Props.clientInfo.addresses.length > 0) {
        const findBillAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 5
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }

        const findAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (Props.clientInfo.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: Props.clientInfo.medicals[0].medicalIssueDate,
          medicalExpiryDate: Props.clientInfo.medicals[0].medicalExpiryDate,
          medicalGrading: Props.clientInfo.medicals[0].medicalGrading,
          xrayIssueDate: Props.clientInfo.medicals[0].xrayIssueDate,
          xrayExpiryDate: Props.clientInfo.medicals[0].xrayExpiryDate,
          xrayGrading: Props.clientInfo.medicals[0].xrayGrading,
          medicalNotes: Props.clientInfo.medicals[0].medicalNotes,
          medicalNotesDetail: Props.clientInfo.medicals[0].medicalNotesDetail,
        };
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
        countryCode: 0,
      };
      if (Props.clientInfo.phones.length > 0) {
        const findMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
          phoneData.countryCodeId =
            findMobile.countryCodeId !== 0 ? findMobile.countryCodeId : "";
        }
        const findSecondaryMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }

      var interestedVisaValue = "";

      if (Props.clientInfo.interestedVisa) {
        if (Props.clientInfo.interestedVisa != 0) {
          interestedVisaValue = Props.clientInfo.interestedVisa.toString();
        }
      } else if (Props.clientInfo.intrestedvisa) {
        if (Props.clientInfo.intrestedvisa != 0) {
          interestedVisaValue = Props.clientInfo.intrestedvisa.toString();
        }
      }
      form.setFieldsValue({
        visaCountryId:
          Props.clientInfo.visaCountryId === 0
            ? ""
            : Props.clientInfo.visaCountryId,
        visaCountryType:
          Props.clientInfo.visaCountyType === 0
            ? ""
            : Props.clientInfo.visaCountyType.toString(),
        title: Props.clientInfo.title.trim(),
        middleName: Props.clientInfo.middleName,
        gender:
          Props.clientInfo.gender === 0
            ? ""
            : Props.clientInfo.gender.toString(),
        dateOfBirth:
          Props.clientInfo.dateOfBirth === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.dateOfBirth),
        address: clientAddressData ? clientAddressData.address : "",
        nationalityId: Props.clientInfo.nationalityId,
        saleDate:
          Props.clientInfo.saleDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.saleDate),
        sourceId:
          Props.clientInfo.sourceId === 0
            ? ""
            : Props.clientInfo.sourceId.toString(),
        jobSectorId:
          Props.clientInfo.jobSectorId === 0
            ? ""
            : Props.clientInfo.jobSectorId.toString(),
        companyOptional: Props.clientInfo.companyOptional.trim(),
        clientSerial: Props.clientInfo.clientSerial.trim(),
        nationalityCountry: Props.clientInfo.nationalityCountry
          ? parseInt(Props.clientInfo.nationalityCountry)
          : "",
        firstName: Props.clientInfo.firstName.trim(),
        lastName: Props.clientInfo.lastName.trim(),
        age: ageByDate ? ageByDate : Props.clientInfo.age,
        dealWorth: Props.clientInfo.dealWorth.trim(),
        maritalStatus: Props.clientInfo.maritalStatus,
        dependentChildren:
          Props.clientInfo.dependentChildren === 0
            ? ""
            : Props.clientInfo.dependentChildren,
        sourceDescription: Props.clientInfo.sourceDescription.trim(),
        occupation: Props.clientInfo.occupation.trim(),
        visaDenied: Props.clientInfo.visaDenied,
        deniedText: Props.clientInfo.deniedText.trim(),
        visaText: Props.clientInfo.visaText.trim(),
        currentVisaTypeId: Props.clientInfo.currentVisaTypeId.toString(),
        currentNewZealandVisaExpiry:
          Props.clientInfo.currentNewZealandVisaExpiry ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.currentNewZealandVisaExpiry),
        travelConditionsValidTo:
          Props.clientInfo.travelConditionsValidTo ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.travelConditionsValidTo),
        clientNumber: Props.clientInfo.clientNumber,
        inzUserName: Props.clientInfo.inzUserName,
        inzPassword: Props.clientInfo.inzPassword,
        inzFeeDate:
          Props.clientInfo.inzFeeDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.inzFeeDate),
        interestedVisa: interestedVisaValue,
        nzqaOnlineSubDate:
          Props.clientInfo.nzqaOnlineSubDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.nzqaOnlineSubDate),
        nzqaDocumentSubDate:
          Props.clientInfo.nzqaDocumentSubDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.nzqaDocumentSubDate),
        nzqaDocumentRetDate:
          Props.clientInfo.nzqaDocumentRetDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(Props.clientInfo.nzqaDocumentRetDate),
        email: email.trim(),
        secondaryEmail: secondaryEmail.trim(),
        otherEmail: otherEmail.trim(),
        contactPerson: billAddressData
          ? billAddressData.contactPerson.trim()
          : "",
        flat: billAddressData ? billAddressData.flat.trim() : "",
        streetNumber: billAddressData ? billAddressData.streetNumber : "",
        suburb: billAddressData ? billAddressData.suburb : "",
        city: billAddressData ? billAddressData.city : "",
        billCountry: billAddressData ? billAddressData.country : "",
        zipcode: billAddressData ? billAddressData.zipcode : "",
        clientAddress: clientAddressData ? clientAddressData.address : "",
        mobilePhone: phoneData ? phoneData.mobile.trim() : "",
        countryCodeId: phoneData ? phoneData.countryCodeId : "",
        secondaryMobile: phoneData ? phoneData.secondaryMobile.trim() : "",
        overseasMobile: phoneData ? phoneData.overseasMobile.trim() : "",
        landLine: phoneData ? phoneData.landLine.trim() : "",
        otherMobile: phoneData ? phoneData.otherMobile.trim() : "",
        medicalIssueDate:
          medicalData &&
          medicalData.medicalIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.medicalIssueDate)
            : "",
        medicalExpiryDate:
          medicalData &&
          medicalData.medicalExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.medicalExpiryDate)
            : "",
        medicalGrading: medicalData ? medicalData.medicalGrading : "",
        xrayIssueDate:
          medicalData &&
          medicalData.xrayIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.xrayIssueDate)
            : "",
        xrayExpiryDate:
          medicalData &&
          medicalData.xrayExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.xrayExpiryDate)
            : "",
        xrayGrading: medicalData ? medicalData.xrayGrading : "",
        medicalNotes: medicalData ? medicalData.medicalNotes : "",
        medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
        passportNo:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportNo
            : "",
        passportCountry:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportCountry.toString()
            : "",
        passportIssueDate:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passports[0].passportIssueDate !==
            "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[0].passportIssueDate)
            : "",
        passportExpiryDate:
          Props.clientInfo.passports.length > 0 &&
          Props.clientInfo.passportExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[0].passportExpiryDate)
            : "",
        secondPassportNo:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportNo
            : "",
        secondPassportCountry:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportCountry.toString()
            : "",
        secondPassportIssueDate:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passportIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[1].passportIssueDate)
            : "",
        secondPassportExpiryDate:
          Props.clientInfo.passports.length > 1 &&
          Props.clientInfo.passportExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(Props.clientInfo.passports[1].passportExpiryDate)
            : "",
        agentId:
          Props.users &&
          Props.users.length > 0 &&
          Props.clientInfo.agentId !== "00000000-0000-0000-0000-000000000000"
            ? Props.clientInfo.agentId
            : "",
        memberType:
          Props.clientInfo.memberType.toLowerCase() == "familymember"
            ? "Family Member"
            : Props.clientInfo.memberType,
        agentUserId:
          Props.clientInfo.agentUserId &&
          Props.clientInfo.agentUserId !==
            "00000000-0000-0000-0000-000000000000"
            ? Props.clientInfo.agentUserId
            : "",
      });
    }
  }, [Props, form]);

  useEffect(() => {
    let jobSectors = [];
    let values = [
      {
        label: "Administrative",
        id: 1,
      },
      {
        label: "Agriculture / Farming",
        id: 2,
      },
      {
        label: "Automotive",
        id: 3,
      },
      {
        label: "Construction",
        id: 4,
      },
      {
        label: "Electrical",
        id: 5,
      },
      {
        label: "Engineer",
        id: 6,
      },
      {
        label: "Finance",
        id: 7,
      },
      {
        label: "FMCG",
        id: 8,
      },
      {
        label: "Hospitality",
        id: 9,
      },
      {
        label: "Human Resources",
        id: 10,
      },
      {
        label: "Insurance",
        id: 11,
      },
      {
        label: "Legal",
        id: 12,
      },
      {
        label: "Marketing",
        id: 13,
      },
      {
        label: "Medical",
        id: 14,
      },
      {
        label: "Real estate",
        id: 15,
      },
      {
        label: "Retail",
        id: 16,
      },
      {
        label: "Sales",
        id: 17,
      },
      {
        label: "Supply chain",
        id: 18,
      },
      {
        label: "Teachers",
        id: 19,
      },
      {
        label: "Trades",
        id: 20,
      },
      {
        label: "Not Employed",
        id: 21,
      },
    ];
    for (var i = 0; i < values.length; i++) {
      jobSectors.push(<Option key={values[i].id}>{values[i].label}</Option>);
    }
    setJobSectoreArray(jobSectors);
  }, []);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  const country = [];
  const authorityOption = [];
  // const visaTypeOption = [];
  if (Props.countriesData && Props.countriesData.items.length > 0) {
    for (let i = 0; i < Props.countriesData.items.length; i++) {
      country.push(
        <Option key={Props.countriesData.items[i].id}>
          {Props.countriesData.items[i].name}
        </Option>
      );
    }
  }

  if (Props.accessingAuthData && Props.accessingAuthData.length > 0) {
    for (let i = 0; i < Props.accessingAuthData.length; i++) {
      authorityOption.push(
        <Option key={Props.accessingAuthData[i].id}>
          {Props.accessingAuthData[i].name}
        </Option>
      );
    }
  }

  useEffect(() => {
    let visaTypeOption = [];
    if (Props.visaTypeData && Props.visaTypeData.items.length > 0) {
      if (
        Props.clientInfo.interestedVisa != "" &&
        Props.clientInfo.interestedVisa !== undefined
      ) {
        var findVisa = Props.visaTypeData.items.find(
          (obj) => obj.id === Props.clientInfo.interestedVisa
        );

        if (!findVisa) {
          const branchUserOpt = {
            url: `v1/company/BranchVisaType/${Props.clientInfo.interestedVisa}`,
          };
          branchUserOpt.types = [
            "GET_VISA_TYPE_SUCCESS",
            "GET_VISA_TYPE_FAILURE",
          ];
          apiRefresh.get(branchUserOpt).then((res) => {
            visaTypeOption = [];
            visaTypeOption.push(
              <Option key={res.id}>{res.visaTypeName}</Option>
            );
            for (let i = 0; i < Props.visaTypeData.items.length; i++) {
              visaTypeOption.push(
                <Option key={Props.visaTypeData.items[i].id}>
                  {Props.visaTypeData.items[i].visaTypeName}
                </Option>
              );
            }
            setVisaTypes(visaTypeOption);
          });
        } else {
          visaTypeOption = [];
          for (let i = 0; i < Props.visaTypeData.items.length; i++) {
            visaTypeOption.push(
              <Option key={Props.visaTypeData.items[i].id}>
                {Props.visaTypeData.items[i].visaTypeName}
              </Option>
            );
          }
        }
      } else {
        for (let i = 0; i < Props.visaTypeData.items.length; i++) {
          visaTypeOption.push(
            <Option key={Props.visaTypeData.items[i].id}>
              {Props.visaTypeData.items[i].visaTypeName}
            </Option>
          );
        }
      }
    }
    setVisaTypes(visaTypeOption);
  }, [Props.visaTypeData]);

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const handleChangeCertificateCountry = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const handleChangePassportCountry = (passportCountry) => {
    // setPassportCountry(passportCountry);
  };

  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const onChangeCertificateIssueDate = (value, dateString) => {
    setCertificateIssueDate(value);
  };

  const onChangeCertificateExpiryDate = (value, dateString) => {
    setCertificateExpiryDate(value);
  };

  const onChangeAuthSubDate = (value, dateString) => {
    setAuthSubDate(value);
  };

  const onChangeAuthRetDate = (value, dateString) => {
    setAuthRetDate(value);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const changeDeclineValue = (value) => {
    console.log("onOk: ", value);
    setVisaDenied(value);
  };

  const onOkCertificateIssueDate = (value) => {
    console.log("onOk: ", value);
  };

  const addCertificate = () => {
    let data = {
      clientId: Props.clientInfo.id,
      issueDate: certificateIssueDate,
      certificateExpiryDate: certificateExpiryDate,
      country: parseInt(selectedCountry),
    };

    Props.onAddCertificate(data);
  };

  const onContentChange = (model) => {
    setContent(model);
  };

  const onUpdatePersonalInfo = (values) => {
    Props.updatePersonalInfo(values, content, Props.clientInfo);
  };

  let config = {
    key:
      "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
    height: "auto",
    toolbarSticky: false,
    events: {
      "charCounter.update": () => {
        // Do something here.
        // this is the editor instance.
        console.log("char");
      },
    },
  };

  return (
    <div>
      <Form form={form} onFinish={onUpdatePersonalInfo}>
        <div style={{ paddingTop: 10, paddingLeft: 15 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: 30,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="profile-down-arrow-cont" style={{ marginTop: 5 }}>
                <img
                  src={Images.whiteArrow}
                  className="profile-down-arrow-icon"
                />
              </div>
              <p className="label">Personal Information</p>
            </div>
            <Form.Item>
              <Button className="button-blue" htmlType="submit">
                <span style={{ color: "#FFFFFF" }}>Update</span>
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="form-container">
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div
              style={{
                width: "100%",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "49%" }}>
                  <div>
                    <p>Visa Country</p>
                    <Form.Item name="visaCountryId">
                      <Select
                        // mode="multiple"
                        showSearch
                        optionFilterProp="children"
                      >
                        {Props.brnchVisaCountriesData &&
                          Props.brnchVisaCountriesData.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return (
                              <Option value={data.countryId}>
                                {data.countryName}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Type</p>
                    <Form.Item name="visaCountryType">
                      <Select showSearch optionFilterProp="children">
                        <Option value="1">Student</Option>
                        <Option value="2">Visa </Option>
                        <Option value="3">Unsuccessful</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Title</p>
                    <div className="profile-input-border">
                      <Form.Item name="title">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Middle Name</p>
                    <div className="profile-input-border">
                      <Form.Item name="middleName">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Gender</p>
                    <Form.Item name="gender">
                      <Select showSearch optionFilterProp="children">
                        <Option value="1">Male</Option>
                        <Option value="2">Female</Option>
                        <Option value="3">Gender Diverse</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Secondary Email</p>
                    <div>
                      <Form.Item
                        name="secondaryEmail"
                        rules={[
                          {
                            type: "email",
                            message: "Invalid!",
                          },
                        ]}
                      >
                        <Input className="profile-input profile-input-border" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Date Of Birth</p>
                    <Form.Item name="dateOfBirth">
                      <DatePicker
                        style={{ width: "100%" }}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <p>Address</p>
                    <div className="profile-input-border">
                      <Form.Item name="address">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Mobile Phone</p>
                    <div>
                      {/* <Form.Item name="mobilePhone"> */}
                      {/* <Input className="profile-input" /> */}
                      <div className="form-mobile-number">
                        <Form.Item
                          className={"add-client-form-inputs"}
                          name="countryCodeId"
                          style={{ marginBottom: 0 }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder={"Select"}
                            style={{
                              width: "150px",
                              minWidth: "150px",
                              marginTop: 12,
                            }}
                            dropdownClassName="dropdown-options-width"
                          >
                            {Props.countriesData &&
                              Props.countriesData.items
                                .filter(
                                  (obj) =>
                                    obj.name.toLowerCase() === "new zealand" ||
                                    obj.name.toLowerCase() === "australia" ||
                                    obj.name.toLowerCase() === "canada"
                                )
                                .map((data) => {
                                  // eslint-disable-next-line react/jsx-no-undef
                                  return (
                                    <Option
                                      value={data.id}
                                      style={{ fontSize: "14px" }}
                                    >
                                      +{data.callingCode} - {data.name}
                                    </Option>
                                  );
                                })}

                            {Props.countriesData &&
                              Props.countriesData.items.map((data, index) => {
                                // eslint-disable-next-line react/jsx-no-undef
                                if (
                                  data.name.toLowerCase() === "new zealand" ||
                                  data.name.toLowerCase() === "australia" ||
                                  data.name.toLowerCase() === "canada"
                                ) {
                                } else {
                                  return (
                                    <Option
                                      value={data.id}
                                      style={{ fontSize: "14px" }}
                                    >
                                      +{data.callingCode} - {data.name}
                                    </Option>
                                  );
                                }
                              })}
                          </Select>
                        </Form.Item>
                        <div style={{ width: "100%" }}>
                          <Form.Item
                            className={
                              ("add-client-form-inputs",
                              "mobile-field-margin-left")
                            }
                            name="mobilePhone"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (Math.ceil(Math.log10(value + 1)) > 15) {
                                    return Promise.reject(
                                      "Character limit exceeded"
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              }),
                            ]}
                          >
                            <InputNumber
                            // style={{ width: "288px" }}
                            // onChange={this.onHandleChangeInputNumber}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* </Form.Item> */}
                    </div>
                  </div>
                  <div>
                    <p>Overseas Mobile #</p>
                    <div className="profile-input-border">
                      <Form.Item name="overseasMobile">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Other Contact Information</p>
                    <div className="profile-input-border">
                      <Form.Item name="otherMobile">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Interested Visa</p>
                    <Form.Item name="interestedVisa">
                      <Select showSearch optionFilterProp="children">
                        {visaTypes}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>National ID</p>
                    <div className="profile-input-border">
                      <Form.Item name="nationalityId">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Sale Date</p>
                    <Form.Item name="saleDate">
                      <DatePicker
                        style={{ width: "100%" }}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <p>Add Agent</p>
                    <Form.Item name="agentUserId">
                      <Select showSearch optionFilterProp="children">
                        {Props.agentsRes &&
                          Props.agentsRes.users &&
                          Props.agentsRes.users.map((listItem) => (
                            <Option key={listItem.id}>
                              {listItem.fullName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Client Source</p>
                    <Form.Item name="sourceId">
                      <Select showSearch optionFilterProp="children">
                        {Props.clientSourceListing &&
                          Props.clientSourceListing.map((listItem) => (
                            <Option key={listItem.id}>{listItem.name}</Option>
                          ))}
                        {/* <Option value="1">Google or other search</Option>
                        <Option value="2">Word of mouth</Option>
                        <Option value="3">Press</Option>
                        <Option value="4">Advertisement</Option>
                        <Option value="5">Article or blog post</Option>
                        <Option value="6">Social media</Option>
                        <Option value="7">Referral</Option>
                        <Option value="8">Other</Option>
                        <Option value="9">Walk In</Option>
                        <Option value="10">Repeat Business</Option>
                        <Option value="11">Recruitment </Option>
                        <Option value="12">Website</Option>
                        <Option value="13">Web Inquiry</Option>
                        <Option value="14">
                          Direct phone call to Immigration Advisor
                        </Option>
                        <Option value="15">Another client</Option>
                        <Option value="16">Employer</Option>
                        <Option value="17">Professional Body</Option> */}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Job Sector</p>
                    <Form.Item name="jobSectorId">
                      <Select showSearch optionFilterProp="children">
                        {jobSectorArray}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Company (Optional)</p>
                    <div className="profile-input-border">
                      <Form.Item name="companyOptional">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div style={{ width: "49%" }}>
                  <>
                    <div style={{ marginTop: 8 }}>
                      <span
                        style={{
                          fontSize: 12,
                          color: "#556370",
                        }}
                      >
                        Client Reference Number (EZM ID){" "}
                      </span>
                    </div>
                    <div style={{ marginBottom: 16, height: 24 }}>
                      <span
                        style={{
                          fontSize: 12,
                          color: "#255C82",
                        }}
                      >
                        {Props.clientInfo
                          ? Props.clientInfo.clientNumberIZM
                          : " "}
                      </span>
                    </div>
                  </>

                  <div>
                    <p>Existing Client Serial Number / Internal ID</p>
                    <div className="profile-input-border">
                      <Form.Item name="clientSerial">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <p>Client First Name*</p>
                    <div>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Input className="profile-input profile-input-border" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Last Name*</p>
                    <div>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Input className="profile-input profile-input-border" />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <p>Primary Email</p>
                    <div
                      style={{
                        display: "flex",
                        height: 30,
                        paddingRight: 3,
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        name="email"
                        className="form-item-email"
                        rules={[
                          {
                            type: "email",
                            message: "Invalid!",
                          },
                        ]}
                      >
                        <Input className="profile-input" />
                      </Form.Item>
                      <div
                        onClick={() => {
                          Props.history.push("/client-email?para=sendEmail");
                        }}
                        className="profile-inner-emailicon-cont"
                        style={{ cursor: "pointer" }}
                      >
                        <img src={Images.emailWhite} className="svg-img" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Other Email</p>
                    <div>
                      <Form.Item
                        name="otherEmail"
                        rules={[
                          {
                            type: "email",
                            message: "Invalid!",
                          },
                        ]}
                      >
                        <Input className="profile-input profile-input-border" />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <p>Relationship</p>
                    <div className="profile-input-border">
                      <Form.Item name="memberType">
                        <Input disabled className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Age</p>
                    <div className="profile-input-border">
                      <Form.Item name="age">
                        <Input disabled className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Deal Worth</p>
                    <div className="profile-input-border">
                      <Form.Item name="dealWorth">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Secondary Mobile</p>
                    <div className="profile-input-border">
                      <Form.Item name="secondaryMobile">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Land Line</p>
                    <div className="profile-input-border">
                      <Form.Item name="landLine">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Marital Status</p>
                    <Form.Item name="maritalStatus">
                      <Select showSearch optionFilterProp="children">
                        <Option value="single">Single</Option>
                        <Option value="married">Married</Option>
                        <Option value="widowed">Widowed</Option>
                        <Option value="defacto">Defacto</Option>
                        <Option value="separated">Separated</Option>
                        <Option value="divorced">Divorced </Option>
                        <Option value="partner">Partner </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Number Of Dependent Children</p>
                    <div className="profile-input-border">
                      <Form.Item name="dependentChildren">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Nationality</p>
                    <div>
                      <Form.Item name="nationalityCountry">
                        <Select showSearch optionFilterProp="children">
                          {Props.countriesData &&
                            Props.countriesData.items
                              .filter(
                                (obj) =>
                                  obj.name.toLowerCase() === "new zealand" ||
                                  obj.name.toLowerCase() === "australia" ||
                                  obj.name.toLowerCase() === "canada"
                              )
                              .map((data) => {
                                // eslint-disable-next-line react/jsx-no-undef
                                return (
                                  <Option value={data.id}>{data.name}</Option>
                                );
                              })}

                          {Props.countriesData &&
                            Props.countriesData.items.map((data, index) => {
                              // eslint-disable-next-line react/jsx-no-undef
                              if (
                                data.name.toLowerCase() === "new zealand" ||
                                data.name.toLowerCase() === "australia" ||
                                data.name.toLowerCase() === "canada"
                              ) {
                              } else {
                                return (
                                  <Option value={data.id}>{data.name}</Option>
                                );
                              }
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Sales Person</p>
                    <Form.Item name="agentId">
                      <Select showSearch optionFilterProp="children">
                        {Props.users &&
                          Props.users.length > 0 &&
                          Props.users.map((person) => (
                            <Option key={person.id}>{person.fullName}</Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>Source Description</p>
                    <div className="profile-input-border">
                      <Form.Item name="sourceDescription">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <p>Occupation</p>
                    <div className="profile-input-border">
                      <Form.Item name="occupation">
                        <Input className="profile-input" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: 20, marginTop: 0 }}>
            <p>Notes</p>
            <Spin size={"large"} spinning={loading}>
              <FroalaEditorCom
                setLoading={(value) => setLoading(value)}
                model={content}
                onModelChange={onContentChange}
              />
            </Spin>
          </div>
        </div>

        <div
          className="denied-cont"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="denied-cont" style={{ padding: 0 }}>
            <div className="profile-down-arrow-cont">
              <img
                src={Images.whiteArrow}
                className="profile-down-arrow-icon"
              />
            </div>
            <span className="denied-text">
              Have You Ever Been Denied a Visa
            </span>
          </div>
        </div>
        <div className="form-container">
          <div
            style={{
              paddingTop: 10,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Form.Item name="visaDenied">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <span style={{ marginLeft: 0 }} className="detailed-text">
              Decline Detail
            </span>
            <div className="profile-input-border">
              <Form.Item name="deniedText">
                <TextArea rows={4} className="profile-input" />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>

      {/*<div className="denied-cont">
        <div className="profile-down-arrow-cont">
          <img
            src={Images.whiteArrow}
            className="profile-down-arrow-icon"
          />
        </div>
        <span className="denied-text">Applicants</span>
      </div>
      <div className="form-container">
        <div
          className="form-cont"
          style={{
            justifyContent: "space-between",
            paddingBottom: 0
          }}
        >
          <div className="input-cont-width">
            <p>Ref #</p>
            <Form.Item>
              <div className="profile-input-border">
                <input
                  className="profile-input"
                  placeholder=""
                  type="text"
                  onChange={e => console.log(e)}
                />
              </div>
            </Form.Item>
          </div>
          <div className="input-cont-width">
            <p>Authority</p>
            <Form.Item>
              <Select
                value={selectedOption}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
        </div>

        <div className="button-org-cont">
          <div className="button-org">
            <span style={{ color: "#FFFFFF" }}>ADD</span>
          </div>
        </div>

        <div
          className="visa-table"
          style={{ paddingBottom: 0, paddingTop: 10 }}
        >
          <div
            className="main-table-cont"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="visa-table-header">
              <div className="header-tab-10">
                <span className="authority-table-head-font">Sr #</span>
              </div>
              <div className="header-tab-40">
                <span className="authority-table-head-font">Name</span>
              </div>
              <div className="header-tab-20">
                <span className="authority-table-head-font">Visa</span>
              </div>
              <div className="header-tab-20">
                <span className="authority-table-head-font">
                  Actions
                </span>
              </div>
            </div>
            <div className="table-content">
              <div className="content-index-10">
                <span className="auth-text">111356</span>
              </div>
              <div className="content-index-40">
                <span className="auth-text">Student Visa - sec 61</span>
              </div>
              <div className="content-index-20">
                <span className="auth-text"></span>
              </div>
              <div className="content-index-15">
                <div className="remove-cont">
                  <img
                    src={Images.cgreenCross}
                    style={{ width: 7, height: 7 }}
                  />
                  <span className="remove-text">REMOVE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}

      {/*<div className="denied-cont">
        <div className="profile-down-arrow-cont">
          <img
            src={Images.whiteArrow}
            className="profile-down-arrow-icon"
          />
        </div>
        <span className="denied-text">Client Agreement</span>
      </div>
      <div className="form-container">
        <div
          className="form-cont"
          style={{
            paddingBottom: 0,
            justifyContent: "space-between"
          }}
        >
          <div className="input-cont-width">
            <p>Agreement</p>
            <Form.Item>
              <Select
                value={selectedOption}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div
              className="black-button"
              style={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <span
                className="black-button-text"
                style={{ fontSize: 8 }}
              >
                Ready Contract
              </span>
            </div>
            <div className="cross-cont" style={{ width: 70 }}>
              <img src={Images.btnImage} className="profile-btn-img" />
              <img src={Images.crossWhite} className="svg-btn-img" />
            </div>
          </div>
        </div>
        <div
          className="form-cont"
          style={{
            justifyContent: "space-between",
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <div className="input-cont-width">
            <p>Advisor</p>
            <Form.Item>
              <Select
                value={selectedOption}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div
            className="button-blue-cont"
            style={{ marginTop: 34, paddingRight: 0, width: 70 }}
          >
            <div className="button-blue">
              <span style={{ color: "#FFFFFF" }}>Save</span>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
}

export default PersonalInformation;
