import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientQuestionnaire from "../Clients/Questionnaire";

import {
  getAllQuestionnaire,
  createDynamicLink,
  getDynamicLink,
  getEmailTemplate,
  sendDynamicLinkEmail,
  setEmailTemplate,
  getCountries,
  getQuestionnaire,
  updateQuestionnaire,
  getMappingParent,
  getMappingChild,
  getQuestionnaireGroup,
  groupedQuestionnaire,
  getFilledQuestionnaire,
  getAllFilledQuestionnaire,
  mergeFromClient,
  updMergeFromClient,
  updMergeToClient,
  submitQuestionnaire,
  getDocumentDownload,
  addDraftClient,
  getEmailContent,
  getSignature,
  getVisaType,
  getClientProfile,
  setProfileData,
  getPartnerData,
  getClientFamily,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    questionnaireList: state.questionnaireReducer.questionnaireList,
    dynamicLinkSuccess: state.questionnaireReducer.dynamicLinkSuccess,
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
    emailTemplateData: state.questionnaireReducer.emailTemplateData,
    setEmailData: state.questionnaireReducer.setEmailData,
    clientTab: state.employerManagReducer.clientTab,
    countriesData: state.visaTypeReducer.countriesData,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    mappParentData: state.questionnaireReducer.mappParentData,
    mappChildData: state.questionnaireReducer.mappChildData,
    questionnaireGroupRes: state.questionnaireReducer.questionnaireGroupRes,
    groupedQuestionnaireRes: state.questionnaireReducer.groupedQuestionnaireRes,
    filledQuestionnaireRes: state.questionnaireReducer.filledQuestionnaireRes,
    allFilledQuestionnaireRes:
      state.questionnaireReducer.allFilledQuestionnaireRes,
    mergeFromClientRes: state.questionnaireReducer.mergeFromClientRes,
    updMergeFromClientRes: state.questionnaireReducer.updMergeFromClientRes,
    updMergeToClientRes: state.questionnaireReducer.updMergeToClientRes,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllQuestionnaire: bindActionCreators(getAllQuestionnaire, dispatch),
    onCreateDynamicLink: bindActionCreators(createDynamicLink, dispatch),
    onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
    onGetEmailTemplate: bindActionCreators(getEmailTemplate, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onSetEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
    onUpdateQuestionnaire: bindActionCreators(updateQuestionnaire, dispatch),
    onGetMappingParent: bindActionCreators(getMappingParent, dispatch),
    onGetMappingChild: bindActionCreators(getMappingChild, dispatch),
    onGetQuestionnaireGroup: bindActionCreators(
      getQuestionnaireGroup,
      dispatch
    ),
    onGetGroupedQuestionnaire: bindActionCreators(
      groupedQuestionnaire,
      dispatch
    ),
    onGetFilledQuestionnaire: bindActionCreators(
      getFilledQuestionnaire,
      dispatch
    ),
    onGetAllFilledQuestionnaire: bindActionCreators(
      getAllFilledQuestionnaire,
      dispatch
    ),
    onMergeFromClient: bindActionCreators(mergeFromClient, dispatch),
    onUpdMergeFromClient: bindActionCreators(updMergeFromClient, dispatch),
    onUpdMergeToClient: bindActionCreators(updMergeToClient, dispatch),
    onSubmitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onAddDraftClient: bindActionCreators(addDraftClient, dispatch),
    onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
    onGetSignature: bindActionCreators(getSignature, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientQuestionnaire);
